import { Row } from "@scaleaq/scaleui";
import { useUnleashClient, useUnleashContext } from "@unleash/proxy-client-react";
import { useSwaggerSwr } from "api/clients/genericApiClient";
import usersApiV2Client from "api/clients/usersApiV2Client";
import { CenteredLoading } from "components/scale/CenteredLoading";
import AppFrame from "components/skeleton/AppFrame";
import WorkersUI from "components/skeleton/WorkersUI";
import i18next from "i18next";
import { useAccessControl } from "providers/accessControlProvider";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { setTimeout } from "timers";
import { Languages } from "../../../i18n";

export type Props = {
  children?: React.ReactNode;
};

export const DefaultLayout = (props: Props) => {
  const { userAccess, userProfile } = useAccessControl();
  const userId = userProfile?.sub;
  const updateContext = useUnleashContext();
  const client = useUnleashClient();
  const { data: user } = useSwaggerSwr(usersApiV2Client.UsersApi.getV2UsersUserId, [
    {
      userId,
    },
  ]);

  const [clientReady, setClientReady] = useState(false);
  const [appBar, drawer] = AppFrame();
  const { hasScaleManagementPrivileges, accessIsLoading, accessIsError } = useAccessControl();

  useEffect(() => {
    if (!user) return;
    const supportedLanguages = Object.values(Languages);
    const prefereddedLocale = user.language?.split("-")[0];
    const supportedLocale = supportedLanguages.find((l) => l == prefereddedLocale) ?? Languages.English;
    i18next.changeLanguage(supportedLocale);
  }, [user]);

  client.on("ready", () => {
    setClientReady(true);
  });

  useEffect(() => {
    setTimeout(() => {
      //For some reason, it isn't enough to wait for clientReady. Adding a timeout to make sure the flags are refetched after context update.
      updateContext({ properties: { isScaleAdmin: `${hasScaleManagementPrivileges}` } });
    });
  }, [hasScaleManagementPrivileges, clientReady]);

  if (accessIsError) {
    return <div>Access error</div>;
  }
  if (!userAccess && accessIsLoading) {
    return <CenteredLoading />;
  }

  return (
    <StyledLayout>
      <WorkersUI />
      {appBar}
      <FullHeightRow>
        {drawer}
        <StyledMain id="main-content">{props.children}</StyledMain>
      </FullHeightRow>
    </StyledLayout>
  );
};

const StyledLayout = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
`;

const StyledMain = styled.main`
  height: 100%;
  width: 100%;
  overflow-y: auto;
`;

const FullHeightRow = styled(Row)`
  overflow: hidden;
  flex-grow: 1;
`;
