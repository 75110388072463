import { makeStyles } from "tss-react/mui";
import { Props } from "./index";

export const useStyles = makeStyles<Props>()(() => {
  return {
    loadingContainer: {
      justifyContent: "center",
      alignItems: "center",
      height: "80vh",
    },
  };
});
