import buildQuery, { QueryOptions } from "odata-query";
import { parse } from "query-string";

export type ODataQueryOptions = {
  $top: number;
  $skip: number;
  $apply: string;
  $count: boolean;
  $filter: string;
  $select: string;
  $expand: string;
  $orderby: string;
};

export default function <Entity>(queryOptions: Partial<QueryOptions<Entity>>): ODataQueryOptions {
  const string = buildQuery(queryOptions);
  const parsed = parse(string);
  return Object.fromEntries(
    Object.entries(parsed).map(([key, value]) => {
      return [key.startsWith("$") ? key : "$" + key, value];
    }),
  ) as unknown as ODataQueryOptions;
}
