import { accessApiBaseUrl } from "utils/appsettings";
import * as AccessApi from "api/swagger/uniteaq.access.api.1.0/api";
import { generateSwaggerApiClient } from "./genericApiClient";

const accessApiV1Client = generateSwaggerApiClient(AccessApi, accessApiBaseUrl);

// these should most likely be exported in the OpenAPI document
export enum AccessAreaName {
  Devices = "devices",
  IdentityProviders = "identity-providers",
  GosUsers = "gos-users",
  GosLookups = "gos-lookups",
  GosApiKey = "gos-apikey",
  GosOperations = "gos-operations",
  GosVersions = "gos-versions",
  GosLicense = "gos-license",
  GosApps = "gos-appps",
  Vision = "vision",
  FeedStations = "feed-station",
  FeedManager = "feed-manager",
  Farmer = "farmer",
  Knowledger = "knowledger",
  Future = "future",
}

export * as AccessApiV1Models from "api/swagger/uniteaq.access.api.1.0/model";

export default accessApiV1Client;
