// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * UniteAQ Access API
 * Access API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { AcceptInvitationDtoWeb } from '../model';
// @ts-ignore
import { InviteUsersDtoWeb } from '../model';
/**
 * InvitationApi - axios parameter creator
 * @export
 */
export const InvitationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {InviteUsersDtoWeb} [inviteUsersDtoWeb] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postInvitation: async (inviteUsersDtoWeb?: InviteUsersDtoWeb, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Invitation`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json;odata.metadata=minimal;odata.streaming=true';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(inviteUsersDtoWeb, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} userId 
         * @param {AcceptInvitationDtoWeb} [acceptInvitationDtoWeb] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postInvitationUserId: async (userId: string, acceptInvitationDtoWeb?: AcceptInvitationDtoWeb, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('postInvitationUserId', 'userId', userId)
            const localVarPath = `/Invitation/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json;odata.metadata=minimal;odata.streaming=true';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(acceptInvitationDtoWeb, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * InvitationApi - functional programming interface
 * @export
 */
export const InvitationApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = InvitationApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {InviteUsersDtoWeb} [inviteUsersDtoWeb] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postInvitation(inviteUsersDtoWeb?: InviteUsersDtoWeb, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postInvitation(inviteUsersDtoWeb, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} userId 
         * @param {AcceptInvitationDtoWeb} [acceptInvitationDtoWeb] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postInvitationUserId(userId: string, acceptInvitationDtoWeb?: AcceptInvitationDtoWeb, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postInvitationUserId(userId, acceptInvitationDtoWeb, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * InvitationApi - factory interface
 * @export
 */
export const InvitationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = InvitationApiFp(configuration)
    return {
        /**
         * 
         * @param {InvitationApiPostInvitationRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postInvitation(requestParameters: InvitationApiPostInvitationRequest = {}, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.postInvitation(requestParameters.inviteUsersDtoWeb, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {InvitationApiPostInvitationUserIdRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postInvitationUserId(requestParameters: InvitationApiPostInvitationUserIdRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.postInvitationUserId(requestParameters.userId, requestParameters.acceptInvitationDtoWeb, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * InvitationApi - interface
 * @export
 * @interface InvitationApi
 */
export interface InvitationApiInterface {
    /**
     * 
     * @param {InviteUsersDtoWeb} [inviteUsersDtoWeb] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvitationApiInterface
     */
    postInvitation(inviteUsersDtoWeb?: InviteUsersDtoWeb, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @param {string} userId 
     * @param {AcceptInvitationDtoWeb} [acceptInvitationDtoWeb] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvitationApiInterface
     */
    postInvitationUserId(userId: string, acceptInvitationDtoWeb?: AcceptInvitationDtoWeb, options?: AxiosRequestConfig): AxiosPromise<void>;

}

/**
 * Request parameters for postInvitation operation in InvitationApi.
 * @export
 * @interface InvitationApiPostInvitationRequest
 */
export interface InvitationApiPostInvitationRequest {
    /**
     * 
     * @type {InviteUsersDtoWeb}
     * @memberof InvitationApiPostInvitation
     */
    readonly inviteUsersDtoWeb?: InviteUsersDtoWeb
}

/**
 * Request parameters for postInvitationUserId operation in InvitationApi.
 * @export
 * @interface InvitationApiPostInvitationUserIdRequest
 */
export interface InvitationApiPostInvitationUserIdRequest {
    /**
     * 
     * @type {string}
     * @memberof InvitationApiPostInvitationUserId
     */
    readonly userId: string

    /**
     * 
     * @type {AcceptInvitationDtoWeb}
     * @memberof InvitationApiPostInvitationUserId
     */
    readonly acceptInvitationDtoWeb?: AcceptInvitationDtoWeb
}

/**
 * InvitationApi - object-oriented interface
 * @export
 * @class InvitationApi
 * @extends {BaseAPI}
 */
export class InvitationApi extends BaseAPI implements InvitationApiInterface {
    /**
     * 
     * @param {InvitationApiPostInvitationRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvitationApi
     */
    public postInvitation(requestParameters: InvitationApiPostInvitationRequest = {}, options?: AxiosRequestConfig) {
        return InvitationApiFp(this.configuration).postInvitation(requestParameters.inviteUsersDtoWeb, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {InvitationApiPostInvitationUserIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvitationApi
     */
    public postInvitationUserId(requestParameters: InvitationApiPostInvitationUserIdRequest, options?: AxiosRequestConfig) {
        return InvitationApiFp(this.configuration).postInvitationUserId(requestParameters.userId, requestParameters.acceptInvitationDtoWeb, options).then((request) => request(this.axios, this.basePath));
    }
}
