// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * UniteAQ Access API
 * Access API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { PostCompanyRolesDtoWeb } from '../model';
// @ts-ignore
import { RedeemUserAccessDtoWeb } from '../model';
// @ts-ignore
import { UserAccessWeb } from '../model';
// @ts-ignore
import { WebError } from '../model';
/**
 * UserAccessApi - axios parameter creator
 * @export
 */
export const UserAccessApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {Array<string>} [userIds] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUserAccess: async (userIds?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/UserAccess`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            if (userIds) {
                localVarQueryParameter['userIds'] = userIds;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUserAccessUserId: async (userId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('deleteUserAccessUserId', 'userId', userId)
            const localVarPath = `/UserAccess/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} userId 
         * @param {string} companyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUserAccessUserIdCompanyCompanyId: async (userId: string, companyId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('deleteUserAccessUserIdCompanyCompanyId', 'userId', userId)
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('deleteUserAccessUserIdCompanyCompanyId', 'companyId', companyId)
            const localVarPath = `/UserAccess/{userId}/company/{companyId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)))
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {boolean} [ignoreCache] 
         * @param {boolean} [includeDisabled] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserAccess: async (ignoreCache?: boolean, includeDisabled?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/UserAccess`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            if (ignoreCache !== undefined) {
                localVarQueryParameter['ignoreCache'] = ignoreCache;
            }

            if (includeDisabled !== undefined) {
                localVarQueryParameter['includeDisabled'] = includeDisabled;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {boolean} [includeDisabled] 
         * @param {boolean} [ignoreCache] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserAccessAll: async (includeDisabled?: boolean, ignoreCache?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/UserAccess/all`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            if (includeDisabled !== undefined) {
                localVarQueryParameter['includeDisabled'] = includeDisabled;
            }

            if (ignoreCache !== undefined) {
                localVarQueryParameter['ignoreCache'] = ignoreCache;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {boolean} [ignoreCache] 
         * @param {boolean} [includeDisabled] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserAccessMe: async (ignoreCache?: boolean, includeDisabled?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/UserAccess/me`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            if (ignoreCache !== undefined) {
                localVarQueryParameter['ignoreCache'] = ignoreCache;
            }

            if (includeDisabled !== undefined) {
                localVarQueryParameter['includeDisabled'] = includeDisabled;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} userId 
         * @param {boolean} [includeDisabled] 
         * @param {boolean} [ignoreCache] 
         * @param {boolean} [ignoreGosEntities] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserAccessUserId: async (userId: string, includeDisabled?: boolean, ignoreCache?: boolean, ignoreGosEntities?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('getUserAccessUserId', 'userId', userId)
            const localVarPath = `/UserAccess/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            if (includeDisabled !== undefined) {
                localVarQueryParameter['includeDisabled'] = includeDisabled;
            }

            if (ignoreCache !== undefined) {
                localVarQueryParameter['ignoreCache'] = ignoreCache;
            }

            if (ignoreGosEntities !== undefined) {
                localVarQueryParameter['ignoreGosEntities'] = ignoreGosEntities;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} userId 
         * @param {string} companyId 
         * @param {boolean} [includeDisabled] 
         * @param {boolean} [ignoreCache] 
         * @param {boolean} [ignoreGosEntities] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserAccessUserIdCompanyCompanyId: async (userId: string, companyId: string, includeDisabled?: boolean, ignoreCache?: boolean, ignoreGosEntities?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('getUserAccessUserIdCompanyCompanyId', 'userId', userId)
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('getUserAccessUserIdCompanyCompanyId', 'companyId', companyId)
            const localVarPath = `/UserAccess/{userId}/company/{companyId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)))
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            if (includeDisabled !== undefined) {
                localVarQueryParameter['includeDisabled'] = includeDisabled;
            }

            if (ignoreCache !== undefined) {
                localVarQueryParameter['ignoreCache'] = ignoreCache;
            }

            if (ignoreGosEntities !== undefined) {
                localVarQueryParameter['ignoreGosEntities'] = ignoreGosEntities;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} companyId 
         * @param {Array<UserAccessWeb>} [userAccessWeb] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postUserAccessCompanyCompanyId: async (companyId: string, userAccessWeb?: Array<UserAccessWeb>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('postUserAccessCompanyCompanyId', 'companyId', companyId)
            const localVarPath = `/UserAccess/company/{companyId}`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json;odata.metadata=minimal;odata.streaming=true';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userAccessWeb, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} userId 
         * @param {UserAccessWeb} [userAccessWeb] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postUserAccessUserId: async (userId: string, userAccessWeb?: UserAccessWeb, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('postUserAccessUserId', 'userId', userId)
            const localVarPath = `/UserAccess/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json;odata.metadata=minimal;odata.streaming=true';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userAccessWeb, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} userId 
         * @param {string} companyId 
         * @param {UserAccessWeb} [userAccessWeb] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postUserAccessUserIdCompanyCompanyId: async (userId: string, companyId: string, userAccessWeb?: UserAccessWeb, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('postUserAccessUserIdCompanyCompanyId', 'userId', userId)
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('postUserAccessUserIdCompanyCompanyId', 'companyId', companyId)
            const localVarPath = `/UserAccess/{userId}/company/{companyId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)))
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json;odata.metadata=minimal;odata.streaming=true';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userAccessWeb, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} userId 
         * @param {string} companyId 
         * @param {PostCompanyRolesDtoWeb} [postCompanyRolesDtoWeb] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postUserAccessUserIdCompanyCompanyIdRoles: async (userId: string, companyId: string, postCompanyRolesDtoWeb?: PostCompanyRolesDtoWeb, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('postUserAccessUserIdCompanyCompanyIdRoles', 'userId', userId)
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('postUserAccessUserIdCompanyCompanyIdRoles', 'companyId', companyId)
            const localVarPath = `/UserAccess/{userId}/company/{companyId}/roles`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)))
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json;odata.metadata=minimal;odata.streaming=true';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(postCompanyRolesDtoWeb, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {RedeemUserAccessDtoWeb} [redeemUserAccessDtoWeb] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putUserAccessRedeem: async (redeemUserAccessDtoWeb?: RedeemUserAccessDtoWeb, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/UserAccess/redeem`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json;odata.metadata=minimal;odata.streaming=true';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(redeemUserAccessDtoWeb, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserAccessApi - functional programming interface
 * @export
 */
export const UserAccessApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UserAccessApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {Array<string>} [userIds] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteUserAccess(userIds?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteUserAccess(userIds, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteUserAccessUserId(userId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteUserAccessUserId(userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} userId 
         * @param {string} companyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteUserAccessUserIdCompanyCompanyId(userId: string, companyId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteUserAccessUserIdCompanyCompanyId(userId, companyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {boolean} [ignoreCache] 
         * @param {boolean} [includeDisabled] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserAccess(ignoreCache?: boolean, includeDisabled?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<UserAccessWeb>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserAccess(ignoreCache, includeDisabled, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {boolean} [includeDisabled] 
         * @param {boolean} [ignoreCache] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserAccessAll(includeDisabled?: boolean, ignoreCache?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<UserAccessWeb>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserAccessAll(includeDisabled, ignoreCache, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {boolean} [ignoreCache] 
         * @param {boolean} [includeDisabled] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserAccessMe(ignoreCache?: boolean, includeDisabled?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserAccessWeb>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserAccessMe(ignoreCache, includeDisabled, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} userId 
         * @param {boolean} [includeDisabled] 
         * @param {boolean} [ignoreCache] 
         * @param {boolean} [ignoreGosEntities] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserAccessUserId(userId: string, includeDisabled?: boolean, ignoreCache?: boolean, ignoreGosEntities?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserAccessWeb>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserAccessUserId(userId, includeDisabled, ignoreCache, ignoreGosEntities, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} userId 
         * @param {string} companyId 
         * @param {boolean} [includeDisabled] 
         * @param {boolean} [ignoreCache] 
         * @param {boolean} [ignoreGosEntities] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserAccessUserIdCompanyCompanyId(userId: string, companyId: string, includeDisabled?: boolean, ignoreCache?: boolean, ignoreGosEntities?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserAccessWeb>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserAccessUserIdCompanyCompanyId(userId, companyId, includeDisabled, ignoreCache, ignoreGosEntities, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} companyId 
         * @param {Array<UserAccessWeb>} [userAccessWeb] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postUserAccessCompanyCompanyId(companyId: string, userAccessWeb?: Array<UserAccessWeb>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postUserAccessCompanyCompanyId(companyId, userAccessWeb, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} userId 
         * @param {UserAccessWeb} [userAccessWeb] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postUserAccessUserId(userId: string, userAccessWeb?: UserAccessWeb, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postUserAccessUserId(userId, userAccessWeb, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} userId 
         * @param {string} companyId 
         * @param {UserAccessWeb} [userAccessWeb] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postUserAccessUserIdCompanyCompanyId(userId: string, companyId: string, userAccessWeb?: UserAccessWeb, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postUserAccessUserIdCompanyCompanyId(userId, companyId, userAccessWeb, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} userId 
         * @param {string} companyId 
         * @param {PostCompanyRolesDtoWeb} [postCompanyRolesDtoWeb] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postUserAccessUserIdCompanyCompanyIdRoles(userId: string, companyId: string, postCompanyRolesDtoWeb?: PostCompanyRolesDtoWeb, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postUserAccessUserIdCompanyCompanyIdRoles(userId, companyId, postCompanyRolesDtoWeb, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {RedeemUserAccessDtoWeb} [redeemUserAccessDtoWeb] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putUserAccessRedeem(redeemUserAccessDtoWeb?: RedeemUserAccessDtoWeb, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putUserAccessRedeem(redeemUserAccessDtoWeb, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UserAccessApi - factory interface
 * @export
 */
export const UserAccessApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UserAccessApiFp(configuration)
    return {
        /**
         * 
         * @param {UserAccessApiDeleteUserAccessRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUserAccess(requestParameters: UserAccessApiDeleteUserAccessRequest = {}, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.deleteUserAccess(requestParameters.userIds, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UserAccessApiDeleteUserAccessUserIdRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUserAccessUserId(requestParameters: UserAccessApiDeleteUserAccessUserIdRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.deleteUserAccessUserId(requestParameters.userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UserAccessApiDeleteUserAccessUserIdCompanyCompanyIdRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUserAccessUserIdCompanyCompanyId(requestParameters: UserAccessApiDeleteUserAccessUserIdCompanyCompanyIdRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.deleteUserAccessUserIdCompanyCompanyId(requestParameters.userId, requestParameters.companyId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UserAccessApiGetUserAccessRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserAccess(requestParameters: UserAccessApiGetUserAccessRequest = {}, options?: AxiosRequestConfig): AxiosPromise<Array<UserAccessWeb>> {
            return localVarFp.getUserAccess(requestParameters.ignoreCache, requestParameters.includeDisabled, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UserAccessApiGetUserAccessAllRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserAccessAll(requestParameters: UserAccessApiGetUserAccessAllRequest = {}, options?: AxiosRequestConfig): AxiosPromise<Array<UserAccessWeb>> {
            return localVarFp.getUserAccessAll(requestParameters.includeDisabled, requestParameters.ignoreCache, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UserAccessApiGetUserAccessMeRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserAccessMe(requestParameters: UserAccessApiGetUserAccessMeRequest = {}, options?: AxiosRequestConfig): AxiosPromise<UserAccessWeb> {
            return localVarFp.getUserAccessMe(requestParameters.ignoreCache, requestParameters.includeDisabled, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UserAccessApiGetUserAccessUserIdRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserAccessUserId(requestParameters: UserAccessApiGetUserAccessUserIdRequest, options?: AxiosRequestConfig): AxiosPromise<UserAccessWeb> {
            return localVarFp.getUserAccessUserId(requestParameters.userId, requestParameters.includeDisabled, requestParameters.ignoreCache, requestParameters.ignoreGosEntities, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UserAccessApiGetUserAccessUserIdCompanyCompanyIdRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserAccessUserIdCompanyCompanyId(requestParameters: UserAccessApiGetUserAccessUserIdCompanyCompanyIdRequest, options?: AxiosRequestConfig): AxiosPromise<UserAccessWeb> {
            return localVarFp.getUserAccessUserIdCompanyCompanyId(requestParameters.userId, requestParameters.companyId, requestParameters.includeDisabled, requestParameters.ignoreCache, requestParameters.ignoreGosEntities, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UserAccessApiPostUserAccessCompanyCompanyIdRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postUserAccessCompanyCompanyId(requestParameters: UserAccessApiPostUserAccessCompanyCompanyIdRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.postUserAccessCompanyCompanyId(requestParameters.companyId, requestParameters.userAccessWeb, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UserAccessApiPostUserAccessUserIdRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postUserAccessUserId(requestParameters: UserAccessApiPostUserAccessUserIdRequest, options?: AxiosRequestConfig): AxiosPromise<number> {
            return localVarFp.postUserAccessUserId(requestParameters.userId, requestParameters.userAccessWeb, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UserAccessApiPostUserAccessUserIdCompanyCompanyIdRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postUserAccessUserIdCompanyCompanyId(requestParameters: UserAccessApiPostUserAccessUserIdCompanyCompanyIdRequest, options?: AxiosRequestConfig): AxiosPromise<number> {
            return localVarFp.postUserAccessUserIdCompanyCompanyId(requestParameters.userId, requestParameters.companyId, requestParameters.userAccessWeb, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UserAccessApiPostUserAccessUserIdCompanyCompanyIdRolesRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postUserAccessUserIdCompanyCompanyIdRoles(requestParameters: UserAccessApiPostUserAccessUserIdCompanyCompanyIdRolesRequest, options?: AxiosRequestConfig): AxiosPromise<number> {
            return localVarFp.postUserAccessUserIdCompanyCompanyIdRoles(requestParameters.userId, requestParameters.companyId, requestParameters.postCompanyRolesDtoWeb, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UserAccessApiPutUserAccessRedeemRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putUserAccessRedeem(requestParameters: UserAccessApiPutUserAccessRedeemRequest = {}, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.putUserAccessRedeem(requestParameters.redeemUserAccessDtoWeb, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UserAccessApi - interface
 * @export
 * @interface UserAccessApi
 */
export interface UserAccessApiInterface {
    /**
     * 
     * @param {Array<string>} [userIds] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserAccessApiInterface
     */
    deleteUserAccess(userIds?: Array<string>, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @param {string} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserAccessApiInterface
     */
    deleteUserAccessUserId(userId: string, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @param {string} userId 
     * @param {string} companyId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserAccessApiInterface
     */
    deleteUserAccessUserIdCompanyCompanyId(userId: string, companyId: string, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @param {boolean} [ignoreCache] 
     * @param {boolean} [includeDisabled] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserAccessApiInterface
     */
    getUserAccess(ignoreCache?: boolean, includeDisabled?: boolean, options?: AxiosRequestConfig): AxiosPromise<Array<UserAccessWeb>>;

    /**
     * 
     * @param {boolean} [includeDisabled] 
     * @param {boolean} [ignoreCache] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserAccessApiInterface
     */
    getUserAccessAll(includeDisabled?: boolean, ignoreCache?: boolean, options?: AxiosRequestConfig): AxiosPromise<Array<UserAccessWeb>>;

    /**
     * 
     * @param {boolean} [ignoreCache] 
     * @param {boolean} [includeDisabled] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserAccessApiInterface
     */
    getUserAccessMe(ignoreCache?: boolean, includeDisabled?: boolean, options?: AxiosRequestConfig): AxiosPromise<UserAccessWeb>;

    /**
     * 
     * @param {string} userId 
     * @param {boolean} [includeDisabled] 
     * @param {boolean} [ignoreCache] 
     * @param {boolean} [ignoreGosEntities] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserAccessApiInterface
     */
    getUserAccessUserId(userId: string, includeDisabled?: boolean, ignoreCache?: boolean, ignoreGosEntities?: boolean, options?: AxiosRequestConfig): AxiosPromise<UserAccessWeb>;

    /**
     * 
     * @param {string} userId 
     * @param {string} companyId 
     * @param {boolean} [includeDisabled] 
     * @param {boolean} [ignoreCache] 
     * @param {boolean} [ignoreGosEntities] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserAccessApiInterface
     */
    getUserAccessUserIdCompanyCompanyId(userId: string, companyId: string, includeDisabled?: boolean, ignoreCache?: boolean, ignoreGosEntities?: boolean, options?: AxiosRequestConfig): AxiosPromise<UserAccessWeb>;

    /**
     * 
     * @param {string} companyId 
     * @param {Array<UserAccessWeb>} [userAccessWeb] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserAccessApiInterface
     */
    postUserAccessCompanyCompanyId(companyId: string, userAccessWeb?: Array<UserAccessWeb>, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @param {string} userId 
     * @param {UserAccessWeb} [userAccessWeb] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserAccessApiInterface
     */
    postUserAccessUserId(userId: string, userAccessWeb?: UserAccessWeb, options?: AxiosRequestConfig): AxiosPromise<number>;

    /**
     * 
     * @param {string} userId 
     * @param {string} companyId 
     * @param {UserAccessWeb} [userAccessWeb] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserAccessApiInterface
     */
    postUserAccessUserIdCompanyCompanyId(userId: string, companyId: string, userAccessWeb?: UserAccessWeb, options?: AxiosRequestConfig): AxiosPromise<number>;

    /**
     * 
     * @param {string} userId 
     * @param {string} companyId 
     * @param {PostCompanyRolesDtoWeb} [postCompanyRolesDtoWeb] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserAccessApiInterface
     */
    postUserAccessUserIdCompanyCompanyIdRoles(userId: string, companyId: string, postCompanyRolesDtoWeb?: PostCompanyRolesDtoWeb, options?: AxiosRequestConfig): AxiosPromise<number>;

    /**
     * 
     * @param {RedeemUserAccessDtoWeb} [redeemUserAccessDtoWeb] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserAccessApiInterface
     */
    putUserAccessRedeem(redeemUserAccessDtoWeb?: RedeemUserAccessDtoWeb, options?: AxiosRequestConfig): AxiosPromise<void>;

}

/**
 * Request parameters for deleteUserAccess operation in UserAccessApi.
 * @export
 * @interface UserAccessApiDeleteUserAccessRequest
 */
export interface UserAccessApiDeleteUserAccessRequest {
    /**
     * 
     * @type {Array<string>}
     * @memberof UserAccessApiDeleteUserAccess
     */
    readonly userIds?: Array<string>
}

/**
 * Request parameters for deleteUserAccessUserId operation in UserAccessApi.
 * @export
 * @interface UserAccessApiDeleteUserAccessUserIdRequest
 */
export interface UserAccessApiDeleteUserAccessUserIdRequest {
    /**
     * 
     * @type {string}
     * @memberof UserAccessApiDeleteUserAccessUserId
     */
    readonly userId: string
}

/**
 * Request parameters for deleteUserAccessUserIdCompanyCompanyId operation in UserAccessApi.
 * @export
 * @interface UserAccessApiDeleteUserAccessUserIdCompanyCompanyIdRequest
 */
export interface UserAccessApiDeleteUserAccessUserIdCompanyCompanyIdRequest {
    /**
     * 
     * @type {string}
     * @memberof UserAccessApiDeleteUserAccessUserIdCompanyCompanyId
     */
    readonly userId: string

    /**
     * 
     * @type {string}
     * @memberof UserAccessApiDeleteUserAccessUserIdCompanyCompanyId
     */
    readonly companyId: string
}

/**
 * Request parameters for getUserAccess operation in UserAccessApi.
 * @export
 * @interface UserAccessApiGetUserAccessRequest
 */
export interface UserAccessApiGetUserAccessRequest {
    /**
     * 
     * @type {boolean}
     * @memberof UserAccessApiGetUserAccess
     */
    readonly ignoreCache?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof UserAccessApiGetUserAccess
     */
    readonly includeDisabled?: boolean
}

/**
 * Request parameters for getUserAccessAll operation in UserAccessApi.
 * @export
 * @interface UserAccessApiGetUserAccessAllRequest
 */
export interface UserAccessApiGetUserAccessAllRequest {
    /**
     * 
     * @type {boolean}
     * @memberof UserAccessApiGetUserAccessAll
     */
    readonly includeDisabled?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof UserAccessApiGetUserAccessAll
     */
    readonly ignoreCache?: boolean
}

/**
 * Request parameters for getUserAccessMe operation in UserAccessApi.
 * @export
 * @interface UserAccessApiGetUserAccessMeRequest
 */
export interface UserAccessApiGetUserAccessMeRequest {
    /**
     * 
     * @type {boolean}
     * @memberof UserAccessApiGetUserAccessMe
     */
    readonly ignoreCache?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof UserAccessApiGetUserAccessMe
     */
    readonly includeDisabled?: boolean
}

/**
 * Request parameters for getUserAccessUserId operation in UserAccessApi.
 * @export
 * @interface UserAccessApiGetUserAccessUserIdRequest
 */
export interface UserAccessApiGetUserAccessUserIdRequest {
    /**
     * 
     * @type {string}
     * @memberof UserAccessApiGetUserAccessUserId
     */
    readonly userId: string

    /**
     * 
     * @type {boolean}
     * @memberof UserAccessApiGetUserAccessUserId
     */
    readonly includeDisabled?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof UserAccessApiGetUserAccessUserId
     */
    readonly ignoreCache?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof UserAccessApiGetUserAccessUserId
     */
    readonly ignoreGosEntities?: boolean
}

/**
 * Request parameters for getUserAccessUserIdCompanyCompanyId operation in UserAccessApi.
 * @export
 * @interface UserAccessApiGetUserAccessUserIdCompanyCompanyIdRequest
 */
export interface UserAccessApiGetUserAccessUserIdCompanyCompanyIdRequest {
    /**
     * 
     * @type {string}
     * @memberof UserAccessApiGetUserAccessUserIdCompanyCompanyId
     */
    readonly userId: string

    /**
     * 
     * @type {string}
     * @memberof UserAccessApiGetUserAccessUserIdCompanyCompanyId
     */
    readonly companyId: string

    /**
     * 
     * @type {boolean}
     * @memberof UserAccessApiGetUserAccessUserIdCompanyCompanyId
     */
    readonly includeDisabled?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof UserAccessApiGetUserAccessUserIdCompanyCompanyId
     */
    readonly ignoreCache?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof UserAccessApiGetUserAccessUserIdCompanyCompanyId
     */
    readonly ignoreGosEntities?: boolean
}

/**
 * Request parameters for postUserAccessCompanyCompanyId operation in UserAccessApi.
 * @export
 * @interface UserAccessApiPostUserAccessCompanyCompanyIdRequest
 */
export interface UserAccessApiPostUserAccessCompanyCompanyIdRequest {
    /**
     * 
     * @type {string}
     * @memberof UserAccessApiPostUserAccessCompanyCompanyId
     */
    readonly companyId: string

    /**
     * 
     * @type {Array<UserAccessWeb>}
     * @memberof UserAccessApiPostUserAccessCompanyCompanyId
     */
    readonly userAccessWeb?: Array<UserAccessWeb>
}

/**
 * Request parameters for postUserAccessUserId operation in UserAccessApi.
 * @export
 * @interface UserAccessApiPostUserAccessUserIdRequest
 */
export interface UserAccessApiPostUserAccessUserIdRequest {
    /**
     * 
     * @type {string}
     * @memberof UserAccessApiPostUserAccessUserId
     */
    readonly userId: string

    /**
     * 
     * @type {UserAccessWeb}
     * @memberof UserAccessApiPostUserAccessUserId
     */
    readonly userAccessWeb?: UserAccessWeb
}

/**
 * Request parameters for postUserAccessUserIdCompanyCompanyId operation in UserAccessApi.
 * @export
 * @interface UserAccessApiPostUserAccessUserIdCompanyCompanyIdRequest
 */
export interface UserAccessApiPostUserAccessUserIdCompanyCompanyIdRequest {
    /**
     * 
     * @type {string}
     * @memberof UserAccessApiPostUserAccessUserIdCompanyCompanyId
     */
    readonly userId: string

    /**
     * 
     * @type {string}
     * @memberof UserAccessApiPostUserAccessUserIdCompanyCompanyId
     */
    readonly companyId: string

    /**
     * 
     * @type {UserAccessWeb}
     * @memberof UserAccessApiPostUserAccessUserIdCompanyCompanyId
     */
    readonly userAccessWeb?: UserAccessWeb
}

/**
 * Request parameters for postUserAccessUserIdCompanyCompanyIdRoles operation in UserAccessApi.
 * @export
 * @interface UserAccessApiPostUserAccessUserIdCompanyCompanyIdRolesRequest
 */
export interface UserAccessApiPostUserAccessUserIdCompanyCompanyIdRolesRequest {
    /**
     * 
     * @type {string}
     * @memberof UserAccessApiPostUserAccessUserIdCompanyCompanyIdRoles
     */
    readonly userId: string

    /**
     * 
     * @type {string}
     * @memberof UserAccessApiPostUserAccessUserIdCompanyCompanyIdRoles
     */
    readonly companyId: string

    /**
     * 
     * @type {PostCompanyRolesDtoWeb}
     * @memberof UserAccessApiPostUserAccessUserIdCompanyCompanyIdRoles
     */
    readonly postCompanyRolesDtoWeb?: PostCompanyRolesDtoWeb
}

/**
 * Request parameters for putUserAccessRedeem operation in UserAccessApi.
 * @export
 * @interface UserAccessApiPutUserAccessRedeemRequest
 */
export interface UserAccessApiPutUserAccessRedeemRequest {
    /**
     * 
     * @type {RedeemUserAccessDtoWeb}
     * @memberof UserAccessApiPutUserAccessRedeem
     */
    readonly redeemUserAccessDtoWeb?: RedeemUserAccessDtoWeb
}

/**
 * UserAccessApi - object-oriented interface
 * @export
 * @class UserAccessApi
 * @extends {BaseAPI}
 */
export class UserAccessApi extends BaseAPI implements UserAccessApiInterface {
    /**
     * 
     * @param {UserAccessApiDeleteUserAccessRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserAccessApi
     */
    public deleteUserAccess(requestParameters: UserAccessApiDeleteUserAccessRequest = {}, options?: AxiosRequestConfig) {
        return UserAccessApiFp(this.configuration).deleteUserAccess(requestParameters.userIds, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UserAccessApiDeleteUserAccessUserIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserAccessApi
     */
    public deleteUserAccessUserId(requestParameters: UserAccessApiDeleteUserAccessUserIdRequest, options?: AxiosRequestConfig) {
        return UserAccessApiFp(this.configuration).deleteUserAccessUserId(requestParameters.userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UserAccessApiDeleteUserAccessUserIdCompanyCompanyIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserAccessApi
     */
    public deleteUserAccessUserIdCompanyCompanyId(requestParameters: UserAccessApiDeleteUserAccessUserIdCompanyCompanyIdRequest, options?: AxiosRequestConfig) {
        return UserAccessApiFp(this.configuration).deleteUserAccessUserIdCompanyCompanyId(requestParameters.userId, requestParameters.companyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UserAccessApiGetUserAccessRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserAccessApi
     */
    public getUserAccess(requestParameters: UserAccessApiGetUserAccessRequest = {}, options?: AxiosRequestConfig) {
        return UserAccessApiFp(this.configuration).getUserAccess(requestParameters.ignoreCache, requestParameters.includeDisabled, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UserAccessApiGetUserAccessAllRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserAccessApi
     */
    public getUserAccessAll(requestParameters: UserAccessApiGetUserAccessAllRequest = {}, options?: AxiosRequestConfig) {
        return UserAccessApiFp(this.configuration).getUserAccessAll(requestParameters.includeDisabled, requestParameters.ignoreCache, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UserAccessApiGetUserAccessMeRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserAccessApi
     */
    public getUserAccessMe(requestParameters: UserAccessApiGetUserAccessMeRequest = {}, options?: AxiosRequestConfig) {
        return UserAccessApiFp(this.configuration).getUserAccessMe(requestParameters.ignoreCache, requestParameters.includeDisabled, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UserAccessApiGetUserAccessUserIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserAccessApi
     */
    public getUserAccessUserId(requestParameters: UserAccessApiGetUserAccessUserIdRequest, options?: AxiosRequestConfig) {
        return UserAccessApiFp(this.configuration).getUserAccessUserId(requestParameters.userId, requestParameters.includeDisabled, requestParameters.ignoreCache, requestParameters.ignoreGosEntities, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UserAccessApiGetUserAccessUserIdCompanyCompanyIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserAccessApi
     */
    public getUserAccessUserIdCompanyCompanyId(requestParameters: UserAccessApiGetUserAccessUserIdCompanyCompanyIdRequest, options?: AxiosRequestConfig) {
        return UserAccessApiFp(this.configuration).getUserAccessUserIdCompanyCompanyId(requestParameters.userId, requestParameters.companyId, requestParameters.includeDisabled, requestParameters.ignoreCache, requestParameters.ignoreGosEntities, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UserAccessApiPostUserAccessCompanyCompanyIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserAccessApi
     */
    public postUserAccessCompanyCompanyId(requestParameters: UserAccessApiPostUserAccessCompanyCompanyIdRequest, options?: AxiosRequestConfig) {
        return UserAccessApiFp(this.configuration).postUserAccessCompanyCompanyId(requestParameters.companyId, requestParameters.userAccessWeb, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UserAccessApiPostUserAccessUserIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserAccessApi
     */
    public postUserAccessUserId(requestParameters: UserAccessApiPostUserAccessUserIdRequest, options?: AxiosRequestConfig) {
        return UserAccessApiFp(this.configuration).postUserAccessUserId(requestParameters.userId, requestParameters.userAccessWeb, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UserAccessApiPostUserAccessUserIdCompanyCompanyIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserAccessApi
     */
    public postUserAccessUserIdCompanyCompanyId(requestParameters: UserAccessApiPostUserAccessUserIdCompanyCompanyIdRequest, options?: AxiosRequestConfig) {
        return UserAccessApiFp(this.configuration).postUserAccessUserIdCompanyCompanyId(requestParameters.userId, requestParameters.companyId, requestParameters.userAccessWeb, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UserAccessApiPostUserAccessUserIdCompanyCompanyIdRolesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserAccessApi
     */
    public postUserAccessUserIdCompanyCompanyIdRoles(requestParameters: UserAccessApiPostUserAccessUserIdCompanyCompanyIdRolesRequest, options?: AxiosRequestConfig) {
        return UserAccessApiFp(this.configuration).postUserAccessUserIdCompanyCompanyIdRoles(requestParameters.userId, requestParameters.companyId, requestParameters.postCompanyRolesDtoWeb, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UserAccessApiPutUserAccessRedeemRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserAccessApi
     */
    public putUserAccessRedeem(requestParameters: UserAccessApiPutUserAccessRedeemRequest = {}, options?: AxiosRequestConfig) {
        return UserAccessApiFp(this.configuration).putUserAccessRedeem(requestParameters.redeemUserAccessDtoWeb, options).then((request) => request(this.axios, this.basePath));
    }
}
