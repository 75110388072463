// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Operation Services API
 * Operation Services API
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { FeedSupplier } from '../model';
// @ts-ignore
import { FeedSupplierIEnumerableScaleODataValue } from '../model';
// @ts-ignore
import { Operation } from '../model';
/**
 * FeedSuppliersApi - axios parameter creator
 * @export
 */
export const FeedSuppliersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} key 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteApiV2FeedSuppliersKey: async (key: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'key' is not null or undefined
            assertParamExists('deleteApiV2FeedSuppliersKey', 'key', key)
            const localVarPath = `/api/v2/FeedSuppliers({key})`
                .replace(`{${"key"}}`, encodeURIComponent(String(key)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} key 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteApiV2FeedSuppliersKey_1: async (key: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'key' is not null or undefined
            assertParamExists('deleteApiV2FeedSuppliersKey_1', 'key', key)
            const localVarPath = `/api/v2/FeedSuppliers/{key}`
                .replace(`{${"key"}}`, encodeURIComponent(String(key)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [$top] The max number of records.
         * @param {string} [$apply] Allows to specify a sequence of transformations to the entity set, such as groupby, filter, aggregate, etc.
         * @param {number} [$skip] The number of records to skip.
         * @param {string} [$filter] A function that must evaluate to true for a record to be returned.
         * @param {string} [$select] Specifies a subset of properties to return. Use a comma separated list.
         * @param {string} [$orderby] Determines what values are used to order a collection of records.
         * @param {string} [$expand] Use to add related query data.
         * @param {boolean} [$count] Allows clients to request a count of the matching resources included with the resources in the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiV2FeedSuppliers: async ($top?: number, $apply?: string, $skip?: number, $filter?: string, $select?: string, $orderby?: string, $expand?: string, $count?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v2/FeedSuppliers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if ($top !== undefined) {
                localVarQueryParameter['$top'] = $top;
            }

            if ($apply !== undefined) {
                localVarQueryParameter['$apply'] = $apply;
            }

            if ($skip !== undefined) {
                localVarQueryParameter['$skip'] = $skip;
            }

            if ($filter !== undefined) {
                localVarQueryParameter['$filter'] = $filter;
            }

            if ($select !== undefined) {
                localVarQueryParameter['$select'] = $select;
            }

            if ($orderby !== undefined) {
                localVarQueryParameter['$orderby'] = $orderby;
            }

            if ($expand !== undefined) {
                localVarQueryParameter['$expand'] = $expand;
            }

            if ($count !== undefined) {
                localVarQueryParameter['$count'] = $count;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [$top] The max number of records.
         * @param {string} [$apply] Allows to specify a sequence of transformations to the entity set, such as groupby, filter, aggregate, etc.
         * @param {number} [$skip] The number of records to skip.
         * @param {string} [$filter] A function that must evaluate to true for a record to be returned.
         * @param {string} [$select] Specifies a subset of properties to return. Use a comma separated list.
         * @param {string} [$orderby] Determines what values are used to order a collection of records.
         * @param {string} [$expand] Use to add related query data.
         * @param {boolean} [$count] Allows clients to request a count of the matching resources included with the resources in the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiV2FeedSuppliersCount: async ($top?: number, $apply?: string, $skip?: number, $filter?: string, $select?: string, $orderby?: string, $expand?: string, $count?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v2/FeedSuppliers/$count`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if ($top !== undefined) {
                localVarQueryParameter['$top'] = $top;
            }

            if ($apply !== undefined) {
                localVarQueryParameter['$apply'] = $apply;
            }

            if ($skip !== undefined) {
                localVarQueryParameter['$skip'] = $skip;
            }

            if ($filter !== undefined) {
                localVarQueryParameter['$filter'] = $filter;
            }

            if ($select !== undefined) {
                localVarQueryParameter['$select'] = $select;
            }

            if ($orderby !== undefined) {
                localVarQueryParameter['$orderby'] = $orderby;
            }

            if ($expand !== undefined) {
                localVarQueryParameter['$expand'] = $expand;
            }

            if ($count !== undefined) {
                localVarQueryParameter['$count'] = $count;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} key 
         * @param {number} [$top] The max number of records.
         * @param {string} [$apply] Allows to specify a sequence of transformations to the entity set, such as groupby, filter, aggregate, etc.
         * @param {number} [$skip] The number of records to skip.
         * @param {string} [$filter] A function that must evaluate to true for a record to be returned.
         * @param {string} [$select] Specifies a subset of properties to return. Use a comma separated list.
         * @param {string} [$orderby] Determines what values are used to order a collection of records.
         * @param {string} [$expand] Use to add related query data.
         * @param {boolean} [$count] Allows clients to request a count of the matching resources included with the resources in the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiV2FeedSuppliersKey: async (key: number, $top?: number, $apply?: string, $skip?: number, $filter?: string, $select?: string, $orderby?: string, $expand?: string, $count?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'key' is not null or undefined
            assertParamExists('getApiV2FeedSuppliersKey', 'key', key)
            const localVarPath = `/api/v2/FeedSuppliers({key})`
                .replace(`{${"key"}}`, encodeURIComponent(String(key)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if ($top !== undefined) {
                localVarQueryParameter['$top'] = $top;
            }

            if ($apply !== undefined) {
                localVarQueryParameter['$apply'] = $apply;
            }

            if ($skip !== undefined) {
                localVarQueryParameter['$skip'] = $skip;
            }

            if ($filter !== undefined) {
                localVarQueryParameter['$filter'] = $filter;
            }

            if ($select !== undefined) {
                localVarQueryParameter['$select'] = $select;
            }

            if ($orderby !== undefined) {
                localVarQueryParameter['$orderby'] = $orderby;
            }

            if ($expand !== undefined) {
                localVarQueryParameter['$expand'] = $expand;
            }

            if ($count !== undefined) {
                localVarQueryParameter['$count'] = $count;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} key 
         * @param {number} [$top] The max number of records.
         * @param {string} [$apply] Allows to specify a sequence of transformations to the entity set, such as groupby, filter, aggregate, etc.
         * @param {number} [$skip] The number of records to skip.
         * @param {string} [$filter] A function that must evaluate to true for a record to be returned.
         * @param {string} [$select] Specifies a subset of properties to return. Use a comma separated list.
         * @param {string} [$orderby] Determines what values are used to order a collection of records.
         * @param {string} [$expand] Use to add related query data.
         * @param {boolean} [$count] Allows clients to request a count of the matching resources included with the resources in the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiV2FeedSuppliersKey_2: async (key: number, $top?: number, $apply?: string, $skip?: number, $filter?: string, $select?: string, $orderby?: string, $expand?: string, $count?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'key' is not null or undefined
            assertParamExists('getApiV2FeedSuppliersKey_2', 'key', key)
            const localVarPath = `/api/v2/FeedSuppliers/{key}`
                .replace(`{${"key"}}`, encodeURIComponent(String(key)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if ($top !== undefined) {
                localVarQueryParameter['$top'] = $top;
            }

            if ($apply !== undefined) {
                localVarQueryParameter['$apply'] = $apply;
            }

            if ($skip !== undefined) {
                localVarQueryParameter['$skip'] = $skip;
            }

            if ($filter !== undefined) {
                localVarQueryParameter['$filter'] = $filter;
            }

            if ($select !== undefined) {
                localVarQueryParameter['$select'] = $select;
            }

            if ($orderby !== undefined) {
                localVarQueryParameter['$orderby'] = $orderby;
            }

            if ($expand !== undefined) {
                localVarQueryParameter['$expand'] = $expand;
            }

            if ($count !== undefined) {
                localVarQueryParameter['$count'] = $count;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} key 
         * @param {number} [$top] The max number of records.
         * @param {string} [$apply] Allows to specify a sequence of transformations to the entity set, such as groupby, filter, aggregate, etc.
         * @param {number} [$skip] The number of records to skip.
         * @param {string} [$filter] A function that must evaluate to true for a record to be returned.
         * @param {string} [$select] Specifies a subset of properties to return. Use a comma separated list.
         * @param {string} [$orderby] Determines what values are used to order a collection of records.
         * @param {string} [$expand] Use to add related query data.
         * @param {boolean} [$count] Allows clients to request a count of the matching resources included with the resources in the response.
         * @param {Array<Operation>} [operation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchApiV2FeedSuppliersKey: async (key: number, $top?: number, $apply?: string, $skip?: number, $filter?: string, $select?: string, $orderby?: string, $expand?: string, $count?: boolean, operation?: Array<Operation>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'key' is not null or undefined
            assertParamExists('patchApiV2FeedSuppliersKey', 'key', key)
            const localVarPath = `/api/v2/FeedSuppliers({key})`
                .replace(`{${"key"}}`, encodeURIComponent(String(key)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if ($top !== undefined) {
                localVarQueryParameter['$top'] = $top;
            }

            if ($apply !== undefined) {
                localVarQueryParameter['$apply'] = $apply;
            }

            if ($skip !== undefined) {
                localVarQueryParameter['$skip'] = $skip;
            }

            if ($filter !== undefined) {
                localVarQueryParameter['$filter'] = $filter;
            }

            if ($select !== undefined) {
                localVarQueryParameter['$select'] = $select;
            }

            if ($orderby !== undefined) {
                localVarQueryParameter['$orderby'] = $orderby;
            }

            if ($expand !== undefined) {
                localVarQueryParameter['$expand'] = $expand;
            }

            if ($count !== undefined) {
                localVarQueryParameter['$count'] = $count;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json;odata.metadata=minimal;odata.streaming=true';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(operation, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} key 
         * @param {number} [$top] The max number of records.
         * @param {string} [$apply] Allows to specify a sequence of transformations to the entity set, such as groupby, filter, aggregate, etc.
         * @param {number} [$skip] The number of records to skip.
         * @param {string} [$filter] A function that must evaluate to true for a record to be returned.
         * @param {string} [$select] Specifies a subset of properties to return. Use a comma separated list.
         * @param {string} [$orderby] Determines what values are used to order a collection of records.
         * @param {string} [$expand] Use to add related query data.
         * @param {boolean} [$count] Allows clients to request a count of the matching resources included with the resources in the response.
         * @param {Array<Operation>} [operation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchApiV2FeedSuppliersKey_3: async (key: number, $top?: number, $apply?: string, $skip?: number, $filter?: string, $select?: string, $orderby?: string, $expand?: string, $count?: boolean, operation?: Array<Operation>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'key' is not null or undefined
            assertParamExists('patchApiV2FeedSuppliersKey_3', 'key', key)
            const localVarPath = `/api/v2/FeedSuppliers/{key}`
                .replace(`{${"key"}}`, encodeURIComponent(String(key)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if ($top !== undefined) {
                localVarQueryParameter['$top'] = $top;
            }

            if ($apply !== undefined) {
                localVarQueryParameter['$apply'] = $apply;
            }

            if ($skip !== undefined) {
                localVarQueryParameter['$skip'] = $skip;
            }

            if ($filter !== undefined) {
                localVarQueryParameter['$filter'] = $filter;
            }

            if ($select !== undefined) {
                localVarQueryParameter['$select'] = $select;
            }

            if ($orderby !== undefined) {
                localVarQueryParameter['$orderby'] = $orderby;
            }

            if ($expand !== undefined) {
                localVarQueryParameter['$expand'] = $expand;
            }

            if ($count !== undefined) {
                localVarQueryParameter['$count'] = $count;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json;odata.metadata=minimal;odata.streaming=true';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(operation, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [$top] The max number of records.
         * @param {string} [$apply] Allows to specify a sequence of transformations to the entity set, such as groupby, filter, aggregate, etc.
         * @param {number} [$skip] The number of records to skip.
         * @param {string} [$filter] A function that must evaluate to true for a record to be returned.
         * @param {string} [$select] Specifies a subset of properties to return. Use a comma separated list.
         * @param {string} [$orderby] Determines what values are used to order a collection of records.
         * @param {string} [$expand] Use to add related query data.
         * @param {boolean} [$count] Allows clients to request a count of the matching resources included with the resources in the response.
         * @param {FeedSupplier} [feedSupplier] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postApiV2FeedSuppliers: async ($top?: number, $apply?: string, $skip?: number, $filter?: string, $select?: string, $orderby?: string, $expand?: string, $count?: boolean, feedSupplier?: FeedSupplier, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v2/FeedSuppliers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if ($top !== undefined) {
                localVarQueryParameter['$top'] = $top;
            }

            if ($apply !== undefined) {
                localVarQueryParameter['$apply'] = $apply;
            }

            if ($skip !== undefined) {
                localVarQueryParameter['$skip'] = $skip;
            }

            if ($filter !== undefined) {
                localVarQueryParameter['$filter'] = $filter;
            }

            if ($select !== undefined) {
                localVarQueryParameter['$select'] = $select;
            }

            if ($orderby !== undefined) {
                localVarQueryParameter['$orderby'] = $orderby;
            }

            if ($expand !== undefined) {
                localVarQueryParameter['$expand'] = $expand;
            }

            if ($count !== undefined) {
                localVarQueryParameter['$count'] = $count;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json;odata.metadata=minimal;odata.streaming=true';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(feedSupplier, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} key 
         * @param {number} [$top] The max number of records.
         * @param {string} [$apply] Allows to specify a sequence of transformations to the entity set, such as groupby, filter, aggregate, etc.
         * @param {number} [$skip] The number of records to skip.
         * @param {string} [$filter] A function that must evaluate to true for a record to be returned.
         * @param {string} [$select] Specifies a subset of properties to return. Use a comma separated list.
         * @param {string} [$orderby] Determines what values are used to order a collection of records.
         * @param {string} [$expand] Use to add related query data.
         * @param {boolean} [$count] Allows clients to request a count of the matching resources included with the resources in the response.
         * @param {FeedSupplier} [feedSupplier] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putApiV2FeedSuppliersKey: async (key: number, $top?: number, $apply?: string, $skip?: number, $filter?: string, $select?: string, $orderby?: string, $expand?: string, $count?: boolean, feedSupplier?: FeedSupplier, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'key' is not null or undefined
            assertParamExists('putApiV2FeedSuppliersKey', 'key', key)
            const localVarPath = `/api/v2/FeedSuppliers({key})`
                .replace(`{${"key"}}`, encodeURIComponent(String(key)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if ($top !== undefined) {
                localVarQueryParameter['$top'] = $top;
            }

            if ($apply !== undefined) {
                localVarQueryParameter['$apply'] = $apply;
            }

            if ($skip !== undefined) {
                localVarQueryParameter['$skip'] = $skip;
            }

            if ($filter !== undefined) {
                localVarQueryParameter['$filter'] = $filter;
            }

            if ($select !== undefined) {
                localVarQueryParameter['$select'] = $select;
            }

            if ($orderby !== undefined) {
                localVarQueryParameter['$orderby'] = $orderby;
            }

            if ($expand !== undefined) {
                localVarQueryParameter['$expand'] = $expand;
            }

            if ($count !== undefined) {
                localVarQueryParameter['$count'] = $count;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json;odata.metadata=minimal;odata.streaming=true';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(feedSupplier, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} key 
         * @param {number} [$top] The max number of records.
         * @param {string} [$apply] Allows to specify a sequence of transformations to the entity set, such as groupby, filter, aggregate, etc.
         * @param {number} [$skip] The number of records to skip.
         * @param {string} [$filter] A function that must evaluate to true for a record to be returned.
         * @param {string} [$select] Specifies a subset of properties to return. Use a comma separated list.
         * @param {string} [$orderby] Determines what values are used to order a collection of records.
         * @param {string} [$expand] Use to add related query data.
         * @param {boolean} [$count] Allows clients to request a count of the matching resources included with the resources in the response.
         * @param {FeedSupplier} [feedSupplier] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putApiV2FeedSuppliersKey_4: async (key: number, $top?: number, $apply?: string, $skip?: number, $filter?: string, $select?: string, $orderby?: string, $expand?: string, $count?: boolean, feedSupplier?: FeedSupplier, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'key' is not null or undefined
            assertParamExists('putApiV2FeedSuppliersKey_4', 'key', key)
            const localVarPath = `/api/v2/FeedSuppliers/{key}`
                .replace(`{${"key"}}`, encodeURIComponent(String(key)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if ($top !== undefined) {
                localVarQueryParameter['$top'] = $top;
            }

            if ($apply !== undefined) {
                localVarQueryParameter['$apply'] = $apply;
            }

            if ($skip !== undefined) {
                localVarQueryParameter['$skip'] = $skip;
            }

            if ($filter !== undefined) {
                localVarQueryParameter['$filter'] = $filter;
            }

            if ($select !== undefined) {
                localVarQueryParameter['$select'] = $select;
            }

            if ($orderby !== undefined) {
                localVarQueryParameter['$orderby'] = $orderby;
            }

            if ($expand !== undefined) {
                localVarQueryParameter['$expand'] = $expand;
            }

            if ($count !== undefined) {
                localVarQueryParameter['$count'] = $count;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json;odata.metadata=minimal;odata.streaming=true';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(feedSupplier, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FeedSuppliersApi - functional programming interface
 * @export
 */
export const FeedSuppliersApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FeedSuppliersApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} key 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteApiV2FeedSuppliersKey(key: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteApiV2FeedSuppliersKey(key, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} key 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteApiV2FeedSuppliersKey_1(key: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteApiV2FeedSuppliersKey_1(key, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [$top] The max number of records.
         * @param {string} [$apply] Allows to specify a sequence of transformations to the entity set, such as groupby, filter, aggregate, etc.
         * @param {number} [$skip] The number of records to skip.
         * @param {string} [$filter] A function that must evaluate to true for a record to be returned.
         * @param {string} [$select] Specifies a subset of properties to return. Use a comma separated list.
         * @param {string} [$orderby] Determines what values are used to order a collection of records.
         * @param {string} [$expand] Use to add related query data.
         * @param {boolean} [$count] Allows clients to request a count of the matching resources included with the resources in the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getApiV2FeedSuppliers($top?: number, $apply?: string, $skip?: number, $filter?: string, $select?: string, $orderby?: string, $expand?: string, $count?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FeedSupplierIEnumerableScaleODataValue>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getApiV2FeedSuppliers($top, $apply, $skip, $filter, $select, $orderby, $expand, $count, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [$top] The max number of records.
         * @param {string} [$apply] Allows to specify a sequence of transformations to the entity set, such as groupby, filter, aggregate, etc.
         * @param {number} [$skip] The number of records to skip.
         * @param {string} [$filter] A function that must evaluate to true for a record to be returned.
         * @param {string} [$select] Specifies a subset of properties to return. Use a comma separated list.
         * @param {string} [$orderby] Determines what values are used to order a collection of records.
         * @param {string} [$expand] Use to add related query data.
         * @param {boolean} [$count] Allows clients to request a count of the matching resources included with the resources in the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getApiV2FeedSuppliersCount($top?: number, $apply?: string, $skip?: number, $filter?: string, $select?: string, $orderby?: string, $expand?: string, $count?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FeedSupplierIEnumerableScaleODataValue>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getApiV2FeedSuppliersCount($top, $apply, $skip, $filter, $select, $orderby, $expand, $count, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} key 
         * @param {number} [$top] The max number of records.
         * @param {string} [$apply] Allows to specify a sequence of transformations to the entity set, such as groupby, filter, aggregate, etc.
         * @param {number} [$skip] The number of records to skip.
         * @param {string} [$filter] A function that must evaluate to true for a record to be returned.
         * @param {string} [$select] Specifies a subset of properties to return. Use a comma separated list.
         * @param {string} [$orderby] Determines what values are used to order a collection of records.
         * @param {string} [$expand] Use to add related query data.
         * @param {boolean} [$count] Allows clients to request a count of the matching resources included with the resources in the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getApiV2FeedSuppliersKey(key: number, $top?: number, $apply?: string, $skip?: number, $filter?: string, $select?: string, $orderby?: string, $expand?: string, $count?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FeedSupplier>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getApiV2FeedSuppliersKey(key, $top, $apply, $skip, $filter, $select, $orderby, $expand, $count, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} key 
         * @param {number} [$top] The max number of records.
         * @param {string} [$apply] Allows to specify a sequence of transformations to the entity set, such as groupby, filter, aggregate, etc.
         * @param {number} [$skip] The number of records to skip.
         * @param {string} [$filter] A function that must evaluate to true for a record to be returned.
         * @param {string} [$select] Specifies a subset of properties to return. Use a comma separated list.
         * @param {string} [$orderby] Determines what values are used to order a collection of records.
         * @param {string} [$expand] Use to add related query data.
         * @param {boolean} [$count] Allows clients to request a count of the matching resources included with the resources in the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getApiV2FeedSuppliersKey_2(key: number, $top?: number, $apply?: string, $skip?: number, $filter?: string, $select?: string, $orderby?: string, $expand?: string, $count?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FeedSupplier>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getApiV2FeedSuppliersKey_2(key, $top, $apply, $skip, $filter, $select, $orderby, $expand, $count, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} key 
         * @param {number} [$top] The max number of records.
         * @param {string} [$apply] Allows to specify a sequence of transformations to the entity set, such as groupby, filter, aggregate, etc.
         * @param {number} [$skip] The number of records to skip.
         * @param {string} [$filter] A function that must evaluate to true for a record to be returned.
         * @param {string} [$select] Specifies a subset of properties to return. Use a comma separated list.
         * @param {string} [$orderby] Determines what values are used to order a collection of records.
         * @param {string} [$expand] Use to add related query data.
         * @param {boolean} [$count] Allows clients to request a count of the matching resources included with the resources in the response.
         * @param {Array<Operation>} [operation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchApiV2FeedSuppliersKey(key: number, $top?: number, $apply?: string, $skip?: number, $filter?: string, $select?: string, $orderby?: string, $expand?: string, $count?: boolean, operation?: Array<Operation>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FeedSupplier>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patchApiV2FeedSuppliersKey(key, $top, $apply, $skip, $filter, $select, $orderby, $expand, $count, operation, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} key 
         * @param {number} [$top] The max number of records.
         * @param {string} [$apply] Allows to specify a sequence of transformations to the entity set, such as groupby, filter, aggregate, etc.
         * @param {number} [$skip] The number of records to skip.
         * @param {string} [$filter] A function that must evaluate to true for a record to be returned.
         * @param {string} [$select] Specifies a subset of properties to return. Use a comma separated list.
         * @param {string} [$orderby] Determines what values are used to order a collection of records.
         * @param {string} [$expand] Use to add related query data.
         * @param {boolean} [$count] Allows clients to request a count of the matching resources included with the resources in the response.
         * @param {Array<Operation>} [operation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchApiV2FeedSuppliersKey_3(key: number, $top?: number, $apply?: string, $skip?: number, $filter?: string, $select?: string, $orderby?: string, $expand?: string, $count?: boolean, operation?: Array<Operation>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FeedSupplier>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patchApiV2FeedSuppliersKey_3(key, $top, $apply, $skip, $filter, $select, $orderby, $expand, $count, operation, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [$top] The max number of records.
         * @param {string} [$apply] Allows to specify a sequence of transformations to the entity set, such as groupby, filter, aggregate, etc.
         * @param {number} [$skip] The number of records to skip.
         * @param {string} [$filter] A function that must evaluate to true for a record to be returned.
         * @param {string} [$select] Specifies a subset of properties to return. Use a comma separated list.
         * @param {string} [$orderby] Determines what values are used to order a collection of records.
         * @param {string} [$expand] Use to add related query data.
         * @param {boolean} [$count] Allows clients to request a count of the matching resources included with the resources in the response.
         * @param {FeedSupplier} [feedSupplier] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postApiV2FeedSuppliers($top?: number, $apply?: string, $skip?: number, $filter?: string, $select?: string, $orderby?: string, $expand?: string, $count?: boolean, feedSupplier?: FeedSupplier, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FeedSupplier>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postApiV2FeedSuppliers($top, $apply, $skip, $filter, $select, $orderby, $expand, $count, feedSupplier, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} key 
         * @param {number} [$top] The max number of records.
         * @param {string} [$apply] Allows to specify a sequence of transformations to the entity set, such as groupby, filter, aggregate, etc.
         * @param {number} [$skip] The number of records to skip.
         * @param {string} [$filter] A function that must evaluate to true for a record to be returned.
         * @param {string} [$select] Specifies a subset of properties to return. Use a comma separated list.
         * @param {string} [$orderby] Determines what values are used to order a collection of records.
         * @param {string} [$expand] Use to add related query data.
         * @param {boolean} [$count] Allows clients to request a count of the matching resources included with the resources in the response.
         * @param {FeedSupplier} [feedSupplier] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putApiV2FeedSuppliersKey(key: number, $top?: number, $apply?: string, $skip?: number, $filter?: string, $select?: string, $orderby?: string, $expand?: string, $count?: boolean, feedSupplier?: FeedSupplier, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FeedSupplier>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putApiV2FeedSuppliersKey(key, $top, $apply, $skip, $filter, $select, $orderby, $expand, $count, feedSupplier, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} key 
         * @param {number} [$top] The max number of records.
         * @param {string} [$apply] Allows to specify a sequence of transformations to the entity set, such as groupby, filter, aggregate, etc.
         * @param {number} [$skip] The number of records to skip.
         * @param {string} [$filter] A function that must evaluate to true for a record to be returned.
         * @param {string} [$select] Specifies a subset of properties to return. Use a comma separated list.
         * @param {string} [$orderby] Determines what values are used to order a collection of records.
         * @param {string} [$expand] Use to add related query data.
         * @param {boolean} [$count] Allows clients to request a count of the matching resources included with the resources in the response.
         * @param {FeedSupplier} [feedSupplier] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putApiV2FeedSuppliersKey_4(key: number, $top?: number, $apply?: string, $skip?: number, $filter?: string, $select?: string, $orderby?: string, $expand?: string, $count?: boolean, feedSupplier?: FeedSupplier, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FeedSupplier>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putApiV2FeedSuppliersKey_4(key, $top, $apply, $skip, $filter, $select, $orderby, $expand, $count, feedSupplier, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * FeedSuppliersApi - factory interface
 * @export
 */
export const FeedSuppliersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FeedSuppliersApiFp(configuration)
    return {
        /**
         * 
         * @param {FeedSuppliersApiDeleteApiV2FeedSuppliersKeyRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteApiV2FeedSuppliersKey(requestParameters: FeedSuppliersApiDeleteApiV2FeedSuppliersKeyRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.deleteApiV2FeedSuppliersKey(requestParameters.key, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {FeedSuppliersApiDeleteApiV2FeedSuppliersKey0Request} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteApiV2FeedSuppliersKey_1(requestParameters: FeedSuppliersApiDeleteApiV2FeedSuppliersKey0Request, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.deleteApiV2FeedSuppliersKey_1(requestParameters.key, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {FeedSuppliersApiGetApiV2FeedSuppliersRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiV2FeedSuppliers(requestParameters: FeedSuppliersApiGetApiV2FeedSuppliersRequest = {}, options?: AxiosRequestConfig): AxiosPromise<FeedSupplierIEnumerableScaleODataValue> {
            return localVarFp.getApiV2FeedSuppliers(requestParameters.$top, requestParameters.$apply, requestParameters.$skip, requestParameters.$filter, requestParameters.$select, requestParameters.$orderby, requestParameters.$expand, requestParameters.$count, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {FeedSuppliersApiGetApiV2FeedSuppliersCountRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiV2FeedSuppliersCount(requestParameters: FeedSuppliersApiGetApiV2FeedSuppliersCountRequest = {}, options?: AxiosRequestConfig): AxiosPromise<FeedSupplierIEnumerableScaleODataValue> {
            return localVarFp.getApiV2FeedSuppliersCount(requestParameters.$top, requestParameters.$apply, requestParameters.$skip, requestParameters.$filter, requestParameters.$select, requestParameters.$orderby, requestParameters.$expand, requestParameters.$count, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {FeedSuppliersApiGetApiV2FeedSuppliersKeyRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiV2FeedSuppliersKey(requestParameters: FeedSuppliersApiGetApiV2FeedSuppliersKeyRequest, options?: AxiosRequestConfig): AxiosPromise<FeedSupplier> {
            return localVarFp.getApiV2FeedSuppliersKey(requestParameters.key, requestParameters.$top, requestParameters.$apply, requestParameters.$skip, requestParameters.$filter, requestParameters.$select, requestParameters.$orderby, requestParameters.$expand, requestParameters.$count, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {FeedSuppliersApiGetApiV2FeedSuppliersKey0Request} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiV2FeedSuppliersKey_2(requestParameters: FeedSuppliersApiGetApiV2FeedSuppliersKey0Request, options?: AxiosRequestConfig): AxiosPromise<FeedSupplier> {
            return localVarFp.getApiV2FeedSuppliersKey_2(requestParameters.key, requestParameters.$top, requestParameters.$apply, requestParameters.$skip, requestParameters.$filter, requestParameters.$select, requestParameters.$orderby, requestParameters.$expand, requestParameters.$count, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {FeedSuppliersApiPatchApiV2FeedSuppliersKeyRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchApiV2FeedSuppliersKey(requestParameters: FeedSuppliersApiPatchApiV2FeedSuppliersKeyRequest, options?: AxiosRequestConfig): AxiosPromise<FeedSupplier> {
            return localVarFp.patchApiV2FeedSuppliersKey(requestParameters.key, requestParameters.$top, requestParameters.$apply, requestParameters.$skip, requestParameters.$filter, requestParameters.$select, requestParameters.$orderby, requestParameters.$expand, requestParameters.$count, requestParameters.operation, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {FeedSuppliersApiPatchApiV2FeedSuppliersKey0Request} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchApiV2FeedSuppliersKey_3(requestParameters: FeedSuppliersApiPatchApiV2FeedSuppliersKey0Request, options?: AxiosRequestConfig): AxiosPromise<FeedSupplier> {
            return localVarFp.patchApiV2FeedSuppliersKey_3(requestParameters.key, requestParameters.$top, requestParameters.$apply, requestParameters.$skip, requestParameters.$filter, requestParameters.$select, requestParameters.$orderby, requestParameters.$expand, requestParameters.$count, requestParameters.operation, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {FeedSuppliersApiPostApiV2FeedSuppliersRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postApiV2FeedSuppliers(requestParameters: FeedSuppliersApiPostApiV2FeedSuppliersRequest = {}, options?: AxiosRequestConfig): AxiosPromise<FeedSupplier> {
            return localVarFp.postApiV2FeedSuppliers(requestParameters.$top, requestParameters.$apply, requestParameters.$skip, requestParameters.$filter, requestParameters.$select, requestParameters.$orderby, requestParameters.$expand, requestParameters.$count, requestParameters.feedSupplier, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {FeedSuppliersApiPutApiV2FeedSuppliersKeyRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putApiV2FeedSuppliersKey(requestParameters: FeedSuppliersApiPutApiV2FeedSuppliersKeyRequest, options?: AxiosRequestConfig): AxiosPromise<FeedSupplier> {
            return localVarFp.putApiV2FeedSuppliersKey(requestParameters.key, requestParameters.$top, requestParameters.$apply, requestParameters.$skip, requestParameters.$filter, requestParameters.$select, requestParameters.$orderby, requestParameters.$expand, requestParameters.$count, requestParameters.feedSupplier, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {FeedSuppliersApiPutApiV2FeedSuppliersKey0Request} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putApiV2FeedSuppliersKey_4(requestParameters: FeedSuppliersApiPutApiV2FeedSuppliersKey0Request, options?: AxiosRequestConfig): AxiosPromise<FeedSupplier> {
            return localVarFp.putApiV2FeedSuppliersKey_4(requestParameters.key, requestParameters.$top, requestParameters.$apply, requestParameters.$skip, requestParameters.$filter, requestParameters.$select, requestParameters.$orderby, requestParameters.$expand, requestParameters.$count, requestParameters.feedSupplier, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * FeedSuppliersApi - interface
 * @export
 * @interface FeedSuppliersApi
 */
export interface FeedSuppliersApiInterface {
    /**
     * 
     * @param {number} key 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeedSuppliersApiInterface
     */
    deleteApiV2FeedSuppliersKey(key: number, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @param {number} key 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeedSuppliersApiInterface
     */
    deleteApiV2FeedSuppliersKey_1(key: number, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @param {number} [$top] The max number of records.
     * @param {string} [$apply] Allows to specify a sequence of transformations to the entity set, such as groupby, filter, aggregate, etc.
     * @param {number} [$skip] The number of records to skip.
     * @param {string} [$filter] A function that must evaluate to true for a record to be returned.
     * @param {string} [$select] Specifies a subset of properties to return. Use a comma separated list.
     * @param {string} [$orderby] Determines what values are used to order a collection of records.
     * @param {string} [$expand] Use to add related query data.
     * @param {boolean} [$count] Allows clients to request a count of the matching resources included with the resources in the response.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeedSuppliersApiInterface
     */
    getApiV2FeedSuppliers($top?: number, $apply?: string, $skip?: number, $filter?: string, $select?: string, $orderby?: string, $expand?: string, $count?: boolean, options?: AxiosRequestConfig): AxiosPromise<FeedSupplierIEnumerableScaleODataValue>;

    /**
     * 
     * @param {number} [$top] The max number of records.
     * @param {string} [$apply] Allows to specify a sequence of transformations to the entity set, such as groupby, filter, aggregate, etc.
     * @param {number} [$skip] The number of records to skip.
     * @param {string} [$filter] A function that must evaluate to true for a record to be returned.
     * @param {string} [$select] Specifies a subset of properties to return. Use a comma separated list.
     * @param {string} [$orderby] Determines what values are used to order a collection of records.
     * @param {string} [$expand] Use to add related query data.
     * @param {boolean} [$count] Allows clients to request a count of the matching resources included with the resources in the response.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeedSuppliersApiInterface
     */
    getApiV2FeedSuppliersCount($top?: number, $apply?: string, $skip?: number, $filter?: string, $select?: string, $orderby?: string, $expand?: string, $count?: boolean, options?: AxiosRequestConfig): AxiosPromise<FeedSupplierIEnumerableScaleODataValue>;

    /**
     * 
     * @param {number} key 
     * @param {number} [$top] The max number of records.
     * @param {string} [$apply] Allows to specify a sequence of transformations to the entity set, such as groupby, filter, aggregate, etc.
     * @param {number} [$skip] The number of records to skip.
     * @param {string} [$filter] A function that must evaluate to true for a record to be returned.
     * @param {string} [$select] Specifies a subset of properties to return. Use a comma separated list.
     * @param {string} [$orderby] Determines what values are used to order a collection of records.
     * @param {string} [$expand] Use to add related query data.
     * @param {boolean} [$count] Allows clients to request a count of the matching resources included with the resources in the response.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeedSuppliersApiInterface
     */
    getApiV2FeedSuppliersKey(key: number, $top?: number, $apply?: string, $skip?: number, $filter?: string, $select?: string, $orderby?: string, $expand?: string, $count?: boolean, options?: AxiosRequestConfig): AxiosPromise<FeedSupplier>;

    /**
     * 
     * @param {number} key 
     * @param {number} [$top] The max number of records.
     * @param {string} [$apply] Allows to specify a sequence of transformations to the entity set, such as groupby, filter, aggregate, etc.
     * @param {number} [$skip] The number of records to skip.
     * @param {string} [$filter] A function that must evaluate to true for a record to be returned.
     * @param {string} [$select] Specifies a subset of properties to return. Use a comma separated list.
     * @param {string} [$orderby] Determines what values are used to order a collection of records.
     * @param {string} [$expand] Use to add related query data.
     * @param {boolean} [$count] Allows clients to request a count of the matching resources included with the resources in the response.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeedSuppliersApiInterface
     */
    getApiV2FeedSuppliersKey_2(key: number, $top?: number, $apply?: string, $skip?: number, $filter?: string, $select?: string, $orderby?: string, $expand?: string, $count?: boolean, options?: AxiosRequestConfig): AxiosPromise<FeedSupplier>;

    /**
     * 
     * @param {number} key 
     * @param {number} [$top] The max number of records.
     * @param {string} [$apply] Allows to specify a sequence of transformations to the entity set, such as groupby, filter, aggregate, etc.
     * @param {number} [$skip] The number of records to skip.
     * @param {string} [$filter] A function that must evaluate to true for a record to be returned.
     * @param {string} [$select] Specifies a subset of properties to return. Use a comma separated list.
     * @param {string} [$orderby] Determines what values are used to order a collection of records.
     * @param {string} [$expand] Use to add related query data.
     * @param {boolean} [$count] Allows clients to request a count of the matching resources included with the resources in the response.
     * @param {Array<Operation>} [operation] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeedSuppliersApiInterface
     */
    patchApiV2FeedSuppliersKey(key: number, $top?: number, $apply?: string, $skip?: number, $filter?: string, $select?: string, $orderby?: string, $expand?: string, $count?: boolean, operation?: Array<Operation>, options?: AxiosRequestConfig): AxiosPromise<FeedSupplier>;

    /**
     * 
     * @param {number} key 
     * @param {number} [$top] The max number of records.
     * @param {string} [$apply] Allows to specify a sequence of transformations to the entity set, such as groupby, filter, aggregate, etc.
     * @param {number} [$skip] The number of records to skip.
     * @param {string} [$filter] A function that must evaluate to true for a record to be returned.
     * @param {string} [$select] Specifies a subset of properties to return. Use a comma separated list.
     * @param {string} [$orderby] Determines what values are used to order a collection of records.
     * @param {string} [$expand] Use to add related query data.
     * @param {boolean} [$count] Allows clients to request a count of the matching resources included with the resources in the response.
     * @param {Array<Operation>} [operation] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeedSuppliersApiInterface
     */
    patchApiV2FeedSuppliersKey_3(key: number, $top?: number, $apply?: string, $skip?: number, $filter?: string, $select?: string, $orderby?: string, $expand?: string, $count?: boolean, operation?: Array<Operation>, options?: AxiosRequestConfig): AxiosPromise<FeedSupplier>;

    /**
     * 
     * @param {number} [$top] The max number of records.
     * @param {string} [$apply] Allows to specify a sequence of transformations to the entity set, such as groupby, filter, aggregate, etc.
     * @param {number} [$skip] The number of records to skip.
     * @param {string} [$filter] A function that must evaluate to true for a record to be returned.
     * @param {string} [$select] Specifies a subset of properties to return. Use a comma separated list.
     * @param {string} [$orderby] Determines what values are used to order a collection of records.
     * @param {string} [$expand] Use to add related query data.
     * @param {boolean} [$count] Allows clients to request a count of the matching resources included with the resources in the response.
     * @param {FeedSupplier} [feedSupplier] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeedSuppliersApiInterface
     */
    postApiV2FeedSuppliers($top?: number, $apply?: string, $skip?: number, $filter?: string, $select?: string, $orderby?: string, $expand?: string, $count?: boolean, feedSupplier?: FeedSupplier, options?: AxiosRequestConfig): AxiosPromise<FeedSupplier>;

    /**
     * 
     * @param {number} key 
     * @param {number} [$top] The max number of records.
     * @param {string} [$apply] Allows to specify a sequence of transformations to the entity set, such as groupby, filter, aggregate, etc.
     * @param {number} [$skip] The number of records to skip.
     * @param {string} [$filter] A function that must evaluate to true for a record to be returned.
     * @param {string} [$select] Specifies a subset of properties to return. Use a comma separated list.
     * @param {string} [$orderby] Determines what values are used to order a collection of records.
     * @param {string} [$expand] Use to add related query data.
     * @param {boolean} [$count] Allows clients to request a count of the matching resources included with the resources in the response.
     * @param {FeedSupplier} [feedSupplier] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeedSuppliersApiInterface
     */
    putApiV2FeedSuppliersKey(key: number, $top?: number, $apply?: string, $skip?: number, $filter?: string, $select?: string, $orderby?: string, $expand?: string, $count?: boolean, feedSupplier?: FeedSupplier, options?: AxiosRequestConfig): AxiosPromise<FeedSupplier>;

    /**
     * 
     * @param {number} key 
     * @param {number} [$top] The max number of records.
     * @param {string} [$apply] Allows to specify a sequence of transformations to the entity set, such as groupby, filter, aggregate, etc.
     * @param {number} [$skip] The number of records to skip.
     * @param {string} [$filter] A function that must evaluate to true for a record to be returned.
     * @param {string} [$select] Specifies a subset of properties to return. Use a comma separated list.
     * @param {string} [$orderby] Determines what values are used to order a collection of records.
     * @param {string} [$expand] Use to add related query data.
     * @param {boolean} [$count] Allows clients to request a count of the matching resources included with the resources in the response.
     * @param {FeedSupplier} [feedSupplier] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeedSuppliersApiInterface
     */
    putApiV2FeedSuppliersKey_4(key: number, $top?: number, $apply?: string, $skip?: number, $filter?: string, $select?: string, $orderby?: string, $expand?: string, $count?: boolean, feedSupplier?: FeedSupplier, options?: AxiosRequestConfig): AxiosPromise<FeedSupplier>;

}

/**
 * Request parameters for deleteApiV2FeedSuppliersKey operation in FeedSuppliersApi.
 * @export
 * @interface FeedSuppliersApiDeleteApiV2FeedSuppliersKeyRequest
 */
export interface FeedSuppliersApiDeleteApiV2FeedSuppliersKeyRequest {
    /**
     * 
     * @type {number}
     * @memberof FeedSuppliersApiDeleteApiV2FeedSuppliersKey
     */
    readonly key: number
}

/**
 * Request parameters for deleteApiV2FeedSuppliersKey_1 operation in FeedSuppliersApi.
 * @export
 * @interface FeedSuppliersApiDeleteApiV2FeedSuppliersKey0Request
 */
export interface FeedSuppliersApiDeleteApiV2FeedSuppliersKey0Request {
    /**
     * 
     * @type {number}
     * @memberof FeedSuppliersApiDeleteApiV2FeedSuppliersKey0
     */
    readonly key: number
}

/**
 * Request parameters for getApiV2FeedSuppliers operation in FeedSuppliersApi.
 * @export
 * @interface FeedSuppliersApiGetApiV2FeedSuppliersRequest
 */
export interface FeedSuppliersApiGetApiV2FeedSuppliersRequest {
    /**
     * The max number of records.
     * @type {number}
     * @memberof FeedSuppliersApiGetApiV2FeedSuppliers
     */
    readonly $top?: number

    /**
     * Allows to specify a sequence of transformations to the entity set, such as groupby, filter, aggregate, etc.
     * @type {string}
     * @memberof FeedSuppliersApiGetApiV2FeedSuppliers
     */
    readonly $apply?: string

    /**
     * The number of records to skip.
     * @type {number}
     * @memberof FeedSuppliersApiGetApiV2FeedSuppliers
     */
    readonly $skip?: number

    /**
     * A function that must evaluate to true for a record to be returned.
     * @type {string}
     * @memberof FeedSuppliersApiGetApiV2FeedSuppliers
     */
    readonly $filter?: string

    /**
     * Specifies a subset of properties to return. Use a comma separated list.
     * @type {string}
     * @memberof FeedSuppliersApiGetApiV2FeedSuppliers
     */
    readonly $select?: string

    /**
     * Determines what values are used to order a collection of records.
     * @type {string}
     * @memberof FeedSuppliersApiGetApiV2FeedSuppliers
     */
    readonly $orderby?: string

    /**
     * Use to add related query data.
     * @type {string}
     * @memberof FeedSuppliersApiGetApiV2FeedSuppliers
     */
    readonly $expand?: string

    /**
     * Allows clients to request a count of the matching resources included with the resources in the response.
     * @type {boolean}
     * @memberof FeedSuppliersApiGetApiV2FeedSuppliers
     */
    readonly $count?: boolean
}

/**
 * Request parameters for getApiV2FeedSuppliersCount operation in FeedSuppliersApi.
 * @export
 * @interface FeedSuppliersApiGetApiV2FeedSuppliersCountRequest
 */
export interface FeedSuppliersApiGetApiV2FeedSuppliersCountRequest {
    /**
     * The max number of records.
     * @type {number}
     * @memberof FeedSuppliersApiGetApiV2FeedSuppliersCount
     */
    readonly $top?: number

    /**
     * Allows to specify a sequence of transformations to the entity set, such as groupby, filter, aggregate, etc.
     * @type {string}
     * @memberof FeedSuppliersApiGetApiV2FeedSuppliersCount
     */
    readonly $apply?: string

    /**
     * The number of records to skip.
     * @type {number}
     * @memberof FeedSuppliersApiGetApiV2FeedSuppliersCount
     */
    readonly $skip?: number

    /**
     * A function that must evaluate to true for a record to be returned.
     * @type {string}
     * @memberof FeedSuppliersApiGetApiV2FeedSuppliersCount
     */
    readonly $filter?: string

    /**
     * Specifies a subset of properties to return. Use a comma separated list.
     * @type {string}
     * @memberof FeedSuppliersApiGetApiV2FeedSuppliersCount
     */
    readonly $select?: string

    /**
     * Determines what values are used to order a collection of records.
     * @type {string}
     * @memberof FeedSuppliersApiGetApiV2FeedSuppliersCount
     */
    readonly $orderby?: string

    /**
     * Use to add related query data.
     * @type {string}
     * @memberof FeedSuppliersApiGetApiV2FeedSuppliersCount
     */
    readonly $expand?: string

    /**
     * Allows clients to request a count of the matching resources included with the resources in the response.
     * @type {boolean}
     * @memberof FeedSuppliersApiGetApiV2FeedSuppliersCount
     */
    readonly $count?: boolean
}

/**
 * Request parameters for getApiV2FeedSuppliersKey operation in FeedSuppliersApi.
 * @export
 * @interface FeedSuppliersApiGetApiV2FeedSuppliersKeyRequest
 */
export interface FeedSuppliersApiGetApiV2FeedSuppliersKeyRequest {
    /**
     * 
     * @type {number}
     * @memberof FeedSuppliersApiGetApiV2FeedSuppliersKey
     */
    readonly key: number

    /**
     * The max number of records.
     * @type {number}
     * @memberof FeedSuppliersApiGetApiV2FeedSuppliersKey
     */
    readonly $top?: number

    /**
     * Allows to specify a sequence of transformations to the entity set, such as groupby, filter, aggregate, etc.
     * @type {string}
     * @memberof FeedSuppliersApiGetApiV2FeedSuppliersKey
     */
    readonly $apply?: string

    /**
     * The number of records to skip.
     * @type {number}
     * @memberof FeedSuppliersApiGetApiV2FeedSuppliersKey
     */
    readonly $skip?: number

    /**
     * A function that must evaluate to true for a record to be returned.
     * @type {string}
     * @memberof FeedSuppliersApiGetApiV2FeedSuppliersKey
     */
    readonly $filter?: string

    /**
     * Specifies a subset of properties to return. Use a comma separated list.
     * @type {string}
     * @memberof FeedSuppliersApiGetApiV2FeedSuppliersKey
     */
    readonly $select?: string

    /**
     * Determines what values are used to order a collection of records.
     * @type {string}
     * @memberof FeedSuppliersApiGetApiV2FeedSuppliersKey
     */
    readonly $orderby?: string

    /**
     * Use to add related query data.
     * @type {string}
     * @memberof FeedSuppliersApiGetApiV2FeedSuppliersKey
     */
    readonly $expand?: string

    /**
     * Allows clients to request a count of the matching resources included with the resources in the response.
     * @type {boolean}
     * @memberof FeedSuppliersApiGetApiV2FeedSuppliersKey
     */
    readonly $count?: boolean
}

/**
 * Request parameters for getApiV2FeedSuppliersKey_2 operation in FeedSuppliersApi.
 * @export
 * @interface FeedSuppliersApiGetApiV2FeedSuppliersKey0Request
 */
export interface FeedSuppliersApiGetApiV2FeedSuppliersKey0Request {
    /**
     * 
     * @type {number}
     * @memberof FeedSuppliersApiGetApiV2FeedSuppliersKey0
     */
    readonly key: number

    /**
     * The max number of records.
     * @type {number}
     * @memberof FeedSuppliersApiGetApiV2FeedSuppliersKey0
     */
    readonly $top?: number

    /**
     * Allows to specify a sequence of transformations to the entity set, such as groupby, filter, aggregate, etc.
     * @type {string}
     * @memberof FeedSuppliersApiGetApiV2FeedSuppliersKey0
     */
    readonly $apply?: string

    /**
     * The number of records to skip.
     * @type {number}
     * @memberof FeedSuppliersApiGetApiV2FeedSuppliersKey0
     */
    readonly $skip?: number

    /**
     * A function that must evaluate to true for a record to be returned.
     * @type {string}
     * @memberof FeedSuppliersApiGetApiV2FeedSuppliersKey0
     */
    readonly $filter?: string

    /**
     * Specifies a subset of properties to return. Use a comma separated list.
     * @type {string}
     * @memberof FeedSuppliersApiGetApiV2FeedSuppliersKey0
     */
    readonly $select?: string

    /**
     * Determines what values are used to order a collection of records.
     * @type {string}
     * @memberof FeedSuppliersApiGetApiV2FeedSuppliersKey0
     */
    readonly $orderby?: string

    /**
     * Use to add related query data.
     * @type {string}
     * @memberof FeedSuppliersApiGetApiV2FeedSuppliersKey0
     */
    readonly $expand?: string

    /**
     * Allows clients to request a count of the matching resources included with the resources in the response.
     * @type {boolean}
     * @memberof FeedSuppliersApiGetApiV2FeedSuppliersKey0
     */
    readonly $count?: boolean
}

/**
 * Request parameters for patchApiV2FeedSuppliersKey operation in FeedSuppliersApi.
 * @export
 * @interface FeedSuppliersApiPatchApiV2FeedSuppliersKeyRequest
 */
export interface FeedSuppliersApiPatchApiV2FeedSuppliersKeyRequest {
    /**
     * 
     * @type {number}
     * @memberof FeedSuppliersApiPatchApiV2FeedSuppliersKey
     */
    readonly key: number

    /**
     * The max number of records.
     * @type {number}
     * @memberof FeedSuppliersApiPatchApiV2FeedSuppliersKey
     */
    readonly $top?: number

    /**
     * Allows to specify a sequence of transformations to the entity set, such as groupby, filter, aggregate, etc.
     * @type {string}
     * @memberof FeedSuppliersApiPatchApiV2FeedSuppliersKey
     */
    readonly $apply?: string

    /**
     * The number of records to skip.
     * @type {number}
     * @memberof FeedSuppliersApiPatchApiV2FeedSuppliersKey
     */
    readonly $skip?: number

    /**
     * A function that must evaluate to true for a record to be returned.
     * @type {string}
     * @memberof FeedSuppliersApiPatchApiV2FeedSuppliersKey
     */
    readonly $filter?: string

    /**
     * Specifies a subset of properties to return. Use a comma separated list.
     * @type {string}
     * @memberof FeedSuppliersApiPatchApiV2FeedSuppliersKey
     */
    readonly $select?: string

    /**
     * Determines what values are used to order a collection of records.
     * @type {string}
     * @memberof FeedSuppliersApiPatchApiV2FeedSuppliersKey
     */
    readonly $orderby?: string

    /**
     * Use to add related query data.
     * @type {string}
     * @memberof FeedSuppliersApiPatchApiV2FeedSuppliersKey
     */
    readonly $expand?: string

    /**
     * Allows clients to request a count of the matching resources included with the resources in the response.
     * @type {boolean}
     * @memberof FeedSuppliersApiPatchApiV2FeedSuppliersKey
     */
    readonly $count?: boolean

    /**
     * 
     * @type {Array<Operation>}
     * @memberof FeedSuppliersApiPatchApiV2FeedSuppliersKey
     */
    readonly operation?: Array<Operation>
}

/**
 * Request parameters for patchApiV2FeedSuppliersKey_3 operation in FeedSuppliersApi.
 * @export
 * @interface FeedSuppliersApiPatchApiV2FeedSuppliersKey0Request
 */
export interface FeedSuppliersApiPatchApiV2FeedSuppliersKey0Request {
    /**
     * 
     * @type {number}
     * @memberof FeedSuppliersApiPatchApiV2FeedSuppliersKey0
     */
    readonly key: number

    /**
     * The max number of records.
     * @type {number}
     * @memberof FeedSuppliersApiPatchApiV2FeedSuppliersKey0
     */
    readonly $top?: number

    /**
     * Allows to specify a sequence of transformations to the entity set, such as groupby, filter, aggregate, etc.
     * @type {string}
     * @memberof FeedSuppliersApiPatchApiV2FeedSuppliersKey0
     */
    readonly $apply?: string

    /**
     * The number of records to skip.
     * @type {number}
     * @memberof FeedSuppliersApiPatchApiV2FeedSuppliersKey0
     */
    readonly $skip?: number

    /**
     * A function that must evaluate to true for a record to be returned.
     * @type {string}
     * @memberof FeedSuppliersApiPatchApiV2FeedSuppliersKey0
     */
    readonly $filter?: string

    /**
     * Specifies a subset of properties to return. Use a comma separated list.
     * @type {string}
     * @memberof FeedSuppliersApiPatchApiV2FeedSuppliersKey0
     */
    readonly $select?: string

    /**
     * Determines what values are used to order a collection of records.
     * @type {string}
     * @memberof FeedSuppliersApiPatchApiV2FeedSuppliersKey0
     */
    readonly $orderby?: string

    /**
     * Use to add related query data.
     * @type {string}
     * @memberof FeedSuppliersApiPatchApiV2FeedSuppliersKey0
     */
    readonly $expand?: string

    /**
     * Allows clients to request a count of the matching resources included with the resources in the response.
     * @type {boolean}
     * @memberof FeedSuppliersApiPatchApiV2FeedSuppliersKey0
     */
    readonly $count?: boolean

    /**
     * 
     * @type {Array<Operation>}
     * @memberof FeedSuppliersApiPatchApiV2FeedSuppliersKey0
     */
    readonly operation?: Array<Operation>
}

/**
 * Request parameters for postApiV2FeedSuppliers operation in FeedSuppliersApi.
 * @export
 * @interface FeedSuppliersApiPostApiV2FeedSuppliersRequest
 */
export interface FeedSuppliersApiPostApiV2FeedSuppliersRequest {
    /**
     * The max number of records.
     * @type {number}
     * @memberof FeedSuppliersApiPostApiV2FeedSuppliers
     */
    readonly $top?: number

    /**
     * Allows to specify a sequence of transformations to the entity set, such as groupby, filter, aggregate, etc.
     * @type {string}
     * @memberof FeedSuppliersApiPostApiV2FeedSuppliers
     */
    readonly $apply?: string

    /**
     * The number of records to skip.
     * @type {number}
     * @memberof FeedSuppliersApiPostApiV2FeedSuppliers
     */
    readonly $skip?: number

    /**
     * A function that must evaluate to true for a record to be returned.
     * @type {string}
     * @memberof FeedSuppliersApiPostApiV2FeedSuppliers
     */
    readonly $filter?: string

    /**
     * Specifies a subset of properties to return. Use a comma separated list.
     * @type {string}
     * @memberof FeedSuppliersApiPostApiV2FeedSuppliers
     */
    readonly $select?: string

    /**
     * Determines what values are used to order a collection of records.
     * @type {string}
     * @memberof FeedSuppliersApiPostApiV2FeedSuppliers
     */
    readonly $orderby?: string

    /**
     * Use to add related query data.
     * @type {string}
     * @memberof FeedSuppliersApiPostApiV2FeedSuppliers
     */
    readonly $expand?: string

    /**
     * Allows clients to request a count of the matching resources included with the resources in the response.
     * @type {boolean}
     * @memberof FeedSuppliersApiPostApiV2FeedSuppliers
     */
    readonly $count?: boolean

    /**
     * 
     * @type {FeedSupplier}
     * @memberof FeedSuppliersApiPostApiV2FeedSuppliers
     */
    readonly feedSupplier?: FeedSupplier
}

/**
 * Request parameters for putApiV2FeedSuppliersKey operation in FeedSuppliersApi.
 * @export
 * @interface FeedSuppliersApiPutApiV2FeedSuppliersKeyRequest
 */
export interface FeedSuppliersApiPutApiV2FeedSuppliersKeyRequest {
    /**
     * 
     * @type {number}
     * @memberof FeedSuppliersApiPutApiV2FeedSuppliersKey
     */
    readonly key: number

    /**
     * The max number of records.
     * @type {number}
     * @memberof FeedSuppliersApiPutApiV2FeedSuppliersKey
     */
    readonly $top?: number

    /**
     * Allows to specify a sequence of transformations to the entity set, such as groupby, filter, aggregate, etc.
     * @type {string}
     * @memberof FeedSuppliersApiPutApiV2FeedSuppliersKey
     */
    readonly $apply?: string

    /**
     * The number of records to skip.
     * @type {number}
     * @memberof FeedSuppliersApiPutApiV2FeedSuppliersKey
     */
    readonly $skip?: number

    /**
     * A function that must evaluate to true for a record to be returned.
     * @type {string}
     * @memberof FeedSuppliersApiPutApiV2FeedSuppliersKey
     */
    readonly $filter?: string

    /**
     * Specifies a subset of properties to return. Use a comma separated list.
     * @type {string}
     * @memberof FeedSuppliersApiPutApiV2FeedSuppliersKey
     */
    readonly $select?: string

    /**
     * Determines what values are used to order a collection of records.
     * @type {string}
     * @memberof FeedSuppliersApiPutApiV2FeedSuppliersKey
     */
    readonly $orderby?: string

    /**
     * Use to add related query data.
     * @type {string}
     * @memberof FeedSuppliersApiPutApiV2FeedSuppliersKey
     */
    readonly $expand?: string

    /**
     * Allows clients to request a count of the matching resources included with the resources in the response.
     * @type {boolean}
     * @memberof FeedSuppliersApiPutApiV2FeedSuppliersKey
     */
    readonly $count?: boolean

    /**
     * 
     * @type {FeedSupplier}
     * @memberof FeedSuppliersApiPutApiV2FeedSuppliersKey
     */
    readonly feedSupplier?: FeedSupplier
}

/**
 * Request parameters for putApiV2FeedSuppliersKey_4 operation in FeedSuppliersApi.
 * @export
 * @interface FeedSuppliersApiPutApiV2FeedSuppliersKey0Request
 */
export interface FeedSuppliersApiPutApiV2FeedSuppliersKey0Request {
    /**
     * 
     * @type {number}
     * @memberof FeedSuppliersApiPutApiV2FeedSuppliersKey0
     */
    readonly key: number

    /**
     * The max number of records.
     * @type {number}
     * @memberof FeedSuppliersApiPutApiV2FeedSuppliersKey0
     */
    readonly $top?: number

    /**
     * Allows to specify a sequence of transformations to the entity set, such as groupby, filter, aggregate, etc.
     * @type {string}
     * @memberof FeedSuppliersApiPutApiV2FeedSuppliersKey0
     */
    readonly $apply?: string

    /**
     * The number of records to skip.
     * @type {number}
     * @memberof FeedSuppliersApiPutApiV2FeedSuppliersKey0
     */
    readonly $skip?: number

    /**
     * A function that must evaluate to true for a record to be returned.
     * @type {string}
     * @memberof FeedSuppliersApiPutApiV2FeedSuppliersKey0
     */
    readonly $filter?: string

    /**
     * Specifies a subset of properties to return. Use a comma separated list.
     * @type {string}
     * @memberof FeedSuppliersApiPutApiV2FeedSuppliersKey0
     */
    readonly $select?: string

    /**
     * Determines what values are used to order a collection of records.
     * @type {string}
     * @memberof FeedSuppliersApiPutApiV2FeedSuppliersKey0
     */
    readonly $orderby?: string

    /**
     * Use to add related query data.
     * @type {string}
     * @memberof FeedSuppliersApiPutApiV2FeedSuppliersKey0
     */
    readonly $expand?: string

    /**
     * Allows clients to request a count of the matching resources included with the resources in the response.
     * @type {boolean}
     * @memberof FeedSuppliersApiPutApiV2FeedSuppliersKey0
     */
    readonly $count?: boolean

    /**
     * 
     * @type {FeedSupplier}
     * @memberof FeedSuppliersApiPutApiV2FeedSuppliersKey0
     */
    readonly feedSupplier?: FeedSupplier
}

/**
 * FeedSuppliersApi - object-oriented interface
 * @export
 * @class FeedSuppliersApi
 * @extends {BaseAPI}
 */
export class FeedSuppliersApi extends BaseAPI implements FeedSuppliersApiInterface {
    /**
     * 
     * @param {FeedSuppliersApiDeleteApiV2FeedSuppliersKeyRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeedSuppliersApi
     */
    public deleteApiV2FeedSuppliersKey(requestParameters: FeedSuppliersApiDeleteApiV2FeedSuppliersKeyRequest, options?: AxiosRequestConfig) {
        return FeedSuppliersApiFp(this.configuration).deleteApiV2FeedSuppliersKey(requestParameters.key, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {FeedSuppliersApiDeleteApiV2FeedSuppliersKey0Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeedSuppliersApi
     */
    public deleteApiV2FeedSuppliersKey_1(requestParameters: FeedSuppliersApiDeleteApiV2FeedSuppliersKey0Request, options?: AxiosRequestConfig) {
        return FeedSuppliersApiFp(this.configuration).deleteApiV2FeedSuppliersKey_1(requestParameters.key, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {FeedSuppliersApiGetApiV2FeedSuppliersRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeedSuppliersApi
     */
    public getApiV2FeedSuppliers(requestParameters: FeedSuppliersApiGetApiV2FeedSuppliersRequest = {}, options?: AxiosRequestConfig) {
        return FeedSuppliersApiFp(this.configuration).getApiV2FeedSuppliers(requestParameters.$top, requestParameters.$apply, requestParameters.$skip, requestParameters.$filter, requestParameters.$select, requestParameters.$orderby, requestParameters.$expand, requestParameters.$count, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {FeedSuppliersApiGetApiV2FeedSuppliersCountRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeedSuppliersApi
     */
    public getApiV2FeedSuppliersCount(requestParameters: FeedSuppliersApiGetApiV2FeedSuppliersCountRequest = {}, options?: AxiosRequestConfig) {
        return FeedSuppliersApiFp(this.configuration).getApiV2FeedSuppliersCount(requestParameters.$top, requestParameters.$apply, requestParameters.$skip, requestParameters.$filter, requestParameters.$select, requestParameters.$orderby, requestParameters.$expand, requestParameters.$count, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {FeedSuppliersApiGetApiV2FeedSuppliersKeyRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeedSuppliersApi
     */
    public getApiV2FeedSuppliersKey(requestParameters: FeedSuppliersApiGetApiV2FeedSuppliersKeyRequest, options?: AxiosRequestConfig) {
        return FeedSuppliersApiFp(this.configuration).getApiV2FeedSuppliersKey(requestParameters.key, requestParameters.$top, requestParameters.$apply, requestParameters.$skip, requestParameters.$filter, requestParameters.$select, requestParameters.$orderby, requestParameters.$expand, requestParameters.$count, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {FeedSuppliersApiGetApiV2FeedSuppliersKey0Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeedSuppliersApi
     */
    public getApiV2FeedSuppliersKey_2(requestParameters: FeedSuppliersApiGetApiV2FeedSuppliersKey0Request, options?: AxiosRequestConfig) {
        return FeedSuppliersApiFp(this.configuration).getApiV2FeedSuppliersKey_2(requestParameters.key, requestParameters.$top, requestParameters.$apply, requestParameters.$skip, requestParameters.$filter, requestParameters.$select, requestParameters.$orderby, requestParameters.$expand, requestParameters.$count, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {FeedSuppliersApiPatchApiV2FeedSuppliersKeyRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeedSuppliersApi
     */
    public patchApiV2FeedSuppliersKey(requestParameters: FeedSuppliersApiPatchApiV2FeedSuppliersKeyRequest, options?: AxiosRequestConfig) {
        return FeedSuppliersApiFp(this.configuration).patchApiV2FeedSuppliersKey(requestParameters.key, requestParameters.$top, requestParameters.$apply, requestParameters.$skip, requestParameters.$filter, requestParameters.$select, requestParameters.$orderby, requestParameters.$expand, requestParameters.$count, requestParameters.operation, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {FeedSuppliersApiPatchApiV2FeedSuppliersKey0Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeedSuppliersApi
     */
    public patchApiV2FeedSuppliersKey_3(requestParameters: FeedSuppliersApiPatchApiV2FeedSuppliersKey0Request, options?: AxiosRequestConfig) {
        return FeedSuppliersApiFp(this.configuration).patchApiV2FeedSuppliersKey_3(requestParameters.key, requestParameters.$top, requestParameters.$apply, requestParameters.$skip, requestParameters.$filter, requestParameters.$select, requestParameters.$orderby, requestParameters.$expand, requestParameters.$count, requestParameters.operation, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {FeedSuppliersApiPostApiV2FeedSuppliersRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeedSuppliersApi
     */
    public postApiV2FeedSuppliers(requestParameters: FeedSuppliersApiPostApiV2FeedSuppliersRequest = {}, options?: AxiosRequestConfig) {
        return FeedSuppliersApiFp(this.configuration).postApiV2FeedSuppliers(requestParameters.$top, requestParameters.$apply, requestParameters.$skip, requestParameters.$filter, requestParameters.$select, requestParameters.$orderby, requestParameters.$expand, requestParameters.$count, requestParameters.feedSupplier, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {FeedSuppliersApiPutApiV2FeedSuppliersKeyRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeedSuppliersApi
     */
    public putApiV2FeedSuppliersKey(requestParameters: FeedSuppliersApiPutApiV2FeedSuppliersKeyRequest, options?: AxiosRequestConfig) {
        return FeedSuppliersApiFp(this.configuration).putApiV2FeedSuppliersKey(requestParameters.key, requestParameters.$top, requestParameters.$apply, requestParameters.$skip, requestParameters.$filter, requestParameters.$select, requestParameters.$orderby, requestParameters.$expand, requestParameters.$count, requestParameters.feedSupplier, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {FeedSuppliersApiPutApiV2FeedSuppliersKey0Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeedSuppliersApi
     */
    public putApiV2FeedSuppliersKey_4(requestParameters: FeedSuppliersApiPutApiV2FeedSuppliersKey0Request, options?: AxiosRequestConfig) {
        return FeedSuppliersApiFp(this.configuration).putApiV2FeedSuppliersKey_4(requestParameters.key, requestParameters.$top, requestParameters.$apply, requestParameters.$skip, requestParameters.$filter, requestParameters.$select, requestParameters.$orderby, requestParameters.$expand, requestParameters.$count, requestParameters.feedSupplier, options).then((request) => request(this.axios, this.basePath));
    }
}
