// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * UniteAQ Access API
 * Access API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @enum {string}
 */

export const AccessType = {
    Entity: 'Entity',
    Role: 'Role',
    Machine: 'Machine'
} as const;

export type AccessType = typeof AccessType[keyof typeof AccessType];



