import { AccessApiV1Models, AccessAreaName } from "api/clients/accessApiV1Client";
import {
  AccessLevel,
  CompanyAccessWeb,
  SiteAccessWeb,
  UserAccessWeb,
} from "api/swagger/uniteaq.access.api.1.0";
import { managementPortalAreas, scaleRoles } from "constants/scaleRoles";

// this should most likely be defined in the Access API
export enum AccessGroupNameWeb {
  Admin = "Admin",
}

export enum AccessAreaNameWeb {
  IdentityProviders = "identity-providers",
}

const isAccessGroup = (ag: any): ag is AccessApiV1Models.AccessGroupWeb => {
  return ag.accessAreas?.length > 0;
};

const levelStringToNumber = (level: AccessApiV1Models.AccessLevel) => {
  switch (level) {
    case "NoAccess":
      return 0;
    case "Read":
      return 1;
    case "Write":
      return 2;
    case "Admin":
      return 3;
    default:
      return 0;
  }
};

const managementRolesList = [scaleRoles.admin, scaleRoles.supportAgent];
const adminRolesList = [scaleRoles.admin];

export const hasAnyRoles = (user: AccessApiV1Models.UserAccessWeb) => {
  if (!user) {
    return null;
  }

  return (user?.role || user?.companyAccesses.some((c) => c.accessGroups.length > 0)) ?? false;
};

export const isSiteAdmin = (companies: AccessApiV1Models.CompanyAccessWeb[]) => {
  if (!companies) {
    return null;
  }

  companies = (companies ?? []).filter((x) => !!x);
  if (!companies?.length) return false;

  const sites = companies.map((c) => c.sites).flat();
  const entityGroups = companies.map((c) => c.entityGroups).flat();

  const accessGroups = [
    ...sites.map((s) => s.accessGroups),
    ...entityGroups.map((eg) => eg.accessGroups),
  ].flat();

  return (
    accessGroups.some((c) =>
      c.accessAreas?.some((aa) => managementPortalAreas.includes(aa.areaName as AccessAreaName)),
    ) ?? false
  );
};

export const hasAdminPrivileges = (userAccess: AccessApiV1Models.UserAccessWeb) => {
  if (!userAccess) {
    return null;
  }

  return adminRolesList.some((x) => userAccess?.role?.name == x);
};
export const hasManagementPrivileges = (userAccess: AccessApiV1Models.UserAccessWeb) => {
  if (!userAccess) {
    return null;
  }

  return managementRolesList.some((x) => userAccess.role?.name == x);
};

export const getCompanyAccesses = (
  userAccess: UserAccessWeb,
  accessAreaName: AccessAreaName,
  accessLevel: AccessLevel = null,
): CompanyAccessWeb[] => {
  return userAccess?.companyAccesses?.filter((companyAccess) =>
    companyAccess.accessGroups.some((accessGroup) =>
      accessGroup.accessAreas.some(
        (accessArea) =>
          accessArea.areaName == accessAreaName &&
          (accessLevel == null || accessArea.accessLevel == accessLevel),
      ),
    ),
  );
};

export const hasCompanyAccess = (
  userAccess: UserAccessWeb,
  accessAreaName: AccessAreaName,
  accessLevel: AccessLevel = null,
): boolean => {
  return getCompanyAccesses(userAccess, accessAreaName, accessLevel)?.length > 0;
};

export const getSiteAcceses = (
  userAccess: UserAccessWeb,
  accessAreaName: AccessAreaName,
  accessLevel: AccessLevel = null,
): SiteAccessWeb[] => {
  return userAccess?.companyAccesses
    ?.map((companyAccess) =>
      companyAccess.sites.filter((siteAccess) =>
        siteAccess.accessGroups.some((accessGroup) =>
          accessGroup.accessAreas.some(
            (accessArea) =>
              accessArea.areaName == accessAreaName &&
              (accessLevel == null || accessArea.accessLevel == accessLevel),
          ),
        ),
      ),
    )
    .flat();
};

export const hasSiteAccess = (
  userAccess: UserAccessWeb,
  accessAreaName: AccessAreaName,
  accessLevel: AccessLevel = null,
): boolean => {
  return getSiteAcceses(userAccess, accessAreaName, accessLevel)?.length > 0;
};

const canAccessCompanyPrivate = (
  company: AccessApiV1Models.CompanyAccessWeb,
  accessArea: AccessAreaName,
  level: AccessApiV1Models.AccessLevel,
) => {
  if (!company?.accessGroups) return false;

  const levelNumber = levelStringToNumber(level);
  const accessGroups = company.accessGroups;
  for (let i = 0; i < accessGroups.length; i++) {
    const ag = accessGroups[i];
    if (isAccessGroup(ag)) {
      const agCanAccess = ag.accessAreas.some(
        (x) => x.areaName === accessArea && levelStringToNumber(x.accessLevel) >= levelNumber,
      );

      if (agCanAccess) {
        return true;
      }
    }
  }

  return false;
};

export function canAccessCompany(
  company: AccessApiV1Models.CompanyAccessWeb,
  accessArea: AccessAreaName[],
  level: AccessApiV1Models.AccessLevel,
): boolean;
export function canAccessCompany(
  company: AccessApiV1Models.CompanyAccessWeb,
  accessArea: AccessAreaName,
  level: AccessApiV1Models.AccessLevel,
): boolean;
export function canAccessCompany(
  company: AccessApiV1Models.CompanyAccessWeb,
  accessArea: unknown,
  level: AccessApiV1Models.AccessLevel,
) {
  if (typeof accessArea === "string") {
    return canAccessCompanyPrivate(company, accessArea as AccessAreaName, level);
  }

  return (accessArea as AccessAreaName[]).some((aa) => canAccessCompanyPrivate(company, aa, level));
}

export const hasAccessToGroup = (
  user: AccessApiV1Models.UserAccessWeb,
  companyId: string,
  accessGroupId: number,
) => {
  if (!user || !companyId || !accessGroupId) return false;
  const companyAccess = user.companyAccesses?.find((c) => c.gosId === companyId);
  if (companyAccess) {
    const entityGroupsAccessGroupes = companyAccess.entityGroups?.map((e) => e.accessGroups).flat();
    const sitesAccessGroupes = companyAccess.sites?.map((e) => e.accessGroups).flat();
    const accessGroupes = [...entityGroupsAccessGroupes, ...sitesAccessGroupes];

    return !!accessGroupes.find((a) => a.id === accessGroupId);
  }
  return false;
};

export const hasAccessToEntityGroup = (
  user: AccessApiV1Models.UserAccessWeb,
  companyId: string,
  entityGroupId: number,
) => {
  if (!user || !companyId || !entityGroupId) return false;
  const companyAccess = user.companyAccesses?.find((c) => c.gosId === companyId);
  if (companyAccess) {
    return companyAccess.entityGroups.some((x) => x.gosId === entityGroupId);
  }
  return false;
};

export const isChileanCompany = (countryCode: string) => {
  //Currently only companies based in Chile can have parent companies
  return countryCode === "CL";
};
