import React, { useMemo } from "react";
import { AppBar, NavBar, Icon } from "@scaleaq/scaleui";
import Link from "next/link";
import styled from "styled-components";
import { useAppFrame } from "./useAppFrame";
import { getEnvUrlPart } from "utils/urlUtils";

const AppFrame = () => {
  const {
    mobileMenuOpen,
    onToggleMenu,
    menuItems,
    onCompanyChange,
    showLeftMenu,
    session,
    title,
    selectedCompanyValue,
    companyOptions,
    userMenu,
    selectedPath,
    mainMenuOpen,
    setMainMenuOpen,
    productHref,
  } = useAppFrame();

  const envTag = useMemo(() => {
    const envUrlPart = getEnvUrlPart(window.location.href);
    if (envUrlPart === "") {
      return undefined;
    }
    let env;
    switch (envUrlPart) {
      case "-dev":
        env = "dev";
        break;
      case "-stage":
        env = "stage";
        break;
    }
    return { env, version: undefined as string | undefined };
  }, []);

  return [
    <StyledAppBar
      key={"app-bar"}
      position="static"
      productName={title}
      logo={<Icon.Large.ManagementPortal />}
      productHref={productHref}
      menuOpen={mobileMenuOpen}
      onToggleMenu={onToggleMenu}
      LinkComponent={Link}
      setCompany={onCompanyChange}
      company={selectedCompanyValue}
      companies={companyOptions}
      userName={session?.profile?.name ?? ""}
      userMenu={userMenu}
      envTag={envTag}
    />,
    showLeftMenu ? (
      <NavBar
        key={"drawer"}
        open={mainMenuOpen}
        setOpen={setMainMenuOpen}
        selectedId={selectedPath}
        LinkComponent={Link}
        links={menuItems}
      />
    ) : null,
  ];
};

export default AppFrame;

const StyledAppBar = styled(AppBar)`
  min-height: 5.6rem;
`;
