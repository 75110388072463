import React, { useEffect } from "react";
import { AppProps } from "next/app";
import { parseUrl } from "query-string";

import { getUrlWithoutLanguage } from "utils/urlUtils";
import { isClientSide } from "utils/commonUtils";
import { URL_QUERY_DEFAULT_KEY } from "hooks/useSessionUrlQuery/constants";
import { useRouter } from "next/router";

const removeFiltersFromStore = (storeKey: string, urlWithoutLanguage: string): void => {
  const _sessionStorage = isClientSide() ? sessionStorage : null;
  const store = _sessionStorage?.getItem(storeKey) || "{}";
  const parsedStore = JSON.parse(store);

  const rootPath = urlWithoutLanguage.split("/")[1];
  const nextStore = Object.fromEntries(
    Object.entries(parsedStore).filter(([key]) => {
      const rootPathInKey = key.split("/")[1];

      // if both starting url chunks in sessionStorage item and current url match, preserve
      // e.g. /data/, /users/
      return rootPath == rootPathInKey;
    }),
  );
  _sessionStorage?.setItem(storeKey, JSON.stringify(nextStore));
};

const cleanupFiltersStore = (url: string) => {
  const _sessionStorage = isClientSide() ? sessionStorage : null;
  const { url: parsedUrl } = parseUrl(url);

  const urlWithoutLanguage = getUrlWithoutLanguage(parsedUrl);
  /* eslint-disable no-console */
  console.log("urlWithoutLanguage", urlWithoutLanguage);

  // home page
  if (urlWithoutLanguage == "/" || urlWithoutLanguage == "") {
    _sessionStorage?.setItem(URL_QUERY_DEFAULT_KEY, JSON.stringify({}));
    return;
  }

  removeFiltersFromStore(URL_QUERY_DEFAULT_KEY, urlWithoutLanguage);
};
export const withFilterStore = <Props extends AppProps = AppProps>(
  WrappedComponent: React.ComponentType<Props>,
) => {
  const ComponentWithFilterStore = (props: Props) => {
    const router = useRouter();

    useEffect(() => {
      router.events.on("routeChangeStart", cleanupFiltersStore);

      // If the component is unmounted, unsubscribe
      // from the event with the `off` method:
      return () => {
        router.events.off("routeChangeStart", cleanupFiltersStore);
      };
    }, []);

    return <WrappedComponent {...props} />;
  };
  return ComponentWithFilterStore;
};
