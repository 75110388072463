import Axios from "axios";

export const fetcher = async (url: string) => {
  const { data } = await Axios.get(url);
  return data;
};
export const poster = async <T = any>(url: string, body: any): Promise<T> => {
  const { data } = await Axios.post(url, body);
  return data;
};
