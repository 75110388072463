import { CssBaseline } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import { ScaleColor } from "@scaleaq/scaleui-react/core/colors";
import { ScaleSnackbarProvider } from "@scaleaq/scaleui-react/core/components/ScaleSnackbar";
import { ScaleuiWrapper as OldScaleuiWrapper } from "@scaleaq/scaleui-react/core/wrappers/wrappers";
import ScaleUiRegistry from "lib/scaleUiRegistry";
import type { AppProps } from "next/app";
import { useRouter } from "next/router";
import FontFace from "pageStyles/FontFace";
import ManagementPortalGlobalStyles from "pageStyles/ManagementPortalGlobalStyles";
import React from "react";
import theme from "theme/light";
import { Theme } from "@scaleaq/scaleui";
import { useAppSelector } from "hooks/useAppSelector";
import { selectUiTheme } from "../../redux/slices/ui/uiSelectors";

declare module "@mui/material/styles" {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface Theme {
    colors: ScaleColor;
    layout: any;
  }
}

const withTheme = <Props extends AppProps>(WrappedComponent: React.ComponentType<Props>) => {
  const ComponentWithTheme = (props: Props) => {
    const router = useRouter();
    const locale = router.locale || router.defaultLocale;

    const SuiTheme = useAppSelector(selectUiTheme);

    return (
      <ScaleUiRegistry theme={SuiTheme ?? Theme.LIGHT}>
        <OldScaleuiWrapper locale={locale}>
          <ThemeProvider theme={theme}>
            <ScaleSnackbarProvider>
              <CssBaseline />
              <FontFace />
              <ManagementPortalGlobalStyles />
              <WrappedComponent {...props} />
            </ScaleSnackbarProvider>
          </ThemeProvider>
        </OldScaleuiWrapper>
      </ScaleUiRegistry>
    );
  };
  return React.memo(ComponentWithTheme);
};

export default withTheme;
