// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Operation Services API
 * Operation Services API
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { FeedProductLine } from '../model';
// @ts-ignore
import { FeedProductLineIEnumerableScaleODataValue } from '../model';
// @ts-ignore
import { Operation } from '../model';
/**
 * FeedProductLinesApi - axios parameter creator
 * @export
 */
export const FeedProductLinesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} key 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteApiV2FeedProductLinesKey: async (key: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'key' is not null or undefined
            assertParamExists('deleteApiV2FeedProductLinesKey', 'key', key)
            const localVarPath = `/api/v2/FeedProductLines({key})`
                .replace(`{${"key"}}`, encodeURIComponent(String(key)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} key 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteApiV2FeedProductLinesKey_1: async (key: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'key' is not null or undefined
            assertParamExists('deleteApiV2FeedProductLinesKey_1', 'key', key)
            const localVarPath = `/api/v2/FeedProductLines/{key}`
                .replace(`{${"key"}}`, encodeURIComponent(String(key)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [$top] The max number of records.
         * @param {string} [$apply] Allows to specify a sequence of transformations to the entity set, such as groupby, filter, aggregate, etc.
         * @param {number} [$skip] The number of records to skip.
         * @param {string} [$filter] A function that must evaluate to true for a record to be returned.
         * @param {string} [$select] Specifies a subset of properties to return. Use a comma separated list.
         * @param {string} [$orderby] Determines what values are used to order a collection of records.
         * @param {string} [$expand] Use to add related query data.
         * @param {boolean} [$count] Allows clients to request a count of the matching resources included with the resources in the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiV2FeedProductLines: async ($top?: number, $apply?: string, $skip?: number, $filter?: string, $select?: string, $orderby?: string, $expand?: string, $count?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v2/FeedProductLines`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if ($top !== undefined) {
                localVarQueryParameter['$top'] = $top;
            }

            if ($apply !== undefined) {
                localVarQueryParameter['$apply'] = $apply;
            }

            if ($skip !== undefined) {
                localVarQueryParameter['$skip'] = $skip;
            }

            if ($filter !== undefined) {
                localVarQueryParameter['$filter'] = $filter;
            }

            if ($select !== undefined) {
                localVarQueryParameter['$select'] = $select;
            }

            if ($orderby !== undefined) {
                localVarQueryParameter['$orderby'] = $orderby;
            }

            if ($expand !== undefined) {
                localVarQueryParameter['$expand'] = $expand;
            }

            if ($count !== undefined) {
                localVarQueryParameter['$count'] = $count;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [$top] The max number of records.
         * @param {string} [$apply] Allows to specify a sequence of transformations to the entity set, such as groupby, filter, aggregate, etc.
         * @param {number} [$skip] The number of records to skip.
         * @param {string} [$filter] A function that must evaluate to true for a record to be returned.
         * @param {string} [$select] Specifies a subset of properties to return. Use a comma separated list.
         * @param {string} [$orderby] Determines what values are used to order a collection of records.
         * @param {string} [$expand] Use to add related query data.
         * @param {boolean} [$count] Allows clients to request a count of the matching resources included with the resources in the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiV2FeedProductLinesCount: async ($top?: number, $apply?: string, $skip?: number, $filter?: string, $select?: string, $orderby?: string, $expand?: string, $count?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v2/FeedProductLines/$count`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if ($top !== undefined) {
                localVarQueryParameter['$top'] = $top;
            }

            if ($apply !== undefined) {
                localVarQueryParameter['$apply'] = $apply;
            }

            if ($skip !== undefined) {
                localVarQueryParameter['$skip'] = $skip;
            }

            if ($filter !== undefined) {
                localVarQueryParameter['$filter'] = $filter;
            }

            if ($select !== undefined) {
                localVarQueryParameter['$select'] = $select;
            }

            if ($orderby !== undefined) {
                localVarQueryParameter['$orderby'] = $orderby;
            }

            if ($expand !== undefined) {
                localVarQueryParameter['$expand'] = $expand;
            }

            if ($count !== undefined) {
                localVarQueryParameter['$count'] = $count;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} key 
         * @param {number} [$top] The max number of records.
         * @param {string} [$apply] Allows to specify a sequence of transformations to the entity set, such as groupby, filter, aggregate, etc.
         * @param {number} [$skip] The number of records to skip.
         * @param {string} [$filter] A function that must evaluate to true for a record to be returned.
         * @param {string} [$select] Specifies a subset of properties to return. Use a comma separated list.
         * @param {string} [$orderby] Determines what values are used to order a collection of records.
         * @param {string} [$expand] Use to add related query data.
         * @param {boolean} [$count] Allows clients to request a count of the matching resources included with the resources in the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiV2FeedProductLinesKey: async (key: number, $top?: number, $apply?: string, $skip?: number, $filter?: string, $select?: string, $orderby?: string, $expand?: string, $count?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'key' is not null or undefined
            assertParamExists('getApiV2FeedProductLinesKey', 'key', key)
            const localVarPath = `/api/v2/FeedProductLines({key})`
                .replace(`{${"key"}}`, encodeURIComponent(String(key)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if ($top !== undefined) {
                localVarQueryParameter['$top'] = $top;
            }

            if ($apply !== undefined) {
                localVarQueryParameter['$apply'] = $apply;
            }

            if ($skip !== undefined) {
                localVarQueryParameter['$skip'] = $skip;
            }

            if ($filter !== undefined) {
                localVarQueryParameter['$filter'] = $filter;
            }

            if ($select !== undefined) {
                localVarQueryParameter['$select'] = $select;
            }

            if ($orderby !== undefined) {
                localVarQueryParameter['$orderby'] = $orderby;
            }

            if ($expand !== undefined) {
                localVarQueryParameter['$expand'] = $expand;
            }

            if ($count !== undefined) {
                localVarQueryParameter['$count'] = $count;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} key 
         * @param {number} [$top] The max number of records.
         * @param {string} [$apply] Allows to specify a sequence of transformations to the entity set, such as groupby, filter, aggregate, etc.
         * @param {number} [$skip] The number of records to skip.
         * @param {string} [$filter] A function that must evaluate to true for a record to be returned.
         * @param {string} [$select] Specifies a subset of properties to return. Use a comma separated list.
         * @param {string} [$orderby] Determines what values are used to order a collection of records.
         * @param {string} [$expand] Use to add related query data.
         * @param {boolean} [$count] Allows clients to request a count of the matching resources included with the resources in the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiV2FeedProductLinesKey_2: async (key: number, $top?: number, $apply?: string, $skip?: number, $filter?: string, $select?: string, $orderby?: string, $expand?: string, $count?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'key' is not null or undefined
            assertParamExists('getApiV2FeedProductLinesKey_2', 'key', key)
            const localVarPath = `/api/v2/FeedProductLines/{key}`
                .replace(`{${"key"}}`, encodeURIComponent(String(key)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if ($top !== undefined) {
                localVarQueryParameter['$top'] = $top;
            }

            if ($apply !== undefined) {
                localVarQueryParameter['$apply'] = $apply;
            }

            if ($skip !== undefined) {
                localVarQueryParameter['$skip'] = $skip;
            }

            if ($filter !== undefined) {
                localVarQueryParameter['$filter'] = $filter;
            }

            if ($select !== undefined) {
                localVarQueryParameter['$select'] = $select;
            }

            if ($orderby !== undefined) {
                localVarQueryParameter['$orderby'] = $orderby;
            }

            if ($expand !== undefined) {
                localVarQueryParameter['$expand'] = $expand;
            }

            if ($count !== undefined) {
                localVarQueryParameter['$count'] = $count;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} key 
         * @param {number} [$top] The max number of records.
         * @param {string} [$apply] Allows to specify a sequence of transformations to the entity set, such as groupby, filter, aggregate, etc.
         * @param {number} [$skip] The number of records to skip.
         * @param {string} [$filter] A function that must evaluate to true for a record to be returned.
         * @param {string} [$select] Specifies a subset of properties to return. Use a comma separated list.
         * @param {string} [$orderby] Determines what values are used to order a collection of records.
         * @param {string} [$expand] Use to add related query data.
         * @param {boolean} [$count] Allows clients to request a count of the matching resources included with the resources in the response.
         * @param {Array<Operation>} [operation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchApiV2FeedProductLinesKey: async (key: number, $top?: number, $apply?: string, $skip?: number, $filter?: string, $select?: string, $orderby?: string, $expand?: string, $count?: boolean, operation?: Array<Operation>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'key' is not null or undefined
            assertParamExists('patchApiV2FeedProductLinesKey', 'key', key)
            const localVarPath = `/api/v2/FeedProductLines({key})`
                .replace(`{${"key"}}`, encodeURIComponent(String(key)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if ($top !== undefined) {
                localVarQueryParameter['$top'] = $top;
            }

            if ($apply !== undefined) {
                localVarQueryParameter['$apply'] = $apply;
            }

            if ($skip !== undefined) {
                localVarQueryParameter['$skip'] = $skip;
            }

            if ($filter !== undefined) {
                localVarQueryParameter['$filter'] = $filter;
            }

            if ($select !== undefined) {
                localVarQueryParameter['$select'] = $select;
            }

            if ($orderby !== undefined) {
                localVarQueryParameter['$orderby'] = $orderby;
            }

            if ($expand !== undefined) {
                localVarQueryParameter['$expand'] = $expand;
            }

            if ($count !== undefined) {
                localVarQueryParameter['$count'] = $count;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json;odata.metadata=minimal;odata.streaming=true';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(operation, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} key 
         * @param {number} [$top] The max number of records.
         * @param {string} [$apply] Allows to specify a sequence of transformations to the entity set, such as groupby, filter, aggregate, etc.
         * @param {number} [$skip] The number of records to skip.
         * @param {string} [$filter] A function that must evaluate to true for a record to be returned.
         * @param {string} [$select] Specifies a subset of properties to return. Use a comma separated list.
         * @param {string} [$orderby] Determines what values are used to order a collection of records.
         * @param {string} [$expand] Use to add related query data.
         * @param {boolean} [$count] Allows clients to request a count of the matching resources included with the resources in the response.
         * @param {Array<Operation>} [operation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchApiV2FeedProductLinesKey_3: async (key: number, $top?: number, $apply?: string, $skip?: number, $filter?: string, $select?: string, $orderby?: string, $expand?: string, $count?: boolean, operation?: Array<Operation>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'key' is not null or undefined
            assertParamExists('patchApiV2FeedProductLinesKey_3', 'key', key)
            const localVarPath = `/api/v2/FeedProductLines/{key}`
                .replace(`{${"key"}}`, encodeURIComponent(String(key)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if ($top !== undefined) {
                localVarQueryParameter['$top'] = $top;
            }

            if ($apply !== undefined) {
                localVarQueryParameter['$apply'] = $apply;
            }

            if ($skip !== undefined) {
                localVarQueryParameter['$skip'] = $skip;
            }

            if ($filter !== undefined) {
                localVarQueryParameter['$filter'] = $filter;
            }

            if ($select !== undefined) {
                localVarQueryParameter['$select'] = $select;
            }

            if ($orderby !== undefined) {
                localVarQueryParameter['$orderby'] = $orderby;
            }

            if ($expand !== undefined) {
                localVarQueryParameter['$expand'] = $expand;
            }

            if ($count !== undefined) {
                localVarQueryParameter['$count'] = $count;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json;odata.metadata=minimal;odata.streaming=true';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(operation, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [$top] The max number of records.
         * @param {string} [$apply] Allows to specify a sequence of transformations to the entity set, such as groupby, filter, aggregate, etc.
         * @param {number} [$skip] The number of records to skip.
         * @param {string} [$filter] A function that must evaluate to true for a record to be returned.
         * @param {string} [$select] Specifies a subset of properties to return. Use a comma separated list.
         * @param {string} [$orderby] Determines what values are used to order a collection of records.
         * @param {string} [$expand] Use to add related query data.
         * @param {boolean} [$count] Allows clients to request a count of the matching resources included with the resources in the response.
         * @param {FeedProductLine} [feedProductLine] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postApiV2FeedProductLines: async ($top?: number, $apply?: string, $skip?: number, $filter?: string, $select?: string, $orderby?: string, $expand?: string, $count?: boolean, feedProductLine?: FeedProductLine, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v2/FeedProductLines`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if ($top !== undefined) {
                localVarQueryParameter['$top'] = $top;
            }

            if ($apply !== undefined) {
                localVarQueryParameter['$apply'] = $apply;
            }

            if ($skip !== undefined) {
                localVarQueryParameter['$skip'] = $skip;
            }

            if ($filter !== undefined) {
                localVarQueryParameter['$filter'] = $filter;
            }

            if ($select !== undefined) {
                localVarQueryParameter['$select'] = $select;
            }

            if ($orderby !== undefined) {
                localVarQueryParameter['$orderby'] = $orderby;
            }

            if ($expand !== undefined) {
                localVarQueryParameter['$expand'] = $expand;
            }

            if ($count !== undefined) {
                localVarQueryParameter['$count'] = $count;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json;odata.metadata=minimal;odata.streaming=true';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(feedProductLine, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} key 
         * @param {number} [$top] The max number of records.
         * @param {string} [$apply] Allows to specify a sequence of transformations to the entity set, such as groupby, filter, aggregate, etc.
         * @param {number} [$skip] The number of records to skip.
         * @param {string} [$filter] A function that must evaluate to true for a record to be returned.
         * @param {string} [$select] Specifies a subset of properties to return. Use a comma separated list.
         * @param {string} [$orderby] Determines what values are used to order a collection of records.
         * @param {string} [$expand] Use to add related query data.
         * @param {boolean} [$count] Allows clients to request a count of the matching resources included with the resources in the response.
         * @param {FeedProductLine} [feedProductLine] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putApiV2FeedProductLinesKey: async (key: number, $top?: number, $apply?: string, $skip?: number, $filter?: string, $select?: string, $orderby?: string, $expand?: string, $count?: boolean, feedProductLine?: FeedProductLine, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'key' is not null or undefined
            assertParamExists('putApiV2FeedProductLinesKey', 'key', key)
            const localVarPath = `/api/v2/FeedProductLines({key})`
                .replace(`{${"key"}}`, encodeURIComponent(String(key)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if ($top !== undefined) {
                localVarQueryParameter['$top'] = $top;
            }

            if ($apply !== undefined) {
                localVarQueryParameter['$apply'] = $apply;
            }

            if ($skip !== undefined) {
                localVarQueryParameter['$skip'] = $skip;
            }

            if ($filter !== undefined) {
                localVarQueryParameter['$filter'] = $filter;
            }

            if ($select !== undefined) {
                localVarQueryParameter['$select'] = $select;
            }

            if ($orderby !== undefined) {
                localVarQueryParameter['$orderby'] = $orderby;
            }

            if ($expand !== undefined) {
                localVarQueryParameter['$expand'] = $expand;
            }

            if ($count !== undefined) {
                localVarQueryParameter['$count'] = $count;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json;odata.metadata=minimal;odata.streaming=true';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(feedProductLine, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} key 
         * @param {number} [$top] The max number of records.
         * @param {string} [$apply] Allows to specify a sequence of transformations to the entity set, such as groupby, filter, aggregate, etc.
         * @param {number} [$skip] The number of records to skip.
         * @param {string} [$filter] A function that must evaluate to true for a record to be returned.
         * @param {string} [$select] Specifies a subset of properties to return. Use a comma separated list.
         * @param {string} [$orderby] Determines what values are used to order a collection of records.
         * @param {string} [$expand] Use to add related query data.
         * @param {boolean} [$count] Allows clients to request a count of the matching resources included with the resources in the response.
         * @param {FeedProductLine} [feedProductLine] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putApiV2FeedProductLinesKey_4: async (key: number, $top?: number, $apply?: string, $skip?: number, $filter?: string, $select?: string, $orderby?: string, $expand?: string, $count?: boolean, feedProductLine?: FeedProductLine, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'key' is not null or undefined
            assertParamExists('putApiV2FeedProductLinesKey_4', 'key', key)
            const localVarPath = `/api/v2/FeedProductLines/{key}`
                .replace(`{${"key"}}`, encodeURIComponent(String(key)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if ($top !== undefined) {
                localVarQueryParameter['$top'] = $top;
            }

            if ($apply !== undefined) {
                localVarQueryParameter['$apply'] = $apply;
            }

            if ($skip !== undefined) {
                localVarQueryParameter['$skip'] = $skip;
            }

            if ($filter !== undefined) {
                localVarQueryParameter['$filter'] = $filter;
            }

            if ($select !== undefined) {
                localVarQueryParameter['$select'] = $select;
            }

            if ($orderby !== undefined) {
                localVarQueryParameter['$orderby'] = $orderby;
            }

            if ($expand !== undefined) {
                localVarQueryParameter['$expand'] = $expand;
            }

            if ($count !== undefined) {
                localVarQueryParameter['$count'] = $count;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json;odata.metadata=minimal;odata.streaming=true';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(feedProductLine, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FeedProductLinesApi - functional programming interface
 * @export
 */
export const FeedProductLinesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FeedProductLinesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} key 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteApiV2FeedProductLinesKey(key: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteApiV2FeedProductLinesKey(key, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} key 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteApiV2FeedProductLinesKey_1(key: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteApiV2FeedProductLinesKey_1(key, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [$top] The max number of records.
         * @param {string} [$apply] Allows to specify a sequence of transformations to the entity set, such as groupby, filter, aggregate, etc.
         * @param {number} [$skip] The number of records to skip.
         * @param {string} [$filter] A function that must evaluate to true for a record to be returned.
         * @param {string} [$select] Specifies a subset of properties to return. Use a comma separated list.
         * @param {string} [$orderby] Determines what values are used to order a collection of records.
         * @param {string} [$expand] Use to add related query data.
         * @param {boolean} [$count] Allows clients to request a count of the matching resources included with the resources in the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getApiV2FeedProductLines($top?: number, $apply?: string, $skip?: number, $filter?: string, $select?: string, $orderby?: string, $expand?: string, $count?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FeedProductLineIEnumerableScaleODataValue>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getApiV2FeedProductLines($top, $apply, $skip, $filter, $select, $orderby, $expand, $count, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [$top] The max number of records.
         * @param {string} [$apply] Allows to specify a sequence of transformations to the entity set, such as groupby, filter, aggregate, etc.
         * @param {number} [$skip] The number of records to skip.
         * @param {string} [$filter] A function that must evaluate to true for a record to be returned.
         * @param {string} [$select] Specifies a subset of properties to return. Use a comma separated list.
         * @param {string} [$orderby] Determines what values are used to order a collection of records.
         * @param {string} [$expand] Use to add related query data.
         * @param {boolean} [$count] Allows clients to request a count of the matching resources included with the resources in the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getApiV2FeedProductLinesCount($top?: number, $apply?: string, $skip?: number, $filter?: string, $select?: string, $orderby?: string, $expand?: string, $count?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FeedProductLineIEnumerableScaleODataValue>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getApiV2FeedProductLinesCount($top, $apply, $skip, $filter, $select, $orderby, $expand, $count, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} key 
         * @param {number} [$top] The max number of records.
         * @param {string} [$apply] Allows to specify a sequence of transformations to the entity set, such as groupby, filter, aggregate, etc.
         * @param {number} [$skip] The number of records to skip.
         * @param {string} [$filter] A function that must evaluate to true for a record to be returned.
         * @param {string} [$select] Specifies a subset of properties to return. Use a comma separated list.
         * @param {string} [$orderby] Determines what values are used to order a collection of records.
         * @param {string} [$expand] Use to add related query data.
         * @param {boolean} [$count] Allows clients to request a count of the matching resources included with the resources in the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getApiV2FeedProductLinesKey(key: number, $top?: number, $apply?: string, $skip?: number, $filter?: string, $select?: string, $orderby?: string, $expand?: string, $count?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FeedProductLine>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getApiV2FeedProductLinesKey(key, $top, $apply, $skip, $filter, $select, $orderby, $expand, $count, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} key 
         * @param {number} [$top] The max number of records.
         * @param {string} [$apply] Allows to specify a sequence of transformations to the entity set, such as groupby, filter, aggregate, etc.
         * @param {number} [$skip] The number of records to skip.
         * @param {string} [$filter] A function that must evaluate to true for a record to be returned.
         * @param {string} [$select] Specifies a subset of properties to return. Use a comma separated list.
         * @param {string} [$orderby] Determines what values are used to order a collection of records.
         * @param {string} [$expand] Use to add related query data.
         * @param {boolean} [$count] Allows clients to request a count of the matching resources included with the resources in the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getApiV2FeedProductLinesKey_2(key: number, $top?: number, $apply?: string, $skip?: number, $filter?: string, $select?: string, $orderby?: string, $expand?: string, $count?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FeedProductLine>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getApiV2FeedProductLinesKey_2(key, $top, $apply, $skip, $filter, $select, $orderby, $expand, $count, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} key 
         * @param {number} [$top] The max number of records.
         * @param {string} [$apply] Allows to specify a sequence of transformations to the entity set, such as groupby, filter, aggregate, etc.
         * @param {number} [$skip] The number of records to skip.
         * @param {string} [$filter] A function that must evaluate to true for a record to be returned.
         * @param {string} [$select] Specifies a subset of properties to return. Use a comma separated list.
         * @param {string} [$orderby] Determines what values are used to order a collection of records.
         * @param {string} [$expand] Use to add related query data.
         * @param {boolean} [$count] Allows clients to request a count of the matching resources included with the resources in the response.
         * @param {Array<Operation>} [operation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchApiV2FeedProductLinesKey(key: number, $top?: number, $apply?: string, $skip?: number, $filter?: string, $select?: string, $orderby?: string, $expand?: string, $count?: boolean, operation?: Array<Operation>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FeedProductLine>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patchApiV2FeedProductLinesKey(key, $top, $apply, $skip, $filter, $select, $orderby, $expand, $count, operation, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} key 
         * @param {number} [$top] The max number of records.
         * @param {string} [$apply] Allows to specify a sequence of transformations to the entity set, such as groupby, filter, aggregate, etc.
         * @param {number} [$skip] The number of records to skip.
         * @param {string} [$filter] A function that must evaluate to true for a record to be returned.
         * @param {string} [$select] Specifies a subset of properties to return. Use a comma separated list.
         * @param {string} [$orderby] Determines what values are used to order a collection of records.
         * @param {string} [$expand] Use to add related query data.
         * @param {boolean} [$count] Allows clients to request a count of the matching resources included with the resources in the response.
         * @param {Array<Operation>} [operation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchApiV2FeedProductLinesKey_3(key: number, $top?: number, $apply?: string, $skip?: number, $filter?: string, $select?: string, $orderby?: string, $expand?: string, $count?: boolean, operation?: Array<Operation>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FeedProductLine>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patchApiV2FeedProductLinesKey_3(key, $top, $apply, $skip, $filter, $select, $orderby, $expand, $count, operation, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [$top] The max number of records.
         * @param {string} [$apply] Allows to specify a sequence of transformations to the entity set, such as groupby, filter, aggregate, etc.
         * @param {number} [$skip] The number of records to skip.
         * @param {string} [$filter] A function that must evaluate to true for a record to be returned.
         * @param {string} [$select] Specifies a subset of properties to return. Use a comma separated list.
         * @param {string} [$orderby] Determines what values are used to order a collection of records.
         * @param {string} [$expand] Use to add related query data.
         * @param {boolean} [$count] Allows clients to request a count of the matching resources included with the resources in the response.
         * @param {FeedProductLine} [feedProductLine] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postApiV2FeedProductLines($top?: number, $apply?: string, $skip?: number, $filter?: string, $select?: string, $orderby?: string, $expand?: string, $count?: boolean, feedProductLine?: FeedProductLine, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FeedProductLine>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postApiV2FeedProductLines($top, $apply, $skip, $filter, $select, $orderby, $expand, $count, feedProductLine, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} key 
         * @param {number} [$top] The max number of records.
         * @param {string} [$apply] Allows to specify a sequence of transformations to the entity set, such as groupby, filter, aggregate, etc.
         * @param {number} [$skip] The number of records to skip.
         * @param {string} [$filter] A function that must evaluate to true for a record to be returned.
         * @param {string} [$select] Specifies a subset of properties to return. Use a comma separated list.
         * @param {string} [$orderby] Determines what values are used to order a collection of records.
         * @param {string} [$expand] Use to add related query data.
         * @param {boolean} [$count] Allows clients to request a count of the matching resources included with the resources in the response.
         * @param {FeedProductLine} [feedProductLine] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putApiV2FeedProductLinesKey(key: number, $top?: number, $apply?: string, $skip?: number, $filter?: string, $select?: string, $orderby?: string, $expand?: string, $count?: boolean, feedProductLine?: FeedProductLine, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FeedProductLine>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putApiV2FeedProductLinesKey(key, $top, $apply, $skip, $filter, $select, $orderby, $expand, $count, feedProductLine, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} key 
         * @param {number} [$top] The max number of records.
         * @param {string} [$apply] Allows to specify a sequence of transformations to the entity set, such as groupby, filter, aggregate, etc.
         * @param {number} [$skip] The number of records to skip.
         * @param {string} [$filter] A function that must evaluate to true for a record to be returned.
         * @param {string} [$select] Specifies a subset of properties to return. Use a comma separated list.
         * @param {string} [$orderby] Determines what values are used to order a collection of records.
         * @param {string} [$expand] Use to add related query data.
         * @param {boolean} [$count] Allows clients to request a count of the matching resources included with the resources in the response.
         * @param {FeedProductLine} [feedProductLine] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putApiV2FeedProductLinesKey_4(key: number, $top?: number, $apply?: string, $skip?: number, $filter?: string, $select?: string, $orderby?: string, $expand?: string, $count?: boolean, feedProductLine?: FeedProductLine, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FeedProductLine>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putApiV2FeedProductLinesKey_4(key, $top, $apply, $skip, $filter, $select, $orderby, $expand, $count, feedProductLine, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * FeedProductLinesApi - factory interface
 * @export
 */
export const FeedProductLinesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FeedProductLinesApiFp(configuration)
    return {
        /**
         * 
         * @param {FeedProductLinesApiDeleteApiV2FeedProductLinesKeyRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteApiV2FeedProductLinesKey(requestParameters: FeedProductLinesApiDeleteApiV2FeedProductLinesKeyRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.deleteApiV2FeedProductLinesKey(requestParameters.key, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {FeedProductLinesApiDeleteApiV2FeedProductLinesKey0Request} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteApiV2FeedProductLinesKey_1(requestParameters: FeedProductLinesApiDeleteApiV2FeedProductLinesKey0Request, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.deleteApiV2FeedProductLinesKey_1(requestParameters.key, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {FeedProductLinesApiGetApiV2FeedProductLinesRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiV2FeedProductLines(requestParameters: FeedProductLinesApiGetApiV2FeedProductLinesRequest = {}, options?: AxiosRequestConfig): AxiosPromise<FeedProductLineIEnumerableScaleODataValue> {
            return localVarFp.getApiV2FeedProductLines(requestParameters.$top, requestParameters.$apply, requestParameters.$skip, requestParameters.$filter, requestParameters.$select, requestParameters.$orderby, requestParameters.$expand, requestParameters.$count, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {FeedProductLinesApiGetApiV2FeedProductLinesCountRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiV2FeedProductLinesCount(requestParameters: FeedProductLinesApiGetApiV2FeedProductLinesCountRequest = {}, options?: AxiosRequestConfig): AxiosPromise<FeedProductLineIEnumerableScaleODataValue> {
            return localVarFp.getApiV2FeedProductLinesCount(requestParameters.$top, requestParameters.$apply, requestParameters.$skip, requestParameters.$filter, requestParameters.$select, requestParameters.$orderby, requestParameters.$expand, requestParameters.$count, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {FeedProductLinesApiGetApiV2FeedProductLinesKeyRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiV2FeedProductLinesKey(requestParameters: FeedProductLinesApiGetApiV2FeedProductLinesKeyRequest, options?: AxiosRequestConfig): AxiosPromise<FeedProductLine> {
            return localVarFp.getApiV2FeedProductLinesKey(requestParameters.key, requestParameters.$top, requestParameters.$apply, requestParameters.$skip, requestParameters.$filter, requestParameters.$select, requestParameters.$orderby, requestParameters.$expand, requestParameters.$count, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {FeedProductLinesApiGetApiV2FeedProductLinesKey0Request} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiV2FeedProductLinesKey_2(requestParameters: FeedProductLinesApiGetApiV2FeedProductLinesKey0Request, options?: AxiosRequestConfig): AxiosPromise<FeedProductLine> {
            return localVarFp.getApiV2FeedProductLinesKey_2(requestParameters.key, requestParameters.$top, requestParameters.$apply, requestParameters.$skip, requestParameters.$filter, requestParameters.$select, requestParameters.$orderby, requestParameters.$expand, requestParameters.$count, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {FeedProductLinesApiPatchApiV2FeedProductLinesKeyRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchApiV2FeedProductLinesKey(requestParameters: FeedProductLinesApiPatchApiV2FeedProductLinesKeyRequest, options?: AxiosRequestConfig): AxiosPromise<FeedProductLine> {
            return localVarFp.patchApiV2FeedProductLinesKey(requestParameters.key, requestParameters.$top, requestParameters.$apply, requestParameters.$skip, requestParameters.$filter, requestParameters.$select, requestParameters.$orderby, requestParameters.$expand, requestParameters.$count, requestParameters.operation, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {FeedProductLinesApiPatchApiV2FeedProductLinesKey0Request} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchApiV2FeedProductLinesKey_3(requestParameters: FeedProductLinesApiPatchApiV2FeedProductLinesKey0Request, options?: AxiosRequestConfig): AxiosPromise<FeedProductLine> {
            return localVarFp.patchApiV2FeedProductLinesKey_3(requestParameters.key, requestParameters.$top, requestParameters.$apply, requestParameters.$skip, requestParameters.$filter, requestParameters.$select, requestParameters.$orderby, requestParameters.$expand, requestParameters.$count, requestParameters.operation, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {FeedProductLinesApiPostApiV2FeedProductLinesRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postApiV2FeedProductLines(requestParameters: FeedProductLinesApiPostApiV2FeedProductLinesRequest = {}, options?: AxiosRequestConfig): AxiosPromise<FeedProductLine> {
            return localVarFp.postApiV2FeedProductLines(requestParameters.$top, requestParameters.$apply, requestParameters.$skip, requestParameters.$filter, requestParameters.$select, requestParameters.$orderby, requestParameters.$expand, requestParameters.$count, requestParameters.feedProductLine, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {FeedProductLinesApiPutApiV2FeedProductLinesKeyRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putApiV2FeedProductLinesKey(requestParameters: FeedProductLinesApiPutApiV2FeedProductLinesKeyRequest, options?: AxiosRequestConfig): AxiosPromise<FeedProductLine> {
            return localVarFp.putApiV2FeedProductLinesKey(requestParameters.key, requestParameters.$top, requestParameters.$apply, requestParameters.$skip, requestParameters.$filter, requestParameters.$select, requestParameters.$orderby, requestParameters.$expand, requestParameters.$count, requestParameters.feedProductLine, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {FeedProductLinesApiPutApiV2FeedProductLinesKey0Request} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putApiV2FeedProductLinesKey_4(requestParameters: FeedProductLinesApiPutApiV2FeedProductLinesKey0Request, options?: AxiosRequestConfig): AxiosPromise<FeedProductLine> {
            return localVarFp.putApiV2FeedProductLinesKey_4(requestParameters.key, requestParameters.$top, requestParameters.$apply, requestParameters.$skip, requestParameters.$filter, requestParameters.$select, requestParameters.$orderby, requestParameters.$expand, requestParameters.$count, requestParameters.feedProductLine, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * FeedProductLinesApi - interface
 * @export
 * @interface FeedProductLinesApi
 */
export interface FeedProductLinesApiInterface {
    /**
     * 
     * @param {number} key 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeedProductLinesApiInterface
     */
    deleteApiV2FeedProductLinesKey(key: number, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @param {number} key 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeedProductLinesApiInterface
     */
    deleteApiV2FeedProductLinesKey_1(key: number, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @param {number} [$top] The max number of records.
     * @param {string} [$apply] Allows to specify a sequence of transformations to the entity set, such as groupby, filter, aggregate, etc.
     * @param {number} [$skip] The number of records to skip.
     * @param {string} [$filter] A function that must evaluate to true for a record to be returned.
     * @param {string} [$select] Specifies a subset of properties to return. Use a comma separated list.
     * @param {string} [$orderby] Determines what values are used to order a collection of records.
     * @param {string} [$expand] Use to add related query data.
     * @param {boolean} [$count] Allows clients to request a count of the matching resources included with the resources in the response.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeedProductLinesApiInterface
     */
    getApiV2FeedProductLines($top?: number, $apply?: string, $skip?: number, $filter?: string, $select?: string, $orderby?: string, $expand?: string, $count?: boolean, options?: AxiosRequestConfig): AxiosPromise<FeedProductLineIEnumerableScaleODataValue>;

    /**
     * 
     * @param {number} [$top] The max number of records.
     * @param {string} [$apply] Allows to specify a sequence of transformations to the entity set, such as groupby, filter, aggregate, etc.
     * @param {number} [$skip] The number of records to skip.
     * @param {string} [$filter] A function that must evaluate to true for a record to be returned.
     * @param {string} [$select] Specifies a subset of properties to return. Use a comma separated list.
     * @param {string} [$orderby] Determines what values are used to order a collection of records.
     * @param {string} [$expand] Use to add related query data.
     * @param {boolean} [$count] Allows clients to request a count of the matching resources included with the resources in the response.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeedProductLinesApiInterface
     */
    getApiV2FeedProductLinesCount($top?: number, $apply?: string, $skip?: number, $filter?: string, $select?: string, $orderby?: string, $expand?: string, $count?: boolean, options?: AxiosRequestConfig): AxiosPromise<FeedProductLineIEnumerableScaleODataValue>;

    /**
     * 
     * @param {number} key 
     * @param {number} [$top] The max number of records.
     * @param {string} [$apply] Allows to specify a sequence of transformations to the entity set, such as groupby, filter, aggregate, etc.
     * @param {number} [$skip] The number of records to skip.
     * @param {string} [$filter] A function that must evaluate to true for a record to be returned.
     * @param {string} [$select] Specifies a subset of properties to return. Use a comma separated list.
     * @param {string} [$orderby] Determines what values are used to order a collection of records.
     * @param {string} [$expand] Use to add related query data.
     * @param {boolean} [$count] Allows clients to request a count of the matching resources included with the resources in the response.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeedProductLinesApiInterface
     */
    getApiV2FeedProductLinesKey(key: number, $top?: number, $apply?: string, $skip?: number, $filter?: string, $select?: string, $orderby?: string, $expand?: string, $count?: boolean, options?: AxiosRequestConfig): AxiosPromise<FeedProductLine>;

    /**
     * 
     * @param {number} key 
     * @param {number} [$top] The max number of records.
     * @param {string} [$apply] Allows to specify a sequence of transformations to the entity set, such as groupby, filter, aggregate, etc.
     * @param {number} [$skip] The number of records to skip.
     * @param {string} [$filter] A function that must evaluate to true for a record to be returned.
     * @param {string} [$select] Specifies a subset of properties to return. Use a comma separated list.
     * @param {string} [$orderby] Determines what values are used to order a collection of records.
     * @param {string} [$expand] Use to add related query data.
     * @param {boolean} [$count] Allows clients to request a count of the matching resources included with the resources in the response.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeedProductLinesApiInterface
     */
    getApiV2FeedProductLinesKey_2(key: number, $top?: number, $apply?: string, $skip?: number, $filter?: string, $select?: string, $orderby?: string, $expand?: string, $count?: boolean, options?: AxiosRequestConfig): AxiosPromise<FeedProductLine>;

    /**
     * 
     * @param {number} key 
     * @param {number} [$top] The max number of records.
     * @param {string} [$apply] Allows to specify a sequence of transformations to the entity set, such as groupby, filter, aggregate, etc.
     * @param {number} [$skip] The number of records to skip.
     * @param {string} [$filter] A function that must evaluate to true for a record to be returned.
     * @param {string} [$select] Specifies a subset of properties to return. Use a comma separated list.
     * @param {string} [$orderby] Determines what values are used to order a collection of records.
     * @param {string} [$expand] Use to add related query data.
     * @param {boolean} [$count] Allows clients to request a count of the matching resources included with the resources in the response.
     * @param {Array<Operation>} [operation] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeedProductLinesApiInterface
     */
    patchApiV2FeedProductLinesKey(key: number, $top?: number, $apply?: string, $skip?: number, $filter?: string, $select?: string, $orderby?: string, $expand?: string, $count?: boolean, operation?: Array<Operation>, options?: AxiosRequestConfig): AxiosPromise<FeedProductLine>;

    /**
     * 
     * @param {number} key 
     * @param {number} [$top] The max number of records.
     * @param {string} [$apply] Allows to specify a sequence of transformations to the entity set, such as groupby, filter, aggregate, etc.
     * @param {number} [$skip] The number of records to skip.
     * @param {string} [$filter] A function that must evaluate to true for a record to be returned.
     * @param {string} [$select] Specifies a subset of properties to return. Use a comma separated list.
     * @param {string} [$orderby] Determines what values are used to order a collection of records.
     * @param {string} [$expand] Use to add related query data.
     * @param {boolean} [$count] Allows clients to request a count of the matching resources included with the resources in the response.
     * @param {Array<Operation>} [operation] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeedProductLinesApiInterface
     */
    patchApiV2FeedProductLinesKey_3(key: number, $top?: number, $apply?: string, $skip?: number, $filter?: string, $select?: string, $orderby?: string, $expand?: string, $count?: boolean, operation?: Array<Operation>, options?: AxiosRequestConfig): AxiosPromise<FeedProductLine>;

    /**
     * 
     * @param {number} [$top] The max number of records.
     * @param {string} [$apply] Allows to specify a sequence of transformations to the entity set, such as groupby, filter, aggregate, etc.
     * @param {number} [$skip] The number of records to skip.
     * @param {string} [$filter] A function that must evaluate to true for a record to be returned.
     * @param {string} [$select] Specifies a subset of properties to return. Use a comma separated list.
     * @param {string} [$orderby] Determines what values are used to order a collection of records.
     * @param {string} [$expand] Use to add related query data.
     * @param {boolean} [$count] Allows clients to request a count of the matching resources included with the resources in the response.
     * @param {FeedProductLine} [feedProductLine] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeedProductLinesApiInterface
     */
    postApiV2FeedProductLines($top?: number, $apply?: string, $skip?: number, $filter?: string, $select?: string, $orderby?: string, $expand?: string, $count?: boolean, feedProductLine?: FeedProductLine, options?: AxiosRequestConfig): AxiosPromise<FeedProductLine>;

    /**
     * 
     * @param {number} key 
     * @param {number} [$top] The max number of records.
     * @param {string} [$apply] Allows to specify a sequence of transformations to the entity set, such as groupby, filter, aggregate, etc.
     * @param {number} [$skip] The number of records to skip.
     * @param {string} [$filter] A function that must evaluate to true for a record to be returned.
     * @param {string} [$select] Specifies a subset of properties to return. Use a comma separated list.
     * @param {string} [$orderby] Determines what values are used to order a collection of records.
     * @param {string} [$expand] Use to add related query data.
     * @param {boolean} [$count] Allows clients to request a count of the matching resources included with the resources in the response.
     * @param {FeedProductLine} [feedProductLine] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeedProductLinesApiInterface
     */
    putApiV2FeedProductLinesKey(key: number, $top?: number, $apply?: string, $skip?: number, $filter?: string, $select?: string, $orderby?: string, $expand?: string, $count?: boolean, feedProductLine?: FeedProductLine, options?: AxiosRequestConfig): AxiosPromise<FeedProductLine>;

    /**
     * 
     * @param {number} key 
     * @param {number} [$top] The max number of records.
     * @param {string} [$apply] Allows to specify a sequence of transformations to the entity set, such as groupby, filter, aggregate, etc.
     * @param {number} [$skip] The number of records to skip.
     * @param {string} [$filter] A function that must evaluate to true for a record to be returned.
     * @param {string} [$select] Specifies a subset of properties to return. Use a comma separated list.
     * @param {string} [$orderby] Determines what values are used to order a collection of records.
     * @param {string} [$expand] Use to add related query data.
     * @param {boolean} [$count] Allows clients to request a count of the matching resources included with the resources in the response.
     * @param {FeedProductLine} [feedProductLine] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeedProductLinesApiInterface
     */
    putApiV2FeedProductLinesKey_4(key: number, $top?: number, $apply?: string, $skip?: number, $filter?: string, $select?: string, $orderby?: string, $expand?: string, $count?: boolean, feedProductLine?: FeedProductLine, options?: AxiosRequestConfig): AxiosPromise<FeedProductLine>;

}

/**
 * Request parameters for deleteApiV2FeedProductLinesKey operation in FeedProductLinesApi.
 * @export
 * @interface FeedProductLinesApiDeleteApiV2FeedProductLinesKeyRequest
 */
export interface FeedProductLinesApiDeleteApiV2FeedProductLinesKeyRequest {
    /**
     * 
     * @type {number}
     * @memberof FeedProductLinesApiDeleteApiV2FeedProductLinesKey
     */
    readonly key: number
}

/**
 * Request parameters for deleteApiV2FeedProductLinesKey_1 operation in FeedProductLinesApi.
 * @export
 * @interface FeedProductLinesApiDeleteApiV2FeedProductLinesKey0Request
 */
export interface FeedProductLinesApiDeleteApiV2FeedProductLinesKey0Request {
    /**
     * 
     * @type {number}
     * @memberof FeedProductLinesApiDeleteApiV2FeedProductLinesKey0
     */
    readonly key: number
}

/**
 * Request parameters for getApiV2FeedProductLines operation in FeedProductLinesApi.
 * @export
 * @interface FeedProductLinesApiGetApiV2FeedProductLinesRequest
 */
export interface FeedProductLinesApiGetApiV2FeedProductLinesRequest {
    /**
     * The max number of records.
     * @type {number}
     * @memberof FeedProductLinesApiGetApiV2FeedProductLines
     */
    readonly $top?: number

    /**
     * Allows to specify a sequence of transformations to the entity set, such as groupby, filter, aggregate, etc.
     * @type {string}
     * @memberof FeedProductLinesApiGetApiV2FeedProductLines
     */
    readonly $apply?: string

    /**
     * The number of records to skip.
     * @type {number}
     * @memberof FeedProductLinesApiGetApiV2FeedProductLines
     */
    readonly $skip?: number

    /**
     * A function that must evaluate to true for a record to be returned.
     * @type {string}
     * @memberof FeedProductLinesApiGetApiV2FeedProductLines
     */
    readonly $filter?: string

    /**
     * Specifies a subset of properties to return. Use a comma separated list.
     * @type {string}
     * @memberof FeedProductLinesApiGetApiV2FeedProductLines
     */
    readonly $select?: string

    /**
     * Determines what values are used to order a collection of records.
     * @type {string}
     * @memberof FeedProductLinesApiGetApiV2FeedProductLines
     */
    readonly $orderby?: string

    /**
     * Use to add related query data.
     * @type {string}
     * @memberof FeedProductLinesApiGetApiV2FeedProductLines
     */
    readonly $expand?: string

    /**
     * Allows clients to request a count of the matching resources included with the resources in the response.
     * @type {boolean}
     * @memberof FeedProductLinesApiGetApiV2FeedProductLines
     */
    readonly $count?: boolean
}

/**
 * Request parameters for getApiV2FeedProductLinesCount operation in FeedProductLinesApi.
 * @export
 * @interface FeedProductLinesApiGetApiV2FeedProductLinesCountRequest
 */
export interface FeedProductLinesApiGetApiV2FeedProductLinesCountRequest {
    /**
     * The max number of records.
     * @type {number}
     * @memberof FeedProductLinesApiGetApiV2FeedProductLinesCount
     */
    readonly $top?: number

    /**
     * Allows to specify a sequence of transformations to the entity set, such as groupby, filter, aggregate, etc.
     * @type {string}
     * @memberof FeedProductLinesApiGetApiV2FeedProductLinesCount
     */
    readonly $apply?: string

    /**
     * The number of records to skip.
     * @type {number}
     * @memberof FeedProductLinesApiGetApiV2FeedProductLinesCount
     */
    readonly $skip?: number

    /**
     * A function that must evaluate to true for a record to be returned.
     * @type {string}
     * @memberof FeedProductLinesApiGetApiV2FeedProductLinesCount
     */
    readonly $filter?: string

    /**
     * Specifies a subset of properties to return. Use a comma separated list.
     * @type {string}
     * @memberof FeedProductLinesApiGetApiV2FeedProductLinesCount
     */
    readonly $select?: string

    /**
     * Determines what values are used to order a collection of records.
     * @type {string}
     * @memberof FeedProductLinesApiGetApiV2FeedProductLinesCount
     */
    readonly $orderby?: string

    /**
     * Use to add related query data.
     * @type {string}
     * @memberof FeedProductLinesApiGetApiV2FeedProductLinesCount
     */
    readonly $expand?: string

    /**
     * Allows clients to request a count of the matching resources included with the resources in the response.
     * @type {boolean}
     * @memberof FeedProductLinesApiGetApiV2FeedProductLinesCount
     */
    readonly $count?: boolean
}

/**
 * Request parameters for getApiV2FeedProductLinesKey operation in FeedProductLinesApi.
 * @export
 * @interface FeedProductLinesApiGetApiV2FeedProductLinesKeyRequest
 */
export interface FeedProductLinesApiGetApiV2FeedProductLinesKeyRequest {
    /**
     * 
     * @type {number}
     * @memberof FeedProductLinesApiGetApiV2FeedProductLinesKey
     */
    readonly key: number

    /**
     * The max number of records.
     * @type {number}
     * @memberof FeedProductLinesApiGetApiV2FeedProductLinesKey
     */
    readonly $top?: number

    /**
     * Allows to specify a sequence of transformations to the entity set, such as groupby, filter, aggregate, etc.
     * @type {string}
     * @memberof FeedProductLinesApiGetApiV2FeedProductLinesKey
     */
    readonly $apply?: string

    /**
     * The number of records to skip.
     * @type {number}
     * @memberof FeedProductLinesApiGetApiV2FeedProductLinesKey
     */
    readonly $skip?: number

    /**
     * A function that must evaluate to true for a record to be returned.
     * @type {string}
     * @memberof FeedProductLinesApiGetApiV2FeedProductLinesKey
     */
    readonly $filter?: string

    /**
     * Specifies a subset of properties to return. Use a comma separated list.
     * @type {string}
     * @memberof FeedProductLinesApiGetApiV2FeedProductLinesKey
     */
    readonly $select?: string

    /**
     * Determines what values are used to order a collection of records.
     * @type {string}
     * @memberof FeedProductLinesApiGetApiV2FeedProductLinesKey
     */
    readonly $orderby?: string

    /**
     * Use to add related query data.
     * @type {string}
     * @memberof FeedProductLinesApiGetApiV2FeedProductLinesKey
     */
    readonly $expand?: string

    /**
     * Allows clients to request a count of the matching resources included with the resources in the response.
     * @type {boolean}
     * @memberof FeedProductLinesApiGetApiV2FeedProductLinesKey
     */
    readonly $count?: boolean
}

/**
 * Request parameters for getApiV2FeedProductLinesKey_2 operation in FeedProductLinesApi.
 * @export
 * @interface FeedProductLinesApiGetApiV2FeedProductLinesKey0Request
 */
export interface FeedProductLinesApiGetApiV2FeedProductLinesKey0Request {
    /**
     * 
     * @type {number}
     * @memberof FeedProductLinesApiGetApiV2FeedProductLinesKey0
     */
    readonly key: number

    /**
     * The max number of records.
     * @type {number}
     * @memberof FeedProductLinesApiGetApiV2FeedProductLinesKey0
     */
    readonly $top?: number

    /**
     * Allows to specify a sequence of transformations to the entity set, such as groupby, filter, aggregate, etc.
     * @type {string}
     * @memberof FeedProductLinesApiGetApiV2FeedProductLinesKey0
     */
    readonly $apply?: string

    /**
     * The number of records to skip.
     * @type {number}
     * @memberof FeedProductLinesApiGetApiV2FeedProductLinesKey0
     */
    readonly $skip?: number

    /**
     * A function that must evaluate to true for a record to be returned.
     * @type {string}
     * @memberof FeedProductLinesApiGetApiV2FeedProductLinesKey0
     */
    readonly $filter?: string

    /**
     * Specifies a subset of properties to return. Use a comma separated list.
     * @type {string}
     * @memberof FeedProductLinesApiGetApiV2FeedProductLinesKey0
     */
    readonly $select?: string

    /**
     * Determines what values are used to order a collection of records.
     * @type {string}
     * @memberof FeedProductLinesApiGetApiV2FeedProductLinesKey0
     */
    readonly $orderby?: string

    /**
     * Use to add related query data.
     * @type {string}
     * @memberof FeedProductLinesApiGetApiV2FeedProductLinesKey0
     */
    readonly $expand?: string

    /**
     * Allows clients to request a count of the matching resources included with the resources in the response.
     * @type {boolean}
     * @memberof FeedProductLinesApiGetApiV2FeedProductLinesKey0
     */
    readonly $count?: boolean
}

/**
 * Request parameters for patchApiV2FeedProductLinesKey operation in FeedProductLinesApi.
 * @export
 * @interface FeedProductLinesApiPatchApiV2FeedProductLinesKeyRequest
 */
export interface FeedProductLinesApiPatchApiV2FeedProductLinesKeyRequest {
    /**
     * 
     * @type {number}
     * @memberof FeedProductLinesApiPatchApiV2FeedProductLinesKey
     */
    readonly key: number

    /**
     * The max number of records.
     * @type {number}
     * @memberof FeedProductLinesApiPatchApiV2FeedProductLinesKey
     */
    readonly $top?: number

    /**
     * Allows to specify a sequence of transformations to the entity set, such as groupby, filter, aggregate, etc.
     * @type {string}
     * @memberof FeedProductLinesApiPatchApiV2FeedProductLinesKey
     */
    readonly $apply?: string

    /**
     * The number of records to skip.
     * @type {number}
     * @memberof FeedProductLinesApiPatchApiV2FeedProductLinesKey
     */
    readonly $skip?: number

    /**
     * A function that must evaluate to true for a record to be returned.
     * @type {string}
     * @memberof FeedProductLinesApiPatchApiV2FeedProductLinesKey
     */
    readonly $filter?: string

    /**
     * Specifies a subset of properties to return. Use a comma separated list.
     * @type {string}
     * @memberof FeedProductLinesApiPatchApiV2FeedProductLinesKey
     */
    readonly $select?: string

    /**
     * Determines what values are used to order a collection of records.
     * @type {string}
     * @memberof FeedProductLinesApiPatchApiV2FeedProductLinesKey
     */
    readonly $orderby?: string

    /**
     * Use to add related query data.
     * @type {string}
     * @memberof FeedProductLinesApiPatchApiV2FeedProductLinesKey
     */
    readonly $expand?: string

    /**
     * Allows clients to request a count of the matching resources included with the resources in the response.
     * @type {boolean}
     * @memberof FeedProductLinesApiPatchApiV2FeedProductLinesKey
     */
    readonly $count?: boolean

    /**
     * 
     * @type {Array<Operation>}
     * @memberof FeedProductLinesApiPatchApiV2FeedProductLinesKey
     */
    readonly operation?: Array<Operation>
}

/**
 * Request parameters for patchApiV2FeedProductLinesKey_3 operation in FeedProductLinesApi.
 * @export
 * @interface FeedProductLinesApiPatchApiV2FeedProductLinesKey0Request
 */
export interface FeedProductLinesApiPatchApiV2FeedProductLinesKey0Request {
    /**
     * 
     * @type {number}
     * @memberof FeedProductLinesApiPatchApiV2FeedProductLinesKey0
     */
    readonly key: number

    /**
     * The max number of records.
     * @type {number}
     * @memberof FeedProductLinesApiPatchApiV2FeedProductLinesKey0
     */
    readonly $top?: number

    /**
     * Allows to specify a sequence of transformations to the entity set, such as groupby, filter, aggregate, etc.
     * @type {string}
     * @memberof FeedProductLinesApiPatchApiV2FeedProductLinesKey0
     */
    readonly $apply?: string

    /**
     * The number of records to skip.
     * @type {number}
     * @memberof FeedProductLinesApiPatchApiV2FeedProductLinesKey0
     */
    readonly $skip?: number

    /**
     * A function that must evaluate to true for a record to be returned.
     * @type {string}
     * @memberof FeedProductLinesApiPatchApiV2FeedProductLinesKey0
     */
    readonly $filter?: string

    /**
     * Specifies a subset of properties to return. Use a comma separated list.
     * @type {string}
     * @memberof FeedProductLinesApiPatchApiV2FeedProductLinesKey0
     */
    readonly $select?: string

    /**
     * Determines what values are used to order a collection of records.
     * @type {string}
     * @memberof FeedProductLinesApiPatchApiV2FeedProductLinesKey0
     */
    readonly $orderby?: string

    /**
     * Use to add related query data.
     * @type {string}
     * @memberof FeedProductLinesApiPatchApiV2FeedProductLinesKey0
     */
    readonly $expand?: string

    /**
     * Allows clients to request a count of the matching resources included with the resources in the response.
     * @type {boolean}
     * @memberof FeedProductLinesApiPatchApiV2FeedProductLinesKey0
     */
    readonly $count?: boolean

    /**
     * 
     * @type {Array<Operation>}
     * @memberof FeedProductLinesApiPatchApiV2FeedProductLinesKey0
     */
    readonly operation?: Array<Operation>
}

/**
 * Request parameters for postApiV2FeedProductLines operation in FeedProductLinesApi.
 * @export
 * @interface FeedProductLinesApiPostApiV2FeedProductLinesRequest
 */
export interface FeedProductLinesApiPostApiV2FeedProductLinesRequest {
    /**
     * The max number of records.
     * @type {number}
     * @memberof FeedProductLinesApiPostApiV2FeedProductLines
     */
    readonly $top?: number

    /**
     * Allows to specify a sequence of transformations to the entity set, such as groupby, filter, aggregate, etc.
     * @type {string}
     * @memberof FeedProductLinesApiPostApiV2FeedProductLines
     */
    readonly $apply?: string

    /**
     * The number of records to skip.
     * @type {number}
     * @memberof FeedProductLinesApiPostApiV2FeedProductLines
     */
    readonly $skip?: number

    /**
     * A function that must evaluate to true for a record to be returned.
     * @type {string}
     * @memberof FeedProductLinesApiPostApiV2FeedProductLines
     */
    readonly $filter?: string

    /**
     * Specifies a subset of properties to return. Use a comma separated list.
     * @type {string}
     * @memberof FeedProductLinesApiPostApiV2FeedProductLines
     */
    readonly $select?: string

    /**
     * Determines what values are used to order a collection of records.
     * @type {string}
     * @memberof FeedProductLinesApiPostApiV2FeedProductLines
     */
    readonly $orderby?: string

    /**
     * Use to add related query data.
     * @type {string}
     * @memberof FeedProductLinesApiPostApiV2FeedProductLines
     */
    readonly $expand?: string

    /**
     * Allows clients to request a count of the matching resources included with the resources in the response.
     * @type {boolean}
     * @memberof FeedProductLinesApiPostApiV2FeedProductLines
     */
    readonly $count?: boolean

    /**
     * 
     * @type {FeedProductLine}
     * @memberof FeedProductLinesApiPostApiV2FeedProductLines
     */
    readonly feedProductLine?: FeedProductLine
}

/**
 * Request parameters for putApiV2FeedProductLinesKey operation in FeedProductLinesApi.
 * @export
 * @interface FeedProductLinesApiPutApiV2FeedProductLinesKeyRequest
 */
export interface FeedProductLinesApiPutApiV2FeedProductLinesKeyRequest {
    /**
     * 
     * @type {number}
     * @memberof FeedProductLinesApiPutApiV2FeedProductLinesKey
     */
    readonly key: number

    /**
     * The max number of records.
     * @type {number}
     * @memberof FeedProductLinesApiPutApiV2FeedProductLinesKey
     */
    readonly $top?: number

    /**
     * Allows to specify a sequence of transformations to the entity set, such as groupby, filter, aggregate, etc.
     * @type {string}
     * @memberof FeedProductLinesApiPutApiV2FeedProductLinesKey
     */
    readonly $apply?: string

    /**
     * The number of records to skip.
     * @type {number}
     * @memberof FeedProductLinesApiPutApiV2FeedProductLinesKey
     */
    readonly $skip?: number

    /**
     * A function that must evaluate to true for a record to be returned.
     * @type {string}
     * @memberof FeedProductLinesApiPutApiV2FeedProductLinesKey
     */
    readonly $filter?: string

    /**
     * Specifies a subset of properties to return. Use a comma separated list.
     * @type {string}
     * @memberof FeedProductLinesApiPutApiV2FeedProductLinesKey
     */
    readonly $select?: string

    /**
     * Determines what values are used to order a collection of records.
     * @type {string}
     * @memberof FeedProductLinesApiPutApiV2FeedProductLinesKey
     */
    readonly $orderby?: string

    /**
     * Use to add related query data.
     * @type {string}
     * @memberof FeedProductLinesApiPutApiV2FeedProductLinesKey
     */
    readonly $expand?: string

    /**
     * Allows clients to request a count of the matching resources included with the resources in the response.
     * @type {boolean}
     * @memberof FeedProductLinesApiPutApiV2FeedProductLinesKey
     */
    readonly $count?: boolean

    /**
     * 
     * @type {FeedProductLine}
     * @memberof FeedProductLinesApiPutApiV2FeedProductLinesKey
     */
    readonly feedProductLine?: FeedProductLine
}

/**
 * Request parameters for putApiV2FeedProductLinesKey_4 operation in FeedProductLinesApi.
 * @export
 * @interface FeedProductLinesApiPutApiV2FeedProductLinesKey0Request
 */
export interface FeedProductLinesApiPutApiV2FeedProductLinesKey0Request {
    /**
     * 
     * @type {number}
     * @memberof FeedProductLinesApiPutApiV2FeedProductLinesKey0
     */
    readonly key: number

    /**
     * The max number of records.
     * @type {number}
     * @memberof FeedProductLinesApiPutApiV2FeedProductLinesKey0
     */
    readonly $top?: number

    /**
     * Allows to specify a sequence of transformations to the entity set, such as groupby, filter, aggregate, etc.
     * @type {string}
     * @memberof FeedProductLinesApiPutApiV2FeedProductLinesKey0
     */
    readonly $apply?: string

    /**
     * The number of records to skip.
     * @type {number}
     * @memberof FeedProductLinesApiPutApiV2FeedProductLinesKey0
     */
    readonly $skip?: number

    /**
     * A function that must evaluate to true for a record to be returned.
     * @type {string}
     * @memberof FeedProductLinesApiPutApiV2FeedProductLinesKey0
     */
    readonly $filter?: string

    /**
     * Specifies a subset of properties to return. Use a comma separated list.
     * @type {string}
     * @memberof FeedProductLinesApiPutApiV2FeedProductLinesKey0
     */
    readonly $select?: string

    /**
     * Determines what values are used to order a collection of records.
     * @type {string}
     * @memberof FeedProductLinesApiPutApiV2FeedProductLinesKey0
     */
    readonly $orderby?: string

    /**
     * Use to add related query data.
     * @type {string}
     * @memberof FeedProductLinesApiPutApiV2FeedProductLinesKey0
     */
    readonly $expand?: string

    /**
     * Allows clients to request a count of the matching resources included with the resources in the response.
     * @type {boolean}
     * @memberof FeedProductLinesApiPutApiV2FeedProductLinesKey0
     */
    readonly $count?: boolean

    /**
     * 
     * @type {FeedProductLine}
     * @memberof FeedProductLinesApiPutApiV2FeedProductLinesKey0
     */
    readonly feedProductLine?: FeedProductLine
}

/**
 * FeedProductLinesApi - object-oriented interface
 * @export
 * @class FeedProductLinesApi
 * @extends {BaseAPI}
 */
export class FeedProductLinesApi extends BaseAPI implements FeedProductLinesApiInterface {
    /**
     * 
     * @param {FeedProductLinesApiDeleteApiV2FeedProductLinesKeyRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeedProductLinesApi
     */
    public deleteApiV2FeedProductLinesKey(requestParameters: FeedProductLinesApiDeleteApiV2FeedProductLinesKeyRequest, options?: AxiosRequestConfig) {
        return FeedProductLinesApiFp(this.configuration).deleteApiV2FeedProductLinesKey(requestParameters.key, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {FeedProductLinesApiDeleteApiV2FeedProductLinesKey0Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeedProductLinesApi
     */
    public deleteApiV2FeedProductLinesKey_1(requestParameters: FeedProductLinesApiDeleteApiV2FeedProductLinesKey0Request, options?: AxiosRequestConfig) {
        return FeedProductLinesApiFp(this.configuration).deleteApiV2FeedProductLinesKey_1(requestParameters.key, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {FeedProductLinesApiGetApiV2FeedProductLinesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeedProductLinesApi
     */
    public getApiV2FeedProductLines(requestParameters: FeedProductLinesApiGetApiV2FeedProductLinesRequest = {}, options?: AxiosRequestConfig) {
        return FeedProductLinesApiFp(this.configuration).getApiV2FeedProductLines(requestParameters.$top, requestParameters.$apply, requestParameters.$skip, requestParameters.$filter, requestParameters.$select, requestParameters.$orderby, requestParameters.$expand, requestParameters.$count, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {FeedProductLinesApiGetApiV2FeedProductLinesCountRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeedProductLinesApi
     */
    public getApiV2FeedProductLinesCount(requestParameters: FeedProductLinesApiGetApiV2FeedProductLinesCountRequest = {}, options?: AxiosRequestConfig) {
        return FeedProductLinesApiFp(this.configuration).getApiV2FeedProductLinesCount(requestParameters.$top, requestParameters.$apply, requestParameters.$skip, requestParameters.$filter, requestParameters.$select, requestParameters.$orderby, requestParameters.$expand, requestParameters.$count, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {FeedProductLinesApiGetApiV2FeedProductLinesKeyRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeedProductLinesApi
     */
    public getApiV2FeedProductLinesKey(requestParameters: FeedProductLinesApiGetApiV2FeedProductLinesKeyRequest, options?: AxiosRequestConfig) {
        return FeedProductLinesApiFp(this.configuration).getApiV2FeedProductLinesKey(requestParameters.key, requestParameters.$top, requestParameters.$apply, requestParameters.$skip, requestParameters.$filter, requestParameters.$select, requestParameters.$orderby, requestParameters.$expand, requestParameters.$count, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {FeedProductLinesApiGetApiV2FeedProductLinesKey0Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeedProductLinesApi
     */
    public getApiV2FeedProductLinesKey_2(requestParameters: FeedProductLinesApiGetApiV2FeedProductLinesKey0Request, options?: AxiosRequestConfig) {
        return FeedProductLinesApiFp(this.configuration).getApiV2FeedProductLinesKey_2(requestParameters.key, requestParameters.$top, requestParameters.$apply, requestParameters.$skip, requestParameters.$filter, requestParameters.$select, requestParameters.$orderby, requestParameters.$expand, requestParameters.$count, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {FeedProductLinesApiPatchApiV2FeedProductLinesKeyRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeedProductLinesApi
     */
    public patchApiV2FeedProductLinesKey(requestParameters: FeedProductLinesApiPatchApiV2FeedProductLinesKeyRequest, options?: AxiosRequestConfig) {
        return FeedProductLinesApiFp(this.configuration).patchApiV2FeedProductLinesKey(requestParameters.key, requestParameters.$top, requestParameters.$apply, requestParameters.$skip, requestParameters.$filter, requestParameters.$select, requestParameters.$orderby, requestParameters.$expand, requestParameters.$count, requestParameters.operation, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {FeedProductLinesApiPatchApiV2FeedProductLinesKey0Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeedProductLinesApi
     */
    public patchApiV2FeedProductLinesKey_3(requestParameters: FeedProductLinesApiPatchApiV2FeedProductLinesKey0Request, options?: AxiosRequestConfig) {
        return FeedProductLinesApiFp(this.configuration).patchApiV2FeedProductLinesKey_3(requestParameters.key, requestParameters.$top, requestParameters.$apply, requestParameters.$skip, requestParameters.$filter, requestParameters.$select, requestParameters.$orderby, requestParameters.$expand, requestParameters.$count, requestParameters.operation, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {FeedProductLinesApiPostApiV2FeedProductLinesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeedProductLinesApi
     */
    public postApiV2FeedProductLines(requestParameters: FeedProductLinesApiPostApiV2FeedProductLinesRequest = {}, options?: AxiosRequestConfig) {
        return FeedProductLinesApiFp(this.configuration).postApiV2FeedProductLines(requestParameters.$top, requestParameters.$apply, requestParameters.$skip, requestParameters.$filter, requestParameters.$select, requestParameters.$orderby, requestParameters.$expand, requestParameters.$count, requestParameters.feedProductLine, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {FeedProductLinesApiPutApiV2FeedProductLinesKeyRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeedProductLinesApi
     */
    public putApiV2FeedProductLinesKey(requestParameters: FeedProductLinesApiPutApiV2FeedProductLinesKeyRequest, options?: AxiosRequestConfig) {
        return FeedProductLinesApiFp(this.configuration).putApiV2FeedProductLinesKey(requestParameters.key, requestParameters.$top, requestParameters.$apply, requestParameters.$skip, requestParameters.$filter, requestParameters.$select, requestParameters.$orderby, requestParameters.$expand, requestParameters.$count, requestParameters.feedProductLine, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {FeedProductLinesApiPutApiV2FeedProductLinesKey0Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeedProductLinesApi
     */
    public putApiV2FeedProductLinesKey_4(requestParameters: FeedProductLinesApiPutApiV2FeedProductLinesKey0Request, options?: AxiosRequestConfig) {
        return FeedProductLinesApiFp(this.configuration).putApiV2FeedProductLinesKey_4(requestParameters.key, requestParameters.$top, requestParameters.$apply, requestParameters.$skip, requestParameters.$filter, requestParameters.$select, requestParameters.$orderby, requestParameters.$expand, requestParameters.$count, requestParameters.feedProductLine, options).then((request) => request(this.axios, this.basePath));
    }
}
