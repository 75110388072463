import { useAsync } from "react-async-hook";
import React, { useContext, useEffect, useMemo } from "react";

import {
  getLoginSession,
  OidcClientTs,
  ResponseState,
  sessionStorageToOidcStorage,
  log,
  AuthenticationError,
  AuthenticationErrors,
  helpers,
} from "@scaleaq/auth-client";
import { b2cClientId, b2cPolicy, tenantName } from "utils/appsettings";
import { isClientSide } from "utils/commonUtils";
import { CenteredLoading } from "components/scale/CenteredLoading";
import { ErrorPage, ErrorPageProps } from "@scaleaq/scaleui";
import { LogLevelFilter } from "@scaleaq/logger";
import { getGenericLogger } from "logger";

type SessionProviderContext = ResponseState;

const SessionContext = React.createContext<SessionProviderContext>(null);

export type Props = {
  children?: React.ReactNode;
};

log.setLevel(LogLevelFilter.Information);
log.setLogger(getGenericLogger());

export const oidcClient = isClientSide()
  ? new OidcClientTs.OidcClient({
      client_id: b2cClientId,
      authority: `https://${tenantName}.b2clogin.com/${tenantName}.onmicrosoft.com/${b2cPolicy}/v2.0`,
      redirect_uri: typeof location == "object" ? location.origin : "/",
      response_type: "code",
      response_mode: "query",
      scope: [b2cClientId, "openid"].join(" "),
      stateStore: sessionStorageToOidcStorage(),
    })
  : null;

export default function ({ children }: Props) {
  const asyncSession = useAsync(getLoginSession, [oidcClient]);

  const logger = getGenericLogger().createCategoryLogger("SessionProvider");
  const errorPageProps: ErrorPageProps = useMemo(() => {
    if (!asyncSession.error) return null;
    const error = asyncSession.error as AuthenticationError;

    if (
      error instanceof AuthenticationErrors.sign_in_response_error ||
      error instanceof AuthenticationErrors.callback_error
    ) {
      try {
        const parsedB2CError = helpers.azureAdB2c.parseError(error.payload);
        return {
          title: "Authentication error",
          explanation: `We are unable to log you in.`,
          message: `We're sorry for the inconvenience. Please report this issue to an admin with the following correlation id: ${parsedB2CError.b2c_error.correlation_id}`,
        };
      } catch (error) {
        //perhaps log error reporting inability to parse error payload.
        //This should never happen
      }
    }

    return {
      title: "Authentication error",
      explanation: `We are unable to log you in.`,
      message: `We're sorry for the inconvenience. Please report this issue to an admin.`,
    };
  }, [asyncSession.error]);

  useEffect(() => {
    if (!asyncSession.error) return;
    console.error(asyncSession.error);
    logger.error(JSON.stringify(asyncSession.error));
  }, [asyncSession.error]);

  return (
    <SessionContext.Provider value={asyncSession.result}>
      {asyncSession.error && <ErrorPage {...errorPageProps} />}
      {asyncSession.loading && <CenteredLoading />}
      {asyncSession.result && children}
    </SessionContext.Provider>
  );
}

export const useSession = (): SessionProviderContext => {
  const context = useContext<SessionProviderContext>(SessionContext);
  if (context === undefined) {
    throw new Error("useSession must be used within a SessionProvider");
  }
  return context;
};
