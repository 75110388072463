import { IconButton } from "@scaleaq/scaleui";
import { useTranslation } from "react-i18next";
import { MouseEvent, useEffect, useState } from "react";
import { WorkerEntryMap } from "workers/workerManager";
import { CircularProgressProps } from "@mui/material";
import WorkersTray from "../WorkersTray";
import WorkersTrayButtons from "../WorkersTrayButtons";
import WorkersTrayProgress from "../WorkersTrayProgress";
import WorkersContainer from "../WorkersContainer";
import WorkerContainer from "../WorkerContainer";
import WorkerNameLabel from "../WorkerNameLabel";
import WorkerIconButton from "../WorkerIconButton";
import WorkerError from "../WorkerError";
import WorkersTrayTitle from "../WorkersTrayTitle";

export function getWorkerProgressAsPercentage([done, total]: [number, number]): number {
  return Math.ceil((done * 100) / total);
}

export type Props = {
  onCancelAll: React.ComponentProps<typeof IconButton>["onClick"];
  onCancel: (event: MouseEvent<HTMLButtonElement>, workerKey: string) => void;
  workers: WorkerEntryMap;
};

export default function ({ onCancel, onCancelAll, workers }: Props) {
  const [open, setOpen] = useState(true);
  const { t } = useTranslation();

  const toggle = (value?: boolean) => setOpen(value ?? !open);

  const [globalProgress, setGlobalProgress] = useState(null);
  const [globalProgressVariant, setGlobalProgressVariant] =
    useState<CircularProgressProps["variant"]>("indeterminate");

  useEffect(() => {
    const workerValues = Object.values(workers);
    const nextVariant: typeof globalProgressVariant =
      workerValues.length == 0
        ? "indeterminate"
        : workerValues.some((w) => w.progress?.length != 2)
          ? "indeterminate"
          : "determinate";

    setGlobalProgressVariant(nextVariant);

    const nextGlobalProgress =
      nextVariant == "indeterminate"
        ? null
        : workerValues.reduce((accumulator, currentValue) => {
            const { progress } = currentValue;

            if (!progress) return accumulator;

            const progressPercentage = Math.floor((progress[0] * 100) / progress[1] / workerValues.length);
            return accumulator + progressPercentage;
          }, 0);

    setGlobalProgress(nextGlobalProgress);
  }, [workers]);

  return (
    <WorkersTray>
      <WorkersTrayTitle type={"sub_heading"}>{t("common:downloading")}</WorkersTrayTitle>
      <WorkersTrayButtons open={open} onToggle={() => toggle()} onCancel={onCancelAll} />
      <WorkersTrayProgress variant={globalProgressVariant} value={globalProgress} />
      {open && (
        <WorkersContainer>
          {Object.entries(workers ?? {}).map(([key, value]) => (
            <WorkerContainer key={key}>
              <WorkerNameLabel title={value.name}>{value.name}</WorkerNameLabel>
              <WorkerIconButton workerEntry={value} onClick={(e) => onCancel(e, key)} />
              {value.error && <WorkerError severity="error">{t("common:errors.generalError")}</WorkerError>}
            </WorkerContainer>
          ))}
        </WorkersContainer>
      )}
    </WorkersTray>
  );
}
