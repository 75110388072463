"use client";

import { ScaleuiSSRWrapper, ScaleuiWrapper, Theme } from "@scaleaq/scaleui";
import Link from "next/link";
import { useServerInsertedHTML } from "next/navigation";
import React from "react";

export default function ScaleUiRegistry({ children, theme }: { children: React.ReactNode; theme?: Theme }) {
  // Only create stylesheet once with lazy initial state
  // x-ref: https://reactjs.org/docs/hooks-reference.html#lazy-initial-state
  if (typeof window !== "undefined")
    return <ScaleuiWrapper theme={theme ?? Theme.LIGHT}>{children}</ScaleuiWrapper>;

  return (
    <ScaleuiSSRWrapper
      theme={theme ?? Theme.LIGHT}
      useServerInsertedHTML={useServerInsertedHTML}
      LinkComponent={Link}
    >
      {children}
    </ScaleuiSSRWrapper>
  );
}
