// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * UniteAQ Access API
 * Access API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { AccessAreaWeb } from '../model';
// @ts-ignore
import { AccessGroupWeb } from '../model';
/**
 * AccessGroupsApi - axios parameter creator
 * @export
 */
export const AccessGroupsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} accessGroupId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAccessGroupsGroupAccessGroupId: async (accessGroupId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'accessGroupId' is not null or undefined
            assertParamExists('deleteAccessGroupsGroupAccessGroupId', 'accessGroupId', accessGroupId)
            const localVarPath = `/AccessGroups/group/{accessGroupId}`
                .replace(`{${"accessGroupId"}}`, encodeURIComponent(String(accessGroupId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccessGroupsAreasEntities: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/AccessGroups/areas/entities`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccessGroupsAreasRoles: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/AccessGroups/areas/roles`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccessGroupsCompanyAll: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/AccessGroups/company/all`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} companyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccessGroupsCompanyCompanyId: async (companyId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('getAccessGroupsCompanyCompanyId', 'companyId', companyId)
            const localVarPath = `/AccessGroups/company/{companyId}`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccessGroupsGlobalEntity: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/AccessGroups/global/entity`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccessGroupsGlobalRole: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/AccessGroups/global/role`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccessGroupsMachine: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/AccessGroups/machine`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {AccessGroupWeb} [accessGroupWeb] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postAccessGroups: async (accessGroupWeb?: AccessGroupWeb, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/AccessGroups`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json;odata.metadata=minimal;odata.streaming=true';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(accessGroupWeb, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AccessGroupsApi - functional programming interface
 * @export
 */
export const AccessGroupsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AccessGroupsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} accessGroupId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteAccessGroupsGroupAccessGroupId(accessGroupId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteAccessGroupsGroupAccessGroupId(accessGroupId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAccessGroupsAreasEntities(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AccessAreaWeb>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAccessGroupsAreasEntities(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAccessGroupsAreasRoles(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AccessAreaWeb>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAccessGroupsAreasRoles(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAccessGroupsCompanyAll(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AccessGroupWeb>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAccessGroupsCompanyAll(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} companyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAccessGroupsCompanyCompanyId(companyId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AccessGroupWeb>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAccessGroupsCompanyCompanyId(companyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAccessGroupsGlobalEntity(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AccessGroupWeb>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAccessGroupsGlobalEntity(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAccessGroupsGlobalRole(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AccessGroupWeb>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAccessGroupsGlobalRole(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAccessGroupsMachine(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AccessGroupWeb>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAccessGroupsMachine(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {AccessGroupWeb} [accessGroupWeb] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postAccessGroups(accessGroupWeb?: AccessGroupWeb, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postAccessGroups(accessGroupWeb, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AccessGroupsApi - factory interface
 * @export
 */
export const AccessGroupsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AccessGroupsApiFp(configuration)
    return {
        /**
         * 
         * @param {AccessGroupsApiDeleteAccessGroupsGroupAccessGroupIdRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAccessGroupsGroupAccessGroupId(requestParameters: AccessGroupsApiDeleteAccessGroupsGroupAccessGroupIdRequest, options?: AxiosRequestConfig): AxiosPromise<number> {
            return localVarFp.deleteAccessGroupsGroupAccessGroupId(requestParameters.accessGroupId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccessGroupsAreasEntities(options?: AxiosRequestConfig): AxiosPromise<Array<AccessAreaWeb>> {
            return localVarFp.getAccessGroupsAreasEntities(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccessGroupsAreasRoles(options?: AxiosRequestConfig): AxiosPromise<Array<AccessAreaWeb>> {
            return localVarFp.getAccessGroupsAreasRoles(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccessGroupsCompanyAll(options?: AxiosRequestConfig): AxiosPromise<Array<AccessGroupWeb>> {
            return localVarFp.getAccessGroupsCompanyAll(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AccessGroupsApiGetAccessGroupsCompanyCompanyIdRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccessGroupsCompanyCompanyId(requestParameters: AccessGroupsApiGetAccessGroupsCompanyCompanyIdRequest, options?: AxiosRequestConfig): AxiosPromise<Array<AccessGroupWeb>> {
            return localVarFp.getAccessGroupsCompanyCompanyId(requestParameters.companyId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccessGroupsGlobalEntity(options?: AxiosRequestConfig): AxiosPromise<Array<AccessGroupWeb>> {
            return localVarFp.getAccessGroupsGlobalEntity(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccessGroupsGlobalRole(options?: AxiosRequestConfig): AxiosPromise<Array<AccessGroupWeb>> {
            return localVarFp.getAccessGroupsGlobalRole(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccessGroupsMachine(options?: AxiosRequestConfig): AxiosPromise<Array<AccessGroupWeb>> {
            return localVarFp.getAccessGroupsMachine(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AccessGroupsApiPostAccessGroupsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postAccessGroups(requestParameters: AccessGroupsApiPostAccessGroupsRequest = {}, options?: AxiosRequestConfig): AxiosPromise<number> {
            return localVarFp.postAccessGroups(requestParameters.accessGroupWeb, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AccessGroupsApi - interface
 * @export
 * @interface AccessGroupsApi
 */
export interface AccessGroupsApiInterface {
    /**
     * 
     * @param {number} accessGroupId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccessGroupsApiInterface
     */
    deleteAccessGroupsGroupAccessGroupId(accessGroupId: number, options?: AxiosRequestConfig): AxiosPromise<number>;

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccessGroupsApiInterface
     */
    getAccessGroupsAreasEntities(options?: AxiosRequestConfig): AxiosPromise<Array<AccessAreaWeb>>;

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccessGroupsApiInterface
     */
    getAccessGroupsAreasRoles(options?: AxiosRequestConfig): AxiosPromise<Array<AccessAreaWeb>>;

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccessGroupsApiInterface
     */
    getAccessGroupsCompanyAll(options?: AxiosRequestConfig): AxiosPromise<Array<AccessGroupWeb>>;

    /**
     * 
     * @param {string} companyId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccessGroupsApiInterface
     */
    getAccessGroupsCompanyCompanyId(companyId: string, options?: AxiosRequestConfig): AxiosPromise<Array<AccessGroupWeb>>;

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccessGroupsApiInterface
     */
    getAccessGroupsGlobalEntity(options?: AxiosRequestConfig): AxiosPromise<Array<AccessGroupWeb>>;

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccessGroupsApiInterface
     */
    getAccessGroupsGlobalRole(options?: AxiosRequestConfig): AxiosPromise<Array<AccessGroupWeb>>;

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccessGroupsApiInterface
     */
    getAccessGroupsMachine(options?: AxiosRequestConfig): AxiosPromise<Array<AccessGroupWeb>>;

    /**
     * 
     * @param {AccessGroupWeb} [accessGroupWeb] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccessGroupsApiInterface
     */
    postAccessGroups(accessGroupWeb?: AccessGroupWeb, options?: AxiosRequestConfig): AxiosPromise<number>;

}

/**
 * Request parameters for deleteAccessGroupsGroupAccessGroupId operation in AccessGroupsApi.
 * @export
 * @interface AccessGroupsApiDeleteAccessGroupsGroupAccessGroupIdRequest
 */
export interface AccessGroupsApiDeleteAccessGroupsGroupAccessGroupIdRequest {
    /**
     * 
     * @type {number}
     * @memberof AccessGroupsApiDeleteAccessGroupsGroupAccessGroupId
     */
    readonly accessGroupId: number
}

/**
 * Request parameters for getAccessGroupsCompanyCompanyId operation in AccessGroupsApi.
 * @export
 * @interface AccessGroupsApiGetAccessGroupsCompanyCompanyIdRequest
 */
export interface AccessGroupsApiGetAccessGroupsCompanyCompanyIdRequest {
    /**
     * 
     * @type {string}
     * @memberof AccessGroupsApiGetAccessGroupsCompanyCompanyId
     */
    readonly companyId: string
}

/**
 * Request parameters for postAccessGroups operation in AccessGroupsApi.
 * @export
 * @interface AccessGroupsApiPostAccessGroupsRequest
 */
export interface AccessGroupsApiPostAccessGroupsRequest {
    /**
     * 
     * @type {AccessGroupWeb}
     * @memberof AccessGroupsApiPostAccessGroups
     */
    readonly accessGroupWeb?: AccessGroupWeb
}

/**
 * AccessGroupsApi - object-oriented interface
 * @export
 * @class AccessGroupsApi
 * @extends {BaseAPI}
 */
export class AccessGroupsApi extends BaseAPI implements AccessGroupsApiInterface {
    /**
     * 
     * @param {AccessGroupsApiDeleteAccessGroupsGroupAccessGroupIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccessGroupsApi
     */
    public deleteAccessGroupsGroupAccessGroupId(requestParameters: AccessGroupsApiDeleteAccessGroupsGroupAccessGroupIdRequest, options?: AxiosRequestConfig) {
        return AccessGroupsApiFp(this.configuration).deleteAccessGroupsGroupAccessGroupId(requestParameters.accessGroupId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccessGroupsApi
     */
    public getAccessGroupsAreasEntities(options?: AxiosRequestConfig) {
        return AccessGroupsApiFp(this.configuration).getAccessGroupsAreasEntities(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccessGroupsApi
     */
    public getAccessGroupsAreasRoles(options?: AxiosRequestConfig) {
        return AccessGroupsApiFp(this.configuration).getAccessGroupsAreasRoles(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccessGroupsApi
     */
    public getAccessGroupsCompanyAll(options?: AxiosRequestConfig) {
        return AccessGroupsApiFp(this.configuration).getAccessGroupsCompanyAll(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AccessGroupsApiGetAccessGroupsCompanyCompanyIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccessGroupsApi
     */
    public getAccessGroupsCompanyCompanyId(requestParameters: AccessGroupsApiGetAccessGroupsCompanyCompanyIdRequest, options?: AxiosRequestConfig) {
        return AccessGroupsApiFp(this.configuration).getAccessGroupsCompanyCompanyId(requestParameters.companyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccessGroupsApi
     */
    public getAccessGroupsGlobalEntity(options?: AxiosRequestConfig) {
        return AccessGroupsApiFp(this.configuration).getAccessGroupsGlobalEntity(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccessGroupsApi
     */
    public getAccessGroupsGlobalRole(options?: AxiosRequestConfig) {
        return AccessGroupsApiFp(this.configuration).getAccessGroupsGlobalRole(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccessGroupsApi
     */
    public getAccessGroupsMachine(options?: AxiosRequestConfig) {
        return AccessGroupsApiFp(this.configuration).getAccessGroupsMachine(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AccessGroupsApiPostAccessGroupsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccessGroupsApi
     */
    public postAccessGroups(requestParameters: AccessGroupsApiPostAccessGroupsRequest = {}, options?: AxiosRequestConfig) {
        return AccessGroupsApiFp(this.configuration).postAccessGroups(requestParameters.accessGroupWeb, options).then((request) => request(this.axios, this.basePath));
    }
}
