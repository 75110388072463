export const isClientSide = () => {
  return typeof window !== "undefined";
};

export const isUUID = (uuid: string) => {
  const s = "" + uuid;
  const match = s.match("^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$");
  if (match === null) {
    return false;
  }
  return true;
};

// export function isNumeric(value: string): boolean {
//   return /^\d+$/.test(value);
// }
//Allow negative values
export function isNumeric(value: string) {
  return /^-?\d+$/.test(value);
}

// Compare two versions.
// Returns 1 if V1 > V2
//         0 if V1 == V2
//        -1 if V1 < V2
//        -2 undefined

export function versionCompare(v1: any, v2: any) {
  // vnum stores each numeric
  // part of version

  let vnum1 = 0,
    vnum2 = 0;

  if (v1 == undefined || v2 == undefined) return -2;

  // loop until both string are
  // processed
  for (let i = 0, j = 0; i < v1.length || j < v2.length; ) {
    // storing numeric part of
    // version 1 in vnum1
    while (i < v1.length && v1[i] != ".") {
      vnum1 = vnum1 * 10 + Number(v1[i]);
      i++;
    }

    // storing numeric part of
    // version 2 in vnum2
    while (j < v2.length && v2[j] != ".") {
      vnum2 = vnum2 * 10 + Number(v2[j]);
      j++;
    }

    if (vnum1 > vnum2) return 1;
    if (vnum2 > vnum1) return -1;

    // if equal, reset variables and
    // go for next numeric part
    vnum1 = vnum2 = 0;
    i++;
    j++;
  }
  return 0;
}
