// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Operation Services API
 * Operation Services API
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { Operation } from '../model';
// @ts-ignore
import { Species } from '../model';
// @ts-ignore
import { SpeciesIEnumerableScaleODataValue } from '../model';
/**
 * SpeciesApi - axios parameter creator
 * @export
 */
export const SpeciesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} key 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteApiV2SpeciesKey: async (key: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'key' is not null or undefined
            assertParamExists('deleteApiV2SpeciesKey', 'key', key)
            const localVarPath = `/api/v2/Species({key})`
                .replace(`{${"key"}}`, encodeURIComponent(String(key)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} key 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteApiV2SpeciesKey_1: async (key: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'key' is not null or undefined
            assertParamExists('deleteApiV2SpeciesKey_1', 'key', key)
            const localVarPath = `/api/v2/Species/{key}`
                .replace(`{${"key"}}`, encodeURIComponent(String(key)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [$top] The max number of records.
         * @param {string} [$apply] Allows to specify a sequence of transformations to the entity set, such as groupby, filter, aggregate, etc.
         * @param {number} [$skip] The number of records to skip.
         * @param {string} [$filter] A function that must evaluate to true for a record to be returned.
         * @param {string} [$select] Specifies a subset of properties to return. Use a comma separated list.
         * @param {string} [$orderby] Determines what values are used to order a collection of records.
         * @param {string} [$expand] Use to add related query data.
         * @param {boolean} [$count] Allows clients to request a count of the matching resources included with the resources in the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiV2Species: async ($top?: number, $apply?: string, $skip?: number, $filter?: string, $select?: string, $orderby?: string, $expand?: string, $count?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v2/Species`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if ($top !== undefined) {
                localVarQueryParameter['$top'] = $top;
            }

            if ($apply !== undefined) {
                localVarQueryParameter['$apply'] = $apply;
            }

            if ($skip !== undefined) {
                localVarQueryParameter['$skip'] = $skip;
            }

            if ($filter !== undefined) {
                localVarQueryParameter['$filter'] = $filter;
            }

            if ($select !== undefined) {
                localVarQueryParameter['$select'] = $select;
            }

            if ($orderby !== undefined) {
                localVarQueryParameter['$orderby'] = $orderby;
            }

            if ($expand !== undefined) {
                localVarQueryParameter['$expand'] = $expand;
            }

            if ($count !== undefined) {
                localVarQueryParameter['$count'] = $count;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [$top] The max number of records.
         * @param {string} [$apply] Allows to specify a sequence of transformations to the entity set, such as groupby, filter, aggregate, etc.
         * @param {number} [$skip] The number of records to skip.
         * @param {string} [$filter] A function that must evaluate to true for a record to be returned.
         * @param {string} [$select] Specifies a subset of properties to return. Use a comma separated list.
         * @param {string} [$orderby] Determines what values are used to order a collection of records.
         * @param {string} [$expand] Use to add related query data.
         * @param {boolean} [$count] Allows clients to request a count of the matching resources included with the resources in the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiV2SpeciesCount: async ($top?: number, $apply?: string, $skip?: number, $filter?: string, $select?: string, $orderby?: string, $expand?: string, $count?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v2/Species/$count`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if ($top !== undefined) {
                localVarQueryParameter['$top'] = $top;
            }

            if ($apply !== undefined) {
                localVarQueryParameter['$apply'] = $apply;
            }

            if ($skip !== undefined) {
                localVarQueryParameter['$skip'] = $skip;
            }

            if ($filter !== undefined) {
                localVarQueryParameter['$filter'] = $filter;
            }

            if ($select !== undefined) {
                localVarQueryParameter['$select'] = $select;
            }

            if ($orderby !== undefined) {
                localVarQueryParameter['$orderby'] = $orderby;
            }

            if ($expand !== undefined) {
                localVarQueryParameter['$expand'] = $expand;
            }

            if ($count !== undefined) {
                localVarQueryParameter['$count'] = $count;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} key 
         * @param {number} [$top] The max number of records.
         * @param {string} [$apply] Allows to specify a sequence of transformations to the entity set, such as groupby, filter, aggregate, etc.
         * @param {number} [$skip] The number of records to skip.
         * @param {string} [$filter] A function that must evaluate to true for a record to be returned.
         * @param {string} [$select] Specifies a subset of properties to return. Use a comma separated list.
         * @param {string} [$orderby] Determines what values are used to order a collection of records.
         * @param {string} [$expand] Use to add related query data.
         * @param {boolean} [$count] Allows clients to request a count of the matching resources included with the resources in the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiV2SpeciesKey: async (key: number, $top?: number, $apply?: string, $skip?: number, $filter?: string, $select?: string, $orderby?: string, $expand?: string, $count?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'key' is not null or undefined
            assertParamExists('getApiV2SpeciesKey', 'key', key)
            const localVarPath = `/api/v2/Species({key})`
                .replace(`{${"key"}}`, encodeURIComponent(String(key)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if ($top !== undefined) {
                localVarQueryParameter['$top'] = $top;
            }

            if ($apply !== undefined) {
                localVarQueryParameter['$apply'] = $apply;
            }

            if ($skip !== undefined) {
                localVarQueryParameter['$skip'] = $skip;
            }

            if ($filter !== undefined) {
                localVarQueryParameter['$filter'] = $filter;
            }

            if ($select !== undefined) {
                localVarQueryParameter['$select'] = $select;
            }

            if ($orderby !== undefined) {
                localVarQueryParameter['$orderby'] = $orderby;
            }

            if ($expand !== undefined) {
                localVarQueryParameter['$expand'] = $expand;
            }

            if ($count !== undefined) {
                localVarQueryParameter['$count'] = $count;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} key 
         * @param {number} [$top] The max number of records.
         * @param {string} [$apply] Allows to specify a sequence of transformations to the entity set, such as groupby, filter, aggregate, etc.
         * @param {number} [$skip] The number of records to skip.
         * @param {string} [$filter] A function that must evaluate to true for a record to be returned.
         * @param {string} [$select] Specifies a subset of properties to return. Use a comma separated list.
         * @param {string} [$orderby] Determines what values are used to order a collection of records.
         * @param {string} [$expand] Use to add related query data.
         * @param {boolean} [$count] Allows clients to request a count of the matching resources included with the resources in the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiV2SpeciesKey_2: async (key: number, $top?: number, $apply?: string, $skip?: number, $filter?: string, $select?: string, $orderby?: string, $expand?: string, $count?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'key' is not null or undefined
            assertParamExists('getApiV2SpeciesKey_2', 'key', key)
            const localVarPath = `/api/v2/Species/{key}`
                .replace(`{${"key"}}`, encodeURIComponent(String(key)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if ($top !== undefined) {
                localVarQueryParameter['$top'] = $top;
            }

            if ($apply !== undefined) {
                localVarQueryParameter['$apply'] = $apply;
            }

            if ($skip !== undefined) {
                localVarQueryParameter['$skip'] = $skip;
            }

            if ($filter !== undefined) {
                localVarQueryParameter['$filter'] = $filter;
            }

            if ($select !== undefined) {
                localVarQueryParameter['$select'] = $select;
            }

            if ($orderby !== undefined) {
                localVarQueryParameter['$orderby'] = $orderby;
            }

            if ($expand !== undefined) {
                localVarQueryParameter['$expand'] = $expand;
            }

            if ($count !== undefined) {
                localVarQueryParameter['$count'] = $count;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} key 
         * @param {number} [$top] The max number of records.
         * @param {string} [$apply] Allows to specify a sequence of transformations to the entity set, such as groupby, filter, aggregate, etc.
         * @param {number} [$skip] The number of records to skip.
         * @param {string} [$filter] A function that must evaluate to true for a record to be returned.
         * @param {string} [$select] Specifies a subset of properties to return. Use a comma separated list.
         * @param {string} [$orderby] Determines what values are used to order a collection of records.
         * @param {string} [$expand] Use to add related query data.
         * @param {boolean} [$count] Allows clients to request a count of the matching resources included with the resources in the response.
         * @param {Array<Operation>} [operation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchApiV2SpeciesKey: async (key: number, $top?: number, $apply?: string, $skip?: number, $filter?: string, $select?: string, $orderby?: string, $expand?: string, $count?: boolean, operation?: Array<Operation>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'key' is not null or undefined
            assertParamExists('patchApiV2SpeciesKey', 'key', key)
            const localVarPath = `/api/v2/Species({key})`
                .replace(`{${"key"}}`, encodeURIComponent(String(key)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if ($top !== undefined) {
                localVarQueryParameter['$top'] = $top;
            }

            if ($apply !== undefined) {
                localVarQueryParameter['$apply'] = $apply;
            }

            if ($skip !== undefined) {
                localVarQueryParameter['$skip'] = $skip;
            }

            if ($filter !== undefined) {
                localVarQueryParameter['$filter'] = $filter;
            }

            if ($select !== undefined) {
                localVarQueryParameter['$select'] = $select;
            }

            if ($orderby !== undefined) {
                localVarQueryParameter['$orderby'] = $orderby;
            }

            if ($expand !== undefined) {
                localVarQueryParameter['$expand'] = $expand;
            }

            if ($count !== undefined) {
                localVarQueryParameter['$count'] = $count;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json;odata.metadata=minimal;odata.streaming=true';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(operation, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} key 
         * @param {number} [$top] The max number of records.
         * @param {string} [$apply] Allows to specify a sequence of transformations to the entity set, such as groupby, filter, aggregate, etc.
         * @param {number} [$skip] The number of records to skip.
         * @param {string} [$filter] A function that must evaluate to true for a record to be returned.
         * @param {string} [$select] Specifies a subset of properties to return. Use a comma separated list.
         * @param {string} [$orderby] Determines what values are used to order a collection of records.
         * @param {string} [$expand] Use to add related query data.
         * @param {boolean} [$count] Allows clients to request a count of the matching resources included with the resources in the response.
         * @param {Array<Operation>} [operation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchApiV2SpeciesKey_3: async (key: number, $top?: number, $apply?: string, $skip?: number, $filter?: string, $select?: string, $orderby?: string, $expand?: string, $count?: boolean, operation?: Array<Operation>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'key' is not null or undefined
            assertParamExists('patchApiV2SpeciesKey_3', 'key', key)
            const localVarPath = `/api/v2/Species/{key}`
                .replace(`{${"key"}}`, encodeURIComponent(String(key)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if ($top !== undefined) {
                localVarQueryParameter['$top'] = $top;
            }

            if ($apply !== undefined) {
                localVarQueryParameter['$apply'] = $apply;
            }

            if ($skip !== undefined) {
                localVarQueryParameter['$skip'] = $skip;
            }

            if ($filter !== undefined) {
                localVarQueryParameter['$filter'] = $filter;
            }

            if ($select !== undefined) {
                localVarQueryParameter['$select'] = $select;
            }

            if ($orderby !== undefined) {
                localVarQueryParameter['$orderby'] = $orderby;
            }

            if ($expand !== undefined) {
                localVarQueryParameter['$expand'] = $expand;
            }

            if ($count !== undefined) {
                localVarQueryParameter['$count'] = $count;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json;odata.metadata=minimal;odata.streaming=true';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(operation, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [$top] The max number of records.
         * @param {string} [$apply] Allows to specify a sequence of transformations to the entity set, such as groupby, filter, aggregate, etc.
         * @param {number} [$skip] The number of records to skip.
         * @param {string} [$filter] A function that must evaluate to true for a record to be returned.
         * @param {string} [$select] Specifies a subset of properties to return. Use a comma separated list.
         * @param {string} [$orderby] Determines what values are used to order a collection of records.
         * @param {string} [$expand] Use to add related query data.
         * @param {boolean} [$count] Allows clients to request a count of the matching resources included with the resources in the response.
         * @param {Species} [species] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postApiV2Species: async ($top?: number, $apply?: string, $skip?: number, $filter?: string, $select?: string, $orderby?: string, $expand?: string, $count?: boolean, species?: Species, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v2/Species`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if ($top !== undefined) {
                localVarQueryParameter['$top'] = $top;
            }

            if ($apply !== undefined) {
                localVarQueryParameter['$apply'] = $apply;
            }

            if ($skip !== undefined) {
                localVarQueryParameter['$skip'] = $skip;
            }

            if ($filter !== undefined) {
                localVarQueryParameter['$filter'] = $filter;
            }

            if ($select !== undefined) {
                localVarQueryParameter['$select'] = $select;
            }

            if ($orderby !== undefined) {
                localVarQueryParameter['$orderby'] = $orderby;
            }

            if ($expand !== undefined) {
                localVarQueryParameter['$expand'] = $expand;
            }

            if ($count !== undefined) {
                localVarQueryParameter['$count'] = $count;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json;odata.metadata=minimal;odata.streaming=true';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(species, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} key 
         * @param {number} [$top] The max number of records.
         * @param {string} [$apply] Allows to specify a sequence of transformations to the entity set, such as groupby, filter, aggregate, etc.
         * @param {number} [$skip] The number of records to skip.
         * @param {string} [$filter] A function that must evaluate to true for a record to be returned.
         * @param {string} [$select] Specifies a subset of properties to return. Use a comma separated list.
         * @param {string} [$orderby] Determines what values are used to order a collection of records.
         * @param {string} [$expand] Use to add related query data.
         * @param {boolean} [$count] Allows clients to request a count of the matching resources included with the resources in the response.
         * @param {Species} [species] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putApiV2SpeciesKey: async (key: number, $top?: number, $apply?: string, $skip?: number, $filter?: string, $select?: string, $orderby?: string, $expand?: string, $count?: boolean, species?: Species, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'key' is not null or undefined
            assertParamExists('putApiV2SpeciesKey', 'key', key)
            const localVarPath = `/api/v2/Species({key})`
                .replace(`{${"key"}}`, encodeURIComponent(String(key)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if ($top !== undefined) {
                localVarQueryParameter['$top'] = $top;
            }

            if ($apply !== undefined) {
                localVarQueryParameter['$apply'] = $apply;
            }

            if ($skip !== undefined) {
                localVarQueryParameter['$skip'] = $skip;
            }

            if ($filter !== undefined) {
                localVarQueryParameter['$filter'] = $filter;
            }

            if ($select !== undefined) {
                localVarQueryParameter['$select'] = $select;
            }

            if ($orderby !== undefined) {
                localVarQueryParameter['$orderby'] = $orderby;
            }

            if ($expand !== undefined) {
                localVarQueryParameter['$expand'] = $expand;
            }

            if ($count !== undefined) {
                localVarQueryParameter['$count'] = $count;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json;odata.metadata=minimal;odata.streaming=true';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(species, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} key 
         * @param {number} [$top] The max number of records.
         * @param {string} [$apply] Allows to specify a sequence of transformations to the entity set, such as groupby, filter, aggregate, etc.
         * @param {number} [$skip] The number of records to skip.
         * @param {string} [$filter] A function that must evaluate to true for a record to be returned.
         * @param {string} [$select] Specifies a subset of properties to return. Use a comma separated list.
         * @param {string} [$orderby] Determines what values are used to order a collection of records.
         * @param {string} [$expand] Use to add related query data.
         * @param {boolean} [$count] Allows clients to request a count of the matching resources included with the resources in the response.
         * @param {Species} [species] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putApiV2SpeciesKey_4: async (key: number, $top?: number, $apply?: string, $skip?: number, $filter?: string, $select?: string, $orderby?: string, $expand?: string, $count?: boolean, species?: Species, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'key' is not null or undefined
            assertParamExists('putApiV2SpeciesKey_4', 'key', key)
            const localVarPath = `/api/v2/Species/{key}`
                .replace(`{${"key"}}`, encodeURIComponent(String(key)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if ($top !== undefined) {
                localVarQueryParameter['$top'] = $top;
            }

            if ($apply !== undefined) {
                localVarQueryParameter['$apply'] = $apply;
            }

            if ($skip !== undefined) {
                localVarQueryParameter['$skip'] = $skip;
            }

            if ($filter !== undefined) {
                localVarQueryParameter['$filter'] = $filter;
            }

            if ($select !== undefined) {
                localVarQueryParameter['$select'] = $select;
            }

            if ($orderby !== undefined) {
                localVarQueryParameter['$orderby'] = $orderby;
            }

            if ($expand !== undefined) {
                localVarQueryParameter['$expand'] = $expand;
            }

            if ($count !== undefined) {
                localVarQueryParameter['$count'] = $count;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json;odata.metadata=minimal;odata.streaming=true';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(species, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SpeciesApi - functional programming interface
 * @export
 */
export const SpeciesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SpeciesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} key 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteApiV2SpeciesKey(key: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteApiV2SpeciesKey(key, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} key 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteApiV2SpeciesKey_1(key: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteApiV2SpeciesKey_1(key, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [$top] The max number of records.
         * @param {string} [$apply] Allows to specify a sequence of transformations to the entity set, such as groupby, filter, aggregate, etc.
         * @param {number} [$skip] The number of records to skip.
         * @param {string} [$filter] A function that must evaluate to true for a record to be returned.
         * @param {string} [$select] Specifies a subset of properties to return. Use a comma separated list.
         * @param {string} [$orderby] Determines what values are used to order a collection of records.
         * @param {string} [$expand] Use to add related query data.
         * @param {boolean} [$count] Allows clients to request a count of the matching resources included with the resources in the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getApiV2Species($top?: number, $apply?: string, $skip?: number, $filter?: string, $select?: string, $orderby?: string, $expand?: string, $count?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SpeciesIEnumerableScaleODataValue>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getApiV2Species($top, $apply, $skip, $filter, $select, $orderby, $expand, $count, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [$top] The max number of records.
         * @param {string} [$apply] Allows to specify a sequence of transformations to the entity set, such as groupby, filter, aggregate, etc.
         * @param {number} [$skip] The number of records to skip.
         * @param {string} [$filter] A function that must evaluate to true for a record to be returned.
         * @param {string} [$select] Specifies a subset of properties to return. Use a comma separated list.
         * @param {string} [$orderby] Determines what values are used to order a collection of records.
         * @param {string} [$expand] Use to add related query data.
         * @param {boolean} [$count] Allows clients to request a count of the matching resources included with the resources in the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getApiV2SpeciesCount($top?: number, $apply?: string, $skip?: number, $filter?: string, $select?: string, $orderby?: string, $expand?: string, $count?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SpeciesIEnumerableScaleODataValue>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getApiV2SpeciesCount($top, $apply, $skip, $filter, $select, $orderby, $expand, $count, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} key 
         * @param {number} [$top] The max number of records.
         * @param {string} [$apply] Allows to specify a sequence of transformations to the entity set, such as groupby, filter, aggregate, etc.
         * @param {number} [$skip] The number of records to skip.
         * @param {string} [$filter] A function that must evaluate to true for a record to be returned.
         * @param {string} [$select] Specifies a subset of properties to return. Use a comma separated list.
         * @param {string} [$orderby] Determines what values are used to order a collection of records.
         * @param {string} [$expand] Use to add related query data.
         * @param {boolean} [$count] Allows clients to request a count of the matching resources included with the resources in the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getApiV2SpeciesKey(key: number, $top?: number, $apply?: string, $skip?: number, $filter?: string, $select?: string, $orderby?: string, $expand?: string, $count?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Species>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getApiV2SpeciesKey(key, $top, $apply, $skip, $filter, $select, $orderby, $expand, $count, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} key 
         * @param {number} [$top] The max number of records.
         * @param {string} [$apply] Allows to specify a sequence of transformations to the entity set, such as groupby, filter, aggregate, etc.
         * @param {number} [$skip] The number of records to skip.
         * @param {string} [$filter] A function that must evaluate to true for a record to be returned.
         * @param {string} [$select] Specifies a subset of properties to return. Use a comma separated list.
         * @param {string} [$orderby] Determines what values are used to order a collection of records.
         * @param {string} [$expand] Use to add related query data.
         * @param {boolean} [$count] Allows clients to request a count of the matching resources included with the resources in the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getApiV2SpeciesKey_2(key: number, $top?: number, $apply?: string, $skip?: number, $filter?: string, $select?: string, $orderby?: string, $expand?: string, $count?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Species>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getApiV2SpeciesKey_2(key, $top, $apply, $skip, $filter, $select, $orderby, $expand, $count, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} key 
         * @param {number} [$top] The max number of records.
         * @param {string} [$apply] Allows to specify a sequence of transformations to the entity set, such as groupby, filter, aggregate, etc.
         * @param {number} [$skip] The number of records to skip.
         * @param {string} [$filter] A function that must evaluate to true for a record to be returned.
         * @param {string} [$select] Specifies a subset of properties to return. Use a comma separated list.
         * @param {string} [$orderby] Determines what values are used to order a collection of records.
         * @param {string} [$expand] Use to add related query data.
         * @param {boolean} [$count] Allows clients to request a count of the matching resources included with the resources in the response.
         * @param {Array<Operation>} [operation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchApiV2SpeciesKey(key: number, $top?: number, $apply?: string, $skip?: number, $filter?: string, $select?: string, $orderby?: string, $expand?: string, $count?: boolean, operation?: Array<Operation>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Species>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patchApiV2SpeciesKey(key, $top, $apply, $skip, $filter, $select, $orderby, $expand, $count, operation, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} key 
         * @param {number} [$top] The max number of records.
         * @param {string} [$apply] Allows to specify a sequence of transformations to the entity set, such as groupby, filter, aggregate, etc.
         * @param {number} [$skip] The number of records to skip.
         * @param {string} [$filter] A function that must evaluate to true for a record to be returned.
         * @param {string} [$select] Specifies a subset of properties to return. Use a comma separated list.
         * @param {string} [$orderby] Determines what values are used to order a collection of records.
         * @param {string} [$expand] Use to add related query data.
         * @param {boolean} [$count] Allows clients to request a count of the matching resources included with the resources in the response.
         * @param {Array<Operation>} [operation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchApiV2SpeciesKey_3(key: number, $top?: number, $apply?: string, $skip?: number, $filter?: string, $select?: string, $orderby?: string, $expand?: string, $count?: boolean, operation?: Array<Operation>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Species>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patchApiV2SpeciesKey_3(key, $top, $apply, $skip, $filter, $select, $orderby, $expand, $count, operation, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [$top] The max number of records.
         * @param {string} [$apply] Allows to specify a sequence of transformations to the entity set, such as groupby, filter, aggregate, etc.
         * @param {number} [$skip] The number of records to skip.
         * @param {string} [$filter] A function that must evaluate to true for a record to be returned.
         * @param {string} [$select] Specifies a subset of properties to return. Use a comma separated list.
         * @param {string} [$orderby] Determines what values are used to order a collection of records.
         * @param {string} [$expand] Use to add related query data.
         * @param {boolean} [$count] Allows clients to request a count of the matching resources included with the resources in the response.
         * @param {Species} [species] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postApiV2Species($top?: number, $apply?: string, $skip?: number, $filter?: string, $select?: string, $orderby?: string, $expand?: string, $count?: boolean, species?: Species, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Species>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postApiV2Species($top, $apply, $skip, $filter, $select, $orderby, $expand, $count, species, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} key 
         * @param {number} [$top] The max number of records.
         * @param {string} [$apply] Allows to specify a sequence of transformations to the entity set, such as groupby, filter, aggregate, etc.
         * @param {number} [$skip] The number of records to skip.
         * @param {string} [$filter] A function that must evaluate to true for a record to be returned.
         * @param {string} [$select] Specifies a subset of properties to return. Use a comma separated list.
         * @param {string} [$orderby] Determines what values are used to order a collection of records.
         * @param {string} [$expand] Use to add related query data.
         * @param {boolean} [$count] Allows clients to request a count of the matching resources included with the resources in the response.
         * @param {Species} [species] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putApiV2SpeciesKey(key: number, $top?: number, $apply?: string, $skip?: number, $filter?: string, $select?: string, $orderby?: string, $expand?: string, $count?: boolean, species?: Species, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Species>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putApiV2SpeciesKey(key, $top, $apply, $skip, $filter, $select, $orderby, $expand, $count, species, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} key 
         * @param {number} [$top] The max number of records.
         * @param {string} [$apply] Allows to specify a sequence of transformations to the entity set, such as groupby, filter, aggregate, etc.
         * @param {number} [$skip] The number of records to skip.
         * @param {string} [$filter] A function that must evaluate to true for a record to be returned.
         * @param {string} [$select] Specifies a subset of properties to return. Use a comma separated list.
         * @param {string} [$orderby] Determines what values are used to order a collection of records.
         * @param {string} [$expand] Use to add related query data.
         * @param {boolean} [$count] Allows clients to request a count of the matching resources included with the resources in the response.
         * @param {Species} [species] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putApiV2SpeciesKey_4(key: number, $top?: number, $apply?: string, $skip?: number, $filter?: string, $select?: string, $orderby?: string, $expand?: string, $count?: boolean, species?: Species, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Species>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putApiV2SpeciesKey_4(key, $top, $apply, $skip, $filter, $select, $orderby, $expand, $count, species, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SpeciesApi - factory interface
 * @export
 */
export const SpeciesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SpeciesApiFp(configuration)
    return {
        /**
         * 
         * @param {SpeciesApiDeleteApiV2SpeciesKeyRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteApiV2SpeciesKey(requestParameters: SpeciesApiDeleteApiV2SpeciesKeyRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.deleteApiV2SpeciesKey(requestParameters.key, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SpeciesApiDeleteApiV2SpeciesKey0Request} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteApiV2SpeciesKey_1(requestParameters: SpeciesApiDeleteApiV2SpeciesKey0Request, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.deleteApiV2SpeciesKey_1(requestParameters.key, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SpeciesApiGetApiV2SpeciesRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiV2Species(requestParameters: SpeciesApiGetApiV2SpeciesRequest = {}, options?: AxiosRequestConfig): AxiosPromise<SpeciesIEnumerableScaleODataValue> {
            return localVarFp.getApiV2Species(requestParameters.$top, requestParameters.$apply, requestParameters.$skip, requestParameters.$filter, requestParameters.$select, requestParameters.$orderby, requestParameters.$expand, requestParameters.$count, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SpeciesApiGetApiV2SpeciesCountRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiV2SpeciesCount(requestParameters: SpeciesApiGetApiV2SpeciesCountRequest = {}, options?: AxiosRequestConfig): AxiosPromise<SpeciesIEnumerableScaleODataValue> {
            return localVarFp.getApiV2SpeciesCount(requestParameters.$top, requestParameters.$apply, requestParameters.$skip, requestParameters.$filter, requestParameters.$select, requestParameters.$orderby, requestParameters.$expand, requestParameters.$count, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SpeciesApiGetApiV2SpeciesKeyRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiV2SpeciesKey(requestParameters: SpeciesApiGetApiV2SpeciesKeyRequest, options?: AxiosRequestConfig): AxiosPromise<Species> {
            return localVarFp.getApiV2SpeciesKey(requestParameters.key, requestParameters.$top, requestParameters.$apply, requestParameters.$skip, requestParameters.$filter, requestParameters.$select, requestParameters.$orderby, requestParameters.$expand, requestParameters.$count, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SpeciesApiGetApiV2SpeciesKey0Request} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiV2SpeciesKey_2(requestParameters: SpeciesApiGetApiV2SpeciesKey0Request, options?: AxiosRequestConfig): AxiosPromise<Species> {
            return localVarFp.getApiV2SpeciesKey_2(requestParameters.key, requestParameters.$top, requestParameters.$apply, requestParameters.$skip, requestParameters.$filter, requestParameters.$select, requestParameters.$orderby, requestParameters.$expand, requestParameters.$count, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SpeciesApiPatchApiV2SpeciesKeyRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchApiV2SpeciesKey(requestParameters: SpeciesApiPatchApiV2SpeciesKeyRequest, options?: AxiosRequestConfig): AxiosPromise<Species> {
            return localVarFp.patchApiV2SpeciesKey(requestParameters.key, requestParameters.$top, requestParameters.$apply, requestParameters.$skip, requestParameters.$filter, requestParameters.$select, requestParameters.$orderby, requestParameters.$expand, requestParameters.$count, requestParameters.operation, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SpeciesApiPatchApiV2SpeciesKey0Request} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchApiV2SpeciesKey_3(requestParameters: SpeciesApiPatchApiV2SpeciesKey0Request, options?: AxiosRequestConfig): AxiosPromise<Species> {
            return localVarFp.patchApiV2SpeciesKey_3(requestParameters.key, requestParameters.$top, requestParameters.$apply, requestParameters.$skip, requestParameters.$filter, requestParameters.$select, requestParameters.$orderby, requestParameters.$expand, requestParameters.$count, requestParameters.operation, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SpeciesApiPostApiV2SpeciesRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postApiV2Species(requestParameters: SpeciesApiPostApiV2SpeciesRequest = {}, options?: AxiosRequestConfig): AxiosPromise<Species> {
            return localVarFp.postApiV2Species(requestParameters.$top, requestParameters.$apply, requestParameters.$skip, requestParameters.$filter, requestParameters.$select, requestParameters.$orderby, requestParameters.$expand, requestParameters.$count, requestParameters.species, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SpeciesApiPutApiV2SpeciesKeyRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putApiV2SpeciesKey(requestParameters: SpeciesApiPutApiV2SpeciesKeyRequest, options?: AxiosRequestConfig): AxiosPromise<Species> {
            return localVarFp.putApiV2SpeciesKey(requestParameters.key, requestParameters.$top, requestParameters.$apply, requestParameters.$skip, requestParameters.$filter, requestParameters.$select, requestParameters.$orderby, requestParameters.$expand, requestParameters.$count, requestParameters.species, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SpeciesApiPutApiV2SpeciesKey0Request} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putApiV2SpeciesKey_4(requestParameters: SpeciesApiPutApiV2SpeciesKey0Request, options?: AxiosRequestConfig): AxiosPromise<Species> {
            return localVarFp.putApiV2SpeciesKey_4(requestParameters.key, requestParameters.$top, requestParameters.$apply, requestParameters.$skip, requestParameters.$filter, requestParameters.$select, requestParameters.$orderby, requestParameters.$expand, requestParameters.$count, requestParameters.species, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SpeciesApi - interface
 * @export
 * @interface SpeciesApi
 */
export interface SpeciesApiInterface {
    /**
     * 
     * @param {number} key 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SpeciesApiInterface
     */
    deleteApiV2SpeciesKey(key: number, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @param {number} key 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SpeciesApiInterface
     */
    deleteApiV2SpeciesKey_1(key: number, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @param {number} [$top] The max number of records.
     * @param {string} [$apply] Allows to specify a sequence of transformations to the entity set, such as groupby, filter, aggregate, etc.
     * @param {number} [$skip] The number of records to skip.
     * @param {string} [$filter] A function that must evaluate to true for a record to be returned.
     * @param {string} [$select] Specifies a subset of properties to return. Use a comma separated list.
     * @param {string} [$orderby] Determines what values are used to order a collection of records.
     * @param {string} [$expand] Use to add related query data.
     * @param {boolean} [$count] Allows clients to request a count of the matching resources included with the resources in the response.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SpeciesApiInterface
     */
    getApiV2Species($top?: number, $apply?: string, $skip?: number, $filter?: string, $select?: string, $orderby?: string, $expand?: string, $count?: boolean, options?: AxiosRequestConfig): AxiosPromise<SpeciesIEnumerableScaleODataValue>;

    /**
     * 
     * @param {number} [$top] The max number of records.
     * @param {string} [$apply] Allows to specify a sequence of transformations to the entity set, such as groupby, filter, aggregate, etc.
     * @param {number} [$skip] The number of records to skip.
     * @param {string} [$filter] A function that must evaluate to true for a record to be returned.
     * @param {string} [$select] Specifies a subset of properties to return. Use a comma separated list.
     * @param {string} [$orderby] Determines what values are used to order a collection of records.
     * @param {string} [$expand] Use to add related query data.
     * @param {boolean} [$count] Allows clients to request a count of the matching resources included with the resources in the response.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SpeciesApiInterface
     */
    getApiV2SpeciesCount($top?: number, $apply?: string, $skip?: number, $filter?: string, $select?: string, $orderby?: string, $expand?: string, $count?: boolean, options?: AxiosRequestConfig): AxiosPromise<SpeciesIEnumerableScaleODataValue>;

    /**
     * 
     * @param {number} key 
     * @param {number} [$top] The max number of records.
     * @param {string} [$apply] Allows to specify a sequence of transformations to the entity set, such as groupby, filter, aggregate, etc.
     * @param {number} [$skip] The number of records to skip.
     * @param {string} [$filter] A function that must evaluate to true for a record to be returned.
     * @param {string} [$select] Specifies a subset of properties to return. Use a comma separated list.
     * @param {string} [$orderby] Determines what values are used to order a collection of records.
     * @param {string} [$expand] Use to add related query data.
     * @param {boolean} [$count] Allows clients to request a count of the matching resources included with the resources in the response.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SpeciesApiInterface
     */
    getApiV2SpeciesKey(key: number, $top?: number, $apply?: string, $skip?: number, $filter?: string, $select?: string, $orderby?: string, $expand?: string, $count?: boolean, options?: AxiosRequestConfig): AxiosPromise<Species>;

    /**
     * 
     * @param {number} key 
     * @param {number} [$top] The max number of records.
     * @param {string} [$apply] Allows to specify a sequence of transformations to the entity set, such as groupby, filter, aggregate, etc.
     * @param {number} [$skip] The number of records to skip.
     * @param {string} [$filter] A function that must evaluate to true for a record to be returned.
     * @param {string} [$select] Specifies a subset of properties to return. Use a comma separated list.
     * @param {string} [$orderby] Determines what values are used to order a collection of records.
     * @param {string} [$expand] Use to add related query data.
     * @param {boolean} [$count] Allows clients to request a count of the matching resources included with the resources in the response.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SpeciesApiInterface
     */
    getApiV2SpeciesKey_2(key: number, $top?: number, $apply?: string, $skip?: number, $filter?: string, $select?: string, $orderby?: string, $expand?: string, $count?: boolean, options?: AxiosRequestConfig): AxiosPromise<Species>;

    /**
     * 
     * @param {number} key 
     * @param {number} [$top] The max number of records.
     * @param {string} [$apply] Allows to specify a sequence of transformations to the entity set, such as groupby, filter, aggregate, etc.
     * @param {number} [$skip] The number of records to skip.
     * @param {string} [$filter] A function that must evaluate to true for a record to be returned.
     * @param {string} [$select] Specifies a subset of properties to return. Use a comma separated list.
     * @param {string} [$orderby] Determines what values are used to order a collection of records.
     * @param {string} [$expand] Use to add related query data.
     * @param {boolean} [$count] Allows clients to request a count of the matching resources included with the resources in the response.
     * @param {Array<Operation>} [operation] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SpeciesApiInterface
     */
    patchApiV2SpeciesKey(key: number, $top?: number, $apply?: string, $skip?: number, $filter?: string, $select?: string, $orderby?: string, $expand?: string, $count?: boolean, operation?: Array<Operation>, options?: AxiosRequestConfig): AxiosPromise<Species>;

    /**
     * 
     * @param {number} key 
     * @param {number} [$top] The max number of records.
     * @param {string} [$apply] Allows to specify a sequence of transformations to the entity set, such as groupby, filter, aggregate, etc.
     * @param {number} [$skip] The number of records to skip.
     * @param {string} [$filter] A function that must evaluate to true for a record to be returned.
     * @param {string} [$select] Specifies a subset of properties to return. Use a comma separated list.
     * @param {string} [$orderby] Determines what values are used to order a collection of records.
     * @param {string} [$expand] Use to add related query data.
     * @param {boolean} [$count] Allows clients to request a count of the matching resources included with the resources in the response.
     * @param {Array<Operation>} [operation] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SpeciesApiInterface
     */
    patchApiV2SpeciesKey_3(key: number, $top?: number, $apply?: string, $skip?: number, $filter?: string, $select?: string, $orderby?: string, $expand?: string, $count?: boolean, operation?: Array<Operation>, options?: AxiosRequestConfig): AxiosPromise<Species>;

    /**
     * 
     * @param {number} [$top] The max number of records.
     * @param {string} [$apply] Allows to specify a sequence of transformations to the entity set, such as groupby, filter, aggregate, etc.
     * @param {number} [$skip] The number of records to skip.
     * @param {string} [$filter] A function that must evaluate to true for a record to be returned.
     * @param {string} [$select] Specifies a subset of properties to return. Use a comma separated list.
     * @param {string} [$orderby] Determines what values are used to order a collection of records.
     * @param {string} [$expand] Use to add related query data.
     * @param {boolean} [$count] Allows clients to request a count of the matching resources included with the resources in the response.
     * @param {Species} [species] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SpeciesApiInterface
     */
    postApiV2Species($top?: number, $apply?: string, $skip?: number, $filter?: string, $select?: string, $orderby?: string, $expand?: string, $count?: boolean, species?: Species, options?: AxiosRequestConfig): AxiosPromise<Species>;

    /**
     * 
     * @param {number} key 
     * @param {number} [$top] The max number of records.
     * @param {string} [$apply] Allows to specify a sequence of transformations to the entity set, such as groupby, filter, aggregate, etc.
     * @param {number} [$skip] The number of records to skip.
     * @param {string} [$filter] A function that must evaluate to true for a record to be returned.
     * @param {string} [$select] Specifies a subset of properties to return. Use a comma separated list.
     * @param {string} [$orderby] Determines what values are used to order a collection of records.
     * @param {string} [$expand] Use to add related query data.
     * @param {boolean} [$count] Allows clients to request a count of the matching resources included with the resources in the response.
     * @param {Species} [species] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SpeciesApiInterface
     */
    putApiV2SpeciesKey(key: number, $top?: number, $apply?: string, $skip?: number, $filter?: string, $select?: string, $orderby?: string, $expand?: string, $count?: boolean, species?: Species, options?: AxiosRequestConfig): AxiosPromise<Species>;

    /**
     * 
     * @param {number} key 
     * @param {number} [$top] The max number of records.
     * @param {string} [$apply] Allows to specify a sequence of transformations to the entity set, such as groupby, filter, aggregate, etc.
     * @param {number} [$skip] The number of records to skip.
     * @param {string} [$filter] A function that must evaluate to true for a record to be returned.
     * @param {string} [$select] Specifies a subset of properties to return. Use a comma separated list.
     * @param {string} [$orderby] Determines what values are used to order a collection of records.
     * @param {string} [$expand] Use to add related query data.
     * @param {boolean} [$count] Allows clients to request a count of the matching resources included with the resources in the response.
     * @param {Species} [species] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SpeciesApiInterface
     */
    putApiV2SpeciesKey_4(key: number, $top?: number, $apply?: string, $skip?: number, $filter?: string, $select?: string, $orderby?: string, $expand?: string, $count?: boolean, species?: Species, options?: AxiosRequestConfig): AxiosPromise<Species>;

}

/**
 * Request parameters for deleteApiV2SpeciesKey operation in SpeciesApi.
 * @export
 * @interface SpeciesApiDeleteApiV2SpeciesKeyRequest
 */
export interface SpeciesApiDeleteApiV2SpeciesKeyRequest {
    /**
     * 
     * @type {number}
     * @memberof SpeciesApiDeleteApiV2SpeciesKey
     */
    readonly key: number
}

/**
 * Request parameters for deleteApiV2SpeciesKey_1 operation in SpeciesApi.
 * @export
 * @interface SpeciesApiDeleteApiV2SpeciesKey0Request
 */
export interface SpeciesApiDeleteApiV2SpeciesKey0Request {
    /**
     * 
     * @type {number}
     * @memberof SpeciesApiDeleteApiV2SpeciesKey0
     */
    readonly key: number
}

/**
 * Request parameters for getApiV2Species operation in SpeciesApi.
 * @export
 * @interface SpeciesApiGetApiV2SpeciesRequest
 */
export interface SpeciesApiGetApiV2SpeciesRequest {
    /**
     * The max number of records.
     * @type {number}
     * @memberof SpeciesApiGetApiV2Species
     */
    readonly $top?: number

    /**
     * Allows to specify a sequence of transformations to the entity set, such as groupby, filter, aggregate, etc.
     * @type {string}
     * @memberof SpeciesApiGetApiV2Species
     */
    readonly $apply?: string

    /**
     * The number of records to skip.
     * @type {number}
     * @memberof SpeciesApiGetApiV2Species
     */
    readonly $skip?: number

    /**
     * A function that must evaluate to true for a record to be returned.
     * @type {string}
     * @memberof SpeciesApiGetApiV2Species
     */
    readonly $filter?: string

    /**
     * Specifies a subset of properties to return. Use a comma separated list.
     * @type {string}
     * @memberof SpeciesApiGetApiV2Species
     */
    readonly $select?: string

    /**
     * Determines what values are used to order a collection of records.
     * @type {string}
     * @memberof SpeciesApiGetApiV2Species
     */
    readonly $orderby?: string

    /**
     * Use to add related query data.
     * @type {string}
     * @memberof SpeciesApiGetApiV2Species
     */
    readonly $expand?: string

    /**
     * Allows clients to request a count of the matching resources included with the resources in the response.
     * @type {boolean}
     * @memberof SpeciesApiGetApiV2Species
     */
    readonly $count?: boolean
}

/**
 * Request parameters for getApiV2SpeciesCount operation in SpeciesApi.
 * @export
 * @interface SpeciesApiGetApiV2SpeciesCountRequest
 */
export interface SpeciesApiGetApiV2SpeciesCountRequest {
    /**
     * The max number of records.
     * @type {number}
     * @memberof SpeciesApiGetApiV2SpeciesCount
     */
    readonly $top?: number

    /**
     * Allows to specify a sequence of transformations to the entity set, such as groupby, filter, aggregate, etc.
     * @type {string}
     * @memberof SpeciesApiGetApiV2SpeciesCount
     */
    readonly $apply?: string

    /**
     * The number of records to skip.
     * @type {number}
     * @memberof SpeciesApiGetApiV2SpeciesCount
     */
    readonly $skip?: number

    /**
     * A function that must evaluate to true for a record to be returned.
     * @type {string}
     * @memberof SpeciesApiGetApiV2SpeciesCount
     */
    readonly $filter?: string

    /**
     * Specifies a subset of properties to return. Use a comma separated list.
     * @type {string}
     * @memberof SpeciesApiGetApiV2SpeciesCount
     */
    readonly $select?: string

    /**
     * Determines what values are used to order a collection of records.
     * @type {string}
     * @memberof SpeciesApiGetApiV2SpeciesCount
     */
    readonly $orderby?: string

    /**
     * Use to add related query data.
     * @type {string}
     * @memberof SpeciesApiGetApiV2SpeciesCount
     */
    readonly $expand?: string

    /**
     * Allows clients to request a count of the matching resources included with the resources in the response.
     * @type {boolean}
     * @memberof SpeciesApiGetApiV2SpeciesCount
     */
    readonly $count?: boolean
}

/**
 * Request parameters for getApiV2SpeciesKey operation in SpeciesApi.
 * @export
 * @interface SpeciesApiGetApiV2SpeciesKeyRequest
 */
export interface SpeciesApiGetApiV2SpeciesKeyRequest {
    /**
     * 
     * @type {number}
     * @memberof SpeciesApiGetApiV2SpeciesKey
     */
    readonly key: number

    /**
     * The max number of records.
     * @type {number}
     * @memberof SpeciesApiGetApiV2SpeciesKey
     */
    readonly $top?: number

    /**
     * Allows to specify a sequence of transformations to the entity set, such as groupby, filter, aggregate, etc.
     * @type {string}
     * @memberof SpeciesApiGetApiV2SpeciesKey
     */
    readonly $apply?: string

    /**
     * The number of records to skip.
     * @type {number}
     * @memberof SpeciesApiGetApiV2SpeciesKey
     */
    readonly $skip?: number

    /**
     * A function that must evaluate to true for a record to be returned.
     * @type {string}
     * @memberof SpeciesApiGetApiV2SpeciesKey
     */
    readonly $filter?: string

    /**
     * Specifies a subset of properties to return. Use a comma separated list.
     * @type {string}
     * @memberof SpeciesApiGetApiV2SpeciesKey
     */
    readonly $select?: string

    /**
     * Determines what values are used to order a collection of records.
     * @type {string}
     * @memberof SpeciesApiGetApiV2SpeciesKey
     */
    readonly $orderby?: string

    /**
     * Use to add related query data.
     * @type {string}
     * @memberof SpeciesApiGetApiV2SpeciesKey
     */
    readonly $expand?: string

    /**
     * Allows clients to request a count of the matching resources included with the resources in the response.
     * @type {boolean}
     * @memberof SpeciesApiGetApiV2SpeciesKey
     */
    readonly $count?: boolean
}

/**
 * Request parameters for getApiV2SpeciesKey_2 operation in SpeciesApi.
 * @export
 * @interface SpeciesApiGetApiV2SpeciesKey0Request
 */
export interface SpeciesApiGetApiV2SpeciesKey0Request {
    /**
     * 
     * @type {number}
     * @memberof SpeciesApiGetApiV2SpeciesKey0
     */
    readonly key: number

    /**
     * The max number of records.
     * @type {number}
     * @memberof SpeciesApiGetApiV2SpeciesKey0
     */
    readonly $top?: number

    /**
     * Allows to specify a sequence of transformations to the entity set, such as groupby, filter, aggregate, etc.
     * @type {string}
     * @memberof SpeciesApiGetApiV2SpeciesKey0
     */
    readonly $apply?: string

    /**
     * The number of records to skip.
     * @type {number}
     * @memberof SpeciesApiGetApiV2SpeciesKey0
     */
    readonly $skip?: number

    /**
     * A function that must evaluate to true for a record to be returned.
     * @type {string}
     * @memberof SpeciesApiGetApiV2SpeciesKey0
     */
    readonly $filter?: string

    /**
     * Specifies a subset of properties to return. Use a comma separated list.
     * @type {string}
     * @memberof SpeciesApiGetApiV2SpeciesKey0
     */
    readonly $select?: string

    /**
     * Determines what values are used to order a collection of records.
     * @type {string}
     * @memberof SpeciesApiGetApiV2SpeciesKey0
     */
    readonly $orderby?: string

    /**
     * Use to add related query data.
     * @type {string}
     * @memberof SpeciesApiGetApiV2SpeciesKey0
     */
    readonly $expand?: string

    /**
     * Allows clients to request a count of the matching resources included with the resources in the response.
     * @type {boolean}
     * @memberof SpeciesApiGetApiV2SpeciesKey0
     */
    readonly $count?: boolean
}

/**
 * Request parameters for patchApiV2SpeciesKey operation in SpeciesApi.
 * @export
 * @interface SpeciesApiPatchApiV2SpeciesKeyRequest
 */
export interface SpeciesApiPatchApiV2SpeciesKeyRequest {
    /**
     * 
     * @type {number}
     * @memberof SpeciesApiPatchApiV2SpeciesKey
     */
    readonly key: number

    /**
     * The max number of records.
     * @type {number}
     * @memberof SpeciesApiPatchApiV2SpeciesKey
     */
    readonly $top?: number

    /**
     * Allows to specify a sequence of transformations to the entity set, such as groupby, filter, aggregate, etc.
     * @type {string}
     * @memberof SpeciesApiPatchApiV2SpeciesKey
     */
    readonly $apply?: string

    /**
     * The number of records to skip.
     * @type {number}
     * @memberof SpeciesApiPatchApiV2SpeciesKey
     */
    readonly $skip?: number

    /**
     * A function that must evaluate to true for a record to be returned.
     * @type {string}
     * @memberof SpeciesApiPatchApiV2SpeciesKey
     */
    readonly $filter?: string

    /**
     * Specifies a subset of properties to return. Use a comma separated list.
     * @type {string}
     * @memberof SpeciesApiPatchApiV2SpeciesKey
     */
    readonly $select?: string

    /**
     * Determines what values are used to order a collection of records.
     * @type {string}
     * @memberof SpeciesApiPatchApiV2SpeciesKey
     */
    readonly $orderby?: string

    /**
     * Use to add related query data.
     * @type {string}
     * @memberof SpeciesApiPatchApiV2SpeciesKey
     */
    readonly $expand?: string

    /**
     * Allows clients to request a count of the matching resources included with the resources in the response.
     * @type {boolean}
     * @memberof SpeciesApiPatchApiV2SpeciesKey
     */
    readonly $count?: boolean

    /**
     * 
     * @type {Array<Operation>}
     * @memberof SpeciesApiPatchApiV2SpeciesKey
     */
    readonly operation?: Array<Operation>
}

/**
 * Request parameters for patchApiV2SpeciesKey_3 operation in SpeciesApi.
 * @export
 * @interface SpeciesApiPatchApiV2SpeciesKey0Request
 */
export interface SpeciesApiPatchApiV2SpeciesKey0Request {
    /**
     * 
     * @type {number}
     * @memberof SpeciesApiPatchApiV2SpeciesKey0
     */
    readonly key: number

    /**
     * The max number of records.
     * @type {number}
     * @memberof SpeciesApiPatchApiV2SpeciesKey0
     */
    readonly $top?: number

    /**
     * Allows to specify a sequence of transformations to the entity set, such as groupby, filter, aggregate, etc.
     * @type {string}
     * @memberof SpeciesApiPatchApiV2SpeciesKey0
     */
    readonly $apply?: string

    /**
     * The number of records to skip.
     * @type {number}
     * @memberof SpeciesApiPatchApiV2SpeciesKey0
     */
    readonly $skip?: number

    /**
     * A function that must evaluate to true for a record to be returned.
     * @type {string}
     * @memberof SpeciesApiPatchApiV2SpeciesKey0
     */
    readonly $filter?: string

    /**
     * Specifies a subset of properties to return. Use a comma separated list.
     * @type {string}
     * @memberof SpeciesApiPatchApiV2SpeciesKey0
     */
    readonly $select?: string

    /**
     * Determines what values are used to order a collection of records.
     * @type {string}
     * @memberof SpeciesApiPatchApiV2SpeciesKey0
     */
    readonly $orderby?: string

    /**
     * Use to add related query data.
     * @type {string}
     * @memberof SpeciesApiPatchApiV2SpeciesKey0
     */
    readonly $expand?: string

    /**
     * Allows clients to request a count of the matching resources included with the resources in the response.
     * @type {boolean}
     * @memberof SpeciesApiPatchApiV2SpeciesKey0
     */
    readonly $count?: boolean

    /**
     * 
     * @type {Array<Operation>}
     * @memberof SpeciesApiPatchApiV2SpeciesKey0
     */
    readonly operation?: Array<Operation>
}

/**
 * Request parameters for postApiV2Species operation in SpeciesApi.
 * @export
 * @interface SpeciesApiPostApiV2SpeciesRequest
 */
export interface SpeciesApiPostApiV2SpeciesRequest {
    /**
     * The max number of records.
     * @type {number}
     * @memberof SpeciesApiPostApiV2Species
     */
    readonly $top?: number

    /**
     * Allows to specify a sequence of transformations to the entity set, such as groupby, filter, aggregate, etc.
     * @type {string}
     * @memberof SpeciesApiPostApiV2Species
     */
    readonly $apply?: string

    /**
     * The number of records to skip.
     * @type {number}
     * @memberof SpeciesApiPostApiV2Species
     */
    readonly $skip?: number

    /**
     * A function that must evaluate to true for a record to be returned.
     * @type {string}
     * @memberof SpeciesApiPostApiV2Species
     */
    readonly $filter?: string

    /**
     * Specifies a subset of properties to return. Use a comma separated list.
     * @type {string}
     * @memberof SpeciesApiPostApiV2Species
     */
    readonly $select?: string

    /**
     * Determines what values are used to order a collection of records.
     * @type {string}
     * @memberof SpeciesApiPostApiV2Species
     */
    readonly $orderby?: string

    /**
     * Use to add related query data.
     * @type {string}
     * @memberof SpeciesApiPostApiV2Species
     */
    readonly $expand?: string

    /**
     * Allows clients to request a count of the matching resources included with the resources in the response.
     * @type {boolean}
     * @memberof SpeciesApiPostApiV2Species
     */
    readonly $count?: boolean

    /**
     * 
     * @type {Species}
     * @memberof SpeciesApiPostApiV2Species
     */
    readonly species?: Species
}

/**
 * Request parameters for putApiV2SpeciesKey operation in SpeciesApi.
 * @export
 * @interface SpeciesApiPutApiV2SpeciesKeyRequest
 */
export interface SpeciesApiPutApiV2SpeciesKeyRequest {
    /**
     * 
     * @type {number}
     * @memberof SpeciesApiPutApiV2SpeciesKey
     */
    readonly key: number

    /**
     * The max number of records.
     * @type {number}
     * @memberof SpeciesApiPutApiV2SpeciesKey
     */
    readonly $top?: number

    /**
     * Allows to specify a sequence of transformations to the entity set, such as groupby, filter, aggregate, etc.
     * @type {string}
     * @memberof SpeciesApiPutApiV2SpeciesKey
     */
    readonly $apply?: string

    /**
     * The number of records to skip.
     * @type {number}
     * @memberof SpeciesApiPutApiV2SpeciesKey
     */
    readonly $skip?: number

    /**
     * A function that must evaluate to true for a record to be returned.
     * @type {string}
     * @memberof SpeciesApiPutApiV2SpeciesKey
     */
    readonly $filter?: string

    /**
     * Specifies a subset of properties to return. Use a comma separated list.
     * @type {string}
     * @memberof SpeciesApiPutApiV2SpeciesKey
     */
    readonly $select?: string

    /**
     * Determines what values are used to order a collection of records.
     * @type {string}
     * @memberof SpeciesApiPutApiV2SpeciesKey
     */
    readonly $orderby?: string

    /**
     * Use to add related query data.
     * @type {string}
     * @memberof SpeciesApiPutApiV2SpeciesKey
     */
    readonly $expand?: string

    /**
     * Allows clients to request a count of the matching resources included with the resources in the response.
     * @type {boolean}
     * @memberof SpeciesApiPutApiV2SpeciesKey
     */
    readonly $count?: boolean

    /**
     * 
     * @type {Species}
     * @memberof SpeciesApiPutApiV2SpeciesKey
     */
    readonly species?: Species
}

/**
 * Request parameters for putApiV2SpeciesKey_4 operation in SpeciesApi.
 * @export
 * @interface SpeciesApiPutApiV2SpeciesKey0Request
 */
export interface SpeciesApiPutApiV2SpeciesKey0Request {
    /**
     * 
     * @type {number}
     * @memberof SpeciesApiPutApiV2SpeciesKey0
     */
    readonly key: number

    /**
     * The max number of records.
     * @type {number}
     * @memberof SpeciesApiPutApiV2SpeciesKey0
     */
    readonly $top?: number

    /**
     * Allows to specify a sequence of transformations to the entity set, such as groupby, filter, aggregate, etc.
     * @type {string}
     * @memberof SpeciesApiPutApiV2SpeciesKey0
     */
    readonly $apply?: string

    /**
     * The number of records to skip.
     * @type {number}
     * @memberof SpeciesApiPutApiV2SpeciesKey0
     */
    readonly $skip?: number

    /**
     * A function that must evaluate to true for a record to be returned.
     * @type {string}
     * @memberof SpeciesApiPutApiV2SpeciesKey0
     */
    readonly $filter?: string

    /**
     * Specifies a subset of properties to return. Use a comma separated list.
     * @type {string}
     * @memberof SpeciesApiPutApiV2SpeciesKey0
     */
    readonly $select?: string

    /**
     * Determines what values are used to order a collection of records.
     * @type {string}
     * @memberof SpeciesApiPutApiV2SpeciesKey0
     */
    readonly $orderby?: string

    /**
     * Use to add related query data.
     * @type {string}
     * @memberof SpeciesApiPutApiV2SpeciesKey0
     */
    readonly $expand?: string

    /**
     * Allows clients to request a count of the matching resources included with the resources in the response.
     * @type {boolean}
     * @memberof SpeciesApiPutApiV2SpeciesKey0
     */
    readonly $count?: boolean

    /**
     * 
     * @type {Species}
     * @memberof SpeciesApiPutApiV2SpeciesKey0
     */
    readonly species?: Species
}

/**
 * SpeciesApi - object-oriented interface
 * @export
 * @class SpeciesApi
 * @extends {BaseAPI}
 */
export class SpeciesApi extends BaseAPI implements SpeciesApiInterface {
    /**
     * 
     * @param {SpeciesApiDeleteApiV2SpeciesKeyRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SpeciesApi
     */
    public deleteApiV2SpeciesKey(requestParameters: SpeciesApiDeleteApiV2SpeciesKeyRequest, options?: AxiosRequestConfig) {
        return SpeciesApiFp(this.configuration).deleteApiV2SpeciesKey(requestParameters.key, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SpeciesApiDeleteApiV2SpeciesKey0Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SpeciesApi
     */
    public deleteApiV2SpeciesKey_1(requestParameters: SpeciesApiDeleteApiV2SpeciesKey0Request, options?: AxiosRequestConfig) {
        return SpeciesApiFp(this.configuration).deleteApiV2SpeciesKey_1(requestParameters.key, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SpeciesApiGetApiV2SpeciesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SpeciesApi
     */
    public getApiV2Species(requestParameters: SpeciesApiGetApiV2SpeciesRequest = {}, options?: AxiosRequestConfig) {
        return SpeciesApiFp(this.configuration).getApiV2Species(requestParameters.$top, requestParameters.$apply, requestParameters.$skip, requestParameters.$filter, requestParameters.$select, requestParameters.$orderby, requestParameters.$expand, requestParameters.$count, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SpeciesApiGetApiV2SpeciesCountRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SpeciesApi
     */
    public getApiV2SpeciesCount(requestParameters: SpeciesApiGetApiV2SpeciesCountRequest = {}, options?: AxiosRequestConfig) {
        return SpeciesApiFp(this.configuration).getApiV2SpeciesCount(requestParameters.$top, requestParameters.$apply, requestParameters.$skip, requestParameters.$filter, requestParameters.$select, requestParameters.$orderby, requestParameters.$expand, requestParameters.$count, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SpeciesApiGetApiV2SpeciesKeyRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SpeciesApi
     */
    public getApiV2SpeciesKey(requestParameters: SpeciesApiGetApiV2SpeciesKeyRequest, options?: AxiosRequestConfig) {
        return SpeciesApiFp(this.configuration).getApiV2SpeciesKey(requestParameters.key, requestParameters.$top, requestParameters.$apply, requestParameters.$skip, requestParameters.$filter, requestParameters.$select, requestParameters.$orderby, requestParameters.$expand, requestParameters.$count, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SpeciesApiGetApiV2SpeciesKey0Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SpeciesApi
     */
    public getApiV2SpeciesKey_2(requestParameters: SpeciesApiGetApiV2SpeciesKey0Request, options?: AxiosRequestConfig) {
        return SpeciesApiFp(this.configuration).getApiV2SpeciesKey_2(requestParameters.key, requestParameters.$top, requestParameters.$apply, requestParameters.$skip, requestParameters.$filter, requestParameters.$select, requestParameters.$orderby, requestParameters.$expand, requestParameters.$count, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SpeciesApiPatchApiV2SpeciesKeyRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SpeciesApi
     */
    public patchApiV2SpeciesKey(requestParameters: SpeciesApiPatchApiV2SpeciesKeyRequest, options?: AxiosRequestConfig) {
        return SpeciesApiFp(this.configuration).patchApiV2SpeciesKey(requestParameters.key, requestParameters.$top, requestParameters.$apply, requestParameters.$skip, requestParameters.$filter, requestParameters.$select, requestParameters.$orderby, requestParameters.$expand, requestParameters.$count, requestParameters.operation, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SpeciesApiPatchApiV2SpeciesKey0Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SpeciesApi
     */
    public patchApiV2SpeciesKey_3(requestParameters: SpeciesApiPatchApiV2SpeciesKey0Request, options?: AxiosRequestConfig) {
        return SpeciesApiFp(this.configuration).patchApiV2SpeciesKey_3(requestParameters.key, requestParameters.$top, requestParameters.$apply, requestParameters.$skip, requestParameters.$filter, requestParameters.$select, requestParameters.$orderby, requestParameters.$expand, requestParameters.$count, requestParameters.operation, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SpeciesApiPostApiV2SpeciesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SpeciesApi
     */
    public postApiV2Species(requestParameters: SpeciesApiPostApiV2SpeciesRequest = {}, options?: AxiosRequestConfig) {
        return SpeciesApiFp(this.configuration).postApiV2Species(requestParameters.$top, requestParameters.$apply, requestParameters.$skip, requestParameters.$filter, requestParameters.$select, requestParameters.$orderby, requestParameters.$expand, requestParameters.$count, requestParameters.species, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SpeciesApiPutApiV2SpeciesKeyRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SpeciesApi
     */
    public putApiV2SpeciesKey(requestParameters: SpeciesApiPutApiV2SpeciesKeyRequest, options?: AxiosRequestConfig) {
        return SpeciesApiFp(this.configuration).putApiV2SpeciesKey(requestParameters.key, requestParameters.$top, requestParameters.$apply, requestParameters.$skip, requestParameters.$filter, requestParameters.$select, requestParameters.$orderby, requestParameters.$expand, requestParameters.$count, requestParameters.species, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SpeciesApiPutApiV2SpeciesKey0Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SpeciesApi
     */
    public putApiV2SpeciesKey_4(requestParameters: SpeciesApiPutApiV2SpeciesKey0Request, options?: AxiosRequestConfig) {
        return SpeciesApiFp(this.configuration).putApiV2SpeciesKey_4(requestParameters.key, requestParameters.$top, requestParameters.$apply, requestParameters.$skip, requestParameters.$filter, requestParameters.$select, requestParameters.$orderby, requestParameters.$expand, requestParameters.$count, requestParameters.species, options).then((request) => request(this.axios, this.basePath));
    }
}
