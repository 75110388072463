// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * UniteAQ Access API
 * Access API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @enum {string}
 */

export const ErrorCodes = {
    invalid_model: 'invalid_model',
    gos_error: 'gos_error',
    user_access_unauthorized: 'user_access_unauthorized',
    entity_not_found: 'entity_not_found',
    unknown_error: 'unknown_error'
} as const;

export type ErrorCodes = typeof ErrorCodes[keyof typeof ErrorCodes];



