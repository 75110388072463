import { createSlice } from "@reduxjs/toolkit";
import { UserWeb } from "api/swagger/users.api.2.0";

type dataState = {
  user: UserWeb | null;
};

const initialState: dataState = {
  user: null,
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUser: (state, action: { payload: UserWeb }) => {
      state.user = action.payload;
    },
  },
});

export const { setUser } = userSlice.actions;
