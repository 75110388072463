import { Typography } from "@scaleaq/scaleui";
import styled from "styled-components";

export default styled(Typography)`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 80%;
  color: inherit;
`;
