// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Operation Services API
 * Operation Services API
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { Operation } from '../model';
// @ts-ignore
import { Silo } from '../model';
// @ts-ignore
import { SiloIEnumerableScaleODataValue } from '../model';
/**
 * SilosApi - axios parameter creator
 * @export
 */
export const SilosApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {boolean} [includeDeleted] 
         * @param {boolean} [includeBargeSilo] 
         * @param {number} [$top] The max number of records.
         * @param {string} [$apply] Allows to specify a sequence of transformations to the entity set, such as groupby, filter, aggregate, etc.
         * @param {number} [$skip] The number of records to skip.
         * @param {string} [$filter] A function that must evaluate to true for a record to be returned.
         * @param {string} [$select] Specifies a subset of properties to return. Use a comma separated list.
         * @param {string} [$orderby] Determines what values are used to order a collection of records.
         * @param {string} [$expand] Use to add related query data.
         * @param {boolean} [$count] Allows clients to request a count of the matching resources included with the resources in the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiV2Silos: async (includeDeleted?: boolean, includeBargeSilo?: boolean, $top?: number, $apply?: string, $skip?: number, $filter?: string, $select?: string, $orderby?: string, $expand?: string, $count?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v2/Silos`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (includeDeleted !== undefined) {
                localVarQueryParameter['includeDeleted'] = includeDeleted;
            }

            if (includeBargeSilo !== undefined) {
                localVarQueryParameter['includeBargeSilo'] = includeBargeSilo;
            }

            if ($top !== undefined) {
                localVarQueryParameter['$top'] = $top;
            }

            if ($apply !== undefined) {
                localVarQueryParameter['$apply'] = $apply;
            }

            if ($skip !== undefined) {
                localVarQueryParameter['$skip'] = $skip;
            }

            if ($filter !== undefined) {
                localVarQueryParameter['$filter'] = $filter;
            }

            if ($select !== undefined) {
                localVarQueryParameter['$select'] = $select;
            }

            if ($orderby !== undefined) {
                localVarQueryParameter['$orderby'] = $orderby;
            }

            if ($expand !== undefined) {
                localVarQueryParameter['$expand'] = $expand;
            }

            if ($count !== undefined) {
                localVarQueryParameter['$count'] = $count;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {boolean} [includeDeleted] 
         * @param {boolean} [includeBargeSilo] 
         * @param {number} [$top] The max number of records.
         * @param {string} [$apply] Allows to specify a sequence of transformations to the entity set, such as groupby, filter, aggregate, etc.
         * @param {number} [$skip] The number of records to skip.
         * @param {string} [$filter] A function that must evaluate to true for a record to be returned.
         * @param {string} [$select] Specifies a subset of properties to return. Use a comma separated list.
         * @param {string} [$orderby] Determines what values are used to order a collection of records.
         * @param {string} [$expand] Use to add related query data.
         * @param {boolean} [$count] Allows clients to request a count of the matching resources included with the resources in the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiV2SilosCount: async (includeDeleted?: boolean, includeBargeSilo?: boolean, $top?: number, $apply?: string, $skip?: number, $filter?: string, $select?: string, $orderby?: string, $expand?: string, $count?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v2/Silos/$count`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (includeDeleted !== undefined) {
                localVarQueryParameter['includeDeleted'] = includeDeleted;
            }

            if (includeBargeSilo !== undefined) {
                localVarQueryParameter['includeBargeSilo'] = includeBargeSilo;
            }

            if ($top !== undefined) {
                localVarQueryParameter['$top'] = $top;
            }

            if ($apply !== undefined) {
                localVarQueryParameter['$apply'] = $apply;
            }

            if ($skip !== undefined) {
                localVarQueryParameter['$skip'] = $skip;
            }

            if ($filter !== undefined) {
                localVarQueryParameter['$filter'] = $filter;
            }

            if ($select !== undefined) {
                localVarQueryParameter['$select'] = $select;
            }

            if ($orderby !== undefined) {
                localVarQueryParameter['$orderby'] = $orderby;
            }

            if ($expand !== undefined) {
                localVarQueryParameter['$expand'] = $expand;
            }

            if ($count !== undefined) {
                localVarQueryParameter['$count'] = $count;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} key 
         * @param {number} [$top] The max number of records.
         * @param {string} [$apply] Allows to specify a sequence of transformations to the entity set, such as groupby, filter, aggregate, etc.
         * @param {number} [$skip] The number of records to skip.
         * @param {string} [$filter] A function that must evaluate to true for a record to be returned.
         * @param {string} [$select] Specifies a subset of properties to return. Use a comma separated list.
         * @param {string} [$orderby] Determines what values are used to order a collection of records.
         * @param {string} [$expand] Use to add related query data.
         * @param {boolean} [$count] Allows clients to request a count of the matching resources included with the resources in the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiV2SilosKey: async (key: number, $top?: number, $apply?: string, $skip?: number, $filter?: string, $select?: string, $orderby?: string, $expand?: string, $count?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'key' is not null or undefined
            assertParamExists('getApiV2SilosKey', 'key', key)
            const localVarPath = `/api/v2/Silos({key})`
                .replace(`{${"key"}}`, encodeURIComponent(String(key)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if ($top !== undefined) {
                localVarQueryParameter['$top'] = $top;
            }

            if ($apply !== undefined) {
                localVarQueryParameter['$apply'] = $apply;
            }

            if ($skip !== undefined) {
                localVarQueryParameter['$skip'] = $skip;
            }

            if ($filter !== undefined) {
                localVarQueryParameter['$filter'] = $filter;
            }

            if ($select !== undefined) {
                localVarQueryParameter['$select'] = $select;
            }

            if ($orderby !== undefined) {
                localVarQueryParameter['$orderby'] = $orderby;
            }

            if ($expand !== undefined) {
                localVarQueryParameter['$expand'] = $expand;
            }

            if ($count !== undefined) {
                localVarQueryParameter['$count'] = $count;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} key 
         * @param {number} [$top] The max number of records.
         * @param {string} [$apply] Allows to specify a sequence of transformations to the entity set, such as groupby, filter, aggregate, etc.
         * @param {number} [$skip] The number of records to skip.
         * @param {string} [$filter] A function that must evaluate to true for a record to be returned.
         * @param {string} [$select] Specifies a subset of properties to return. Use a comma separated list.
         * @param {string} [$orderby] Determines what values are used to order a collection of records.
         * @param {string} [$expand] Use to add related query data.
         * @param {boolean} [$count] Allows clients to request a count of the matching resources included with the resources in the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiV2SilosKey_1: async (key: number, $top?: number, $apply?: string, $skip?: number, $filter?: string, $select?: string, $orderby?: string, $expand?: string, $count?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'key' is not null or undefined
            assertParamExists('getApiV2SilosKey_1', 'key', key)
            const localVarPath = `/api/v2/Silos/{key}`
                .replace(`{${"key"}}`, encodeURIComponent(String(key)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if ($top !== undefined) {
                localVarQueryParameter['$top'] = $top;
            }

            if ($apply !== undefined) {
                localVarQueryParameter['$apply'] = $apply;
            }

            if ($skip !== undefined) {
                localVarQueryParameter['$skip'] = $skip;
            }

            if ($filter !== undefined) {
                localVarQueryParameter['$filter'] = $filter;
            }

            if ($select !== undefined) {
                localVarQueryParameter['$select'] = $select;
            }

            if ($orderby !== undefined) {
                localVarQueryParameter['$orderby'] = $orderby;
            }

            if ($expand !== undefined) {
                localVarQueryParameter['$expand'] = $expand;
            }

            if ($count !== undefined) {
                localVarQueryParameter['$count'] = $count;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} key 
         * @param {string} [select] 
         * @param {string} [expand] 
         * @param {Array<Operation>} [operation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchApiV2SilosKey: async (key: number, select?: string, expand?: string, operation?: Array<Operation>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'key' is not null or undefined
            assertParamExists('patchApiV2SilosKey', 'key', key)
            const localVarPath = `/api/v2/Silos({key})`
                .replace(`{${"key"}}`, encodeURIComponent(String(key)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (select !== undefined) {
                localVarQueryParameter['select'] = select;
            }

            if (expand !== undefined) {
                localVarQueryParameter['expand'] = expand;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json;odata.metadata=minimal;odata.streaming=true';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(operation, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} key 
         * @param {string} [select] 
         * @param {string} [expand] 
         * @param {Array<Operation>} [operation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchApiV2SilosKey_2: async (key: number, select?: string, expand?: string, operation?: Array<Operation>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'key' is not null or undefined
            assertParamExists('patchApiV2SilosKey_2', 'key', key)
            const localVarPath = `/api/v2/Silos/{key}`
                .replace(`{${"key"}}`, encodeURIComponent(String(key)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (select !== undefined) {
                localVarQueryParameter['select'] = select;
            }

            if (expand !== undefined) {
                localVarQueryParameter['expand'] = expand;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json;odata.metadata=minimal;odata.streaming=true';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(operation, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} key 
         * @param {string} [select] 
         * @param {string} [expand] 
         * @param {Silo} [silo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putApiV2SilosKey: async (key: number, select?: string, expand?: string, silo?: Silo, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'key' is not null or undefined
            assertParamExists('putApiV2SilosKey', 'key', key)
            const localVarPath = `/api/v2/Silos({key})`
                .replace(`{${"key"}}`, encodeURIComponent(String(key)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (select !== undefined) {
                localVarQueryParameter['select'] = select;
            }

            if (expand !== undefined) {
                localVarQueryParameter['expand'] = expand;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json;odata.metadata=minimal;odata.streaming=true';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(silo, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} key 
         * @param {string} [select] 
         * @param {string} [expand] 
         * @param {Silo} [silo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putApiV2SilosKey_3: async (key: number, select?: string, expand?: string, silo?: Silo, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'key' is not null or undefined
            assertParamExists('putApiV2SilosKey_3', 'key', key)
            const localVarPath = `/api/v2/Silos/{key}`
                .replace(`{${"key"}}`, encodeURIComponent(String(key)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (select !== undefined) {
                localVarQueryParameter['select'] = select;
            }

            if (expand !== undefined) {
                localVarQueryParameter['expand'] = expand;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json;odata.metadata=minimal;odata.streaming=true';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(silo, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SilosApi - functional programming interface
 * @export
 */
export const SilosApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SilosApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {boolean} [includeDeleted] 
         * @param {boolean} [includeBargeSilo] 
         * @param {number} [$top] The max number of records.
         * @param {string} [$apply] Allows to specify a sequence of transformations to the entity set, such as groupby, filter, aggregate, etc.
         * @param {number} [$skip] The number of records to skip.
         * @param {string} [$filter] A function that must evaluate to true for a record to be returned.
         * @param {string} [$select] Specifies a subset of properties to return. Use a comma separated list.
         * @param {string} [$orderby] Determines what values are used to order a collection of records.
         * @param {string} [$expand] Use to add related query data.
         * @param {boolean} [$count] Allows clients to request a count of the matching resources included with the resources in the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getApiV2Silos(includeDeleted?: boolean, includeBargeSilo?: boolean, $top?: number, $apply?: string, $skip?: number, $filter?: string, $select?: string, $orderby?: string, $expand?: string, $count?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SiloIEnumerableScaleODataValue>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getApiV2Silos(includeDeleted, includeBargeSilo, $top, $apply, $skip, $filter, $select, $orderby, $expand, $count, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {boolean} [includeDeleted] 
         * @param {boolean} [includeBargeSilo] 
         * @param {number} [$top] The max number of records.
         * @param {string} [$apply] Allows to specify a sequence of transformations to the entity set, such as groupby, filter, aggregate, etc.
         * @param {number} [$skip] The number of records to skip.
         * @param {string} [$filter] A function that must evaluate to true for a record to be returned.
         * @param {string} [$select] Specifies a subset of properties to return. Use a comma separated list.
         * @param {string} [$orderby] Determines what values are used to order a collection of records.
         * @param {string} [$expand] Use to add related query data.
         * @param {boolean} [$count] Allows clients to request a count of the matching resources included with the resources in the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getApiV2SilosCount(includeDeleted?: boolean, includeBargeSilo?: boolean, $top?: number, $apply?: string, $skip?: number, $filter?: string, $select?: string, $orderby?: string, $expand?: string, $count?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SiloIEnumerableScaleODataValue>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getApiV2SilosCount(includeDeleted, includeBargeSilo, $top, $apply, $skip, $filter, $select, $orderby, $expand, $count, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} key 
         * @param {number} [$top] The max number of records.
         * @param {string} [$apply] Allows to specify a sequence of transformations to the entity set, such as groupby, filter, aggregate, etc.
         * @param {number} [$skip] The number of records to skip.
         * @param {string} [$filter] A function that must evaluate to true for a record to be returned.
         * @param {string} [$select] Specifies a subset of properties to return. Use a comma separated list.
         * @param {string} [$orderby] Determines what values are used to order a collection of records.
         * @param {string} [$expand] Use to add related query data.
         * @param {boolean} [$count] Allows clients to request a count of the matching resources included with the resources in the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getApiV2SilosKey(key: number, $top?: number, $apply?: string, $skip?: number, $filter?: string, $select?: string, $orderby?: string, $expand?: string, $count?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Silo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getApiV2SilosKey(key, $top, $apply, $skip, $filter, $select, $orderby, $expand, $count, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} key 
         * @param {number} [$top] The max number of records.
         * @param {string} [$apply] Allows to specify a sequence of transformations to the entity set, such as groupby, filter, aggregate, etc.
         * @param {number} [$skip] The number of records to skip.
         * @param {string} [$filter] A function that must evaluate to true for a record to be returned.
         * @param {string} [$select] Specifies a subset of properties to return. Use a comma separated list.
         * @param {string} [$orderby] Determines what values are used to order a collection of records.
         * @param {string} [$expand] Use to add related query data.
         * @param {boolean} [$count] Allows clients to request a count of the matching resources included with the resources in the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getApiV2SilosKey_1(key: number, $top?: number, $apply?: string, $skip?: number, $filter?: string, $select?: string, $orderby?: string, $expand?: string, $count?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Silo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getApiV2SilosKey_1(key, $top, $apply, $skip, $filter, $select, $orderby, $expand, $count, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} key 
         * @param {string} [select] 
         * @param {string} [expand] 
         * @param {Array<Operation>} [operation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchApiV2SilosKey(key: number, select?: string, expand?: string, operation?: Array<Operation>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Silo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patchApiV2SilosKey(key, select, expand, operation, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} key 
         * @param {string} [select] 
         * @param {string} [expand] 
         * @param {Array<Operation>} [operation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchApiV2SilosKey_2(key: number, select?: string, expand?: string, operation?: Array<Operation>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Silo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patchApiV2SilosKey_2(key, select, expand, operation, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} key 
         * @param {string} [select] 
         * @param {string} [expand] 
         * @param {Silo} [silo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putApiV2SilosKey(key: number, select?: string, expand?: string, silo?: Silo, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Silo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putApiV2SilosKey(key, select, expand, silo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} key 
         * @param {string} [select] 
         * @param {string} [expand] 
         * @param {Silo} [silo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putApiV2SilosKey_3(key: number, select?: string, expand?: string, silo?: Silo, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Silo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putApiV2SilosKey_3(key, select, expand, silo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SilosApi - factory interface
 * @export
 */
export const SilosApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SilosApiFp(configuration)
    return {
        /**
         * 
         * @param {SilosApiGetApiV2SilosRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiV2Silos(requestParameters: SilosApiGetApiV2SilosRequest = {}, options?: AxiosRequestConfig): AxiosPromise<SiloIEnumerableScaleODataValue> {
            return localVarFp.getApiV2Silos(requestParameters.includeDeleted, requestParameters.includeBargeSilo, requestParameters.$top, requestParameters.$apply, requestParameters.$skip, requestParameters.$filter, requestParameters.$select, requestParameters.$orderby, requestParameters.$expand, requestParameters.$count, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SilosApiGetApiV2SilosCountRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiV2SilosCount(requestParameters: SilosApiGetApiV2SilosCountRequest = {}, options?: AxiosRequestConfig): AxiosPromise<SiloIEnumerableScaleODataValue> {
            return localVarFp.getApiV2SilosCount(requestParameters.includeDeleted, requestParameters.includeBargeSilo, requestParameters.$top, requestParameters.$apply, requestParameters.$skip, requestParameters.$filter, requestParameters.$select, requestParameters.$orderby, requestParameters.$expand, requestParameters.$count, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SilosApiGetApiV2SilosKeyRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiV2SilosKey(requestParameters: SilosApiGetApiV2SilosKeyRequest, options?: AxiosRequestConfig): AxiosPromise<Silo> {
            return localVarFp.getApiV2SilosKey(requestParameters.key, requestParameters.$top, requestParameters.$apply, requestParameters.$skip, requestParameters.$filter, requestParameters.$select, requestParameters.$orderby, requestParameters.$expand, requestParameters.$count, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SilosApiGetApiV2SilosKey0Request} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiV2SilosKey_1(requestParameters: SilosApiGetApiV2SilosKey0Request, options?: AxiosRequestConfig): AxiosPromise<Silo> {
            return localVarFp.getApiV2SilosKey_1(requestParameters.key, requestParameters.$top, requestParameters.$apply, requestParameters.$skip, requestParameters.$filter, requestParameters.$select, requestParameters.$orderby, requestParameters.$expand, requestParameters.$count, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SilosApiPatchApiV2SilosKeyRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchApiV2SilosKey(requestParameters: SilosApiPatchApiV2SilosKeyRequest, options?: AxiosRequestConfig): AxiosPromise<Silo> {
            return localVarFp.patchApiV2SilosKey(requestParameters.key, requestParameters.select, requestParameters.expand, requestParameters.operation, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SilosApiPatchApiV2SilosKey0Request} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchApiV2SilosKey_2(requestParameters: SilosApiPatchApiV2SilosKey0Request, options?: AxiosRequestConfig): AxiosPromise<Silo> {
            return localVarFp.patchApiV2SilosKey_2(requestParameters.key, requestParameters.select, requestParameters.expand, requestParameters.operation, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SilosApiPutApiV2SilosKeyRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putApiV2SilosKey(requestParameters: SilosApiPutApiV2SilosKeyRequest, options?: AxiosRequestConfig): AxiosPromise<Silo> {
            return localVarFp.putApiV2SilosKey(requestParameters.key, requestParameters.select, requestParameters.expand, requestParameters.silo, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SilosApiPutApiV2SilosKey0Request} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putApiV2SilosKey_3(requestParameters: SilosApiPutApiV2SilosKey0Request, options?: AxiosRequestConfig): AxiosPromise<Silo> {
            return localVarFp.putApiV2SilosKey_3(requestParameters.key, requestParameters.select, requestParameters.expand, requestParameters.silo, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SilosApi - interface
 * @export
 * @interface SilosApi
 */
export interface SilosApiInterface {
    /**
     * 
     * @param {boolean} [includeDeleted] 
     * @param {boolean} [includeBargeSilo] 
     * @param {number} [$top] The max number of records.
     * @param {string} [$apply] Allows to specify a sequence of transformations to the entity set, such as groupby, filter, aggregate, etc.
     * @param {number} [$skip] The number of records to skip.
     * @param {string} [$filter] A function that must evaluate to true for a record to be returned.
     * @param {string} [$select] Specifies a subset of properties to return. Use a comma separated list.
     * @param {string} [$orderby] Determines what values are used to order a collection of records.
     * @param {string} [$expand] Use to add related query data.
     * @param {boolean} [$count] Allows clients to request a count of the matching resources included with the resources in the response.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SilosApiInterface
     */
    getApiV2Silos(includeDeleted?: boolean, includeBargeSilo?: boolean, $top?: number, $apply?: string, $skip?: number, $filter?: string, $select?: string, $orderby?: string, $expand?: string, $count?: boolean, options?: AxiosRequestConfig): AxiosPromise<SiloIEnumerableScaleODataValue>;

    /**
     * 
     * @param {boolean} [includeDeleted] 
     * @param {boolean} [includeBargeSilo] 
     * @param {number} [$top] The max number of records.
     * @param {string} [$apply] Allows to specify a sequence of transformations to the entity set, such as groupby, filter, aggregate, etc.
     * @param {number} [$skip] The number of records to skip.
     * @param {string} [$filter] A function that must evaluate to true for a record to be returned.
     * @param {string} [$select] Specifies a subset of properties to return. Use a comma separated list.
     * @param {string} [$orderby] Determines what values are used to order a collection of records.
     * @param {string} [$expand] Use to add related query data.
     * @param {boolean} [$count] Allows clients to request a count of the matching resources included with the resources in the response.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SilosApiInterface
     */
    getApiV2SilosCount(includeDeleted?: boolean, includeBargeSilo?: boolean, $top?: number, $apply?: string, $skip?: number, $filter?: string, $select?: string, $orderby?: string, $expand?: string, $count?: boolean, options?: AxiosRequestConfig): AxiosPromise<SiloIEnumerableScaleODataValue>;

    /**
     * 
     * @param {number} key 
     * @param {number} [$top] The max number of records.
     * @param {string} [$apply] Allows to specify a sequence of transformations to the entity set, such as groupby, filter, aggregate, etc.
     * @param {number} [$skip] The number of records to skip.
     * @param {string} [$filter] A function that must evaluate to true for a record to be returned.
     * @param {string} [$select] Specifies a subset of properties to return. Use a comma separated list.
     * @param {string} [$orderby] Determines what values are used to order a collection of records.
     * @param {string} [$expand] Use to add related query data.
     * @param {boolean} [$count] Allows clients to request a count of the matching resources included with the resources in the response.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SilosApiInterface
     */
    getApiV2SilosKey(key: number, $top?: number, $apply?: string, $skip?: number, $filter?: string, $select?: string, $orderby?: string, $expand?: string, $count?: boolean, options?: AxiosRequestConfig): AxiosPromise<Silo>;

    /**
     * 
     * @param {number} key 
     * @param {number} [$top] The max number of records.
     * @param {string} [$apply] Allows to specify a sequence of transformations to the entity set, such as groupby, filter, aggregate, etc.
     * @param {number} [$skip] The number of records to skip.
     * @param {string} [$filter] A function that must evaluate to true for a record to be returned.
     * @param {string} [$select] Specifies a subset of properties to return. Use a comma separated list.
     * @param {string} [$orderby] Determines what values are used to order a collection of records.
     * @param {string} [$expand] Use to add related query data.
     * @param {boolean} [$count] Allows clients to request a count of the matching resources included with the resources in the response.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SilosApiInterface
     */
    getApiV2SilosKey_1(key: number, $top?: number, $apply?: string, $skip?: number, $filter?: string, $select?: string, $orderby?: string, $expand?: string, $count?: boolean, options?: AxiosRequestConfig): AxiosPromise<Silo>;

    /**
     * 
     * @param {number} key 
     * @param {string} [select] 
     * @param {string} [expand] 
     * @param {Array<Operation>} [operation] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SilosApiInterface
     */
    patchApiV2SilosKey(key: number, select?: string, expand?: string, operation?: Array<Operation>, options?: AxiosRequestConfig): AxiosPromise<Silo>;

    /**
     * 
     * @param {number} key 
     * @param {string} [select] 
     * @param {string} [expand] 
     * @param {Array<Operation>} [operation] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SilosApiInterface
     */
    patchApiV2SilosKey_2(key: number, select?: string, expand?: string, operation?: Array<Operation>, options?: AxiosRequestConfig): AxiosPromise<Silo>;

    /**
     * 
     * @param {number} key 
     * @param {string} [select] 
     * @param {string} [expand] 
     * @param {Silo} [silo] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SilosApiInterface
     */
    putApiV2SilosKey(key: number, select?: string, expand?: string, silo?: Silo, options?: AxiosRequestConfig): AxiosPromise<Silo>;

    /**
     * 
     * @param {number} key 
     * @param {string} [select] 
     * @param {string} [expand] 
     * @param {Silo} [silo] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SilosApiInterface
     */
    putApiV2SilosKey_3(key: number, select?: string, expand?: string, silo?: Silo, options?: AxiosRequestConfig): AxiosPromise<Silo>;

}

/**
 * Request parameters for getApiV2Silos operation in SilosApi.
 * @export
 * @interface SilosApiGetApiV2SilosRequest
 */
export interface SilosApiGetApiV2SilosRequest {
    /**
     * 
     * @type {boolean}
     * @memberof SilosApiGetApiV2Silos
     */
    readonly includeDeleted?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof SilosApiGetApiV2Silos
     */
    readonly includeBargeSilo?: boolean

    /**
     * The max number of records.
     * @type {number}
     * @memberof SilosApiGetApiV2Silos
     */
    readonly $top?: number

    /**
     * Allows to specify a sequence of transformations to the entity set, such as groupby, filter, aggregate, etc.
     * @type {string}
     * @memberof SilosApiGetApiV2Silos
     */
    readonly $apply?: string

    /**
     * The number of records to skip.
     * @type {number}
     * @memberof SilosApiGetApiV2Silos
     */
    readonly $skip?: number

    /**
     * A function that must evaluate to true for a record to be returned.
     * @type {string}
     * @memberof SilosApiGetApiV2Silos
     */
    readonly $filter?: string

    /**
     * Specifies a subset of properties to return. Use a comma separated list.
     * @type {string}
     * @memberof SilosApiGetApiV2Silos
     */
    readonly $select?: string

    /**
     * Determines what values are used to order a collection of records.
     * @type {string}
     * @memberof SilosApiGetApiV2Silos
     */
    readonly $orderby?: string

    /**
     * Use to add related query data.
     * @type {string}
     * @memberof SilosApiGetApiV2Silos
     */
    readonly $expand?: string

    /**
     * Allows clients to request a count of the matching resources included with the resources in the response.
     * @type {boolean}
     * @memberof SilosApiGetApiV2Silos
     */
    readonly $count?: boolean
}

/**
 * Request parameters for getApiV2SilosCount operation in SilosApi.
 * @export
 * @interface SilosApiGetApiV2SilosCountRequest
 */
export interface SilosApiGetApiV2SilosCountRequest {
    /**
     * 
     * @type {boolean}
     * @memberof SilosApiGetApiV2SilosCount
     */
    readonly includeDeleted?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof SilosApiGetApiV2SilosCount
     */
    readonly includeBargeSilo?: boolean

    /**
     * The max number of records.
     * @type {number}
     * @memberof SilosApiGetApiV2SilosCount
     */
    readonly $top?: number

    /**
     * Allows to specify a sequence of transformations to the entity set, such as groupby, filter, aggregate, etc.
     * @type {string}
     * @memberof SilosApiGetApiV2SilosCount
     */
    readonly $apply?: string

    /**
     * The number of records to skip.
     * @type {number}
     * @memberof SilosApiGetApiV2SilosCount
     */
    readonly $skip?: number

    /**
     * A function that must evaluate to true for a record to be returned.
     * @type {string}
     * @memberof SilosApiGetApiV2SilosCount
     */
    readonly $filter?: string

    /**
     * Specifies a subset of properties to return. Use a comma separated list.
     * @type {string}
     * @memberof SilosApiGetApiV2SilosCount
     */
    readonly $select?: string

    /**
     * Determines what values are used to order a collection of records.
     * @type {string}
     * @memberof SilosApiGetApiV2SilosCount
     */
    readonly $orderby?: string

    /**
     * Use to add related query data.
     * @type {string}
     * @memberof SilosApiGetApiV2SilosCount
     */
    readonly $expand?: string

    /**
     * Allows clients to request a count of the matching resources included with the resources in the response.
     * @type {boolean}
     * @memberof SilosApiGetApiV2SilosCount
     */
    readonly $count?: boolean
}

/**
 * Request parameters for getApiV2SilosKey operation in SilosApi.
 * @export
 * @interface SilosApiGetApiV2SilosKeyRequest
 */
export interface SilosApiGetApiV2SilosKeyRequest {
    /**
     * 
     * @type {number}
     * @memberof SilosApiGetApiV2SilosKey
     */
    readonly key: number

    /**
     * The max number of records.
     * @type {number}
     * @memberof SilosApiGetApiV2SilosKey
     */
    readonly $top?: number

    /**
     * Allows to specify a sequence of transformations to the entity set, such as groupby, filter, aggregate, etc.
     * @type {string}
     * @memberof SilosApiGetApiV2SilosKey
     */
    readonly $apply?: string

    /**
     * The number of records to skip.
     * @type {number}
     * @memberof SilosApiGetApiV2SilosKey
     */
    readonly $skip?: number

    /**
     * A function that must evaluate to true for a record to be returned.
     * @type {string}
     * @memberof SilosApiGetApiV2SilosKey
     */
    readonly $filter?: string

    /**
     * Specifies a subset of properties to return. Use a comma separated list.
     * @type {string}
     * @memberof SilosApiGetApiV2SilosKey
     */
    readonly $select?: string

    /**
     * Determines what values are used to order a collection of records.
     * @type {string}
     * @memberof SilosApiGetApiV2SilosKey
     */
    readonly $orderby?: string

    /**
     * Use to add related query data.
     * @type {string}
     * @memberof SilosApiGetApiV2SilosKey
     */
    readonly $expand?: string

    /**
     * Allows clients to request a count of the matching resources included with the resources in the response.
     * @type {boolean}
     * @memberof SilosApiGetApiV2SilosKey
     */
    readonly $count?: boolean
}

/**
 * Request parameters for getApiV2SilosKey_1 operation in SilosApi.
 * @export
 * @interface SilosApiGetApiV2SilosKey0Request
 */
export interface SilosApiGetApiV2SilosKey0Request {
    /**
     * 
     * @type {number}
     * @memberof SilosApiGetApiV2SilosKey0
     */
    readonly key: number

    /**
     * The max number of records.
     * @type {number}
     * @memberof SilosApiGetApiV2SilosKey0
     */
    readonly $top?: number

    /**
     * Allows to specify a sequence of transformations to the entity set, such as groupby, filter, aggregate, etc.
     * @type {string}
     * @memberof SilosApiGetApiV2SilosKey0
     */
    readonly $apply?: string

    /**
     * The number of records to skip.
     * @type {number}
     * @memberof SilosApiGetApiV2SilosKey0
     */
    readonly $skip?: number

    /**
     * A function that must evaluate to true for a record to be returned.
     * @type {string}
     * @memberof SilosApiGetApiV2SilosKey0
     */
    readonly $filter?: string

    /**
     * Specifies a subset of properties to return. Use a comma separated list.
     * @type {string}
     * @memberof SilosApiGetApiV2SilosKey0
     */
    readonly $select?: string

    /**
     * Determines what values are used to order a collection of records.
     * @type {string}
     * @memberof SilosApiGetApiV2SilosKey0
     */
    readonly $orderby?: string

    /**
     * Use to add related query data.
     * @type {string}
     * @memberof SilosApiGetApiV2SilosKey0
     */
    readonly $expand?: string

    /**
     * Allows clients to request a count of the matching resources included with the resources in the response.
     * @type {boolean}
     * @memberof SilosApiGetApiV2SilosKey0
     */
    readonly $count?: boolean
}

/**
 * Request parameters for patchApiV2SilosKey operation in SilosApi.
 * @export
 * @interface SilosApiPatchApiV2SilosKeyRequest
 */
export interface SilosApiPatchApiV2SilosKeyRequest {
    /**
     * 
     * @type {number}
     * @memberof SilosApiPatchApiV2SilosKey
     */
    readonly key: number

    /**
     * 
     * @type {string}
     * @memberof SilosApiPatchApiV2SilosKey
     */
    readonly select?: string

    /**
     * 
     * @type {string}
     * @memberof SilosApiPatchApiV2SilosKey
     */
    readonly expand?: string

    /**
     * 
     * @type {Array<Operation>}
     * @memberof SilosApiPatchApiV2SilosKey
     */
    readonly operation?: Array<Operation>
}

/**
 * Request parameters for patchApiV2SilosKey_2 operation in SilosApi.
 * @export
 * @interface SilosApiPatchApiV2SilosKey0Request
 */
export interface SilosApiPatchApiV2SilosKey0Request {
    /**
     * 
     * @type {number}
     * @memberof SilosApiPatchApiV2SilosKey0
     */
    readonly key: number

    /**
     * 
     * @type {string}
     * @memberof SilosApiPatchApiV2SilosKey0
     */
    readonly select?: string

    /**
     * 
     * @type {string}
     * @memberof SilosApiPatchApiV2SilosKey0
     */
    readonly expand?: string

    /**
     * 
     * @type {Array<Operation>}
     * @memberof SilosApiPatchApiV2SilosKey0
     */
    readonly operation?: Array<Operation>
}

/**
 * Request parameters for putApiV2SilosKey operation in SilosApi.
 * @export
 * @interface SilosApiPutApiV2SilosKeyRequest
 */
export interface SilosApiPutApiV2SilosKeyRequest {
    /**
     * 
     * @type {number}
     * @memberof SilosApiPutApiV2SilosKey
     */
    readonly key: number

    /**
     * 
     * @type {string}
     * @memberof SilosApiPutApiV2SilosKey
     */
    readonly select?: string

    /**
     * 
     * @type {string}
     * @memberof SilosApiPutApiV2SilosKey
     */
    readonly expand?: string

    /**
     * 
     * @type {Silo}
     * @memberof SilosApiPutApiV2SilosKey
     */
    readonly silo?: Silo
}

/**
 * Request parameters for putApiV2SilosKey_3 operation in SilosApi.
 * @export
 * @interface SilosApiPutApiV2SilosKey0Request
 */
export interface SilosApiPutApiV2SilosKey0Request {
    /**
     * 
     * @type {number}
     * @memberof SilosApiPutApiV2SilosKey0
     */
    readonly key: number

    /**
     * 
     * @type {string}
     * @memberof SilosApiPutApiV2SilosKey0
     */
    readonly select?: string

    /**
     * 
     * @type {string}
     * @memberof SilosApiPutApiV2SilosKey0
     */
    readonly expand?: string

    /**
     * 
     * @type {Silo}
     * @memberof SilosApiPutApiV2SilosKey0
     */
    readonly silo?: Silo
}

/**
 * SilosApi - object-oriented interface
 * @export
 * @class SilosApi
 * @extends {BaseAPI}
 */
export class SilosApi extends BaseAPI implements SilosApiInterface {
    /**
     * 
     * @param {SilosApiGetApiV2SilosRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SilosApi
     */
    public getApiV2Silos(requestParameters: SilosApiGetApiV2SilosRequest = {}, options?: AxiosRequestConfig) {
        return SilosApiFp(this.configuration).getApiV2Silos(requestParameters.includeDeleted, requestParameters.includeBargeSilo, requestParameters.$top, requestParameters.$apply, requestParameters.$skip, requestParameters.$filter, requestParameters.$select, requestParameters.$orderby, requestParameters.$expand, requestParameters.$count, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SilosApiGetApiV2SilosCountRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SilosApi
     */
    public getApiV2SilosCount(requestParameters: SilosApiGetApiV2SilosCountRequest = {}, options?: AxiosRequestConfig) {
        return SilosApiFp(this.configuration).getApiV2SilosCount(requestParameters.includeDeleted, requestParameters.includeBargeSilo, requestParameters.$top, requestParameters.$apply, requestParameters.$skip, requestParameters.$filter, requestParameters.$select, requestParameters.$orderby, requestParameters.$expand, requestParameters.$count, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SilosApiGetApiV2SilosKeyRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SilosApi
     */
    public getApiV2SilosKey(requestParameters: SilosApiGetApiV2SilosKeyRequest, options?: AxiosRequestConfig) {
        return SilosApiFp(this.configuration).getApiV2SilosKey(requestParameters.key, requestParameters.$top, requestParameters.$apply, requestParameters.$skip, requestParameters.$filter, requestParameters.$select, requestParameters.$orderby, requestParameters.$expand, requestParameters.$count, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SilosApiGetApiV2SilosKey0Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SilosApi
     */
    public getApiV2SilosKey_1(requestParameters: SilosApiGetApiV2SilosKey0Request, options?: AxiosRequestConfig) {
        return SilosApiFp(this.configuration).getApiV2SilosKey_1(requestParameters.key, requestParameters.$top, requestParameters.$apply, requestParameters.$skip, requestParameters.$filter, requestParameters.$select, requestParameters.$orderby, requestParameters.$expand, requestParameters.$count, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SilosApiPatchApiV2SilosKeyRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SilosApi
     */
    public patchApiV2SilosKey(requestParameters: SilosApiPatchApiV2SilosKeyRequest, options?: AxiosRequestConfig) {
        return SilosApiFp(this.configuration).patchApiV2SilosKey(requestParameters.key, requestParameters.select, requestParameters.expand, requestParameters.operation, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SilosApiPatchApiV2SilosKey0Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SilosApi
     */
    public patchApiV2SilosKey_2(requestParameters: SilosApiPatchApiV2SilosKey0Request, options?: AxiosRequestConfig) {
        return SilosApiFp(this.configuration).patchApiV2SilosKey_2(requestParameters.key, requestParameters.select, requestParameters.expand, requestParameters.operation, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SilosApiPutApiV2SilosKeyRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SilosApi
     */
    public putApiV2SilosKey(requestParameters: SilosApiPutApiV2SilosKeyRequest, options?: AxiosRequestConfig) {
        return SilosApiFp(this.configuration).putApiV2SilosKey(requestParameters.key, requestParameters.select, requestParameters.expand, requestParameters.silo, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SilosApiPutApiV2SilosKey0Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SilosApi
     */
    public putApiV2SilosKey_3(requestParameters: SilosApiPutApiV2SilosKey0Request, options?: AxiosRequestConfig) {
        return SilosApiFp(this.configuration).putApiV2SilosKey_3(requestParameters.key, requestParameters.select, requestParameters.expand, requestParameters.silo, options).then((request) => request(this.axios, this.basePath));
    }
}
