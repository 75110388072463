import accessApiV1Client, { AccessApiV1Models } from "api/clients/accessApiV1Client";
import { useSwaggerSwr } from "api/clients/genericApiClient";

import { managementPortalAreas } from "constants/scaleRoles";
import { useCurrentCompanyId } from "hooks/useGlobalCompanyId";
import { useRouter } from "next/router";
import React, { useContext, useEffect } from "react";
import {
  isSiteAdmin,
  hasAdminPrivileges,
  hasAnyRoles,
  hasManagementPrivileges,
  canAccessCompany,
} from "utils/accessChecks";
import { OidcClientTs } from "@scaleaq/auth-client";
import { useSession } from "./sessionProvider";

type Context = {
  userProfile: OidcClientTs.UserProfile;
  userAccess: AccessApiV1Models.UserAccessWeb;
  accessIsLoading: boolean;
  accessIsError: boolean;
  hasScaleAdminPrivileges: boolean;
  hasScaleManagementPrivileges: boolean;
  isSiteAdminInCurrentCompany: boolean;
  isCustomerAdminForCurrentCompany: boolean;
  isSiteAdmin: boolean;
};

const AccessControlContext = React.createContext<Context>(null);

export type Props = {
  children?: React.ReactNode;
};

export default function AccessControlProvider({ children }: Props) {
  const session = useSession();
  const userId = session?.profile.sub;

  const router = useRouter();

  const {
    data: userAccess,
    isLoading: isLoadingUserAccess,
    error: isErrorUserAccess,
  } = useSwaggerSwr(accessApiV1Client.UserAccessApi.getUserAccessUserId, [
    {
      userId,
    },
  ]);

  const isValid = (company: AccessApiV1Models.CompanyAccessWeb) => {
    return (
      !company?.suspended &&
      (canAccessCompany(company, managementPortalAreas, AccessApiV1Models.AccessLevel.Read) ||
        isSiteAdmin([company]))
    );
  };

  const companyId = userAccess?.companyAccesses.find((x) => isValid(x))?.gosId ?? "";
  const { currentCompanyId, setCurrentCompanyId } = useCurrentCompanyId();

  const currentCompanyIsValid = () => {
    const currentCompany = userAccess?.companyAccesses.find((x) => x.gosId === currentCompanyId);
    return isValid(currentCompany);
  };

  useEffect(() => {
    if (userAccess && (!currentCompanyId || !currentCompanyIsValid())) {
      setCurrentCompanyId(companyId);
    }
  }, [currentCompanyId, userAccess]);

  const hasScaleAdminPrivileges = hasAdminPrivileges(userAccess);
  const hasScaleManagementPrivileges = hasManagementPrivileges(userAccess);

  const hasAnyMPRoles = hasAnyRoles(userAccess);
  const isSiteAdministrator = isSiteAdmin(userAccess?.companyAccesses);

  const userAccessCurrentCompany = userAccess?.companyAccesses.find((x) => x.gosId === currentCompanyId);
  const isSiteAdminInCurrentCompany = isSiteAdmin([userAccessCurrentCompany]);

  const isCustomerAdminForCurrentCompany = canAccessCompany(
    userAccessCurrentCompany,
    managementPortalAreas,
    AccessApiV1Models.AccessLevel.Read,
  );

  const isProfilePage = router.asPath.includes("/profile");
  const isRedeemPage = router.asPath.includes("/redeem");
  const isWhitelistedPage = isProfilePage || isRedeemPage;

  useEffect(() => {
    if (!isLoadingUserAccess && !hasAnyMPRoles && !isSiteAdministrator && !isWhitelistedPage) {
      if (userId) {
        router.push({
          pathname: "/users/[userId]/profile",
          query: { userId },
        });
      }
    }
  }, [isLoadingUserAccess, hasAnyMPRoles, isSiteAdministrator, isWhitelistedPage, userId]);

  return (
    <AccessControlContext.Provider
      value={{
        accessIsLoading: isLoadingUserAccess,
        accessIsError: isErrorUserAccess,
        userProfile: session?.profile,
        isSiteAdmin: isSiteAdministrator,
        userAccess,
        hasScaleAdminPrivileges,
        hasScaleManagementPrivileges,
        isSiteAdminInCurrentCompany,
        isCustomerAdminForCurrentCompany,
      }}
    >
      {children}
    </AccessControlContext.Provider>
  );
}

export const useAccessControl = (): Context => {
  const context = useContext<Context>(AccessControlContext);
  if (context === undefined) {
    throw new Error("useAccessControl must be used within a AccessControlProvider");
  }
  return context;
};
