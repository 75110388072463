// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * UniteAQ Access API
 * Access API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { SiteAccessRollbackDtoWeb } from '../model';
/**
 * SiteAccessApi - axios parameter creator
 * @export
 */
export const SiteAccessApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} siteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSiteAccessSiteId: async (siteId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'siteId' is not null or undefined
            assertParamExists('deleteSiteAccessSiteId', 'siteId', siteId)
            const localVarPath = `/SiteAccess/{siteId}`
                .replace(`{${"siteId"}}`, encodeURIComponent(String(siteId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} siteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSiteAccessSiteIdRollback: async (siteId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'siteId' is not null or undefined
            assertParamExists('getSiteAccessSiteIdRollback', 'siteId', siteId)
            const localVarPath = `/SiteAccess/{siteId}/rollback`
                .replace(`{${"siteId"}}`, encodeURIComponent(String(siteId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} siteId 
         * @param {Array<SiteAccessRollbackDtoWeb>} [siteAccessRollbackDtoWeb] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postSiteAccessSiteIdRollback: async (siteId: string, siteAccessRollbackDtoWeb?: Array<SiteAccessRollbackDtoWeb>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'siteId' is not null or undefined
            assertParamExists('postSiteAccessSiteIdRollback', 'siteId', siteId)
            const localVarPath = `/SiteAccess/{siteId}/rollback`
                .replace(`{${"siteId"}}`, encodeURIComponent(String(siteId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json;odata.metadata=minimal;odata.streaming=true';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(siteAccessRollbackDtoWeb, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SiteAccessApi - functional programming interface
 * @export
 */
export const SiteAccessApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SiteAccessApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} siteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteSiteAccessSiteId(siteId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteSiteAccessSiteId(siteId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} siteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSiteAccessSiteIdRollback(siteId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SiteAccessRollbackDtoWeb>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSiteAccessSiteIdRollback(siteId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} siteId 
         * @param {Array<SiteAccessRollbackDtoWeb>} [siteAccessRollbackDtoWeb] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postSiteAccessSiteIdRollback(siteId: string, siteAccessRollbackDtoWeb?: Array<SiteAccessRollbackDtoWeb>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postSiteAccessSiteIdRollback(siteId, siteAccessRollbackDtoWeb, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SiteAccessApi - factory interface
 * @export
 */
export const SiteAccessApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SiteAccessApiFp(configuration)
    return {
        /**
         * 
         * @param {SiteAccessApiDeleteSiteAccessSiteIdRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSiteAccessSiteId(requestParameters: SiteAccessApiDeleteSiteAccessSiteIdRequest, options?: AxiosRequestConfig): AxiosPromise<number> {
            return localVarFp.deleteSiteAccessSiteId(requestParameters.siteId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SiteAccessApiGetSiteAccessSiteIdRollbackRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSiteAccessSiteIdRollback(requestParameters: SiteAccessApiGetSiteAccessSiteIdRollbackRequest, options?: AxiosRequestConfig): AxiosPromise<Array<SiteAccessRollbackDtoWeb>> {
            return localVarFp.getSiteAccessSiteIdRollback(requestParameters.siteId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SiteAccessApiPostSiteAccessSiteIdRollbackRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postSiteAccessSiteIdRollback(requestParameters: SiteAccessApiPostSiteAccessSiteIdRollbackRequest, options?: AxiosRequestConfig): AxiosPromise<number> {
            return localVarFp.postSiteAccessSiteIdRollback(requestParameters.siteId, requestParameters.siteAccessRollbackDtoWeb, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SiteAccessApi - interface
 * @export
 * @interface SiteAccessApi
 */
export interface SiteAccessApiInterface {
    /**
     * 
     * @param {number} siteId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SiteAccessApiInterface
     */
    deleteSiteAccessSiteId(siteId: number, options?: AxiosRequestConfig): AxiosPromise<number>;

    /**
     * 
     * @param {number} siteId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SiteAccessApiInterface
     */
    getSiteAccessSiteIdRollback(siteId: number, options?: AxiosRequestConfig): AxiosPromise<Array<SiteAccessRollbackDtoWeb>>;

    /**
     * 
     * @param {string} siteId 
     * @param {Array<SiteAccessRollbackDtoWeb>} [siteAccessRollbackDtoWeb] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SiteAccessApiInterface
     */
    postSiteAccessSiteIdRollback(siteId: string, siteAccessRollbackDtoWeb?: Array<SiteAccessRollbackDtoWeb>, options?: AxiosRequestConfig): AxiosPromise<number>;

}

/**
 * Request parameters for deleteSiteAccessSiteId operation in SiteAccessApi.
 * @export
 * @interface SiteAccessApiDeleteSiteAccessSiteIdRequest
 */
export interface SiteAccessApiDeleteSiteAccessSiteIdRequest {
    /**
     * 
     * @type {number}
     * @memberof SiteAccessApiDeleteSiteAccessSiteId
     */
    readonly siteId: number
}

/**
 * Request parameters for getSiteAccessSiteIdRollback operation in SiteAccessApi.
 * @export
 * @interface SiteAccessApiGetSiteAccessSiteIdRollbackRequest
 */
export interface SiteAccessApiGetSiteAccessSiteIdRollbackRequest {
    /**
     * 
     * @type {number}
     * @memberof SiteAccessApiGetSiteAccessSiteIdRollback
     */
    readonly siteId: number
}

/**
 * Request parameters for postSiteAccessSiteIdRollback operation in SiteAccessApi.
 * @export
 * @interface SiteAccessApiPostSiteAccessSiteIdRollbackRequest
 */
export interface SiteAccessApiPostSiteAccessSiteIdRollbackRequest {
    /**
     * 
     * @type {string}
     * @memberof SiteAccessApiPostSiteAccessSiteIdRollback
     */
    readonly siteId: string

    /**
     * 
     * @type {Array<SiteAccessRollbackDtoWeb>}
     * @memberof SiteAccessApiPostSiteAccessSiteIdRollback
     */
    readonly siteAccessRollbackDtoWeb?: Array<SiteAccessRollbackDtoWeb>
}

/**
 * SiteAccessApi - object-oriented interface
 * @export
 * @class SiteAccessApi
 * @extends {BaseAPI}
 */
export class SiteAccessApi extends BaseAPI implements SiteAccessApiInterface {
    /**
     * 
     * @param {SiteAccessApiDeleteSiteAccessSiteIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SiteAccessApi
     */
    public deleteSiteAccessSiteId(requestParameters: SiteAccessApiDeleteSiteAccessSiteIdRequest, options?: AxiosRequestConfig) {
        return SiteAccessApiFp(this.configuration).deleteSiteAccessSiteId(requestParameters.siteId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SiteAccessApiGetSiteAccessSiteIdRollbackRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SiteAccessApi
     */
    public getSiteAccessSiteIdRollback(requestParameters: SiteAccessApiGetSiteAccessSiteIdRollbackRequest, options?: AxiosRequestConfig) {
        return SiteAccessApiFp(this.configuration).getSiteAccessSiteIdRollback(requestParameters.siteId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SiteAccessApiPostSiteAccessSiteIdRollbackRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SiteAccessApi
     */
    public postSiteAccessSiteIdRollback(requestParameters: SiteAccessApiPostSiteAccessSiteIdRollbackRequest, options?: AxiosRequestConfig) {
        return SiteAccessApiFp(this.configuration).postSiteAccessSiteIdRollback(requestParameters.siteId, requestParameters.siteAccessRollbackDtoWeb, options).then((request) => request(this.axios, this.basePath));
    }
}
