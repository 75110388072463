export enum NumeralLocale {
  English = "en-gb",
  Norwegian = "no",
}

export enum NextLocale {
  English = "en",
  Norwegian = "nb",
}

export enum Translation {
  Common = "common",
  Insights = "insights",
  Realtime = "realtime",
  Settings = "settings",
}

export enum RequestMethod {
  GET = "GET",
  HEAD = "HEAD",
  POST = "POST",
  PUT = "PUT",
  DELETE = "DELETE",
  CONNECT = "CONNECT",
  OPTIONS = "OPTIONS",
  TRACE = "TRACE",
  PATCH = "PATCH",
}

export enum HttpHeader {
  ApplicationId = "X-Application-Id",
  Authorization = "Authorization",
  ContentType = "Content-Type",
}

export enum KeyCode {
  Enter = "Enter",
}

export enum HttpContentType {
  JSON = "application/json",
}

export enum HtmlTagName {
  Input = "INPUT",
}

export enum ReducerPath {
  Menu = "menu",
  Filter = "filter",
  Realtime = "realtime",
  RealtimeFilter = "realtimeFilter",
  InsightsFilter = "insightsFilter",
  Activity = "activity",
  Deviation = "deviation",
  Log = "log",
  Dashboard = "dashboard",
  Settings = "settings",
  Snackbar = "snackbar",
}

export enum QuickActionMenySourceType {
  Report = "log",
  Dashboard = "dashboard",
  Table = "table",
  View = "view",
}

export enum Environment {
  Local = "local",
  Development = "dev",
  Stage = "stage",
  Production = "prod",
}

export enum ScaleRole {
  Admin = "scaleaq/administrator",
  SupportAgent = "scaleaq/support_agent",
  AccountManager = "scaleaq/account_manager",
}

export enum UnleashFlag {
  Insights = "knowledger.insights",
}

export enum BucketSize {
  ONE_DAY = "1.00:00:00",
  ONE_HOUR = "0.01:00:00",
  TWO_HOURS = "0.02:00:00",
}
