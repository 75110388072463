import { MenuItem as _MenuItem } from "@scaleaq/scaleui-react/core/components/LeftMenu";
import { useFlag } from "@unleash/proxy-client-react";
import { URL_QUERY_DEFAULT_KEY } from "hooks/useSessionUrlQuery/constants";
import { DateTime } from "luxon";
import { useAccessControl } from "providers/accessControlProvider";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { Icon, LinkItem } from "@scaleaq/scaleui";
import { hasCompanyAccess } from "utils/accessChecks";
import { AccessAreaName } from "api/clients/accessApiV1Client";
import { environment } from "utils/appsettings";
import { Environment } from "types/enums";

export type MenuItem = Omit<_MenuItem, "text"> & {
  text: React.ReactNode;
};

enum FeatureFlagName {
  BargeManagement = "barge-management-page",
  ProductsManagement = "uniteaq-products-page",
}

export const useMenuItems = (): LinkItem[] => {
  const {
    accessIsLoading,
    hasScaleManagementPrivileges,
    isSiteAdminInCurrentCompany,
    isCustomerAdminForCurrentCompany,
    userAccess,
  } = useAccessControl();

  const { t } = useTranslation();

  const bargeFlagIsEnabled = useFlag(FeatureFlagName.BargeManagement);
  const productsFlagIsEnabled = useFlag(FeatureFlagName.ProductsManagement);

  const query = {
    date: {
      start: DateTime.now().startOf("day").toISO(),
      end: DateTime.now().endOf("day").toISO(),
    },
  };

  const dateQuery = {
    [URL_QUERY_DEFAULT_KEY]: JSON.stringify({ date: query?.date }),
  };

  const identityProvidersAdminAccessGroups = hasCompanyAccess(userAccess, AccessAreaName.IdentityProviders);

  const siteAdminMenuItems: LinkItem[] = useMemo(() => {
    return [
      {
        id: "sites",
        Icon: Icon.Large.Location,
        label: t("common:mainMenu.sites"),
        href: { pathname: "/sites" },
        domAttributes: { "data-testid": "sites-page-button" },
      },
      ...(bargeFlagIsEnabled
        ? [
            {
              id: "barges",
              Icon: Icon.Large.Barge,
              label: t("common:mainMenu.barges"),
              href: { pathname: "/barges" },
              domAttributes: { "data-testid": "barges-page-button" },
            } as const,
          ]
        : []),
      {
        id: "devices",
        Icon: Icon.Large.Camera,
        label: t("common:mainMenu.devices"),
        href: { pathname: "/devices" },
        domAttributes: { "data-testid": "devices-page-button" },
      },
      {
        id: "integrations",
        Icon: Icon.Large.Integration,
        label: t("common:mainMenu.integrations"),
        href: { pathname: "/integrations" },
        domAttributes: { "data-testid": "integrations-page-button" },
      },
      {
        id: "data",
        Icon: Icon.Large.DataManagement,
        label: t("common:mainMenu.data"),
        href: {
          pathname: "/data",
          query: dateQuery,
        },
        domAttributes: { "data-testid": "data-page-button" },
      },
      {
        id: "licenses",
        Icon: Icon.Large.Licence,
        label: t("common:mainMenu.licenses"),
        href: {
          pathname: "/licenses/active",
        },
        domAttributes: { "data-testid": "licenses-page-button" },
      },
      ...(productsFlagIsEnabled
        ? [
            {
              id: "products",
              Icon: Icon.Large.Product,
              label: t("common:mainMenu.products"),
              href: { pathname: "/products" },
              domAttributes: { "data-testid": "products-page-button" },
            } as const,
          ]
        : []),
    ];
  }, [t, bargeFlagIsEnabled, productsFlagIsEnabled]);

  const adminMenuItems: LinkItem[] = useMemo(() => {
    return [
      {
        id: "dashboard",
        Icon: Icon.Large.Home,
        label: t("common:mainMenu.home"),
        href: { pathname: "/" },
        domAttributes: { "data-testid": "dashboard-page-button" },
      },
      {
        id: "companies",
        Icon: Icon.Large.Operation,
        label: hasScaleManagementPrivileges ? t("common:mainMenu.companies") : t("common:mainMenu.company"),
        href: { pathname: "/companies" },
        domAttributes: { "data-testid": "companies-page-button" },
      },
      {
        id: "users",
        Icon: Icon.Large.Users,
        label: t("common:mainMenu.users"),
        href: { pathname: "/users" },
        domAttributes: { "data-testid": "users-page-button" },
      },
      {
        id: "sites",
        Icon: Icon.Large.Location,
        label: t("common:mainMenu.sites"),
        href: { pathname: "/sites" },
        domAttributes: { "data-testid": "sites-page-button" },
      },
      ...(bargeFlagIsEnabled
        ? [
            {
              id: "barges",
              Icon: Icon.Large.Barge,
              label: t("common:mainMenu.barges"),
              href: { pathname: "/barges" },
              domAttributes: { "data-testid": "barges-page-button" },
            } as const,
          ]
        : []),
      {
        id: "devices",
        Icon: Icon.Large.Camera,
        label: t("common:mainMenu.devices"),
        href: { pathname: "/devices" },
        domAttributes: { "data-testid": "devices-page-button" },
      },
      {
        id: "integrations",
        Icon: Icon.Large.Integration,
        label: t("common:mainMenu.integrations"),
        href: { pathname: "/integrations" },
        domAttributes: { "data-testid": "integrations-page-button" },
      },
      {
        id: "data",
        Icon: Icon.Large.DataManagement,
        label: t("common:mainMenu.data"),
        href: {
          pathname: "/data",
          query: dateQuery,
        },
        domAttributes: { "data-testid": "data-page-button" },
      },
      {
        id: "licenses",
        Icon: Icon.Large.Licence,
        label: t("common:mainMenu.licenses"),
        href: {
          pathname: "/licenses/active",
        },
        domAttributes: { "data-testid": "licenses-page-button" },
      },
      ...(productsFlagIsEnabled
        ? [
            {
              id: "products",
              Icon: Icon.Large.Product,
              label: t("common:mainMenu.products"),
              href: {
                pathname: "/products",
              },
              domAttributes: { "data-testid": "products-page-button" },
            } as const,
          ]
        : []),
      // TODO: remove check and enable always for customer admins
      ...(environment == Environment.Production
        ? []
        : [
            {
              id: "identity-providers",
              Icon: Icon.Large.SettingsUser,
              label: t("common:mainMenu.identityProviders"),
              href: {
                pathname: "/identity-providers",
              },
            },
          ]),
    ];
  }, [
    environment,
    identityProvidersAdminAccessGroups,
    hasScaleManagementPrivileges,
    bargeFlagIsEnabled,
    productsFlagIsEnabled,
    t,
  ]);

  const scaleAdminMenuItems: LinkItem[] = useMemo(() => {
    return [
      ...adminMenuItems,
      ...(environment == Environment.Production
        ? [
            {
              id: "identity-providers",
              Icon: Icon.Large.SettingsUser,
              label: t("common:mainMenu.identityProviders"),
              href: {
                pathname: "/identity-providers",
              },
            },
          ]
        : []),
    ];
  }, [adminMenuItems, environment]);

  if (accessIsLoading) return;
  if (hasScaleManagementPrivileges) {
    return scaleAdminMenuItems;
  }

  if (isCustomerAdminForCurrentCompany) {
    return adminMenuItems;
  }

  if (isSiteAdminInCurrentCompany) {
    return siteAdminMenuItems;
  }
};
