// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Operation Services API
 * Operation Services API
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { Barge } from '../model';
// @ts-ignore
import { BargeIEnumerableScaleODataValue } from '../model';
// @ts-ignore
import { Operation } from '../model';
/**
 * BargesApi - axios parameter creator
 * @export
 */
export const BargesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {boolean} [includeDeleted] 
         * @param {number} [$top] The max number of records.
         * @param {string} [$apply] Allows to specify a sequence of transformations to the entity set, such as groupby, filter, aggregate, etc.
         * @param {number} [$skip] The number of records to skip.
         * @param {string} [$filter] A function that must evaluate to true for a record to be returned.
         * @param {string} [$select] Specifies a subset of properties to return. Use a comma separated list.
         * @param {string} [$orderby] Determines what values are used to order a collection of records.
         * @param {string} [$expand] Use to add related query data.
         * @param {boolean} [$count] Allows clients to request a count of the matching resources included with the resources in the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiV2Barges: async (includeDeleted?: boolean, $top?: number, $apply?: string, $skip?: number, $filter?: string, $select?: string, $orderby?: string, $expand?: string, $count?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v2/Barges`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (includeDeleted !== undefined) {
                localVarQueryParameter['includeDeleted'] = includeDeleted;
            }

            if ($top !== undefined) {
                localVarQueryParameter['$top'] = $top;
            }

            if ($apply !== undefined) {
                localVarQueryParameter['$apply'] = $apply;
            }

            if ($skip !== undefined) {
                localVarQueryParameter['$skip'] = $skip;
            }

            if ($filter !== undefined) {
                localVarQueryParameter['$filter'] = $filter;
            }

            if ($select !== undefined) {
                localVarQueryParameter['$select'] = $select;
            }

            if ($orderby !== undefined) {
                localVarQueryParameter['$orderby'] = $orderby;
            }

            if ($expand !== undefined) {
                localVarQueryParameter['$expand'] = $expand;
            }

            if ($count !== undefined) {
                localVarQueryParameter['$count'] = $count;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {boolean} [includeDeleted] 
         * @param {number} [$top] The max number of records.
         * @param {string} [$apply] Allows to specify a sequence of transformations to the entity set, such as groupby, filter, aggregate, etc.
         * @param {number} [$skip] The number of records to skip.
         * @param {string} [$filter] A function that must evaluate to true for a record to be returned.
         * @param {string} [$select] Specifies a subset of properties to return. Use a comma separated list.
         * @param {string} [$orderby] Determines what values are used to order a collection of records.
         * @param {string} [$expand] Use to add related query data.
         * @param {boolean} [$count] Allows clients to request a count of the matching resources included with the resources in the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiV2BargesCount: async (includeDeleted?: boolean, $top?: number, $apply?: string, $skip?: number, $filter?: string, $select?: string, $orderby?: string, $expand?: string, $count?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v2/Barges/$count`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (includeDeleted !== undefined) {
                localVarQueryParameter['includeDeleted'] = includeDeleted;
            }

            if ($top !== undefined) {
                localVarQueryParameter['$top'] = $top;
            }

            if ($apply !== undefined) {
                localVarQueryParameter['$apply'] = $apply;
            }

            if ($skip !== undefined) {
                localVarQueryParameter['$skip'] = $skip;
            }

            if ($filter !== undefined) {
                localVarQueryParameter['$filter'] = $filter;
            }

            if ($select !== undefined) {
                localVarQueryParameter['$select'] = $select;
            }

            if ($orderby !== undefined) {
                localVarQueryParameter['$orderby'] = $orderby;
            }

            if ($expand !== undefined) {
                localVarQueryParameter['$expand'] = $expand;
            }

            if ($count !== undefined) {
                localVarQueryParameter['$count'] = $count;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} key 
         * @param {string} [select] 
         * @param {string} [expand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiV2BargesKey: async (key: number, select?: string, expand?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'key' is not null or undefined
            assertParamExists('getApiV2BargesKey', 'key', key)
            const localVarPath = `/api/v2/Barges({key})`
                .replace(`{${"key"}}`, encodeURIComponent(String(key)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (select !== undefined) {
                localVarQueryParameter['select'] = select;
            }

            if (expand !== undefined) {
                localVarQueryParameter['expand'] = expand;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} key 
         * @param {string} [select] 
         * @param {string} [expand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiV2BargesKey_1: async (key: number, select?: string, expand?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'key' is not null or undefined
            assertParamExists('getApiV2BargesKey_1', 'key', key)
            const localVarPath = `/api/v2/Barges/{key}`
                .replace(`{${"key"}}`, encodeURIComponent(String(key)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (select !== undefined) {
                localVarQueryParameter['select'] = select;
            }

            if (expand !== undefined) {
                localVarQueryParameter['expand'] = expand;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} key 
         * @param {string} [select] 
         * @param {string} [expand] 
         * @param {Array<Operation>} [operation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchApiV2BargesKey: async (key: number, select?: string, expand?: string, operation?: Array<Operation>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'key' is not null or undefined
            assertParamExists('patchApiV2BargesKey', 'key', key)
            const localVarPath = `/api/v2/Barges({key})`
                .replace(`{${"key"}}`, encodeURIComponent(String(key)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (select !== undefined) {
                localVarQueryParameter['select'] = select;
            }

            if (expand !== undefined) {
                localVarQueryParameter['expand'] = expand;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json;odata.metadata=minimal;odata.streaming=true';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(operation, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} key 
         * @param {string} [select] 
         * @param {string} [expand] 
         * @param {Array<Operation>} [operation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchApiV2BargesKey_2: async (key: number, select?: string, expand?: string, operation?: Array<Operation>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'key' is not null or undefined
            assertParamExists('patchApiV2BargesKey_2', 'key', key)
            const localVarPath = `/api/v2/Barges/{key}`
                .replace(`{${"key"}}`, encodeURIComponent(String(key)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (select !== undefined) {
                localVarQueryParameter['select'] = select;
            }

            if (expand !== undefined) {
                localVarQueryParameter['expand'] = expand;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json;odata.metadata=minimal;odata.streaming=true';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(operation, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [select] 
         * @param {string} [expand] 
         * @param {Barge} [barge] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postApiV2Barges: async (select?: string, expand?: string, barge?: Barge, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v2/Barges`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (select !== undefined) {
                localVarQueryParameter['select'] = select;
            }

            if (expand !== undefined) {
                localVarQueryParameter['expand'] = expand;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json;odata.metadata=minimal;odata.streaming=true';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(barge, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} key 
         * @param {string} [select] 
         * @param {string} [expand] 
         * @param {Barge} [barge] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putApiV2BargesKey: async (key: number, select?: string, expand?: string, barge?: Barge, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'key' is not null or undefined
            assertParamExists('putApiV2BargesKey', 'key', key)
            const localVarPath = `/api/v2/Barges({key})`
                .replace(`{${"key"}}`, encodeURIComponent(String(key)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (select !== undefined) {
                localVarQueryParameter['select'] = select;
            }

            if (expand !== undefined) {
                localVarQueryParameter['expand'] = expand;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json;odata.metadata=minimal;odata.streaming=true';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(barge, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} key 
         * @param {string} [select] 
         * @param {string} [expand] 
         * @param {Barge} [barge] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putApiV2BargesKey_3: async (key: number, select?: string, expand?: string, barge?: Barge, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'key' is not null or undefined
            assertParamExists('putApiV2BargesKey_3', 'key', key)
            const localVarPath = `/api/v2/Barges/{key}`
                .replace(`{${"key"}}`, encodeURIComponent(String(key)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (select !== undefined) {
                localVarQueryParameter['select'] = select;
            }

            if (expand !== undefined) {
                localVarQueryParameter['expand'] = expand;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json;odata.metadata=minimal;odata.streaming=true';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(barge, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BargesApi - functional programming interface
 * @export
 */
export const BargesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BargesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {boolean} [includeDeleted] 
         * @param {number} [$top] The max number of records.
         * @param {string} [$apply] Allows to specify a sequence of transformations to the entity set, such as groupby, filter, aggregate, etc.
         * @param {number} [$skip] The number of records to skip.
         * @param {string} [$filter] A function that must evaluate to true for a record to be returned.
         * @param {string} [$select] Specifies a subset of properties to return. Use a comma separated list.
         * @param {string} [$orderby] Determines what values are used to order a collection of records.
         * @param {string} [$expand] Use to add related query data.
         * @param {boolean} [$count] Allows clients to request a count of the matching resources included with the resources in the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getApiV2Barges(includeDeleted?: boolean, $top?: number, $apply?: string, $skip?: number, $filter?: string, $select?: string, $orderby?: string, $expand?: string, $count?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BargeIEnumerableScaleODataValue>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getApiV2Barges(includeDeleted, $top, $apply, $skip, $filter, $select, $orderby, $expand, $count, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {boolean} [includeDeleted] 
         * @param {number} [$top] The max number of records.
         * @param {string} [$apply] Allows to specify a sequence of transformations to the entity set, such as groupby, filter, aggregate, etc.
         * @param {number} [$skip] The number of records to skip.
         * @param {string} [$filter] A function that must evaluate to true for a record to be returned.
         * @param {string} [$select] Specifies a subset of properties to return. Use a comma separated list.
         * @param {string} [$orderby] Determines what values are used to order a collection of records.
         * @param {string} [$expand] Use to add related query data.
         * @param {boolean} [$count] Allows clients to request a count of the matching resources included with the resources in the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getApiV2BargesCount(includeDeleted?: boolean, $top?: number, $apply?: string, $skip?: number, $filter?: string, $select?: string, $orderby?: string, $expand?: string, $count?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BargeIEnumerableScaleODataValue>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getApiV2BargesCount(includeDeleted, $top, $apply, $skip, $filter, $select, $orderby, $expand, $count, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} key 
         * @param {string} [select] 
         * @param {string} [expand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getApiV2BargesKey(key: number, select?: string, expand?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Barge>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getApiV2BargesKey(key, select, expand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} key 
         * @param {string} [select] 
         * @param {string} [expand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getApiV2BargesKey_1(key: number, select?: string, expand?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Barge>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getApiV2BargesKey_1(key, select, expand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} key 
         * @param {string} [select] 
         * @param {string} [expand] 
         * @param {Array<Operation>} [operation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchApiV2BargesKey(key: number, select?: string, expand?: string, operation?: Array<Operation>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Barge>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patchApiV2BargesKey(key, select, expand, operation, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} key 
         * @param {string} [select] 
         * @param {string} [expand] 
         * @param {Array<Operation>} [operation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchApiV2BargesKey_2(key: number, select?: string, expand?: string, operation?: Array<Operation>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Barge>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patchApiV2BargesKey_2(key, select, expand, operation, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [select] 
         * @param {string} [expand] 
         * @param {Barge} [barge] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postApiV2Barges(select?: string, expand?: string, barge?: Barge, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Barge>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postApiV2Barges(select, expand, barge, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} key 
         * @param {string} [select] 
         * @param {string} [expand] 
         * @param {Barge} [barge] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putApiV2BargesKey(key: number, select?: string, expand?: string, barge?: Barge, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Barge>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putApiV2BargesKey(key, select, expand, barge, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} key 
         * @param {string} [select] 
         * @param {string} [expand] 
         * @param {Barge} [barge] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putApiV2BargesKey_3(key: number, select?: string, expand?: string, barge?: Barge, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Barge>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putApiV2BargesKey_3(key, select, expand, barge, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * BargesApi - factory interface
 * @export
 */
export const BargesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BargesApiFp(configuration)
    return {
        /**
         * 
         * @param {BargesApiGetApiV2BargesRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiV2Barges(requestParameters: BargesApiGetApiV2BargesRequest = {}, options?: AxiosRequestConfig): AxiosPromise<BargeIEnumerableScaleODataValue> {
            return localVarFp.getApiV2Barges(requestParameters.includeDeleted, requestParameters.$top, requestParameters.$apply, requestParameters.$skip, requestParameters.$filter, requestParameters.$select, requestParameters.$orderby, requestParameters.$expand, requestParameters.$count, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {BargesApiGetApiV2BargesCountRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiV2BargesCount(requestParameters: BargesApiGetApiV2BargesCountRequest = {}, options?: AxiosRequestConfig): AxiosPromise<BargeIEnumerableScaleODataValue> {
            return localVarFp.getApiV2BargesCount(requestParameters.includeDeleted, requestParameters.$top, requestParameters.$apply, requestParameters.$skip, requestParameters.$filter, requestParameters.$select, requestParameters.$orderby, requestParameters.$expand, requestParameters.$count, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {BargesApiGetApiV2BargesKeyRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiV2BargesKey(requestParameters: BargesApiGetApiV2BargesKeyRequest, options?: AxiosRequestConfig): AxiosPromise<Barge> {
            return localVarFp.getApiV2BargesKey(requestParameters.key, requestParameters.select, requestParameters.expand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {BargesApiGetApiV2BargesKey0Request} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiV2BargesKey_1(requestParameters: BargesApiGetApiV2BargesKey0Request, options?: AxiosRequestConfig): AxiosPromise<Barge> {
            return localVarFp.getApiV2BargesKey_1(requestParameters.key, requestParameters.select, requestParameters.expand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {BargesApiPatchApiV2BargesKeyRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchApiV2BargesKey(requestParameters: BargesApiPatchApiV2BargesKeyRequest, options?: AxiosRequestConfig): AxiosPromise<Barge> {
            return localVarFp.patchApiV2BargesKey(requestParameters.key, requestParameters.select, requestParameters.expand, requestParameters.operation, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {BargesApiPatchApiV2BargesKey0Request} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchApiV2BargesKey_2(requestParameters: BargesApiPatchApiV2BargesKey0Request, options?: AxiosRequestConfig): AxiosPromise<Barge> {
            return localVarFp.patchApiV2BargesKey_2(requestParameters.key, requestParameters.select, requestParameters.expand, requestParameters.operation, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {BargesApiPostApiV2BargesRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postApiV2Barges(requestParameters: BargesApiPostApiV2BargesRequest = {}, options?: AxiosRequestConfig): AxiosPromise<Barge> {
            return localVarFp.postApiV2Barges(requestParameters.select, requestParameters.expand, requestParameters.barge, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {BargesApiPutApiV2BargesKeyRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putApiV2BargesKey(requestParameters: BargesApiPutApiV2BargesKeyRequest, options?: AxiosRequestConfig): AxiosPromise<Barge> {
            return localVarFp.putApiV2BargesKey(requestParameters.key, requestParameters.select, requestParameters.expand, requestParameters.barge, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {BargesApiPutApiV2BargesKey0Request} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putApiV2BargesKey_3(requestParameters: BargesApiPutApiV2BargesKey0Request, options?: AxiosRequestConfig): AxiosPromise<Barge> {
            return localVarFp.putApiV2BargesKey_3(requestParameters.key, requestParameters.select, requestParameters.expand, requestParameters.barge, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * BargesApi - interface
 * @export
 * @interface BargesApi
 */
export interface BargesApiInterface {
    /**
     * 
     * @param {boolean} [includeDeleted] 
     * @param {number} [$top] The max number of records.
     * @param {string} [$apply] Allows to specify a sequence of transformations to the entity set, such as groupby, filter, aggregate, etc.
     * @param {number} [$skip] The number of records to skip.
     * @param {string} [$filter] A function that must evaluate to true for a record to be returned.
     * @param {string} [$select] Specifies a subset of properties to return. Use a comma separated list.
     * @param {string} [$orderby] Determines what values are used to order a collection of records.
     * @param {string} [$expand] Use to add related query data.
     * @param {boolean} [$count] Allows clients to request a count of the matching resources included with the resources in the response.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BargesApiInterface
     */
    getApiV2Barges(includeDeleted?: boolean, $top?: number, $apply?: string, $skip?: number, $filter?: string, $select?: string, $orderby?: string, $expand?: string, $count?: boolean, options?: AxiosRequestConfig): AxiosPromise<BargeIEnumerableScaleODataValue>;

    /**
     * 
     * @param {boolean} [includeDeleted] 
     * @param {number} [$top] The max number of records.
     * @param {string} [$apply] Allows to specify a sequence of transformations to the entity set, such as groupby, filter, aggregate, etc.
     * @param {number} [$skip] The number of records to skip.
     * @param {string} [$filter] A function that must evaluate to true for a record to be returned.
     * @param {string} [$select] Specifies a subset of properties to return. Use a comma separated list.
     * @param {string} [$orderby] Determines what values are used to order a collection of records.
     * @param {string} [$expand] Use to add related query data.
     * @param {boolean} [$count] Allows clients to request a count of the matching resources included with the resources in the response.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BargesApiInterface
     */
    getApiV2BargesCount(includeDeleted?: boolean, $top?: number, $apply?: string, $skip?: number, $filter?: string, $select?: string, $orderby?: string, $expand?: string, $count?: boolean, options?: AxiosRequestConfig): AxiosPromise<BargeIEnumerableScaleODataValue>;

    /**
     * 
     * @param {number} key 
     * @param {string} [select] 
     * @param {string} [expand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BargesApiInterface
     */
    getApiV2BargesKey(key: number, select?: string, expand?: string, options?: AxiosRequestConfig): AxiosPromise<Barge>;

    /**
     * 
     * @param {number} key 
     * @param {string} [select] 
     * @param {string} [expand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BargesApiInterface
     */
    getApiV2BargesKey_1(key: number, select?: string, expand?: string, options?: AxiosRequestConfig): AxiosPromise<Barge>;

    /**
     * 
     * @param {number} key 
     * @param {string} [select] 
     * @param {string} [expand] 
     * @param {Array<Operation>} [operation] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BargesApiInterface
     */
    patchApiV2BargesKey(key: number, select?: string, expand?: string, operation?: Array<Operation>, options?: AxiosRequestConfig): AxiosPromise<Barge>;

    /**
     * 
     * @param {number} key 
     * @param {string} [select] 
     * @param {string} [expand] 
     * @param {Array<Operation>} [operation] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BargesApiInterface
     */
    patchApiV2BargesKey_2(key: number, select?: string, expand?: string, operation?: Array<Operation>, options?: AxiosRequestConfig): AxiosPromise<Barge>;

    /**
     * 
     * @param {string} [select] 
     * @param {string} [expand] 
     * @param {Barge} [barge] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BargesApiInterface
     */
    postApiV2Barges(select?: string, expand?: string, barge?: Barge, options?: AxiosRequestConfig): AxiosPromise<Barge>;

    /**
     * 
     * @param {number} key 
     * @param {string} [select] 
     * @param {string} [expand] 
     * @param {Barge} [barge] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BargesApiInterface
     */
    putApiV2BargesKey(key: number, select?: string, expand?: string, barge?: Barge, options?: AxiosRequestConfig): AxiosPromise<Barge>;

    /**
     * 
     * @param {number} key 
     * @param {string} [select] 
     * @param {string} [expand] 
     * @param {Barge} [barge] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BargesApiInterface
     */
    putApiV2BargesKey_3(key: number, select?: string, expand?: string, barge?: Barge, options?: AxiosRequestConfig): AxiosPromise<Barge>;

}

/**
 * Request parameters for getApiV2Barges operation in BargesApi.
 * @export
 * @interface BargesApiGetApiV2BargesRequest
 */
export interface BargesApiGetApiV2BargesRequest {
    /**
     * 
     * @type {boolean}
     * @memberof BargesApiGetApiV2Barges
     */
    readonly includeDeleted?: boolean

    /**
     * The max number of records.
     * @type {number}
     * @memberof BargesApiGetApiV2Barges
     */
    readonly $top?: number

    /**
     * Allows to specify a sequence of transformations to the entity set, such as groupby, filter, aggregate, etc.
     * @type {string}
     * @memberof BargesApiGetApiV2Barges
     */
    readonly $apply?: string

    /**
     * The number of records to skip.
     * @type {number}
     * @memberof BargesApiGetApiV2Barges
     */
    readonly $skip?: number

    /**
     * A function that must evaluate to true for a record to be returned.
     * @type {string}
     * @memberof BargesApiGetApiV2Barges
     */
    readonly $filter?: string

    /**
     * Specifies a subset of properties to return. Use a comma separated list.
     * @type {string}
     * @memberof BargesApiGetApiV2Barges
     */
    readonly $select?: string

    /**
     * Determines what values are used to order a collection of records.
     * @type {string}
     * @memberof BargesApiGetApiV2Barges
     */
    readonly $orderby?: string

    /**
     * Use to add related query data.
     * @type {string}
     * @memberof BargesApiGetApiV2Barges
     */
    readonly $expand?: string

    /**
     * Allows clients to request a count of the matching resources included with the resources in the response.
     * @type {boolean}
     * @memberof BargesApiGetApiV2Barges
     */
    readonly $count?: boolean
}

/**
 * Request parameters for getApiV2BargesCount operation in BargesApi.
 * @export
 * @interface BargesApiGetApiV2BargesCountRequest
 */
export interface BargesApiGetApiV2BargesCountRequest {
    /**
     * 
     * @type {boolean}
     * @memberof BargesApiGetApiV2BargesCount
     */
    readonly includeDeleted?: boolean

    /**
     * The max number of records.
     * @type {number}
     * @memberof BargesApiGetApiV2BargesCount
     */
    readonly $top?: number

    /**
     * Allows to specify a sequence of transformations to the entity set, such as groupby, filter, aggregate, etc.
     * @type {string}
     * @memberof BargesApiGetApiV2BargesCount
     */
    readonly $apply?: string

    /**
     * The number of records to skip.
     * @type {number}
     * @memberof BargesApiGetApiV2BargesCount
     */
    readonly $skip?: number

    /**
     * A function that must evaluate to true for a record to be returned.
     * @type {string}
     * @memberof BargesApiGetApiV2BargesCount
     */
    readonly $filter?: string

    /**
     * Specifies a subset of properties to return. Use a comma separated list.
     * @type {string}
     * @memberof BargesApiGetApiV2BargesCount
     */
    readonly $select?: string

    /**
     * Determines what values are used to order a collection of records.
     * @type {string}
     * @memberof BargesApiGetApiV2BargesCount
     */
    readonly $orderby?: string

    /**
     * Use to add related query data.
     * @type {string}
     * @memberof BargesApiGetApiV2BargesCount
     */
    readonly $expand?: string

    /**
     * Allows clients to request a count of the matching resources included with the resources in the response.
     * @type {boolean}
     * @memberof BargesApiGetApiV2BargesCount
     */
    readonly $count?: boolean
}

/**
 * Request parameters for getApiV2BargesKey operation in BargesApi.
 * @export
 * @interface BargesApiGetApiV2BargesKeyRequest
 */
export interface BargesApiGetApiV2BargesKeyRequest {
    /**
     * 
     * @type {number}
     * @memberof BargesApiGetApiV2BargesKey
     */
    readonly key: number

    /**
     * 
     * @type {string}
     * @memberof BargesApiGetApiV2BargesKey
     */
    readonly select?: string

    /**
     * 
     * @type {string}
     * @memberof BargesApiGetApiV2BargesKey
     */
    readonly expand?: string
}

/**
 * Request parameters for getApiV2BargesKey_1 operation in BargesApi.
 * @export
 * @interface BargesApiGetApiV2BargesKey0Request
 */
export interface BargesApiGetApiV2BargesKey0Request {
    /**
     * 
     * @type {number}
     * @memberof BargesApiGetApiV2BargesKey0
     */
    readonly key: number

    /**
     * 
     * @type {string}
     * @memberof BargesApiGetApiV2BargesKey0
     */
    readonly select?: string

    /**
     * 
     * @type {string}
     * @memberof BargesApiGetApiV2BargesKey0
     */
    readonly expand?: string
}

/**
 * Request parameters for patchApiV2BargesKey operation in BargesApi.
 * @export
 * @interface BargesApiPatchApiV2BargesKeyRequest
 */
export interface BargesApiPatchApiV2BargesKeyRequest {
    /**
     * 
     * @type {number}
     * @memberof BargesApiPatchApiV2BargesKey
     */
    readonly key: number

    /**
     * 
     * @type {string}
     * @memberof BargesApiPatchApiV2BargesKey
     */
    readonly select?: string

    /**
     * 
     * @type {string}
     * @memberof BargesApiPatchApiV2BargesKey
     */
    readonly expand?: string

    /**
     * 
     * @type {Array<Operation>}
     * @memberof BargesApiPatchApiV2BargesKey
     */
    readonly operation?: Array<Operation>
}

/**
 * Request parameters for patchApiV2BargesKey_2 operation in BargesApi.
 * @export
 * @interface BargesApiPatchApiV2BargesKey0Request
 */
export interface BargesApiPatchApiV2BargesKey0Request {
    /**
     * 
     * @type {number}
     * @memberof BargesApiPatchApiV2BargesKey0
     */
    readonly key: number

    /**
     * 
     * @type {string}
     * @memberof BargesApiPatchApiV2BargesKey0
     */
    readonly select?: string

    /**
     * 
     * @type {string}
     * @memberof BargesApiPatchApiV2BargesKey0
     */
    readonly expand?: string

    /**
     * 
     * @type {Array<Operation>}
     * @memberof BargesApiPatchApiV2BargesKey0
     */
    readonly operation?: Array<Operation>
}

/**
 * Request parameters for postApiV2Barges operation in BargesApi.
 * @export
 * @interface BargesApiPostApiV2BargesRequest
 */
export interface BargesApiPostApiV2BargesRequest {
    /**
     * 
     * @type {string}
     * @memberof BargesApiPostApiV2Barges
     */
    readonly select?: string

    /**
     * 
     * @type {string}
     * @memberof BargesApiPostApiV2Barges
     */
    readonly expand?: string

    /**
     * 
     * @type {Barge}
     * @memberof BargesApiPostApiV2Barges
     */
    readonly barge?: Barge
}

/**
 * Request parameters for putApiV2BargesKey operation in BargesApi.
 * @export
 * @interface BargesApiPutApiV2BargesKeyRequest
 */
export interface BargesApiPutApiV2BargesKeyRequest {
    /**
     * 
     * @type {number}
     * @memberof BargesApiPutApiV2BargesKey
     */
    readonly key: number

    /**
     * 
     * @type {string}
     * @memberof BargesApiPutApiV2BargesKey
     */
    readonly select?: string

    /**
     * 
     * @type {string}
     * @memberof BargesApiPutApiV2BargesKey
     */
    readonly expand?: string

    /**
     * 
     * @type {Barge}
     * @memberof BargesApiPutApiV2BargesKey
     */
    readonly barge?: Barge
}

/**
 * Request parameters for putApiV2BargesKey_3 operation in BargesApi.
 * @export
 * @interface BargesApiPutApiV2BargesKey0Request
 */
export interface BargesApiPutApiV2BargesKey0Request {
    /**
     * 
     * @type {number}
     * @memberof BargesApiPutApiV2BargesKey0
     */
    readonly key: number

    /**
     * 
     * @type {string}
     * @memberof BargesApiPutApiV2BargesKey0
     */
    readonly select?: string

    /**
     * 
     * @type {string}
     * @memberof BargesApiPutApiV2BargesKey0
     */
    readonly expand?: string

    /**
     * 
     * @type {Barge}
     * @memberof BargesApiPutApiV2BargesKey0
     */
    readonly barge?: Barge
}

/**
 * BargesApi - object-oriented interface
 * @export
 * @class BargesApi
 * @extends {BaseAPI}
 */
export class BargesApi extends BaseAPI implements BargesApiInterface {
    /**
     * 
     * @param {BargesApiGetApiV2BargesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BargesApi
     */
    public getApiV2Barges(requestParameters: BargesApiGetApiV2BargesRequest = {}, options?: AxiosRequestConfig) {
        return BargesApiFp(this.configuration).getApiV2Barges(requestParameters.includeDeleted, requestParameters.$top, requestParameters.$apply, requestParameters.$skip, requestParameters.$filter, requestParameters.$select, requestParameters.$orderby, requestParameters.$expand, requestParameters.$count, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {BargesApiGetApiV2BargesCountRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BargesApi
     */
    public getApiV2BargesCount(requestParameters: BargesApiGetApiV2BargesCountRequest = {}, options?: AxiosRequestConfig) {
        return BargesApiFp(this.configuration).getApiV2BargesCount(requestParameters.includeDeleted, requestParameters.$top, requestParameters.$apply, requestParameters.$skip, requestParameters.$filter, requestParameters.$select, requestParameters.$orderby, requestParameters.$expand, requestParameters.$count, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {BargesApiGetApiV2BargesKeyRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BargesApi
     */
    public getApiV2BargesKey(requestParameters: BargesApiGetApiV2BargesKeyRequest, options?: AxiosRequestConfig) {
        return BargesApiFp(this.configuration).getApiV2BargesKey(requestParameters.key, requestParameters.select, requestParameters.expand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {BargesApiGetApiV2BargesKey0Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BargesApi
     */
    public getApiV2BargesKey_1(requestParameters: BargesApiGetApiV2BargesKey0Request, options?: AxiosRequestConfig) {
        return BargesApiFp(this.configuration).getApiV2BargesKey_1(requestParameters.key, requestParameters.select, requestParameters.expand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {BargesApiPatchApiV2BargesKeyRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BargesApi
     */
    public patchApiV2BargesKey(requestParameters: BargesApiPatchApiV2BargesKeyRequest, options?: AxiosRequestConfig) {
        return BargesApiFp(this.configuration).patchApiV2BargesKey(requestParameters.key, requestParameters.select, requestParameters.expand, requestParameters.operation, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {BargesApiPatchApiV2BargesKey0Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BargesApi
     */
    public patchApiV2BargesKey_2(requestParameters: BargesApiPatchApiV2BargesKey0Request, options?: AxiosRequestConfig) {
        return BargesApiFp(this.configuration).patchApiV2BargesKey_2(requestParameters.key, requestParameters.select, requestParameters.expand, requestParameters.operation, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {BargesApiPostApiV2BargesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BargesApi
     */
    public postApiV2Barges(requestParameters: BargesApiPostApiV2BargesRequest = {}, options?: AxiosRequestConfig) {
        return BargesApiFp(this.configuration).postApiV2Barges(requestParameters.select, requestParameters.expand, requestParameters.barge, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {BargesApiPutApiV2BargesKeyRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BargesApi
     */
    public putApiV2BargesKey(requestParameters: BargesApiPutApiV2BargesKeyRequest, options?: AxiosRequestConfig) {
        return BargesApiFp(this.configuration).putApiV2BargesKey(requestParameters.key, requestParameters.select, requestParameters.expand, requestParameters.barge, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {BargesApiPutApiV2BargesKey0Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BargesApi
     */
    public putApiV2BargesKey_3(requestParameters: BargesApiPutApiV2BargesKey0Request, options?: AxiosRequestConfig) {
        return BargesApiFp(this.configuration).putApiV2BargesKey_3(requestParameters.key, requestParameters.select, requestParameters.expand, requestParameters.barge, options).then((request) => request(this.axios, this.basePath));
    }
}
