import i18n from "i18next";
import { initReactI18next, useTranslation } from "react-i18next";

// English
import AccessControlEn from "../public/locales/en/accessControl.json" assert { type: "json" };
import AdminEn from "../public/locales/en/admin.json";
import BargesEn from "../public/locales/en/barges.json";
import CommentsEn from "../public/locales/en/comments.json";
import CommonEn from "../public/locales/en/common.json";
import CountriesEn from "../public/locales/en/countries.json";
import DataEn from "../public/locales/en/data.json";
import DataManagementEn from "../public/locales/en/dataManagement.json";
import DevicesEn from "../public/locales/en/devices.json";
import HomeEn from "../public/locales/en/home.json";
import LicensesEn from "../public/locales/en/licenses.json";
import OperationsEn from "../public/locales/en/operations.json";
import SitesEn from "../public/locales/en/sites.json";
import UsersEn from "../public/locales/en/users.json";
import IdentityProvidersEn from "../public/locales/en/identityProviders.json";

// Norwegian
import AccessControlNb from "../public/locales/nb/accessControl.json";
import AdminNb from "../public/locales/nb/admin.json" assert { type: "json" };
import BargesNb from "../public/locales/nb/barges.json";
import CommentsNb from "../public/locales/nb/comments.json";
import CommonNb from "../public/locales/nb/common.json";
import CountriesNb from "../public/locales/nb/countries.json";
import DataNb from "../public/locales/nb/data.json";
import DataManagementNb from "../public/locales/nb/dataManagement.json";
import DevicesNb from "../public/locales/nb/devices.json";
import HomeNb from "../public/locales/nb/home.json";
import LicensesNb from "../public/locales/nb/licenses.json";
import OperationsNb from "../public/locales/nb/operations.json";
import SitesNb from "../public/locales/nb/sites.json";
import UsersNb from "../public/locales/nb/users.json";
import IdentityProvidersNb from "../public/locales/nb/identityProviders.json";

// Spanish
import AccessControlEs from "../public/locales/es/accessControl.json";
import AdminEs from "../public/locales/es/admin.json";
import BargesEs from "../public/locales/es/barges.json";
import CommentsEs from "../public/locales/es/comments.json";
import CommonEs from "../public/locales/es/common.json";
import CountriesEs from "../public/locales/es/countries.json";
import DataEs from "../public/locales/es/data.json";
import DataManagementEs from "../public/locales/es/dataManagement.json";
import DevicesEs from "../public/locales/es/devices.json";
import HomeEs from "../public/locales/es/home.json";
import LicensesEs from "../public/locales/es/licenses.json";
import OperationsEs from "../public/locales/es/operations.json";
import SitesEs from "../public/locales/es/sites.json";
import UsersEs from "../public/locales/es/users.json";
import IdentityProvidersEs from "../public/locales/es/identityProviders.json";

export enum Languages {
  English = "en",
  Norwegian = "nb",
  Spanish = "es",
}

export enum TranslationResource {
  AccessControl = "accessControl",
  Admin = "admin",
  Countries = "countries",
  Barges = "barges",
  Comments = "comments",
  Common = "common",
  Data = "data",
  DataManagement = "dataManagement",
  Devices = "devices",
  Home = "home",
  Licenses = "licenses",
  Operations = "operations",
  Sites = "sites",
  Users = "users",
  IdentityProviders = "identityProviders",
}

export const translationResources = {
  [Languages.English]: {
    [TranslationResource.AccessControl]: AccessControlEn,
    [TranslationResource.Countries]: CountriesEn,
    [TranslationResource.Admin]: AdminEn,
    [TranslationResource.Barges]: BargesEn,
    [TranslationResource.Comments]: CommentsEn,
    [TranslationResource.Common]: CommonEn,
    [TranslationResource.Data]: DataEn,
    [TranslationResource.DataManagement]: DataManagementEn,
    [TranslationResource.Devices]: DevicesEn,
    [TranslationResource.Home]: HomeEn,
    [TranslationResource.Licenses]: LicensesEn,
    [TranslationResource.Operations]: OperationsEn,
    [TranslationResource.Sites]: SitesEn,
    [TranslationResource.Users]: UsersEn,
    [TranslationResource.IdentityProviders]: IdentityProvidersEn,
  },
  [Languages.Norwegian]: {
    [TranslationResource.AccessControl]: AccessControlNb,
    [TranslationResource.Countries]: CountriesNb,
    [TranslationResource.Admin]: AdminNb,
    [TranslationResource.Barges]: BargesNb,
    [TranslationResource.Comments]: CommentsNb,
    [TranslationResource.Common]: CommonNb,
    [TranslationResource.Data]: DataNb,
    [TranslationResource.DataManagement]: DataManagementNb,
    [TranslationResource.Devices]: DevicesNb,
    [TranslationResource.Home]: HomeNb,
    [TranslationResource.Licenses]: LicensesNb,
    [TranslationResource.Operations]: OperationsNb,
    [TranslationResource.Sites]: SitesNb,
    [TranslationResource.Users]: UsersNb,
    [TranslationResource.IdentityProviders]: IdentityProvidersNb,
  },
  [Languages.Spanish]: {
    [TranslationResource.AccessControl]: AccessControlEs,
    [TranslationResource.Countries]: CountriesEs,
    [TranslationResource.Admin]: AdminEs,
    [TranslationResource.Barges]: BargesEs,
    [TranslationResource.Comments]: CommentsEs,
    [TranslationResource.Common]: CommonEs,
    [TranslationResource.Data]: DataEs,
    [TranslationResource.DataManagement]: DataManagementEs,
    [TranslationResource.Devices]: DevicesEs,
    [TranslationResource.Home]: HomeEs,
    [TranslationResource.Licenses]: LicensesEs,
    [TranslationResource.Operations]: OperationsEs,
    [TranslationResource.Sites]: SitesEs,
    [TranslationResource.Users]: UsersEs,
    [TranslationResource.IdentityProviders]: IdentityProvidersEs,
  },
};

i18n.use(initReactI18next).init({
  resources: translationResources,
  //lng: Languages.English,
});

export type TFunction = ReturnType<typeof useTranslation>["t"];
export type TranslationKey = Parameters<ReturnType<typeof useTranslation>["t"]>[0];
