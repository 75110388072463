import ScaleUiTheme from "@scaleaq/scaleui-react/core/theme/light";

const tmp: any = ScaleUiTheme;

const colors: any = {
  ...tmp.colors,
  gray1: "#C4C4C4",
  gray2: "#E5E5E5",
  gray3: "#A5A5A5",
};
const theme = {
  ...ScaleUiTheme,
  colors,
  palette: {
    ...ScaleUiTheme.palette,
    snackbar: {
      success: colors.ocean8,
      error: colors.fire5,
      warning: colors.fire2,
      info: colors.ocean8,
    },
  },
  typography: {
    ...ScaleUiTheme.typography,
    htmlFontSize: 10,
    fontSize: 14,
    caption: {
      fontSize: 14,
      fontWeight: 500,
    },
    h1: {
      fontSize: 30,
      fontWeight: 700,
      color: colors.ocean7,
      fontFamily: "proxima-nova",
    },
    h2: {
      fontSize: 26,
      fontWeight: 500,
      color: colors.neutral10,
    },
    h3: {
      fontSize: 22,
      fontWeight: 400,
      color: colors.neutral10,
      fontFamily: "proxima-nova",
    },
    h4: {
      fontSize: 22,
      fontWeight: 700,
      color: colors.neutral10,
      textTransform: "uppercase",
      fontFamily: "proxima-nova",
    },
    h5: {
      fontSize: 24,
      fontWeight: 500,
      color: colors.neutral10,
      fontFamily: "proxima-nova",
    },
    h6: {
      fontSize: 20,
      fontWeight: 500,
      color: colors.neutral10,
    },
    subtitle2: {
      fontSize: 14,
      fontWeight: 400,
      color: colors.neutral6,
    },
  },
};

export default theme;
