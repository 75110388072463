import { Grid } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import Loading from "../Loading";
import { useStyles } from "./styles";
import clsx from "clsx";

export type Props = {
  className?: string;
};

export const CenteredLoading = (props: Props) => {
  const { className } = props ?? {};
  const { t } = useTranslation();
  const { classes } = useStyles(props);
  return (
    <Grid className={clsx(classes.loadingContainer, className)} container>
      <Loading loadingText={t("common:loading", "Loading")} />
    </Grid>
  );
};
