import React from "react";
import fetch from "isomorphic-unfetch";
import { environment, unleashProxyClientKeys, unleashProxyUrl } from "utils/appsettings";
import { FlagProvider, InMemoryStorageProvider } from "@unleash/proxy-client-react";
import { AppProps } from "next/app";

const config = {
  url: unleashProxyUrl,
  clientKey: unleashProxyClientKeys,
  refreshInterval: 60,
  appName: "scale-aq-proxy",
  environment: environment,
  fetch,
  storageProvider: new InMemoryStorageProvider(),
};

export const withUnleash = <Props extends AppProps = AppProps>(
  WrappedComponent: React.ComponentType<Props>,
) => {
  const ComponentWithUnleash = (props: Props) => {
    return (
      <FlagProvider config={config}>
        <WrappedComponent {...props} />
      </FlagProvider>
    );
  };
  return ComponentWithUnleash;
};
