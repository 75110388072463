import { userApiBaseUrl } from "utils/appsettings";
import * as UsersApi from "api/swagger/users.api.2.0/api";
import { generateSwaggerApiClient } from "./genericApiClient";

const usersApiV2Client = generateSwaggerApiClient(UsersApi, userApiBaseUrl);
export * as UsersApiModels from "api/swagger/users.api.2.0/model";

export enum SignInType {
  Federated = "Federated",
  EmailAddress = "EmailAddress",
}

export default usersApiV2Client;
