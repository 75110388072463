import pubsub from "pubsub-js";
import { ErrorOutgoingMessage } from "./apiBatchExportWorker/workerTypes";

export type WorkerEntry = {
  id: string;
  name: string;
  progress?: [done: number, total: number];
  error?: ErrorOutgoingMessage;
  worker: Worker;
};

export type WorkerEntryMap = Record<string, WorkerEntry>;

type WorkerTracker = {
  addWorker: (workerEntry: WorkerEntry) => void;
  removeWorker: (id: string) => void;
  getWorkers: () => WorkerEntryMap;
  getWorker: (id: string) => WorkerEntry;
  setProgress: (id: string, progress: [number, number]) => void;
  setError: (id: string, error: ErrorOutgoingMessage) => void;
};

const workerEntryMap: WorkerEntryMap = {};

export enum WorkerCommands {
  ADD_WORKER = "ADD_WORKER",
  REMOVE_WORKER = "REMOVE_WORKER",
}

export enum WorkerListeners {
  WORKERS = "WORKERS",
}

export const workerTracker: WorkerTracker = {
  addWorker: (workerEntry) => {
    workerEntryMap[workerEntry.id] = workerEntry;
    pubsub.publishSync(WorkerListeners.WORKERS, {
      ...workerEntryMap,
    });
  },
  removeWorker: (id) => {
    const workerEntry = workerEntryMap[id];
    if (!workerEntry) {
      throw new Error(`Unable to remove worker with id '${id}' as it doesn't exist.`);
    }

    workerEntry.worker.terminate();
    delete workerEntryMap[id];
    pubsub.publishSync(WorkerListeners.WORKERS, {
      ...workerEntryMap,
    });
  },
  setError: (id, error) => {
    /* eslint-disable no-console */
    console.log("id", id, "error", error);
    workerEntryMap[id] = {
      ...workerEntryMap[id],
      error: {
        ...error,
      },
    };

    pubsub.publishSync(WorkerListeners.WORKERS, {
      ...workerEntryMap,
    });
  },
  setProgress: (id, progress) => {
    workerEntryMap[id] = {
      ...workerEntryMap[id],
      progress,
    };

    pubsub.publishSync(WorkerListeners.WORKERS, {
      ...workerEntryMap,
    });
  },
  getWorkers: () => {
    return {
      ...workerEntryMap,
    };
  },
  getWorker: (id: string) => {
    return workerEntryMap[id];
  },
};
