import { logout } from "@scaleaq/auth-client";
import { Icon, LinkItem, TextItem } from "@scaleaq/scaleui";
import buildODataQuery from "api/clients/buildODataQuery";
import { useSwaggerSwr } from "api/clients/genericApiClient";
import gosApiV2Client, { GosApiV2Models } from "api/clients/gosApiV2Client";
import { useCurrentCompanyId } from "hooks/useGlobalCompanyId";
import { usePathname } from "next/navigation";
import { useRouter } from "next/router";
import { useAccessControl } from "providers/accessControlProvider";
import { oidcClient, useSession } from "providers/sessionProvider";
import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSessionStorage } from "usehooks-ts";
import { useMenuItems } from "./useMenuItems";

type CompanySelectorValue = TextItem & {
  companyId: string;
  companyName: string;
};

export const useAppFrame = () => {
  const router = useRouter();
  const { t } = useTranslation();

  const { hasScaleManagementPrivileges, accessIsLoading, isCustomerAdminForCurrentCompany, isSiteAdmin } =
    useAccessControl();

  const session = useSession();

  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const { currentCompanyId, setCurrentCompanyId } = useCurrentCompanyId();

  const isMyProfile = router.asPath.includes("/profile");

  const showLeftMenu = !isMyProfile || isCustomerAdminForCurrentCompany || hasScaleManagementPrivileges;

  const skipCompanies = !showLeftMenu || hasScaleManagementPrivileges;

  const { data: companies } = useSwaggerSwr(
    gosApiV2Client.CompaniesApi.getApiV2Companies,
    [
      buildODataQuery<GosApiV2Models.Company>({
        select: ["companyId", "companyName"],
      }),
    ],
    skipCompanies,
  );

  const companyOptions: CompanySelectorValue[] = useMemo(() => {
    return (
      companies?.value.map((x) => ({
        id: x.companyId,
        label: x.companyName,
        companyId: x.companyId,
        companyName: x.companyName,
      })) ?? []
    );
  }, [companies]);

  const currentCompany = useMemo(() => {
    return companies?.value.find((x) => x.companyId === currentCompanyId);
  }, [currentCompanyId, companies]);

  const selectedCompanyValue: CompanySelectorValue = useMemo(
    () => ({
      id: currentCompany?.companyId ?? "0",
      label: currentCompany?.companyName ?? "",
      companyId: currentCompany?.companyId ?? "0",
      companyName: currentCompany?.companyName ?? "",
    }),
    [currentCompany],
  );

  const title = isMyProfile ? t("common:myProfile") : "Management Portal";

  const handleLogout = async () => {
    await logout(oidcClient, {
      post_logout_redirect_uri: location.origin,
    });
  };

  const onToggleMenu = (event: React.SyntheticEvent<unknown>) => {
    event.preventDefault();
    setMobileMenuOpen(!mobileMenuOpen);
  };

  const onCompanyChange = (company: CompanySelectorValue) => {
    setCurrentCompanyId(company?.companyId ?? undefined);
  };

  const menuItems = useMenuItems();

  const userMenu: LinkItem[] = useMemo(() => {
    const userId = session?.profile?.sub;

    return [
      {
        label: t("common:mainMenu.profile"),
        Icon: Icon.Large.SettingsUser,
        href: {
          pathname: "/users/[userId]/profile",
          query: { userId },
        },
      },
      {
        label: t("common:mainMenu.logOut"),
        Icon: Icon.Large.Logout,
        onClick: handleLogout,
      },
    ];
  }, [t, handleLogout, session]);

  const currentUrl = usePathname();

  const selectedPath = useMemo(() => {
    if (!currentUrl || currentUrl == "/") {
      return "dashboard";
    }

    const parts = currentUrl.split("/");
    return parts.length > 1 ? parts[1] : parts[0] || "";
  }, [currentUrl]);

  const [mainMenuOpen, setMainMenuOpen] = useSessionStorage("main-menu-drawer-open", true);

  const productHref = useMemo(() => {
    if (accessIsLoading) {
      return "#";
    }

    if (
      !accessIsLoading &&
      hasScaleManagementPrivileges == false &&
      isCustomerAdminForCurrentCompany == false &&
      !isSiteAdmin
    ) {
      return "#";
    }

    return "/";
  }, [accessIsLoading, hasScaleManagementPrivileges, isCustomerAdminForCurrentCompany, isSiteAdmin]);

  return {
    mobileMenuOpen,
    onToggleMenu,
    menuItems,
    currentCompanyId,
    companies,
    selectedCompanyValue,
    onCompanyChange,
    title,
    showLeftMenu,
    session,
    companyOptions,
    userMenu,
    selectedPath,
    mainMenuOpen,
    setMainMenuOpen,
    productHref,
  };
};
