import { AxiosResponse } from "axios";
import { useMemo } from "react";
import useSWR, { SWRConfiguration, SWRResponse } from "swr";

type Configuration = {
  /**
   * A URL containing the base path that will be used when generating an API client.
   */
  basePath: string;
};

type Constructor = new (...args: any) => any;

type FullOperationId = {
  /**
   * A unique OperationId generated using the API client base path and an OpenAPI document.
   * @see https://swagger.io/docs/specification/paths-and-operations/
   */
  operationId: string;
};

type Api<T> = {
  [K in keyof T]: T[K] extends Constructor ? Record<K, Constructor> : never;
}[keyof T];

type ApiClient<T extends Api<T>> = {
  [K in keyof T]: T[K] extends Constructor
    ? {
        [K in keyof T]: {
          [_K in keyof InstanceType<T[K]>]: InstanceType<T[K]>[_K] & FullOperationId;
        };
      }
    : never;
}[keyof T] &
  Configuration;

/**
 * Generates an API client given a group of API definitions created through the `typescript-axios` template of https://openapi-generator.tech/
 *
 * @param apiDefinitions All definitions created by OpenApi Generator under the /api folder.
 * @param basePath A URL that will be used as basePath for all API calls.
 * @see https://openapi-generator.tech/
 * @see https://openapi-generator.tech/docs/generators/typescript-axios/
 * @example
 * import * as Api from "openapi-generator-output-folder/api";
 * const client = generateSwaggerApiClient(Api, "https://some-base-path.com");
 */
export function generateSwaggerApiClient<T extends Api<T>>(
  apiDefinitions: T,
  basePath: string,
): ApiClient<T> {
  const client: ApiClient<T> = Object.fromEntries(
    Object.entries(apiDefinitions)
      .filter(([key]) => key.endsWith("Api"))
      .map(([key, value]) => {
        // eslint-disable-next-line @typescript-eslint/ban-types
        const instance: Record<keyof T, Function & FullOperationId> = new (value as Constructor)({
          basePath,
        });

        Object.getOwnPropertyNames(Object.getPrototypeOf(instance)).forEach((_key) => {
          const __key = _key as unknown as keyof T;
          // we need to explicitly bind this because of how OpenAPI generator generates classes.
          instance[__key] = instance[__key].bind(instance);
          instance[__key].operationId = `${basePath}/${_key}`;
        });

        return [key, instance];
      }),
  ) as ApiClient<T>;

  return {
    ...client,
    basePath,
  };
}

/**
 * @see https://swr.vercel.app/docs/api#options
 */
export const defaultSwrOptions: Partial<SWRConfiguration> = {
  revalidateOnFocus: false,
};

/**
 * SWR Response with loading state.
 */
export type SWRResponseWithLoading<Data = any, Error = any> = SWRResponse<Data, Error> & {
  isLoading: boolean;
};

/**
 * @summary Returns a SWR hook from any API method generated by the generateSwaggerApiClient method.
 * @param operation Any method from the API client generated by the generateSwaggerApiClient method.
 * @param args Any parameters the API client method receives.
 * @param skipFetch Whether executing the API client method should be skipped. Will result in using null as a SWR key.
 * @param swrOptions Options that will be passed to SWR.
 * @returns The default response from the SWR hook plus a loading state.
 * @see https://swr.vercel.app/docs/conditional-fetching#conditional
 */
export function useSwaggerSwr<T extends ((...args: any[]) => Promise<AxiosResponse>) & FullOperationId>(
  operation: T,
  args: Parameters<T> = [] as Parameters<T>,
  skipFetch = false,
  swrOptions: Partial<SWRConfiguration> = {},
): SWRResponseWithLoading<
  (ReturnType<T> extends Promise<infer Q> ? Q : never) extends AxiosResponse<infer Q> ? Q : never
> {
  /**
   * We could probably just pass [ operation.operationId, ...args ] as key values for SWR,
     but memoizing here is preferrable for debugging
   * @see https://swr.vercel.app/docs/arguments#passing-objects
   */
  const memoizedSwrKey = useMemo(
    () => JSON.stringify([operation?.operationId, ...args]),
    [operation?.operationId, JSON.stringify(args)],
  );

  const hook = useSWR(
    skipFetch ? null : memoizedSwrKey,
    async () => {
      const { data } = await operation(...args);
      return data;
    },
    {
      ...defaultSwrOptions,
      ...swrOptions,
    },
  ) as SWRResponse<any, any>;

  return hook;
}
