import { createSlice } from "@reduxjs/toolkit";
import { Theme } from "@scaleaq/scaleui";

export type UI_STATE = {
  theme: Theme;
};

// const initialState: UI_STATE = {
//   theme: Theme.DARK,
// };

export const uiSlice = createSlice({
  name: "ui",
  initialState: {
    theme: Theme.LIGHT,
  },
  reducers: {
    uiSetTheme: (state, action: { payload: Theme }) => {
      state.theme = action.payload;
    },
  },
});

export const { uiSetTheme: uiSetTheme } = uiSlice.actions;
