export const fontConfig = {
  main: {
    name: "Roboto",
    family: '"Roboto", sans-serif',
  },
  secondary: {
    family: '"proxima-nova"',
    name: '"proxima-nova"',
  },
};
