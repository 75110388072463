// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Users API
 * Users API
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { CompleteMobilePhoneVerificationRequestWeb } from '../model';
// @ts-ignore
import { CompleteMobilePhoneVerificationResponseWeb } from '../model';
// @ts-ignore
import { CreateUserRequestWeb } from '../model';
// @ts-ignore
import { CreateUserResponseWeb } from '../model';
// @ts-ignore
import { PublicUserWeb } from '../model';
// @ts-ignore
import { StartMobilePhoneVerificationResponseWeb } from '../model';
// @ts-ignore
import { UpdateUserPasswordRequestWeb } from '../model';
// @ts-ignore
import { UpdateUserRequestWeb } from '../model';
// @ts-ignore
import { UserWeb } from '../model';
/**
 * UsersApi - axios parameter creator
 * @export
 */
export const UsersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteV2UsersUserId: async (userId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('deleteV2UsersUserId', 'userId', userId)
            const localVarPath = `/v2/users/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<string>} [userIds] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getV2UsersPublic: async (userIds?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/users/public`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (userIds) {
                localVarQueryParameter['userIds'] = userIds.join(COLLECTION_FORMATS.csv);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getV2UsersUserId: async (userId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('getV2UsersUserId', 'userId', userId)
            const localVarPath = `/v2/users/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreateUserRequestWeb} [createUserRequestWeb] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postV2Users: async (createUserRequestWeb?: CreateUserRequestWeb, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json;odata.metadata=minimal;odata.streaming=true';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createUserRequestWeb, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} userId 
         * @param {CompleteMobilePhoneVerificationRequestWeb} [completeMobilePhoneVerificationRequestWeb] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postV2UsersUserIdCompleteMobilePhoneVerification: async (userId: string, completeMobilePhoneVerificationRequestWeb?: CompleteMobilePhoneVerificationRequestWeb, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('postV2UsersUserIdCompleteMobilePhoneVerification', 'userId', userId)
            const localVarPath = `/v2/users/{userId}/complete-mobile-phone-verification`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json;odata.metadata=minimal;odata.streaming=true';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(completeMobilePhoneVerificationRequestWeb, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postV2UsersUserIdStartMobilePhoneVerification: async (userId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('postV2UsersUserIdStartMobilePhoneVerification', 'userId', userId)
            const localVarPath = `/v2/users/{userId}/start-mobile-phone-verification`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} userId 
         * @param {UpdateUserRequestWeb} [updateUserRequestWeb] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putV2UsersUserId: async (userId: string, updateUserRequestWeb?: UpdateUserRequestWeb, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('putV2UsersUserId', 'userId', userId)
            const localVarPath = `/v2/users/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json;odata.metadata=minimal;odata.streaming=true';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateUserRequestWeb, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} userId 
         * @param {UpdateUserPasswordRequestWeb} [updateUserPasswordRequestWeb] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putV2UsersUserIdPassword: async (userId: string, updateUserPasswordRequestWeb?: UpdateUserPasswordRequestWeb, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('putV2UsersUserIdPassword', 'userId', userId)
            const localVarPath = `/v2/users/{userId}/password`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json;odata.metadata=minimal;odata.streaming=true';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateUserPasswordRequestWeb, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UsersApi - functional programming interface
 * @export
 */
export const UsersApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UsersApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteV2UsersUserId(userId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteV2UsersUserId(userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {Array<string>} [userIds] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getV2UsersPublic(userIds?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PublicUserWeb>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getV2UsersPublic(userIds, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getV2UsersUserId(userId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserWeb>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getV2UsersUserId(userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CreateUserRequestWeb} [createUserRequestWeb] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postV2Users(createUserRequestWeb?: CreateUserRequestWeb, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateUserResponseWeb>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postV2Users(createUserRequestWeb, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} userId 
         * @param {CompleteMobilePhoneVerificationRequestWeb} [completeMobilePhoneVerificationRequestWeb] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postV2UsersUserIdCompleteMobilePhoneVerification(userId: string, completeMobilePhoneVerificationRequestWeb?: CompleteMobilePhoneVerificationRequestWeb, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompleteMobilePhoneVerificationResponseWeb>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postV2UsersUserIdCompleteMobilePhoneVerification(userId, completeMobilePhoneVerificationRequestWeb, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postV2UsersUserIdStartMobilePhoneVerification(userId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StartMobilePhoneVerificationResponseWeb>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postV2UsersUserIdStartMobilePhoneVerification(userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} userId 
         * @param {UpdateUserRequestWeb} [updateUserRequestWeb] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putV2UsersUserId(userId: string, updateUserRequestWeb?: UpdateUserRequestWeb, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putV2UsersUserId(userId, updateUserRequestWeb, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} userId 
         * @param {UpdateUserPasswordRequestWeb} [updateUserPasswordRequestWeb] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putV2UsersUserIdPassword(userId: string, updateUserPasswordRequestWeb?: UpdateUserPasswordRequestWeb, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putV2UsersUserIdPassword(userId, updateUserPasswordRequestWeb, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UsersApi - factory interface
 * @export
 */
export const UsersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UsersApiFp(configuration)
    return {
        /**
         * 
         * @param {UsersApiDeleteV2UsersUserIdRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteV2UsersUserId(requestParameters: UsersApiDeleteV2UsersUserIdRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.deleteV2UsersUserId(requestParameters.userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UsersApiGetV2UsersPublicRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getV2UsersPublic(requestParameters: UsersApiGetV2UsersPublicRequest = {}, options?: AxiosRequestConfig): AxiosPromise<Array<PublicUserWeb>> {
            return localVarFp.getV2UsersPublic(requestParameters.userIds, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UsersApiGetV2UsersUserIdRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getV2UsersUserId(requestParameters: UsersApiGetV2UsersUserIdRequest, options?: AxiosRequestConfig): AxiosPromise<UserWeb> {
            return localVarFp.getV2UsersUserId(requestParameters.userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UsersApiPostV2UsersRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postV2Users(requestParameters: UsersApiPostV2UsersRequest = {}, options?: AxiosRequestConfig): AxiosPromise<CreateUserResponseWeb> {
            return localVarFp.postV2Users(requestParameters.createUserRequestWeb, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UsersApiPostV2UsersUserIdCompleteMobilePhoneVerificationRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postV2UsersUserIdCompleteMobilePhoneVerification(requestParameters: UsersApiPostV2UsersUserIdCompleteMobilePhoneVerificationRequest, options?: AxiosRequestConfig): AxiosPromise<CompleteMobilePhoneVerificationResponseWeb> {
            return localVarFp.postV2UsersUserIdCompleteMobilePhoneVerification(requestParameters.userId, requestParameters.completeMobilePhoneVerificationRequestWeb, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UsersApiPostV2UsersUserIdStartMobilePhoneVerificationRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postV2UsersUserIdStartMobilePhoneVerification(requestParameters: UsersApiPostV2UsersUserIdStartMobilePhoneVerificationRequest, options?: AxiosRequestConfig): AxiosPromise<StartMobilePhoneVerificationResponseWeb> {
            return localVarFp.postV2UsersUserIdStartMobilePhoneVerification(requestParameters.userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UsersApiPutV2UsersUserIdRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putV2UsersUserId(requestParameters: UsersApiPutV2UsersUserIdRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.putV2UsersUserId(requestParameters.userId, requestParameters.updateUserRequestWeb, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UsersApiPutV2UsersUserIdPasswordRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putV2UsersUserIdPassword(requestParameters: UsersApiPutV2UsersUserIdPasswordRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.putV2UsersUserIdPassword(requestParameters.userId, requestParameters.updateUserPasswordRequestWeb, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UsersApi - interface
 * @export
 * @interface UsersApi
 */
export interface UsersApiInterface {
    /**
     * 
     * @param {string} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApiInterface
     */
    deleteV2UsersUserId(userId: string, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @param {Array<string>} [userIds] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApiInterface
     */
    getV2UsersPublic(userIds?: Array<string>, options?: AxiosRequestConfig): AxiosPromise<Array<PublicUserWeb>>;

    /**
     * 
     * @param {string} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApiInterface
     */
    getV2UsersUserId(userId: string, options?: AxiosRequestConfig): AxiosPromise<UserWeb>;

    /**
     * 
     * @param {CreateUserRequestWeb} [createUserRequestWeb] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApiInterface
     */
    postV2Users(createUserRequestWeb?: CreateUserRequestWeb, options?: AxiosRequestConfig): AxiosPromise<CreateUserResponseWeb>;

    /**
     * 
     * @param {string} userId 
     * @param {CompleteMobilePhoneVerificationRequestWeb} [completeMobilePhoneVerificationRequestWeb] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApiInterface
     */
    postV2UsersUserIdCompleteMobilePhoneVerification(userId: string, completeMobilePhoneVerificationRequestWeb?: CompleteMobilePhoneVerificationRequestWeb, options?: AxiosRequestConfig): AxiosPromise<CompleteMobilePhoneVerificationResponseWeb>;

    /**
     * 
     * @param {string} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApiInterface
     */
    postV2UsersUserIdStartMobilePhoneVerification(userId: string, options?: AxiosRequestConfig): AxiosPromise<StartMobilePhoneVerificationResponseWeb>;

    /**
     * 
     * @param {string} userId 
     * @param {UpdateUserRequestWeb} [updateUserRequestWeb] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApiInterface
     */
    putV2UsersUserId(userId: string, updateUserRequestWeb?: UpdateUserRequestWeb, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @param {string} userId 
     * @param {UpdateUserPasswordRequestWeb} [updateUserPasswordRequestWeb] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApiInterface
     */
    putV2UsersUserIdPassword(userId: string, updateUserPasswordRequestWeb?: UpdateUserPasswordRequestWeb, options?: AxiosRequestConfig): AxiosPromise<void>;

}

/**
 * Request parameters for deleteV2UsersUserId operation in UsersApi.
 * @export
 * @interface UsersApiDeleteV2UsersUserIdRequest
 */
export interface UsersApiDeleteV2UsersUserIdRequest {
    /**
     * 
     * @type {string}
     * @memberof UsersApiDeleteV2UsersUserId
     */
    readonly userId: string
}

/**
 * Request parameters for getV2UsersPublic operation in UsersApi.
 * @export
 * @interface UsersApiGetV2UsersPublicRequest
 */
export interface UsersApiGetV2UsersPublicRequest {
    /**
     * 
     * @type {Array<string>}
     * @memberof UsersApiGetV2UsersPublic
     */
    readonly userIds?: Array<string>
}

/**
 * Request parameters for getV2UsersUserId operation in UsersApi.
 * @export
 * @interface UsersApiGetV2UsersUserIdRequest
 */
export interface UsersApiGetV2UsersUserIdRequest {
    /**
     * 
     * @type {string}
     * @memberof UsersApiGetV2UsersUserId
     */
    readonly userId: string
}

/**
 * Request parameters for postV2Users operation in UsersApi.
 * @export
 * @interface UsersApiPostV2UsersRequest
 */
export interface UsersApiPostV2UsersRequest {
    /**
     * 
     * @type {CreateUserRequestWeb}
     * @memberof UsersApiPostV2Users
     */
    readonly createUserRequestWeb?: CreateUserRequestWeb
}

/**
 * Request parameters for postV2UsersUserIdCompleteMobilePhoneVerification operation in UsersApi.
 * @export
 * @interface UsersApiPostV2UsersUserIdCompleteMobilePhoneVerificationRequest
 */
export interface UsersApiPostV2UsersUserIdCompleteMobilePhoneVerificationRequest {
    /**
     * 
     * @type {string}
     * @memberof UsersApiPostV2UsersUserIdCompleteMobilePhoneVerification
     */
    readonly userId: string

    /**
     * 
     * @type {CompleteMobilePhoneVerificationRequestWeb}
     * @memberof UsersApiPostV2UsersUserIdCompleteMobilePhoneVerification
     */
    readonly completeMobilePhoneVerificationRequestWeb?: CompleteMobilePhoneVerificationRequestWeb
}

/**
 * Request parameters for postV2UsersUserIdStartMobilePhoneVerification operation in UsersApi.
 * @export
 * @interface UsersApiPostV2UsersUserIdStartMobilePhoneVerificationRequest
 */
export interface UsersApiPostV2UsersUserIdStartMobilePhoneVerificationRequest {
    /**
     * 
     * @type {string}
     * @memberof UsersApiPostV2UsersUserIdStartMobilePhoneVerification
     */
    readonly userId: string
}

/**
 * Request parameters for putV2UsersUserId operation in UsersApi.
 * @export
 * @interface UsersApiPutV2UsersUserIdRequest
 */
export interface UsersApiPutV2UsersUserIdRequest {
    /**
     * 
     * @type {string}
     * @memberof UsersApiPutV2UsersUserId
     */
    readonly userId: string

    /**
     * 
     * @type {UpdateUserRequestWeb}
     * @memberof UsersApiPutV2UsersUserId
     */
    readonly updateUserRequestWeb?: UpdateUserRequestWeb
}

/**
 * Request parameters for putV2UsersUserIdPassword operation in UsersApi.
 * @export
 * @interface UsersApiPutV2UsersUserIdPasswordRequest
 */
export interface UsersApiPutV2UsersUserIdPasswordRequest {
    /**
     * 
     * @type {string}
     * @memberof UsersApiPutV2UsersUserIdPassword
     */
    readonly userId: string

    /**
     * 
     * @type {UpdateUserPasswordRequestWeb}
     * @memberof UsersApiPutV2UsersUserIdPassword
     */
    readonly updateUserPasswordRequestWeb?: UpdateUserPasswordRequestWeb
}

/**
 * UsersApi - object-oriented interface
 * @export
 * @class UsersApi
 * @extends {BaseAPI}
 */
export class UsersApi extends BaseAPI implements UsersApiInterface {
    /**
     * 
     * @param {UsersApiDeleteV2UsersUserIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public deleteV2UsersUserId(requestParameters: UsersApiDeleteV2UsersUserIdRequest, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).deleteV2UsersUserId(requestParameters.userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UsersApiGetV2UsersPublicRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public getV2UsersPublic(requestParameters: UsersApiGetV2UsersPublicRequest = {}, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).getV2UsersPublic(requestParameters.userIds, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UsersApiGetV2UsersUserIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public getV2UsersUserId(requestParameters: UsersApiGetV2UsersUserIdRequest, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).getV2UsersUserId(requestParameters.userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UsersApiPostV2UsersRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public postV2Users(requestParameters: UsersApiPostV2UsersRequest = {}, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).postV2Users(requestParameters.createUserRequestWeb, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UsersApiPostV2UsersUserIdCompleteMobilePhoneVerificationRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public postV2UsersUserIdCompleteMobilePhoneVerification(requestParameters: UsersApiPostV2UsersUserIdCompleteMobilePhoneVerificationRequest, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).postV2UsersUserIdCompleteMobilePhoneVerification(requestParameters.userId, requestParameters.completeMobilePhoneVerificationRequestWeb, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UsersApiPostV2UsersUserIdStartMobilePhoneVerificationRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public postV2UsersUserIdStartMobilePhoneVerification(requestParameters: UsersApiPostV2UsersUserIdStartMobilePhoneVerificationRequest, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).postV2UsersUserIdStartMobilePhoneVerification(requestParameters.userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UsersApiPutV2UsersUserIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public putV2UsersUserId(requestParameters: UsersApiPutV2UsersUserIdRequest, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).putV2UsersUserId(requestParameters.userId, requestParameters.updateUserRequestWeb, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UsersApiPutV2UsersUserIdPasswordRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public putV2UsersUserIdPassword(requestParameters: UsersApiPutV2UsersUserIdPasswordRequest, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).putV2UsersUserIdPassword(requestParameters.userId, requestParameters.updateUserPasswordRequestWeb, options).then((request) => request(this.axios, this.basePath));
    }
}
