import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "redux/store";

function getUiState(state: RootState) {
  return state.ui;
}

export const selectUiTheme = createSelector([getUiState], (uiState) => {
  return uiState.theme;
});
