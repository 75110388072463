import { LinearProgress } from "@mui/material";
import styled from "styled-components";

const ActiveWorkersTrayProgress = styled(LinearProgress)`
  width: 100%;
  margin-top: 0.5rem;

  .MuiLinearProgress-bar {
    background-color: ${({ theme }) => theme.palette.text.text1};
  }
`;

export default ActiveWorkersTrayProgress;
