// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Operation Services API
 * Operation Services API
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { Level } from '../model';
// @ts-ignore
import { LevelIEnumerableScaleODataValue } from '../model';
// @ts-ignore
import { LevelsPostMiddleLevelParameters } from '../model';
// @ts-ignore
import { LevelsPostTreeParameters } from '../model';
/**
 * LevelsApi - axios parameter creator
 * @export
 */
export const LevelsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} [$top] The max number of records.
         * @param {string} [$apply] Allows to specify a sequence of transformations to the entity set, such as groupby, filter, aggregate, etc.
         * @param {number} [$skip] The number of records to skip.
         * @param {string} [$filter] A function that must evaluate to true for a record to be returned.
         * @param {string} [$select] Specifies a subset of properties to return. Use a comma separated list.
         * @param {string} [$orderby] Determines what values are used to order a collection of records.
         * @param {string} [$expand] Use to add related query data.
         * @param {boolean} [$count] Allows clients to request a count of the matching resources included with the resources in the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiV2Levels: async ($top?: number, $apply?: string, $skip?: number, $filter?: string, $select?: string, $orderby?: string, $expand?: string, $count?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v2/Levels`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if ($top !== undefined) {
                localVarQueryParameter['$top'] = $top;
            }

            if ($apply !== undefined) {
                localVarQueryParameter['$apply'] = $apply;
            }

            if ($skip !== undefined) {
                localVarQueryParameter['$skip'] = $skip;
            }

            if ($filter !== undefined) {
                localVarQueryParameter['$filter'] = $filter;
            }

            if ($select !== undefined) {
                localVarQueryParameter['$select'] = $select;
            }

            if ($orderby !== undefined) {
                localVarQueryParameter['$orderby'] = $orderby;
            }

            if ($expand !== undefined) {
                localVarQueryParameter['$expand'] = $expand;
            }

            if ($count !== undefined) {
                localVarQueryParameter['$count'] = $count;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [$top] The max number of records.
         * @param {string} [$apply] Allows to specify a sequence of transformations to the entity set, such as groupby, filter, aggregate, etc.
         * @param {number} [$skip] The number of records to skip.
         * @param {string} [$filter] A function that must evaluate to true for a record to be returned.
         * @param {string} [$select] Specifies a subset of properties to return. Use a comma separated list.
         * @param {string} [$orderby] Determines what values are used to order a collection of records.
         * @param {string} [$expand] Use to add related query data.
         * @param {boolean} [$count] Allows clients to request a count of the matching resources included with the resources in the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiV2LevelsCount: async ($top?: number, $apply?: string, $skip?: number, $filter?: string, $select?: string, $orderby?: string, $expand?: string, $count?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v2/Levels/$count`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if ($top !== undefined) {
                localVarQueryParameter['$top'] = $top;
            }

            if ($apply !== undefined) {
                localVarQueryParameter['$apply'] = $apply;
            }

            if ($skip !== undefined) {
                localVarQueryParameter['$skip'] = $skip;
            }

            if ($filter !== undefined) {
                localVarQueryParameter['$filter'] = $filter;
            }

            if ($select !== undefined) {
                localVarQueryParameter['$select'] = $select;
            }

            if ($orderby !== undefined) {
                localVarQueryParameter['$orderby'] = $orderby;
            }

            if ($expand !== undefined) {
                localVarQueryParameter['$expand'] = $expand;
            }

            if ($count !== undefined) {
                localVarQueryParameter['$count'] = $count;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} companyId 
         * @param {number} [$top] The max number of records.
         * @param {string} [$apply] Allows to specify a sequence of transformations to the entity set, such as groupby, filter, aggregate, etc.
         * @param {number} [$skip] The number of records to skip.
         * @param {string} [$filter] A function that must evaluate to true for a record to be returned.
         * @param {string} [$select] Specifies a subset of properties to return. Use a comma separated list.
         * @param {string} [$orderby] Determines what values are used to order a collection of records.
         * @param {string} [$expand] Use to add related query data.
         * @param {boolean} [$count] Allows clients to request a count of the matching resources included with the resources in the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiV2LevelsGetAllForCompanyCompanyIdCompanyId: async (companyId: string, $top?: number, $apply?: string, $skip?: number, $filter?: string, $select?: string, $orderby?: string, $expand?: string, $count?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('getApiV2LevelsGetAllForCompanyCompanyIdCompanyId', 'companyId', companyId)
            const localVarPath = `/api/v2/Levels/GetAllForCompany(companyId={companyId})`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if ($top !== undefined) {
                localVarQueryParameter['$top'] = $top;
            }

            if ($apply !== undefined) {
                localVarQueryParameter['$apply'] = $apply;
            }

            if ($skip !== undefined) {
                localVarQueryParameter['$skip'] = $skip;
            }

            if ($filter !== undefined) {
                localVarQueryParameter['$filter'] = $filter;
            }

            if ($select !== undefined) {
                localVarQueryParameter['$select'] = $select;
            }

            if ($orderby !== undefined) {
                localVarQueryParameter['$orderby'] = $orderby;
            }

            if ($expand !== undefined) {
                localVarQueryParameter['$expand'] = $expand;
            }

            if ($count !== undefined) {
                localVarQueryParameter['$count'] = $count;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} key 
         * @param {number} [$top] The max number of records.
         * @param {string} [$apply] Allows to specify a sequence of transformations to the entity set, such as groupby, filter, aggregate, etc.
         * @param {number} [$skip] The number of records to skip.
         * @param {string} [$filter] A function that must evaluate to true for a record to be returned.
         * @param {string} [$select] Specifies a subset of properties to return. Use a comma separated list.
         * @param {string} [$orderby] Determines what values are used to order a collection of records.
         * @param {string} [$expand] Use to add related query data.
         * @param {boolean} [$count] Allows clients to request a count of the matching resources included with the resources in the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiV2LevelsKey: async (key: number, $top?: number, $apply?: string, $skip?: number, $filter?: string, $select?: string, $orderby?: string, $expand?: string, $count?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'key' is not null or undefined
            assertParamExists('getApiV2LevelsKey', 'key', key)
            const localVarPath = `/api/v2/Levels({key})`
                .replace(`{${"key"}}`, encodeURIComponent(String(key)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if ($top !== undefined) {
                localVarQueryParameter['$top'] = $top;
            }

            if ($apply !== undefined) {
                localVarQueryParameter['$apply'] = $apply;
            }

            if ($skip !== undefined) {
                localVarQueryParameter['$skip'] = $skip;
            }

            if ($filter !== undefined) {
                localVarQueryParameter['$filter'] = $filter;
            }

            if ($select !== undefined) {
                localVarQueryParameter['$select'] = $select;
            }

            if ($orderby !== undefined) {
                localVarQueryParameter['$orderby'] = $orderby;
            }

            if ($expand !== undefined) {
                localVarQueryParameter['$expand'] = $expand;
            }

            if ($count !== undefined) {
                localVarQueryParameter['$count'] = $count;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} key 
         * @param {number} [$top] The max number of records.
         * @param {string} [$apply] Allows to specify a sequence of transformations to the entity set, such as groupby, filter, aggregate, etc.
         * @param {number} [$skip] The number of records to skip.
         * @param {string} [$filter] A function that must evaluate to true for a record to be returned.
         * @param {string} [$select] Specifies a subset of properties to return. Use a comma separated list.
         * @param {string} [$orderby] Determines what values are used to order a collection of records.
         * @param {string} [$expand] Use to add related query data.
         * @param {boolean} [$count] Allows clients to request a count of the matching resources included with the resources in the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiV2LevelsKey_1: async (key: number, $top?: number, $apply?: string, $skip?: number, $filter?: string, $select?: string, $orderby?: string, $expand?: string, $count?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'key' is not null or undefined
            assertParamExists('getApiV2LevelsKey_1', 'key', key)
            const localVarPath = `/api/v2/Levels/{key}`
                .replace(`{${"key"}}`, encodeURIComponent(String(key)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if ($top !== undefined) {
                localVarQueryParameter['$top'] = $top;
            }

            if ($apply !== undefined) {
                localVarQueryParameter['$apply'] = $apply;
            }

            if ($skip !== undefined) {
                localVarQueryParameter['$skip'] = $skip;
            }

            if ($filter !== undefined) {
                localVarQueryParameter['$filter'] = $filter;
            }

            if ($select !== undefined) {
                localVarQueryParameter['$select'] = $select;
            }

            if ($orderby !== undefined) {
                localVarQueryParameter['$orderby'] = $orderby;
            }

            if ($expand !== undefined) {
                localVarQueryParameter['$expand'] = $expand;
            }

            if ($count !== undefined) {
                localVarQueryParameter['$count'] = $count;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [$top] The max number of records.
         * @param {string} [$apply] Allows to specify a sequence of transformations to the entity set, such as groupby, filter, aggregate, etc.
         * @param {number} [$skip] The number of records to skip.
         * @param {string} [$filter] A function that must evaluate to true for a record to be returned.
         * @param {string} [$select] Specifies a subset of properties to return. Use a comma separated list.
         * @param {string} [$orderby] Determines what values are used to order a collection of records.
         * @param {string} [$expand] Use to add related query data.
         * @param {boolean} [$count] Allows clients to request a count of the matching resources included with the resources in the response.
         * @param {Level} [level] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        postApiV2Levels: async ($top?: number, $apply?: string, $skip?: number, $filter?: string, $select?: string, $orderby?: string, $expand?: string, $count?: boolean, level?: Level, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v2/Levels`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if ($top !== undefined) {
                localVarQueryParameter['$top'] = $top;
            }

            if ($apply !== undefined) {
                localVarQueryParameter['$apply'] = $apply;
            }

            if ($skip !== undefined) {
                localVarQueryParameter['$skip'] = $skip;
            }

            if ($filter !== undefined) {
                localVarQueryParameter['$filter'] = $filter;
            }

            if ($select !== undefined) {
                localVarQueryParameter['$select'] = $select;
            }

            if ($orderby !== undefined) {
                localVarQueryParameter['$orderby'] = $orderby;
            }

            if ($expand !== undefined) {
                localVarQueryParameter['$expand'] = $expand;
            }

            if ($count !== undefined) {
                localVarQueryParameter['$count'] = $count;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json;odata.metadata=minimal;odata.streaming=true';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(level, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} key 
         * @param {string} [select] 
         * @param {string} [expand] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        postApiV2LevelsKeyDeleteAndDescendants: async (key: number, select?: string, expand?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'key' is not null or undefined
            assertParamExists('postApiV2LevelsKeyDeleteAndDescendants', 'key', key)
            const localVarPath = `/api/v2/Levels({key})/DeleteAndDescendants`
                .replace(`{${"key"}}`, encodeURIComponent(String(key)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (select !== undefined) {
                localVarQueryParameter['select'] = select;
            }

            if (expand !== undefined) {
                localVarQueryParameter['expand'] = expand;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} key 
         * @param {string} [select] 
         * @param {string} [expand] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        postApiV2LevelsKeyDeleteAndDescendants_2: async (key: number, select?: string, expand?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'key' is not null or undefined
            assertParamExists('postApiV2LevelsKeyDeleteAndDescendants_2', 'key', key)
            const localVarPath = `/api/v2/Levels/{key}/DeleteAndDescendants`
                .replace(`{${"key"}}`, encodeURIComponent(String(key)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (select !== undefined) {
                localVarQueryParameter['select'] = select;
            }

            if (expand !== undefined) {
                localVarQueryParameter['expand'] = expand;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} key 
         * @param {string} [select] 
         * @param {string} [expand] 
         * @param {LevelsPostMiddleLevelParameters} [levelsPostMiddleLevelParameters] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        postApiV2LevelsKeyPostMiddleLevel: async (key: number, select?: string, expand?: string, levelsPostMiddleLevelParameters?: LevelsPostMiddleLevelParameters, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'key' is not null or undefined
            assertParamExists('postApiV2LevelsKeyPostMiddleLevel', 'key', key)
            const localVarPath = `/api/v2/Levels({key})/PostMiddleLevel`
                .replace(`{${"key"}}`, encodeURIComponent(String(key)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (select !== undefined) {
                localVarQueryParameter['select'] = select;
            }

            if (expand !== undefined) {
                localVarQueryParameter['expand'] = expand;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json;odata.metadata=minimal;odata.streaming=true';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(levelsPostMiddleLevelParameters, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} key 
         * @param {string} [select] 
         * @param {string} [expand] 
         * @param {LevelsPostMiddleLevelParameters} [levelsPostMiddleLevelParameters] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        postApiV2LevelsKeyPostMiddleLevel_3: async (key: number, select?: string, expand?: string, levelsPostMiddleLevelParameters?: LevelsPostMiddleLevelParameters, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'key' is not null or undefined
            assertParamExists('postApiV2LevelsKeyPostMiddleLevel_3', 'key', key)
            const localVarPath = `/api/v2/Levels/{key}/PostMiddleLevel`
                .replace(`{${"key"}}`, encodeURIComponent(String(key)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (select !== undefined) {
                localVarQueryParameter['select'] = select;
            }

            if (expand !== undefined) {
                localVarQueryParameter['expand'] = expand;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json;odata.metadata=minimal;odata.streaming=true';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(levelsPostMiddleLevelParameters, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [select] 
         * @param {string} [expand] 
         * @param {LevelsPostTreeParameters} [levelsPostTreeParameters] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postApiV2LevelsPostTree: async (select?: string, expand?: string, levelsPostTreeParameters?: LevelsPostTreeParameters, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v2/Levels/PostTree`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (select !== undefined) {
                localVarQueryParameter['select'] = select;
            }

            if (expand !== undefined) {
                localVarQueryParameter['expand'] = expand;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json;odata.metadata=minimal;odata.streaming=true';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(levelsPostTreeParameters, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} key 
         * @param {number} [$top] The max number of records.
         * @param {string} [$apply] Allows to specify a sequence of transformations to the entity set, such as groupby, filter, aggregate, etc.
         * @param {number} [$skip] The number of records to skip.
         * @param {string} [$filter] A function that must evaluate to true for a record to be returned.
         * @param {string} [$select] Specifies a subset of properties to return. Use a comma separated list.
         * @param {string} [$orderby] Determines what values are used to order a collection of records.
         * @param {string} [$expand] Use to add related query data.
         * @param {boolean} [$count] Allows clients to request a count of the matching resources included with the resources in the response.
         * @param {Level} [level] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        putApiV2LevelsKey: async (key: number, $top?: number, $apply?: string, $skip?: number, $filter?: string, $select?: string, $orderby?: string, $expand?: string, $count?: boolean, level?: Level, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'key' is not null or undefined
            assertParamExists('putApiV2LevelsKey', 'key', key)
            const localVarPath = `/api/v2/Levels({key})`
                .replace(`{${"key"}}`, encodeURIComponent(String(key)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if ($top !== undefined) {
                localVarQueryParameter['$top'] = $top;
            }

            if ($apply !== undefined) {
                localVarQueryParameter['$apply'] = $apply;
            }

            if ($skip !== undefined) {
                localVarQueryParameter['$skip'] = $skip;
            }

            if ($filter !== undefined) {
                localVarQueryParameter['$filter'] = $filter;
            }

            if ($select !== undefined) {
                localVarQueryParameter['$select'] = $select;
            }

            if ($orderby !== undefined) {
                localVarQueryParameter['$orderby'] = $orderby;
            }

            if ($expand !== undefined) {
                localVarQueryParameter['$expand'] = $expand;
            }

            if ($count !== undefined) {
                localVarQueryParameter['$count'] = $count;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json;odata.metadata=minimal;odata.streaming=true';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(level, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} key 
         * @param {number} [$top] The max number of records.
         * @param {string} [$apply] Allows to specify a sequence of transformations to the entity set, such as groupby, filter, aggregate, etc.
         * @param {number} [$skip] The number of records to skip.
         * @param {string} [$filter] A function that must evaluate to true for a record to be returned.
         * @param {string} [$select] Specifies a subset of properties to return. Use a comma separated list.
         * @param {string} [$orderby] Determines what values are used to order a collection of records.
         * @param {string} [$expand] Use to add related query data.
         * @param {boolean} [$count] Allows clients to request a count of the matching resources included with the resources in the response.
         * @param {Level} [level] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        putApiV2LevelsKey_4: async (key: number, $top?: number, $apply?: string, $skip?: number, $filter?: string, $select?: string, $orderby?: string, $expand?: string, $count?: boolean, level?: Level, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'key' is not null or undefined
            assertParamExists('putApiV2LevelsKey_4', 'key', key)
            const localVarPath = `/api/v2/Levels/{key}`
                .replace(`{${"key"}}`, encodeURIComponent(String(key)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if ($top !== undefined) {
                localVarQueryParameter['$top'] = $top;
            }

            if ($apply !== undefined) {
                localVarQueryParameter['$apply'] = $apply;
            }

            if ($skip !== undefined) {
                localVarQueryParameter['$skip'] = $skip;
            }

            if ($filter !== undefined) {
                localVarQueryParameter['$filter'] = $filter;
            }

            if ($select !== undefined) {
                localVarQueryParameter['$select'] = $select;
            }

            if ($orderby !== undefined) {
                localVarQueryParameter['$orderby'] = $orderby;
            }

            if ($expand !== undefined) {
                localVarQueryParameter['$expand'] = $expand;
            }

            if ($count !== undefined) {
                localVarQueryParameter['$count'] = $count;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json;odata.metadata=minimal;odata.streaming=true';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(level, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * LevelsApi - functional programming interface
 * @export
 */
export const LevelsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = LevelsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} [$top] The max number of records.
         * @param {string} [$apply] Allows to specify a sequence of transformations to the entity set, such as groupby, filter, aggregate, etc.
         * @param {number} [$skip] The number of records to skip.
         * @param {string} [$filter] A function that must evaluate to true for a record to be returned.
         * @param {string} [$select] Specifies a subset of properties to return. Use a comma separated list.
         * @param {string} [$orderby] Determines what values are used to order a collection of records.
         * @param {string} [$expand] Use to add related query data.
         * @param {boolean} [$count] Allows clients to request a count of the matching resources included with the resources in the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getApiV2Levels($top?: number, $apply?: string, $skip?: number, $filter?: string, $select?: string, $orderby?: string, $expand?: string, $count?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LevelIEnumerableScaleODataValue>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getApiV2Levels($top, $apply, $skip, $filter, $select, $orderby, $expand, $count, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [$top] The max number of records.
         * @param {string} [$apply] Allows to specify a sequence of transformations to the entity set, such as groupby, filter, aggregate, etc.
         * @param {number} [$skip] The number of records to skip.
         * @param {string} [$filter] A function that must evaluate to true for a record to be returned.
         * @param {string} [$select] Specifies a subset of properties to return. Use a comma separated list.
         * @param {string} [$orderby] Determines what values are used to order a collection of records.
         * @param {string} [$expand] Use to add related query data.
         * @param {boolean} [$count] Allows clients to request a count of the matching resources included with the resources in the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getApiV2LevelsCount($top?: number, $apply?: string, $skip?: number, $filter?: string, $select?: string, $orderby?: string, $expand?: string, $count?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LevelIEnumerableScaleODataValue>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getApiV2LevelsCount($top, $apply, $skip, $filter, $select, $orderby, $expand, $count, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} companyId 
         * @param {number} [$top] The max number of records.
         * @param {string} [$apply] Allows to specify a sequence of transformations to the entity set, such as groupby, filter, aggregate, etc.
         * @param {number} [$skip] The number of records to skip.
         * @param {string} [$filter] A function that must evaluate to true for a record to be returned.
         * @param {string} [$select] Specifies a subset of properties to return. Use a comma separated list.
         * @param {string} [$orderby] Determines what values are used to order a collection of records.
         * @param {string} [$expand] Use to add related query data.
         * @param {boolean} [$count] Allows clients to request a count of the matching resources included with the resources in the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getApiV2LevelsGetAllForCompanyCompanyIdCompanyId(companyId: string, $top?: number, $apply?: string, $skip?: number, $filter?: string, $select?: string, $orderby?: string, $expand?: string, $count?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LevelIEnumerableScaleODataValue>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getApiV2LevelsGetAllForCompanyCompanyIdCompanyId(companyId, $top, $apply, $skip, $filter, $select, $orderby, $expand, $count, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} key 
         * @param {number} [$top] The max number of records.
         * @param {string} [$apply] Allows to specify a sequence of transformations to the entity set, such as groupby, filter, aggregate, etc.
         * @param {number} [$skip] The number of records to skip.
         * @param {string} [$filter] A function that must evaluate to true for a record to be returned.
         * @param {string} [$select] Specifies a subset of properties to return. Use a comma separated list.
         * @param {string} [$orderby] Determines what values are used to order a collection of records.
         * @param {string} [$expand] Use to add related query data.
         * @param {boolean} [$count] Allows clients to request a count of the matching resources included with the resources in the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getApiV2LevelsKey(key: number, $top?: number, $apply?: string, $skip?: number, $filter?: string, $select?: string, $orderby?: string, $expand?: string, $count?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Level>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getApiV2LevelsKey(key, $top, $apply, $skip, $filter, $select, $orderby, $expand, $count, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} key 
         * @param {number} [$top] The max number of records.
         * @param {string} [$apply] Allows to specify a sequence of transformations to the entity set, such as groupby, filter, aggregate, etc.
         * @param {number} [$skip] The number of records to skip.
         * @param {string} [$filter] A function that must evaluate to true for a record to be returned.
         * @param {string} [$select] Specifies a subset of properties to return. Use a comma separated list.
         * @param {string} [$orderby] Determines what values are used to order a collection of records.
         * @param {string} [$expand] Use to add related query data.
         * @param {boolean} [$count] Allows clients to request a count of the matching resources included with the resources in the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getApiV2LevelsKey_1(key: number, $top?: number, $apply?: string, $skip?: number, $filter?: string, $select?: string, $orderby?: string, $expand?: string, $count?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Level>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getApiV2LevelsKey_1(key, $top, $apply, $skip, $filter, $select, $orderby, $expand, $count, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [$top] The max number of records.
         * @param {string} [$apply] Allows to specify a sequence of transformations to the entity set, such as groupby, filter, aggregate, etc.
         * @param {number} [$skip] The number of records to skip.
         * @param {string} [$filter] A function that must evaluate to true for a record to be returned.
         * @param {string} [$select] Specifies a subset of properties to return. Use a comma separated list.
         * @param {string} [$orderby] Determines what values are used to order a collection of records.
         * @param {string} [$expand] Use to add related query data.
         * @param {boolean} [$count] Allows clients to request a count of the matching resources included with the resources in the response.
         * @param {Level} [level] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async postApiV2Levels($top?: number, $apply?: string, $skip?: number, $filter?: string, $select?: string, $orderby?: string, $expand?: string, $count?: boolean, level?: Level, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Level>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postApiV2Levels($top, $apply, $skip, $filter, $select, $orderby, $expand, $count, level, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} key 
         * @param {string} [select] 
         * @param {string} [expand] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async postApiV2LevelsKeyDeleteAndDescendants(key: number, select?: string, expand?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postApiV2LevelsKeyDeleteAndDescendants(key, select, expand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} key 
         * @param {string} [select] 
         * @param {string} [expand] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async postApiV2LevelsKeyDeleteAndDescendants_2(key: number, select?: string, expand?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postApiV2LevelsKeyDeleteAndDescendants_2(key, select, expand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} key 
         * @param {string} [select] 
         * @param {string} [expand] 
         * @param {LevelsPostMiddleLevelParameters} [levelsPostMiddleLevelParameters] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async postApiV2LevelsKeyPostMiddleLevel(key: number, select?: string, expand?: string, levelsPostMiddleLevelParameters?: LevelsPostMiddleLevelParameters, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Level>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postApiV2LevelsKeyPostMiddleLevel(key, select, expand, levelsPostMiddleLevelParameters, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} key 
         * @param {string} [select] 
         * @param {string} [expand] 
         * @param {LevelsPostMiddleLevelParameters} [levelsPostMiddleLevelParameters] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async postApiV2LevelsKeyPostMiddleLevel_3(key: number, select?: string, expand?: string, levelsPostMiddleLevelParameters?: LevelsPostMiddleLevelParameters, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Level>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postApiV2LevelsKeyPostMiddleLevel_3(key, select, expand, levelsPostMiddleLevelParameters, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [select] 
         * @param {string} [expand] 
         * @param {LevelsPostTreeParameters} [levelsPostTreeParameters] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postApiV2LevelsPostTree(select?: string, expand?: string, levelsPostTreeParameters?: LevelsPostTreeParameters, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postApiV2LevelsPostTree(select, expand, levelsPostTreeParameters, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} key 
         * @param {number} [$top] The max number of records.
         * @param {string} [$apply] Allows to specify a sequence of transformations to the entity set, such as groupby, filter, aggregate, etc.
         * @param {number} [$skip] The number of records to skip.
         * @param {string} [$filter] A function that must evaluate to true for a record to be returned.
         * @param {string} [$select] Specifies a subset of properties to return. Use a comma separated list.
         * @param {string} [$orderby] Determines what values are used to order a collection of records.
         * @param {string} [$expand] Use to add related query data.
         * @param {boolean} [$count] Allows clients to request a count of the matching resources included with the resources in the response.
         * @param {Level} [level] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async putApiV2LevelsKey(key: number, $top?: number, $apply?: string, $skip?: number, $filter?: string, $select?: string, $orderby?: string, $expand?: string, $count?: boolean, level?: Level, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Level>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putApiV2LevelsKey(key, $top, $apply, $skip, $filter, $select, $orderby, $expand, $count, level, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} key 
         * @param {number} [$top] The max number of records.
         * @param {string} [$apply] Allows to specify a sequence of transformations to the entity set, such as groupby, filter, aggregate, etc.
         * @param {number} [$skip] The number of records to skip.
         * @param {string} [$filter] A function that must evaluate to true for a record to be returned.
         * @param {string} [$select] Specifies a subset of properties to return. Use a comma separated list.
         * @param {string} [$orderby] Determines what values are used to order a collection of records.
         * @param {string} [$expand] Use to add related query data.
         * @param {boolean} [$count] Allows clients to request a count of the matching resources included with the resources in the response.
         * @param {Level} [level] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async putApiV2LevelsKey_4(key: number, $top?: number, $apply?: string, $skip?: number, $filter?: string, $select?: string, $orderby?: string, $expand?: string, $count?: boolean, level?: Level, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Level>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putApiV2LevelsKey_4(key, $top, $apply, $skip, $filter, $select, $orderby, $expand, $count, level, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * LevelsApi - factory interface
 * @export
 */
export const LevelsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = LevelsApiFp(configuration)
    return {
        /**
         * 
         * @param {LevelsApiGetApiV2LevelsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiV2Levels(requestParameters: LevelsApiGetApiV2LevelsRequest = {}, options?: AxiosRequestConfig): AxiosPromise<LevelIEnumerableScaleODataValue> {
            return localVarFp.getApiV2Levels(requestParameters.$top, requestParameters.$apply, requestParameters.$skip, requestParameters.$filter, requestParameters.$select, requestParameters.$orderby, requestParameters.$expand, requestParameters.$count, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {LevelsApiGetApiV2LevelsCountRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiV2LevelsCount(requestParameters: LevelsApiGetApiV2LevelsCountRequest = {}, options?: AxiosRequestConfig): AxiosPromise<LevelIEnumerableScaleODataValue> {
            return localVarFp.getApiV2LevelsCount(requestParameters.$top, requestParameters.$apply, requestParameters.$skip, requestParameters.$filter, requestParameters.$select, requestParameters.$orderby, requestParameters.$expand, requestParameters.$count, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {LevelsApiGetApiV2LevelsGetAllForCompanyCompanyIdCompanyIdRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiV2LevelsGetAllForCompanyCompanyIdCompanyId(requestParameters: LevelsApiGetApiV2LevelsGetAllForCompanyCompanyIdCompanyIdRequest, options?: AxiosRequestConfig): AxiosPromise<LevelIEnumerableScaleODataValue> {
            return localVarFp.getApiV2LevelsGetAllForCompanyCompanyIdCompanyId(requestParameters.companyId, requestParameters.$top, requestParameters.$apply, requestParameters.$skip, requestParameters.$filter, requestParameters.$select, requestParameters.$orderby, requestParameters.$expand, requestParameters.$count, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {LevelsApiGetApiV2LevelsKeyRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiV2LevelsKey(requestParameters: LevelsApiGetApiV2LevelsKeyRequest, options?: AxiosRequestConfig): AxiosPromise<Level> {
            return localVarFp.getApiV2LevelsKey(requestParameters.key, requestParameters.$top, requestParameters.$apply, requestParameters.$skip, requestParameters.$filter, requestParameters.$select, requestParameters.$orderby, requestParameters.$expand, requestParameters.$count, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {LevelsApiGetApiV2LevelsKey0Request} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiV2LevelsKey_1(requestParameters: LevelsApiGetApiV2LevelsKey0Request, options?: AxiosRequestConfig): AxiosPromise<Level> {
            return localVarFp.getApiV2LevelsKey_1(requestParameters.key, requestParameters.$top, requestParameters.$apply, requestParameters.$skip, requestParameters.$filter, requestParameters.$select, requestParameters.$orderby, requestParameters.$expand, requestParameters.$count, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {LevelsApiPostApiV2LevelsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        postApiV2Levels(requestParameters: LevelsApiPostApiV2LevelsRequest = {}, options?: AxiosRequestConfig): AxiosPromise<Level> {
            return localVarFp.postApiV2Levels(requestParameters.$top, requestParameters.$apply, requestParameters.$skip, requestParameters.$filter, requestParameters.$select, requestParameters.$orderby, requestParameters.$expand, requestParameters.$count, requestParameters.level, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {LevelsApiPostApiV2LevelsKeyDeleteAndDescendantsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        postApiV2LevelsKeyDeleteAndDescendants(requestParameters: LevelsApiPostApiV2LevelsKeyDeleteAndDescendantsRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.postApiV2LevelsKeyDeleteAndDescendants(requestParameters.key, requestParameters.select, requestParameters.expand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {LevelsApiPostApiV2LevelsKeyDeleteAndDescendants0Request} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        postApiV2LevelsKeyDeleteAndDescendants_2(requestParameters: LevelsApiPostApiV2LevelsKeyDeleteAndDescendants0Request, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.postApiV2LevelsKeyDeleteAndDescendants_2(requestParameters.key, requestParameters.select, requestParameters.expand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {LevelsApiPostApiV2LevelsKeyPostMiddleLevelRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        postApiV2LevelsKeyPostMiddleLevel(requestParameters: LevelsApiPostApiV2LevelsKeyPostMiddleLevelRequest, options?: AxiosRequestConfig): AxiosPromise<Level> {
            return localVarFp.postApiV2LevelsKeyPostMiddleLevel(requestParameters.key, requestParameters.select, requestParameters.expand, requestParameters.levelsPostMiddleLevelParameters, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {LevelsApiPostApiV2LevelsKeyPostMiddleLevel0Request} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        postApiV2LevelsKeyPostMiddleLevel_3(requestParameters: LevelsApiPostApiV2LevelsKeyPostMiddleLevel0Request, options?: AxiosRequestConfig): AxiosPromise<Level> {
            return localVarFp.postApiV2LevelsKeyPostMiddleLevel_3(requestParameters.key, requestParameters.select, requestParameters.expand, requestParameters.levelsPostMiddleLevelParameters, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {LevelsApiPostApiV2LevelsPostTreeRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postApiV2LevelsPostTree(requestParameters: LevelsApiPostApiV2LevelsPostTreeRequest = {}, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.postApiV2LevelsPostTree(requestParameters.select, requestParameters.expand, requestParameters.levelsPostTreeParameters, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {LevelsApiPutApiV2LevelsKeyRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        putApiV2LevelsKey(requestParameters: LevelsApiPutApiV2LevelsKeyRequest, options?: AxiosRequestConfig): AxiosPromise<Level> {
            return localVarFp.putApiV2LevelsKey(requestParameters.key, requestParameters.$top, requestParameters.$apply, requestParameters.$skip, requestParameters.$filter, requestParameters.$select, requestParameters.$orderby, requestParameters.$expand, requestParameters.$count, requestParameters.level, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {LevelsApiPutApiV2LevelsKey0Request} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        putApiV2LevelsKey_4(requestParameters: LevelsApiPutApiV2LevelsKey0Request, options?: AxiosRequestConfig): AxiosPromise<Level> {
            return localVarFp.putApiV2LevelsKey_4(requestParameters.key, requestParameters.$top, requestParameters.$apply, requestParameters.$skip, requestParameters.$filter, requestParameters.$select, requestParameters.$orderby, requestParameters.$expand, requestParameters.$count, requestParameters.level, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * LevelsApi - interface
 * @export
 * @interface LevelsApi
 */
export interface LevelsApiInterface {
    /**
     * 
     * @param {number} [$top] The max number of records.
     * @param {string} [$apply] Allows to specify a sequence of transformations to the entity set, such as groupby, filter, aggregate, etc.
     * @param {number} [$skip] The number of records to skip.
     * @param {string} [$filter] A function that must evaluate to true for a record to be returned.
     * @param {string} [$select] Specifies a subset of properties to return. Use a comma separated list.
     * @param {string} [$orderby] Determines what values are used to order a collection of records.
     * @param {string} [$expand] Use to add related query data.
     * @param {boolean} [$count] Allows clients to request a count of the matching resources included with the resources in the response.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LevelsApiInterface
     */
    getApiV2Levels($top?: number, $apply?: string, $skip?: number, $filter?: string, $select?: string, $orderby?: string, $expand?: string, $count?: boolean, options?: AxiosRequestConfig): AxiosPromise<LevelIEnumerableScaleODataValue>;

    /**
     * 
     * @param {number} [$top] The max number of records.
     * @param {string} [$apply] Allows to specify a sequence of transformations to the entity set, such as groupby, filter, aggregate, etc.
     * @param {number} [$skip] The number of records to skip.
     * @param {string} [$filter] A function that must evaluate to true for a record to be returned.
     * @param {string} [$select] Specifies a subset of properties to return. Use a comma separated list.
     * @param {string} [$orderby] Determines what values are used to order a collection of records.
     * @param {string} [$expand] Use to add related query data.
     * @param {boolean} [$count] Allows clients to request a count of the matching resources included with the resources in the response.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LevelsApiInterface
     */
    getApiV2LevelsCount($top?: number, $apply?: string, $skip?: number, $filter?: string, $select?: string, $orderby?: string, $expand?: string, $count?: boolean, options?: AxiosRequestConfig): AxiosPromise<LevelIEnumerableScaleODataValue>;

    /**
     * 
     * @param {string} companyId 
     * @param {number} [$top] The max number of records.
     * @param {string} [$apply] Allows to specify a sequence of transformations to the entity set, such as groupby, filter, aggregate, etc.
     * @param {number} [$skip] The number of records to skip.
     * @param {string} [$filter] A function that must evaluate to true for a record to be returned.
     * @param {string} [$select] Specifies a subset of properties to return. Use a comma separated list.
     * @param {string} [$orderby] Determines what values are used to order a collection of records.
     * @param {string} [$expand] Use to add related query data.
     * @param {boolean} [$count] Allows clients to request a count of the matching resources included with the resources in the response.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LevelsApiInterface
     */
    getApiV2LevelsGetAllForCompanyCompanyIdCompanyId(companyId: string, $top?: number, $apply?: string, $skip?: number, $filter?: string, $select?: string, $orderby?: string, $expand?: string, $count?: boolean, options?: AxiosRequestConfig): AxiosPromise<LevelIEnumerableScaleODataValue>;

    /**
     * 
     * @param {number} key 
     * @param {number} [$top] The max number of records.
     * @param {string} [$apply] Allows to specify a sequence of transformations to the entity set, such as groupby, filter, aggregate, etc.
     * @param {number} [$skip] The number of records to skip.
     * @param {string} [$filter] A function that must evaluate to true for a record to be returned.
     * @param {string} [$select] Specifies a subset of properties to return. Use a comma separated list.
     * @param {string} [$orderby] Determines what values are used to order a collection of records.
     * @param {string} [$expand] Use to add related query data.
     * @param {boolean} [$count] Allows clients to request a count of the matching resources included with the resources in the response.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LevelsApiInterface
     */
    getApiV2LevelsKey(key: number, $top?: number, $apply?: string, $skip?: number, $filter?: string, $select?: string, $orderby?: string, $expand?: string, $count?: boolean, options?: AxiosRequestConfig): AxiosPromise<Level>;

    /**
     * 
     * @param {number} key 
     * @param {number} [$top] The max number of records.
     * @param {string} [$apply] Allows to specify a sequence of transformations to the entity set, such as groupby, filter, aggregate, etc.
     * @param {number} [$skip] The number of records to skip.
     * @param {string} [$filter] A function that must evaluate to true for a record to be returned.
     * @param {string} [$select] Specifies a subset of properties to return. Use a comma separated list.
     * @param {string} [$orderby] Determines what values are used to order a collection of records.
     * @param {string} [$expand] Use to add related query data.
     * @param {boolean} [$count] Allows clients to request a count of the matching resources included with the resources in the response.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LevelsApiInterface
     */
    getApiV2LevelsKey_1(key: number, $top?: number, $apply?: string, $skip?: number, $filter?: string, $select?: string, $orderby?: string, $expand?: string, $count?: boolean, options?: AxiosRequestConfig): AxiosPromise<Level>;

    /**
     * 
     * @param {number} [$top] The max number of records.
     * @param {string} [$apply] Allows to specify a sequence of transformations to the entity set, such as groupby, filter, aggregate, etc.
     * @param {number} [$skip] The number of records to skip.
     * @param {string} [$filter] A function that must evaluate to true for a record to be returned.
     * @param {string} [$select] Specifies a subset of properties to return. Use a comma separated list.
     * @param {string} [$orderby] Determines what values are used to order a collection of records.
     * @param {string} [$expand] Use to add related query data.
     * @param {boolean} [$count] Allows clients to request a count of the matching resources included with the resources in the response.
     * @param {Level} [level] 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof LevelsApiInterface
     */
    postApiV2Levels($top?: number, $apply?: string, $skip?: number, $filter?: string, $select?: string, $orderby?: string, $expand?: string, $count?: boolean, level?: Level, options?: AxiosRequestConfig): AxiosPromise<Level>;

    /**
     * 
     * @param {number} key 
     * @param {string} [select] 
     * @param {string} [expand] 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof LevelsApiInterface
     */
    postApiV2LevelsKeyDeleteAndDescendants(key: number, select?: string, expand?: string, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @param {number} key 
     * @param {string} [select] 
     * @param {string} [expand] 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof LevelsApiInterface
     */
    postApiV2LevelsKeyDeleteAndDescendants_2(key: number, select?: string, expand?: string, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @param {number} key 
     * @param {string} [select] 
     * @param {string} [expand] 
     * @param {LevelsPostMiddleLevelParameters} [levelsPostMiddleLevelParameters] 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof LevelsApiInterface
     */
    postApiV2LevelsKeyPostMiddleLevel(key: number, select?: string, expand?: string, levelsPostMiddleLevelParameters?: LevelsPostMiddleLevelParameters, options?: AxiosRequestConfig): AxiosPromise<Level>;

    /**
     * 
     * @param {number} key 
     * @param {string} [select] 
     * @param {string} [expand] 
     * @param {LevelsPostMiddleLevelParameters} [levelsPostMiddleLevelParameters] 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof LevelsApiInterface
     */
    postApiV2LevelsKeyPostMiddleLevel_3(key: number, select?: string, expand?: string, levelsPostMiddleLevelParameters?: LevelsPostMiddleLevelParameters, options?: AxiosRequestConfig): AxiosPromise<Level>;

    /**
     * 
     * @param {string} [select] 
     * @param {string} [expand] 
     * @param {LevelsPostTreeParameters} [levelsPostTreeParameters] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LevelsApiInterface
     */
    postApiV2LevelsPostTree(select?: string, expand?: string, levelsPostTreeParameters?: LevelsPostTreeParameters, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @param {number} key 
     * @param {number} [$top] The max number of records.
     * @param {string} [$apply] Allows to specify a sequence of transformations to the entity set, such as groupby, filter, aggregate, etc.
     * @param {number} [$skip] The number of records to skip.
     * @param {string} [$filter] A function that must evaluate to true for a record to be returned.
     * @param {string} [$select] Specifies a subset of properties to return. Use a comma separated list.
     * @param {string} [$orderby] Determines what values are used to order a collection of records.
     * @param {string} [$expand] Use to add related query data.
     * @param {boolean} [$count] Allows clients to request a count of the matching resources included with the resources in the response.
     * @param {Level} [level] 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof LevelsApiInterface
     */
    putApiV2LevelsKey(key: number, $top?: number, $apply?: string, $skip?: number, $filter?: string, $select?: string, $orderby?: string, $expand?: string, $count?: boolean, level?: Level, options?: AxiosRequestConfig): AxiosPromise<Level>;

    /**
     * 
     * @param {number} key 
     * @param {number} [$top] The max number of records.
     * @param {string} [$apply] Allows to specify a sequence of transformations to the entity set, such as groupby, filter, aggregate, etc.
     * @param {number} [$skip] The number of records to skip.
     * @param {string} [$filter] A function that must evaluate to true for a record to be returned.
     * @param {string} [$select] Specifies a subset of properties to return. Use a comma separated list.
     * @param {string} [$orderby] Determines what values are used to order a collection of records.
     * @param {string} [$expand] Use to add related query data.
     * @param {boolean} [$count] Allows clients to request a count of the matching resources included with the resources in the response.
     * @param {Level} [level] 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof LevelsApiInterface
     */
    putApiV2LevelsKey_4(key: number, $top?: number, $apply?: string, $skip?: number, $filter?: string, $select?: string, $orderby?: string, $expand?: string, $count?: boolean, level?: Level, options?: AxiosRequestConfig): AxiosPromise<Level>;

}

/**
 * Request parameters for getApiV2Levels operation in LevelsApi.
 * @export
 * @interface LevelsApiGetApiV2LevelsRequest
 */
export interface LevelsApiGetApiV2LevelsRequest {
    /**
     * The max number of records.
     * @type {number}
     * @memberof LevelsApiGetApiV2Levels
     */
    readonly $top?: number

    /**
     * Allows to specify a sequence of transformations to the entity set, such as groupby, filter, aggregate, etc.
     * @type {string}
     * @memberof LevelsApiGetApiV2Levels
     */
    readonly $apply?: string

    /**
     * The number of records to skip.
     * @type {number}
     * @memberof LevelsApiGetApiV2Levels
     */
    readonly $skip?: number

    /**
     * A function that must evaluate to true for a record to be returned.
     * @type {string}
     * @memberof LevelsApiGetApiV2Levels
     */
    readonly $filter?: string

    /**
     * Specifies a subset of properties to return. Use a comma separated list.
     * @type {string}
     * @memberof LevelsApiGetApiV2Levels
     */
    readonly $select?: string

    /**
     * Determines what values are used to order a collection of records.
     * @type {string}
     * @memberof LevelsApiGetApiV2Levels
     */
    readonly $orderby?: string

    /**
     * Use to add related query data.
     * @type {string}
     * @memberof LevelsApiGetApiV2Levels
     */
    readonly $expand?: string

    /**
     * Allows clients to request a count of the matching resources included with the resources in the response.
     * @type {boolean}
     * @memberof LevelsApiGetApiV2Levels
     */
    readonly $count?: boolean
}

/**
 * Request parameters for getApiV2LevelsCount operation in LevelsApi.
 * @export
 * @interface LevelsApiGetApiV2LevelsCountRequest
 */
export interface LevelsApiGetApiV2LevelsCountRequest {
    /**
     * The max number of records.
     * @type {number}
     * @memberof LevelsApiGetApiV2LevelsCount
     */
    readonly $top?: number

    /**
     * Allows to specify a sequence of transformations to the entity set, such as groupby, filter, aggregate, etc.
     * @type {string}
     * @memberof LevelsApiGetApiV2LevelsCount
     */
    readonly $apply?: string

    /**
     * The number of records to skip.
     * @type {number}
     * @memberof LevelsApiGetApiV2LevelsCount
     */
    readonly $skip?: number

    /**
     * A function that must evaluate to true for a record to be returned.
     * @type {string}
     * @memberof LevelsApiGetApiV2LevelsCount
     */
    readonly $filter?: string

    /**
     * Specifies a subset of properties to return. Use a comma separated list.
     * @type {string}
     * @memberof LevelsApiGetApiV2LevelsCount
     */
    readonly $select?: string

    /**
     * Determines what values are used to order a collection of records.
     * @type {string}
     * @memberof LevelsApiGetApiV2LevelsCount
     */
    readonly $orderby?: string

    /**
     * Use to add related query data.
     * @type {string}
     * @memberof LevelsApiGetApiV2LevelsCount
     */
    readonly $expand?: string

    /**
     * Allows clients to request a count of the matching resources included with the resources in the response.
     * @type {boolean}
     * @memberof LevelsApiGetApiV2LevelsCount
     */
    readonly $count?: boolean
}

/**
 * Request parameters for getApiV2LevelsGetAllForCompanyCompanyIdCompanyId operation in LevelsApi.
 * @export
 * @interface LevelsApiGetApiV2LevelsGetAllForCompanyCompanyIdCompanyIdRequest
 */
export interface LevelsApiGetApiV2LevelsGetAllForCompanyCompanyIdCompanyIdRequest {
    /**
     * 
     * @type {string}
     * @memberof LevelsApiGetApiV2LevelsGetAllForCompanyCompanyIdCompanyId
     */
    readonly companyId: string

    /**
     * The max number of records.
     * @type {number}
     * @memberof LevelsApiGetApiV2LevelsGetAllForCompanyCompanyIdCompanyId
     */
    readonly $top?: number

    /**
     * Allows to specify a sequence of transformations to the entity set, such as groupby, filter, aggregate, etc.
     * @type {string}
     * @memberof LevelsApiGetApiV2LevelsGetAllForCompanyCompanyIdCompanyId
     */
    readonly $apply?: string

    /**
     * The number of records to skip.
     * @type {number}
     * @memberof LevelsApiGetApiV2LevelsGetAllForCompanyCompanyIdCompanyId
     */
    readonly $skip?: number

    /**
     * A function that must evaluate to true for a record to be returned.
     * @type {string}
     * @memberof LevelsApiGetApiV2LevelsGetAllForCompanyCompanyIdCompanyId
     */
    readonly $filter?: string

    /**
     * Specifies a subset of properties to return. Use a comma separated list.
     * @type {string}
     * @memberof LevelsApiGetApiV2LevelsGetAllForCompanyCompanyIdCompanyId
     */
    readonly $select?: string

    /**
     * Determines what values are used to order a collection of records.
     * @type {string}
     * @memberof LevelsApiGetApiV2LevelsGetAllForCompanyCompanyIdCompanyId
     */
    readonly $orderby?: string

    /**
     * Use to add related query data.
     * @type {string}
     * @memberof LevelsApiGetApiV2LevelsGetAllForCompanyCompanyIdCompanyId
     */
    readonly $expand?: string

    /**
     * Allows clients to request a count of the matching resources included with the resources in the response.
     * @type {boolean}
     * @memberof LevelsApiGetApiV2LevelsGetAllForCompanyCompanyIdCompanyId
     */
    readonly $count?: boolean
}

/**
 * Request parameters for getApiV2LevelsKey operation in LevelsApi.
 * @export
 * @interface LevelsApiGetApiV2LevelsKeyRequest
 */
export interface LevelsApiGetApiV2LevelsKeyRequest {
    /**
     * 
     * @type {number}
     * @memberof LevelsApiGetApiV2LevelsKey
     */
    readonly key: number

    /**
     * The max number of records.
     * @type {number}
     * @memberof LevelsApiGetApiV2LevelsKey
     */
    readonly $top?: number

    /**
     * Allows to specify a sequence of transformations to the entity set, such as groupby, filter, aggregate, etc.
     * @type {string}
     * @memberof LevelsApiGetApiV2LevelsKey
     */
    readonly $apply?: string

    /**
     * The number of records to skip.
     * @type {number}
     * @memberof LevelsApiGetApiV2LevelsKey
     */
    readonly $skip?: number

    /**
     * A function that must evaluate to true for a record to be returned.
     * @type {string}
     * @memberof LevelsApiGetApiV2LevelsKey
     */
    readonly $filter?: string

    /**
     * Specifies a subset of properties to return. Use a comma separated list.
     * @type {string}
     * @memberof LevelsApiGetApiV2LevelsKey
     */
    readonly $select?: string

    /**
     * Determines what values are used to order a collection of records.
     * @type {string}
     * @memberof LevelsApiGetApiV2LevelsKey
     */
    readonly $orderby?: string

    /**
     * Use to add related query data.
     * @type {string}
     * @memberof LevelsApiGetApiV2LevelsKey
     */
    readonly $expand?: string

    /**
     * Allows clients to request a count of the matching resources included with the resources in the response.
     * @type {boolean}
     * @memberof LevelsApiGetApiV2LevelsKey
     */
    readonly $count?: boolean
}

/**
 * Request parameters for getApiV2LevelsKey_1 operation in LevelsApi.
 * @export
 * @interface LevelsApiGetApiV2LevelsKey0Request
 */
export interface LevelsApiGetApiV2LevelsKey0Request {
    /**
     * 
     * @type {number}
     * @memberof LevelsApiGetApiV2LevelsKey0
     */
    readonly key: number

    /**
     * The max number of records.
     * @type {number}
     * @memberof LevelsApiGetApiV2LevelsKey0
     */
    readonly $top?: number

    /**
     * Allows to specify a sequence of transformations to the entity set, such as groupby, filter, aggregate, etc.
     * @type {string}
     * @memberof LevelsApiGetApiV2LevelsKey0
     */
    readonly $apply?: string

    /**
     * The number of records to skip.
     * @type {number}
     * @memberof LevelsApiGetApiV2LevelsKey0
     */
    readonly $skip?: number

    /**
     * A function that must evaluate to true for a record to be returned.
     * @type {string}
     * @memberof LevelsApiGetApiV2LevelsKey0
     */
    readonly $filter?: string

    /**
     * Specifies a subset of properties to return. Use a comma separated list.
     * @type {string}
     * @memberof LevelsApiGetApiV2LevelsKey0
     */
    readonly $select?: string

    /**
     * Determines what values are used to order a collection of records.
     * @type {string}
     * @memberof LevelsApiGetApiV2LevelsKey0
     */
    readonly $orderby?: string

    /**
     * Use to add related query data.
     * @type {string}
     * @memberof LevelsApiGetApiV2LevelsKey0
     */
    readonly $expand?: string

    /**
     * Allows clients to request a count of the matching resources included with the resources in the response.
     * @type {boolean}
     * @memberof LevelsApiGetApiV2LevelsKey0
     */
    readonly $count?: boolean
}

/**
 * Request parameters for postApiV2Levels operation in LevelsApi.
 * @export
 * @interface LevelsApiPostApiV2LevelsRequest
 */
export interface LevelsApiPostApiV2LevelsRequest {
    /**
     * The max number of records.
     * @type {number}
     * @memberof LevelsApiPostApiV2Levels
     */
    readonly $top?: number

    /**
     * Allows to specify a sequence of transformations to the entity set, such as groupby, filter, aggregate, etc.
     * @type {string}
     * @memberof LevelsApiPostApiV2Levels
     */
    readonly $apply?: string

    /**
     * The number of records to skip.
     * @type {number}
     * @memberof LevelsApiPostApiV2Levels
     */
    readonly $skip?: number

    /**
     * A function that must evaluate to true for a record to be returned.
     * @type {string}
     * @memberof LevelsApiPostApiV2Levels
     */
    readonly $filter?: string

    /**
     * Specifies a subset of properties to return. Use a comma separated list.
     * @type {string}
     * @memberof LevelsApiPostApiV2Levels
     */
    readonly $select?: string

    /**
     * Determines what values are used to order a collection of records.
     * @type {string}
     * @memberof LevelsApiPostApiV2Levels
     */
    readonly $orderby?: string

    /**
     * Use to add related query data.
     * @type {string}
     * @memberof LevelsApiPostApiV2Levels
     */
    readonly $expand?: string

    /**
     * Allows clients to request a count of the matching resources included with the resources in the response.
     * @type {boolean}
     * @memberof LevelsApiPostApiV2Levels
     */
    readonly $count?: boolean

    /**
     * 
     * @type {Level}
     * @memberof LevelsApiPostApiV2Levels
     */
    readonly level?: Level
}

/**
 * Request parameters for postApiV2LevelsKeyDeleteAndDescendants operation in LevelsApi.
 * @export
 * @interface LevelsApiPostApiV2LevelsKeyDeleteAndDescendantsRequest
 */
export interface LevelsApiPostApiV2LevelsKeyDeleteAndDescendantsRequest {
    /**
     * 
     * @type {number}
     * @memberof LevelsApiPostApiV2LevelsKeyDeleteAndDescendants
     */
    readonly key: number

    /**
     * 
     * @type {string}
     * @memberof LevelsApiPostApiV2LevelsKeyDeleteAndDescendants
     */
    readonly select?: string

    /**
     * 
     * @type {string}
     * @memberof LevelsApiPostApiV2LevelsKeyDeleteAndDescendants
     */
    readonly expand?: string
}

/**
 * Request parameters for postApiV2LevelsKeyDeleteAndDescendants_2 operation in LevelsApi.
 * @export
 * @interface LevelsApiPostApiV2LevelsKeyDeleteAndDescendants0Request
 */
export interface LevelsApiPostApiV2LevelsKeyDeleteAndDescendants0Request {
    /**
     * 
     * @type {number}
     * @memberof LevelsApiPostApiV2LevelsKeyDeleteAndDescendants0
     */
    readonly key: number

    /**
     * 
     * @type {string}
     * @memberof LevelsApiPostApiV2LevelsKeyDeleteAndDescendants0
     */
    readonly select?: string

    /**
     * 
     * @type {string}
     * @memberof LevelsApiPostApiV2LevelsKeyDeleteAndDescendants0
     */
    readonly expand?: string
}

/**
 * Request parameters for postApiV2LevelsKeyPostMiddleLevel operation in LevelsApi.
 * @export
 * @interface LevelsApiPostApiV2LevelsKeyPostMiddleLevelRequest
 */
export interface LevelsApiPostApiV2LevelsKeyPostMiddleLevelRequest {
    /**
     * 
     * @type {number}
     * @memberof LevelsApiPostApiV2LevelsKeyPostMiddleLevel
     */
    readonly key: number

    /**
     * 
     * @type {string}
     * @memberof LevelsApiPostApiV2LevelsKeyPostMiddleLevel
     */
    readonly select?: string

    /**
     * 
     * @type {string}
     * @memberof LevelsApiPostApiV2LevelsKeyPostMiddleLevel
     */
    readonly expand?: string

    /**
     * 
     * @type {LevelsPostMiddleLevelParameters}
     * @memberof LevelsApiPostApiV2LevelsKeyPostMiddleLevel
     */
    readonly levelsPostMiddleLevelParameters?: LevelsPostMiddleLevelParameters
}

/**
 * Request parameters for postApiV2LevelsKeyPostMiddleLevel_3 operation in LevelsApi.
 * @export
 * @interface LevelsApiPostApiV2LevelsKeyPostMiddleLevel0Request
 */
export interface LevelsApiPostApiV2LevelsKeyPostMiddleLevel0Request {
    /**
     * 
     * @type {number}
     * @memberof LevelsApiPostApiV2LevelsKeyPostMiddleLevel0
     */
    readonly key: number

    /**
     * 
     * @type {string}
     * @memberof LevelsApiPostApiV2LevelsKeyPostMiddleLevel0
     */
    readonly select?: string

    /**
     * 
     * @type {string}
     * @memberof LevelsApiPostApiV2LevelsKeyPostMiddleLevel0
     */
    readonly expand?: string

    /**
     * 
     * @type {LevelsPostMiddleLevelParameters}
     * @memberof LevelsApiPostApiV2LevelsKeyPostMiddleLevel0
     */
    readonly levelsPostMiddleLevelParameters?: LevelsPostMiddleLevelParameters
}

/**
 * Request parameters for postApiV2LevelsPostTree operation in LevelsApi.
 * @export
 * @interface LevelsApiPostApiV2LevelsPostTreeRequest
 */
export interface LevelsApiPostApiV2LevelsPostTreeRequest {
    /**
     * 
     * @type {string}
     * @memberof LevelsApiPostApiV2LevelsPostTree
     */
    readonly select?: string

    /**
     * 
     * @type {string}
     * @memberof LevelsApiPostApiV2LevelsPostTree
     */
    readonly expand?: string

    /**
     * 
     * @type {LevelsPostTreeParameters}
     * @memberof LevelsApiPostApiV2LevelsPostTree
     */
    readonly levelsPostTreeParameters?: LevelsPostTreeParameters
}

/**
 * Request parameters for putApiV2LevelsKey operation in LevelsApi.
 * @export
 * @interface LevelsApiPutApiV2LevelsKeyRequest
 */
export interface LevelsApiPutApiV2LevelsKeyRequest {
    /**
     * 
     * @type {number}
     * @memberof LevelsApiPutApiV2LevelsKey
     */
    readonly key: number

    /**
     * The max number of records.
     * @type {number}
     * @memberof LevelsApiPutApiV2LevelsKey
     */
    readonly $top?: number

    /**
     * Allows to specify a sequence of transformations to the entity set, such as groupby, filter, aggregate, etc.
     * @type {string}
     * @memberof LevelsApiPutApiV2LevelsKey
     */
    readonly $apply?: string

    /**
     * The number of records to skip.
     * @type {number}
     * @memberof LevelsApiPutApiV2LevelsKey
     */
    readonly $skip?: number

    /**
     * A function that must evaluate to true for a record to be returned.
     * @type {string}
     * @memberof LevelsApiPutApiV2LevelsKey
     */
    readonly $filter?: string

    /**
     * Specifies a subset of properties to return. Use a comma separated list.
     * @type {string}
     * @memberof LevelsApiPutApiV2LevelsKey
     */
    readonly $select?: string

    /**
     * Determines what values are used to order a collection of records.
     * @type {string}
     * @memberof LevelsApiPutApiV2LevelsKey
     */
    readonly $orderby?: string

    /**
     * Use to add related query data.
     * @type {string}
     * @memberof LevelsApiPutApiV2LevelsKey
     */
    readonly $expand?: string

    /**
     * Allows clients to request a count of the matching resources included with the resources in the response.
     * @type {boolean}
     * @memberof LevelsApiPutApiV2LevelsKey
     */
    readonly $count?: boolean

    /**
     * 
     * @type {Level}
     * @memberof LevelsApiPutApiV2LevelsKey
     */
    readonly level?: Level
}

/**
 * Request parameters for putApiV2LevelsKey_4 operation in LevelsApi.
 * @export
 * @interface LevelsApiPutApiV2LevelsKey0Request
 */
export interface LevelsApiPutApiV2LevelsKey0Request {
    /**
     * 
     * @type {number}
     * @memberof LevelsApiPutApiV2LevelsKey0
     */
    readonly key: number

    /**
     * The max number of records.
     * @type {number}
     * @memberof LevelsApiPutApiV2LevelsKey0
     */
    readonly $top?: number

    /**
     * Allows to specify a sequence of transformations to the entity set, such as groupby, filter, aggregate, etc.
     * @type {string}
     * @memberof LevelsApiPutApiV2LevelsKey0
     */
    readonly $apply?: string

    /**
     * The number of records to skip.
     * @type {number}
     * @memberof LevelsApiPutApiV2LevelsKey0
     */
    readonly $skip?: number

    /**
     * A function that must evaluate to true for a record to be returned.
     * @type {string}
     * @memberof LevelsApiPutApiV2LevelsKey0
     */
    readonly $filter?: string

    /**
     * Specifies a subset of properties to return. Use a comma separated list.
     * @type {string}
     * @memberof LevelsApiPutApiV2LevelsKey0
     */
    readonly $select?: string

    /**
     * Determines what values are used to order a collection of records.
     * @type {string}
     * @memberof LevelsApiPutApiV2LevelsKey0
     */
    readonly $orderby?: string

    /**
     * Use to add related query data.
     * @type {string}
     * @memberof LevelsApiPutApiV2LevelsKey0
     */
    readonly $expand?: string

    /**
     * Allows clients to request a count of the matching resources included with the resources in the response.
     * @type {boolean}
     * @memberof LevelsApiPutApiV2LevelsKey0
     */
    readonly $count?: boolean

    /**
     * 
     * @type {Level}
     * @memberof LevelsApiPutApiV2LevelsKey0
     */
    readonly level?: Level
}

/**
 * LevelsApi - object-oriented interface
 * @export
 * @class LevelsApi
 * @extends {BaseAPI}
 */
export class LevelsApi extends BaseAPI implements LevelsApiInterface {
    /**
     * 
     * @param {LevelsApiGetApiV2LevelsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LevelsApi
     */
    public getApiV2Levels(requestParameters: LevelsApiGetApiV2LevelsRequest = {}, options?: AxiosRequestConfig) {
        return LevelsApiFp(this.configuration).getApiV2Levels(requestParameters.$top, requestParameters.$apply, requestParameters.$skip, requestParameters.$filter, requestParameters.$select, requestParameters.$orderby, requestParameters.$expand, requestParameters.$count, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {LevelsApiGetApiV2LevelsCountRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LevelsApi
     */
    public getApiV2LevelsCount(requestParameters: LevelsApiGetApiV2LevelsCountRequest = {}, options?: AxiosRequestConfig) {
        return LevelsApiFp(this.configuration).getApiV2LevelsCount(requestParameters.$top, requestParameters.$apply, requestParameters.$skip, requestParameters.$filter, requestParameters.$select, requestParameters.$orderby, requestParameters.$expand, requestParameters.$count, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {LevelsApiGetApiV2LevelsGetAllForCompanyCompanyIdCompanyIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LevelsApi
     */
    public getApiV2LevelsGetAllForCompanyCompanyIdCompanyId(requestParameters: LevelsApiGetApiV2LevelsGetAllForCompanyCompanyIdCompanyIdRequest, options?: AxiosRequestConfig) {
        return LevelsApiFp(this.configuration).getApiV2LevelsGetAllForCompanyCompanyIdCompanyId(requestParameters.companyId, requestParameters.$top, requestParameters.$apply, requestParameters.$skip, requestParameters.$filter, requestParameters.$select, requestParameters.$orderby, requestParameters.$expand, requestParameters.$count, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {LevelsApiGetApiV2LevelsKeyRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LevelsApi
     */
    public getApiV2LevelsKey(requestParameters: LevelsApiGetApiV2LevelsKeyRequest, options?: AxiosRequestConfig) {
        return LevelsApiFp(this.configuration).getApiV2LevelsKey(requestParameters.key, requestParameters.$top, requestParameters.$apply, requestParameters.$skip, requestParameters.$filter, requestParameters.$select, requestParameters.$orderby, requestParameters.$expand, requestParameters.$count, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {LevelsApiGetApiV2LevelsKey0Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LevelsApi
     */
    public getApiV2LevelsKey_1(requestParameters: LevelsApiGetApiV2LevelsKey0Request, options?: AxiosRequestConfig) {
        return LevelsApiFp(this.configuration).getApiV2LevelsKey_1(requestParameters.key, requestParameters.$top, requestParameters.$apply, requestParameters.$skip, requestParameters.$filter, requestParameters.$select, requestParameters.$orderby, requestParameters.$expand, requestParameters.$count, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {LevelsApiPostApiV2LevelsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof LevelsApi
     */
    public postApiV2Levels(requestParameters: LevelsApiPostApiV2LevelsRequest = {}, options?: AxiosRequestConfig) {
        return LevelsApiFp(this.configuration).postApiV2Levels(requestParameters.$top, requestParameters.$apply, requestParameters.$skip, requestParameters.$filter, requestParameters.$select, requestParameters.$orderby, requestParameters.$expand, requestParameters.$count, requestParameters.level, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {LevelsApiPostApiV2LevelsKeyDeleteAndDescendantsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof LevelsApi
     */
    public postApiV2LevelsKeyDeleteAndDescendants(requestParameters: LevelsApiPostApiV2LevelsKeyDeleteAndDescendantsRequest, options?: AxiosRequestConfig) {
        return LevelsApiFp(this.configuration).postApiV2LevelsKeyDeleteAndDescendants(requestParameters.key, requestParameters.select, requestParameters.expand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {LevelsApiPostApiV2LevelsKeyDeleteAndDescendants0Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof LevelsApi
     */
    public postApiV2LevelsKeyDeleteAndDescendants_2(requestParameters: LevelsApiPostApiV2LevelsKeyDeleteAndDescendants0Request, options?: AxiosRequestConfig) {
        return LevelsApiFp(this.configuration).postApiV2LevelsKeyDeleteAndDescendants_2(requestParameters.key, requestParameters.select, requestParameters.expand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {LevelsApiPostApiV2LevelsKeyPostMiddleLevelRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof LevelsApi
     */
    public postApiV2LevelsKeyPostMiddleLevel(requestParameters: LevelsApiPostApiV2LevelsKeyPostMiddleLevelRequest, options?: AxiosRequestConfig) {
        return LevelsApiFp(this.configuration).postApiV2LevelsKeyPostMiddleLevel(requestParameters.key, requestParameters.select, requestParameters.expand, requestParameters.levelsPostMiddleLevelParameters, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {LevelsApiPostApiV2LevelsKeyPostMiddleLevel0Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof LevelsApi
     */
    public postApiV2LevelsKeyPostMiddleLevel_3(requestParameters: LevelsApiPostApiV2LevelsKeyPostMiddleLevel0Request, options?: AxiosRequestConfig) {
        return LevelsApiFp(this.configuration).postApiV2LevelsKeyPostMiddleLevel_3(requestParameters.key, requestParameters.select, requestParameters.expand, requestParameters.levelsPostMiddleLevelParameters, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {LevelsApiPostApiV2LevelsPostTreeRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LevelsApi
     */
    public postApiV2LevelsPostTree(requestParameters: LevelsApiPostApiV2LevelsPostTreeRequest = {}, options?: AxiosRequestConfig) {
        return LevelsApiFp(this.configuration).postApiV2LevelsPostTree(requestParameters.select, requestParameters.expand, requestParameters.levelsPostTreeParameters, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {LevelsApiPutApiV2LevelsKeyRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof LevelsApi
     */
    public putApiV2LevelsKey(requestParameters: LevelsApiPutApiV2LevelsKeyRequest, options?: AxiosRequestConfig) {
        return LevelsApiFp(this.configuration).putApiV2LevelsKey(requestParameters.key, requestParameters.$top, requestParameters.$apply, requestParameters.$skip, requestParameters.$filter, requestParameters.$select, requestParameters.$orderby, requestParameters.$expand, requestParameters.$count, requestParameters.level, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {LevelsApiPutApiV2LevelsKey0Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof LevelsApi
     */
    public putApiV2LevelsKey_4(requestParameters: LevelsApiPutApiV2LevelsKey0Request, options?: AxiosRequestConfig) {
        return LevelsApiFp(this.configuration).putApiV2LevelsKey_4(requestParameters.key, requestParameters.$top, requestParameters.$apply, requestParameters.$skip, requestParameters.$filter, requestParameters.$select, requestParameters.$orderby, requestParameters.$expand, requestParameters.$count, requestParameters.level, options).then((request) => request(this.axios, this.basePath));
    }
}
