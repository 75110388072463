import { Typography } from "@mui/material";
import { useStyles } from "./styles";

export type Props = {
  standaloneAnimation?: boolean;
  loadingText?: string;
  width?: number;
  height?: number;
};

function Loading(props: Props) {
  const { classes } = useStyles(props);

  const { loadingText, standaloneAnimation } = props;

  const animation = (
    <div className={classes.spinner}>
      <div className={"bounce1"}></div>
      <div className={"bounce2"}></div>
      <div className={"bounce3"}></div>
    </div>
  );

  if (standaloneAnimation) {
    return animation;
  }

  return (
    <Typography variant="h3" className={classes.h3}>
      {animation}
      {loadingText && loadingText}
    </Typography>
  );
}

export default Loading;
