import { Environment } from "types/enums";

export const commentsApiBaseUrl = process.env.NEXT_PUBLIC_COMMENTS_API_BASE_URL;
export const userApiBaseUrl = process.env.NEXT_PUBLIC_USER_API_BASE_URL;
export const gosApiBaseUrl = process.env.GOS_API_BASE_URL;
export const identityProvidersApiBaseUrl = process.env.IDENTITY_PROVIDERS_API_BASE_URL;
export const notificationsApiBaseUrl = process.env.NOTIFICATIONS_API_BASE_URL;
export const notificationSubscriptionsBaseUrl = process.env.NOTIFICATION_SUBSCRIPTIONS_API_BASE_URL;
export const consumeApiBaseUrl = process.env.CONSUME_API_BASE_URL;
export const googleMapsApiKey = process.env.GOOGLE_MAPS_API_KEY;
export const integrationApiBaseUrl = process.env.INTEGRATION_API_BASE_URL;
export const equipmentApiBaseUrl = process.env.EQUIPMENT_API_BASE_URL;
export const accessApiBaseUrl = process.env.NEXT_PUBLIC_ACCESS_API_BASE_URL;
export const uniteAqFunctionsApiBaseUrl = process.env.UNITEAQ_FUNCTIONS_API_BASE_URL;
export const akvakulturRegistryBaseUrl = process.env.NEXT_PUBLIC_AKVAKULTUR_REGISTRY_BASE_URL;
export const appId = process.env.NEXT_PUBLIC_MANAGEMENT_PORTAL_GOS_APPLICATION_ID;
export const b2cClientId = process.env.NEXT_PUBLIC_B2C_CLIENT_ID;
export const tenantId = process.env.NEXT_PUBLIC_B2C_TENANT_ID;
export const tenantName = process.env.NEXT_PUBLIC_B2C_TENANT_NAME;
export const b2cPolicy = process.env.NEXT_PUBLIC_B2C_POLICY;
export const environment = process.env.NEXT_PUBLIC_ENV as Environment;
export const unleashProxyUrl = process.env.NEXT_PUBLIC_UNLEASH_URL;
export const unleashProxyClientKeys = process.env.NEXT_PUBLIC_UNLEASH_PROXY_CLIENT_KEYS;
export const nextAuthSecret = process.env.NEXTAUTH_SECRET;
export const baseUrl = process.env.NEXT_PUBLIC_APP_URL;
export const releaseNotesApiBaseUrl = process.env.NEXT_PUBLIC_RELEASE_NOTES_API_BASE_URL;

export const isInDev = () => {
  return environment === Environment.Development;
};
