import axios from "axios";
import React from "react";
import { appId } from "utils/appsettings";
import { DefaultLayout } from "../DefaultLayout";
import { getLoginSession } from "@scaleaq/auth-client";
import SessionProvider, { oidcClient } from "providers/sessionProvider";
import AccessControlProvider from "providers/accessControlProvider";

export type Props = {
  children: React.ReactNode;
};

export const getHeaders = (accessToken: string) => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${accessToken}`,
    "X-Application-Id": appId,
  };
  return headers;
};

export default function AuthenticationLayout(props: Props) {
  axios.interceptors.request.use(async (req) => {
    const session = await getLoginSession(oidcClient);
    req.headers = getHeaders(session.access_token);
    return req;
  });

  return (
    <SessionProvider>
      <AccessControlProvider>
        <DefaultLayout>{props.children}</DefaultLayout>
      </AccessControlProvider>
    </SessionProvider>
  );
}
