import { keyframes } from "tss-react";
import { makeStyles } from "tss-react/mui";
import { Props } from "./index";

const loadingAnimation = keyframes`
  0%, 80%, 100% {
    transform: scale(0);
    opacity: 0;
  }
  40% {
    transform: scale(1.0);
    opacity: 1;
  }
`;

export const useStyles = makeStyles<Props>()((theme, props) => {
  return {
    h3: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    },
    spinner: {
      margin: props.standaloneAnimation ? "0" : "16px auto 16px",
      width: props.width ? `${props.width * 3 + 15}px` : "70px",
      textAlign: "center",
      "& > div": {
        width: props.width ? `${props.width}px` : "18px",
        height: props.height ? `${props.height}px` : "18px",
        backgroundColor: theme.colors.ocean6,
        borderRadius: "100%",
        marginRight: 2,
        display: "inline-block",
        animation: `${loadingAnimation} 1.4s ease-in-out infinite`,
        animationFillMode: "both",
      },

      "& > .bounce1": {
        animationDelay: "-0.32s",
      },
      "& > div.bounce2": {
        animationDelay: "-0.16s",
      },
      "& > div.bounce3": {
        animationDelay: "0s",
      },
    },
  };
});
