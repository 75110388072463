import "react-toastify/dist/ReactToastify.css";
import "@scaleaq/scaleui/dist/style.css";

import { fetcher } from "api/swr/fetchers";
import AuthenticationLayout from "components/layouts/AuthenticationLayout";
import withTheme from "infrastructure/hocs/withTheme";
import compose from "lodash/fp/compose";
import { AppProps } from "next/dist/shared/lib/router/router";
import Head from "next/head";
import { SWRConfig } from "swr";
import { CacheProvider } from "@emotion/react";
import createEmotionCache from "infrastructure/createEmotionCache";
import { withFilterStore } from "infrastructure/hocs/withFilterStore";
import { withUnleash } from "infrastructure/hocs/withUnleash";
import { getGenericLogger } from "logger";
import React from "react";
import { ErrorPage } from "@scaleaq/scaleui";
import NextAdapterApp from "next-query-params/app";
import { QueryParamProvider } from "use-query-params";
import { withStore } from "infrastructure/hocs/withStore";
import { cache } from "swr/dist/_internal";

const clientSideEmotionCache = createEmotionCache();

const logger = getGenericLogger().createCategoryLogger("ErrorBoundary");
class ErrorBoundary extends React.Component {
  constructor(props: any) {
    super(props);
    this.state = { hasError: false, error: null };
  }

  componentDidCatch(error: any, info: any) {
    this.setState({ hasError: true, error: error });

    logger.debug("crash", { details: { error, info } });

    const sessionStorageKeys = Object.keys(sessionStorage);
    const sessionStorageValues: { [key: string]: string } = {};

    sessionStorageKeys.forEach((key) => {
      sessionStorageValues[key] = sessionStorage.getItem(key) ?? "";
    });

    const errorField = typeof error === "string" ? { error } : { errorObject: error };
    const infoField = typeof info === "string" ? { info } : { infoObject: info };

    logger.critical("crash", {
      details: { ...errorField, ...infoField, message: "Unhandled application error" },
    });
  }

  render() {
    if ((this.state as any).hasError) {
      return (
        <ErrorPage
          title={"Something weird has happened"}
          explanation={
            "This means we have an issue that is hard to locate. Clear your cache and local storage data and try again."
          }
          message={"If it still doesn't work contact our support"}
        />
      );
    }
    return (this.props as any).children;
  }
}

const localStorageProvider = () => {
  if (typeof window != "object") return new Map();
  const map = new Map(JSON.parse(localStorage.getItem("app-cache") || "[]"));

  window.addEventListener("beforeunload", () => {
    const appCache = JSON.stringify(Array.from(map.entries()));
    try {
      localStorage.setItem("app-cache", appCache);
    } catch (error) {
      localStorage.removeItem("app-cache");
      throw error;
    }
  });

  return map as typeof cache;
};

function ManagmentPortalApp({
  Component,
  pageProps,
  emotionCache = clientSideEmotionCache,
}: AppProps & { emotionCache: any }) {
  return (
    <SWRConfig value={{ fetcher, provider: localStorageProvider }}>
      <CacheProvider value={emotionCache}>
        <QueryParamProvider adapter={NextAdapterApp}>
          <Head>
            <meta name="viewport" content="initial-scale=1.0, width=device-width" key="viewport" />
            <title>Management Portal</title>
          </Head>
          <AuthenticationLayout>
            <Component {...pageProps} />
          </AuthenticationLayout>
        </QueryParamProvider>
      </CacheProvider>
    </SWRConfig>
  );
}

function withErrorBoundry<Props extends AppProps = AppProps>(WrappedComponent: React.ComponentType<Props>) {
  return (props: Props) => {
    return (
      <ErrorBoundary>
        <WrappedComponent {...props} />
      </ErrorBoundary>
    );
  };
}

const ComposedApp = compose(
  withStore,
  withTheme,
  withErrorBoundry,
  withUnleash,
  withFilterStore,
)(ManagmentPortalApp);
export default ComposedApp;
