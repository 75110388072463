import { Environment } from "types/enums";
import { environment } from "utils/appsettings";

export const getEnvUrlPart = (url: string) =>
  environment === Environment.Development || url.includes("-dev")
    ? "-dev"
    : url.includes("-stage")
      ? "-stage"
      : "";

export const getUrlWithoutLanguage = (url: string) => url.replace(/^(\/(en|nb))/, "");
