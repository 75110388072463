import { useCallback, useEffect, useState, MouseEvent } from "react";
import { WorkerEntryMap, WorkerListeners, workerTracker } from "workers/workerManager";
import pubsub from "pubsub-js";
import { toast, ToastContainer, ToastOptions } from "react-toastify";
import React from "react";
import styled from "styled-components";
import WorkersUIToastContent from "./WorkersToastContent";

const StyledToastContainer = styled(ToastContainer).attrs({
  bodyClassName: "body",
  className: "toast-container",
  toastClassName: "toast",
})`
  .toast {
    background-color: ${({ theme }) => theme.palette.accent.accent7};
    color: ${({ theme }) => theme.palette.text.text1};
  }
  .body {
    width: 100%;
    > div {
      width: 100%;
    }
  }
`;

const WorkersUIContainerId = "workers-ui";

export default function WorkersUI() {
  const toastId = React.useRef(null);
  const [workers, setWorkers] = useState<WorkerEntryMap>({});

  useEffect(() => {
    pubsub.subscribe(WorkerListeners.WORKERS, (message, data) => {
      setWorkers(data);
    });
  }, []);

  const onCancelAll = useCallback(() => {
    Object.keys(workers).forEach(workerTracker.removeWorker);
    toast.dismiss(toastId.current);
  }, [workers]);

  const onCancel = useCallback((event: MouseEvent<HTMLButtonElement>, workerId: string) => {
    workerTracker.removeWorker(workerId);
  }, []);

  useEffect(() => {
    if (Object.keys(workers).length < 1) {
      toast.dismiss(toastId.current);
      toastId.current = null;
      return;
    }

    const options: ToastOptions = {
      containerId: WorkersUIContainerId,
      autoClose: false,
      closeButton: false,
      hideProgressBar: true,
      closeOnClick: false,
      pauseOnHover: false,
      draggable: false,
      progress: undefined,
      position: toast.POSITION.BOTTOM_RIGHT,
    };

    const component = (
      <WorkersUIToastContent onCancelAll={onCancelAll} onCancel={onCancel} workers={workers} />
    );

    if (!toastId.current) {
      toastId.current = toast(component, options);
      return;
    }

    toast.update(toastId.current, {
      ...options,
      render: component,
    });
  }, [workers, toastId]);

  return <StyledToastContainer enableMultiContainer containerId={WorkersUIContainerId} />;
}
