import { GlobalStyles } from "tss-react";
import React from "react";
import { fontConfig } from "config/fontConfig";

const FontFace = () => {
  return (
    <>
      <GlobalStyles
        key="font-face"
        styles={[
          {
            "@font-face": {
              fontFamily: `${fontConfig.secondary.name}`,
              src: `url("https://use.typekit.net/af/949f99/00000000000000003b9b3068/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") 
                    format("woff2"),
                url("https://use.typekit.net/af/949f99/00000000000000003b9b3068/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3")
                    format("woff"),
                 url("https://use.typekit.net/af/949f99/00000000000000003b9b3068/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3")
                    format("opentype")`,
              fontDisplay: "swap",
              fontStyle: "normal",
              fontWeight: 700,
            },
          },
          {
            "@font-face": {
              fontFamily: `${fontConfig.secondary.name}`,
              src: `url("https://use.typekit.net/af/705e94/00000000000000003b9b3062/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3")
                    format("woff2"),
                url("https://use.typekit.net/af/705e94/00000000000000003b9b3062/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3")
                    format("woff"),
                url("https://use.typekit.net/af/705e94/00000000000000003b9b3062/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3")
                    format("opentype")`,
              fontDisplay: "swap",
              fontStyle: "normal",
              fontWeight: 400,
            },
          },
          {
            "@font-face": {
              fontFamily: `${fontConfig.secondary.name}`,
              src: `url("https://use.typekit.net/af/6e816b/00000000000000003b9b3064/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n5&v=3")
                     format("woff2"),
                url("https://use.typekit.net/af/6e816b/00000000000000003b9b3064/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n5&v=3")
                    format("woff"),
                url("https://use.typekit.net/af/6e816b/00000000000000003b9b3064/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n5&v=3")
                    format("opentype")`,
              fontDisplay: "swap",
              fontStyle: "normal",
              fontWeight: 500,
            },
          },
        ]}
      />
    </>
  );
};

export default React.memo(FontFace);
