import { useEffect, useState } from "react";
import useSWR from "swr";
import { isClientSide } from "./commonUtils";

const getSessionState = (key: string) => {
  if (isClientSide()) {
    const data = sessionStorage.getItem(`swr-${key}`);
    if (data) {
      return JSON.parse(data);
    }
    return null;
  }
};

const setSessionState = (key: string, data: any) => {
  if (isClientSide()) {
    sessionStorage.setItem(`swr-${key}`, JSON.stringify(data));
  }
};

export default function useGlobalState<T = any>(key: string, initialData: T) {
  const sessionState = getSessionState(key);
  const { data, mutate } = useSWR(key, async () => {}, {
    fallbackData: sessionState ?? initialData,
    refreshWhenHidden: false,
    refreshWhenOffline: false,
    revalidateOnFocus: false,
    revalidateOnMount: false,
    revalidateOnReconnect: false,
  });

  const setData = (data: T) => {
    mutate(data as Promise<void>, false);
    setSessionState(key, data);
  };

  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    setIsLoading(false);
  }, []);

  return [data as T, setData, isLoading] as const;
}
