import styled from "styled-components";

export default styled.div`
  flex-wrap: wrap;
  width: 100%;
  zindex: 100000;
  justify-content: space-between;
  display: flex;
  flex-wrap: wrap;
`;
