import React, { ReactPropTypes } from "react";
import { Provider } from "react-redux";
import store from "../../redux/store";

export const withStore = <Props extends ReactPropTypes = ReactPropTypes>(
  WrappedComponent: React.ComponentType<Props>,
) => {
  const ComponentWithStore = (props: Props) => {
    return (
      <Provider store={store}>
        <WrappedComponent {...props} />
      </Provider>
    );
  };
  return ComponentWithStore;
};
