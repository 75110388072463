import { getLoginSession } from "@scaleaq/auth-client";
import { createLogger, createConsoleOutput, LogLevelFilter, createWebLogOutput } from "@scaleaq/logger";
import { CategoriesLogLevel } from "@scaleaq/logger/lib/logger";
import { ILoggerOutput, IScaleLogger } from "@scaleaq/logger/lib/types";
import { oidcClient } from "providers/sessionProvider";
import { Environment } from "types/enums";
import { environment } from "utils/appsettings";

const CONSOLE_OUTPUT_NAME = "console";
const WEBLOG_OUTPUT_NAME = "weblog";

let genericLogger: IScaleLogger;

export const getOutputs = (): ILoggerOutput[] => {
  const consoleWrapper = createConsoleOutput({ outputName: CONSOLE_OUTPUT_NAME });

  const weblog = createWebLogOutput({
    outputName: WEBLOG_OUTPUT_NAME,
    // @scaleaq/logger should export this
    enviroment: environment as Parameters<typeof createWebLogOutput>[0]["enviroment"],
    application: "management-portal-web",
    // we should probably get this version from the Docker image or something
    applicationVersion: "v2",
    authTokenFactory: async () => (await getLoginSession(oidcClient)).access_token,
  });

  return [consoleWrapper, weblog];
};

const outputLogLevels = {
  default: LogLevelFilter.Warning,
  [CONSOLE_OUTPUT_NAME]: environment == Environment.Development ? LogLevelFilter.Error : LogLevelFilter.Error,
  [WEBLOG_OUTPUT_NAME]: LogLevelFilter.Error,
};

const categoryLogLevels: CategoriesLogLevel = {};

export const getGenericLogger = () => {
  if (genericLogger) return genericLogger;

  const outputs = getOutputs();

  genericLogger = createLogger({
    outputs,
    outputLogLevels,
    categoryLogLevels,
  });

  return genericLogger;
};
