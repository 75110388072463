// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Operation Services API
 * Operation Services API
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { Feed } from '../model';
// @ts-ignore
import { FeedIEnumerableScaleODataValue } from '../model';
// @ts-ignore
import { Operation } from '../model';
/**
 * FeedsApi - axios parameter creator
 * @export
 */
export const FeedsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} key 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteApiV2FeedsKey: async (key: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'key' is not null or undefined
            assertParamExists('deleteApiV2FeedsKey', 'key', key)
            const localVarPath = `/api/v2/Feeds({key})`
                .replace(`{${"key"}}`, encodeURIComponent(String(key)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} key 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteApiV2FeedsKey_1: async (key: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'key' is not null or undefined
            assertParamExists('deleteApiV2FeedsKey_1', 'key', key)
            const localVarPath = `/api/v2/Feeds/{key}`
                .replace(`{${"key"}}`, encodeURIComponent(String(key)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [$top] The max number of records.
         * @param {string} [$apply] Allows to specify a sequence of transformations to the entity set, such as groupby, filter, aggregate, etc.
         * @param {number} [$skip] The number of records to skip.
         * @param {string} [$filter] A function that must evaluate to true for a record to be returned.
         * @param {string} [$select] Specifies a subset of properties to return. Use a comma separated list.
         * @param {string} [$orderby] Determines what values are used to order a collection of records.
         * @param {string} [$expand] Use to add related query data.
         * @param {boolean} [$count] Allows clients to request a count of the matching resources included with the resources in the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiV2Feeds: async ($top?: number, $apply?: string, $skip?: number, $filter?: string, $select?: string, $orderby?: string, $expand?: string, $count?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v2/Feeds`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if ($top !== undefined) {
                localVarQueryParameter['$top'] = $top;
            }

            if ($apply !== undefined) {
                localVarQueryParameter['$apply'] = $apply;
            }

            if ($skip !== undefined) {
                localVarQueryParameter['$skip'] = $skip;
            }

            if ($filter !== undefined) {
                localVarQueryParameter['$filter'] = $filter;
            }

            if ($select !== undefined) {
                localVarQueryParameter['$select'] = $select;
            }

            if ($orderby !== undefined) {
                localVarQueryParameter['$orderby'] = $orderby;
            }

            if ($expand !== undefined) {
                localVarQueryParameter['$expand'] = $expand;
            }

            if ($count !== undefined) {
                localVarQueryParameter['$count'] = $count;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [$top] The max number of records.
         * @param {string} [$apply] Allows to specify a sequence of transformations to the entity set, such as groupby, filter, aggregate, etc.
         * @param {number} [$skip] The number of records to skip.
         * @param {string} [$filter] A function that must evaluate to true for a record to be returned.
         * @param {string} [$select] Specifies a subset of properties to return. Use a comma separated list.
         * @param {string} [$orderby] Determines what values are used to order a collection of records.
         * @param {string} [$expand] Use to add related query data.
         * @param {boolean} [$count] Allows clients to request a count of the matching resources included with the resources in the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiV2FeedsCount: async ($top?: number, $apply?: string, $skip?: number, $filter?: string, $select?: string, $orderby?: string, $expand?: string, $count?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v2/Feeds/$count`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if ($top !== undefined) {
                localVarQueryParameter['$top'] = $top;
            }

            if ($apply !== undefined) {
                localVarQueryParameter['$apply'] = $apply;
            }

            if ($skip !== undefined) {
                localVarQueryParameter['$skip'] = $skip;
            }

            if ($filter !== undefined) {
                localVarQueryParameter['$filter'] = $filter;
            }

            if ($select !== undefined) {
                localVarQueryParameter['$select'] = $select;
            }

            if ($orderby !== undefined) {
                localVarQueryParameter['$orderby'] = $orderby;
            }

            if ($expand !== undefined) {
                localVarQueryParameter['$expand'] = $expand;
            }

            if ($count !== undefined) {
                localVarQueryParameter['$count'] = $count;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} companyId 
         * @param {number} [$top] The max number of records.
         * @param {string} [$apply] Allows to specify a sequence of transformations to the entity set, such as groupby, filter, aggregate, etc.
         * @param {number} [$skip] The number of records to skip.
         * @param {string} [$filter] A function that must evaluate to true for a record to be returned.
         * @param {string} [$select] Specifies a subset of properties to return. Use a comma separated list.
         * @param {string} [$orderby] Determines what values are used to order a collection of records.
         * @param {string} [$expand] Use to add related query data.
         * @param {boolean} [$count] Allows clients to request a count of the matching resources included with the resources in the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiV2FeedsGetAllForCompanyCompanyIdCompanyId: async (companyId: string, $top?: number, $apply?: string, $skip?: number, $filter?: string, $select?: string, $orderby?: string, $expand?: string, $count?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('getApiV2FeedsGetAllForCompanyCompanyIdCompanyId', 'companyId', companyId)
            const localVarPath = `/api/v2/Feeds/GetAllForCompany(companyId={companyId})`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if ($top !== undefined) {
                localVarQueryParameter['$top'] = $top;
            }

            if ($apply !== undefined) {
                localVarQueryParameter['$apply'] = $apply;
            }

            if ($skip !== undefined) {
                localVarQueryParameter['$skip'] = $skip;
            }

            if ($filter !== undefined) {
                localVarQueryParameter['$filter'] = $filter;
            }

            if ($select !== undefined) {
                localVarQueryParameter['$select'] = $select;
            }

            if ($orderby !== undefined) {
                localVarQueryParameter['$orderby'] = $orderby;
            }

            if ($expand !== undefined) {
                localVarQueryParameter['$expand'] = $expand;
            }

            if ($count !== undefined) {
                localVarQueryParameter['$count'] = $count;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} groupId 
         * @param {number} [$top] The max number of records.
         * @param {string} [$apply] Allows to specify a sequence of transformations to the entity set, such as groupby, filter, aggregate, etc.
         * @param {number} [$skip] The number of records to skip.
         * @param {string} [$filter] A function that must evaluate to true for a record to be returned.
         * @param {string} [$select] Specifies a subset of properties to return. Use a comma separated list.
         * @param {string} [$orderby] Determines what values are used to order a collection of records.
         * @param {string} [$expand] Use to add related query data.
         * @param {boolean} [$count] Allows clients to request a count of the matching resources included with the resources in the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiV2FeedsGetAllForGroupAndAncestorsGroupIdGroupId: async (groupId: number, $top?: number, $apply?: string, $skip?: number, $filter?: string, $select?: string, $orderby?: string, $expand?: string, $count?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'groupId' is not null or undefined
            assertParamExists('getApiV2FeedsGetAllForGroupAndAncestorsGroupIdGroupId', 'groupId', groupId)
            const localVarPath = `/api/v2/Feeds/GetAllForGroupAndAncestors(groupId={groupId})`
                .replace(`{${"groupId"}}`, encodeURIComponent(String(groupId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if ($top !== undefined) {
                localVarQueryParameter['$top'] = $top;
            }

            if ($apply !== undefined) {
                localVarQueryParameter['$apply'] = $apply;
            }

            if ($skip !== undefined) {
                localVarQueryParameter['$skip'] = $skip;
            }

            if ($filter !== undefined) {
                localVarQueryParameter['$filter'] = $filter;
            }

            if ($select !== undefined) {
                localVarQueryParameter['$select'] = $select;
            }

            if ($orderby !== undefined) {
                localVarQueryParameter['$orderby'] = $orderby;
            }

            if ($expand !== undefined) {
                localVarQueryParameter['$expand'] = $expand;
            }

            if ($count !== undefined) {
                localVarQueryParameter['$count'] = $count;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} groupId 
         * @param {number} [$top] The max number of records.
         * @param {string} [$apply] Allows to specify a sequence of transformations to the entity set, such as groupby, filter, aggregate, etc.
         * @param {number} [$skip] The number of records to skip.
         * @param {string} [$filter] A function that must evaluate to true for a record to be returned.
         * @param {string} [$select] Specifies a subset of properties to return. Use a comma separated list.
         * @param {string} [$orderby] Determines what values are used to order a collection of records.
         * @param {string} [$expand] Use to add related query data.
         * @param {boolean} [$count] Allows clients to request a count of the matching resources included with the resources in the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiV2FeedsGetAllForGroupAndDescendantsGroupIdGroupId: async (groupId: number, $top?: number, $apply?: string, $skip?: number, $filter?: string, $select?: string, $orderby?: string, $expand?: string, $count?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'groupId' is not null or undefined
            assertParamExists('getApiV2FeedsGetAllForGroupAndDescendantsGroupIdGroupId', 'groupId', groupId)
            const localVarPath = `/api/v2/Feeds/GetAllForGroupAndDescendants(groupId={groupId})`
                .replace(`{${"groupId"}}`, encodeURIComponent(String(groupId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if ($top !== undefined) {
                localVarQueryParameter['$top'] = $top;
            }

            if ($apply !== undefined) {
                localVarQueryParameter['$apply'] = $apply;
            }

            if ($skip !== undefined) {
                localVarQueryParameter['$skip'] = $skip;
            }

            if ($filter !== undefined) {
                localVarQueryParameter['$filter'] = $filter;
            }

            if ($select !== undefined) {
                localVarQueryParameter['$select'] = $select;
            }

            if ($orderby !== undefined) {
                localVarQueryParameter['$orderby'] = $orderby;
            }

            if ($expand !== undefined) {
                localVarQueryParameter['$expand'] = $expand;
            }

            if ($count !== undefined) {
                localVarQueryParameter['$count'] = $count;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} groupId 
         * @param {number} [$top] The max number of records.
         * @param {string} [$apply] Allows to specify a sequence of transformations to the entity set, such as groupby, filter, aggregate, etc.
         * @param {number} [$skip] The number of records to skip.
         * @param {string} [$filter] A function that must evaluate to true for a record to be returned.
         * @param {string} [$select] Specifies a subset of properties to return. Use a comma separated list.
         * @param {string} [$orderby] Determines what values are used to order a collection of records.
         * @param {string} [$expand] Use to add related query data.
         * @param {boolean} [$count] Allows clients to request a count of the matching resources included with the resources in the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiV2FeedsGetAllForGroupGroupIdGroupId: async (groupId: number, $top?: number, $apply?: string, $skip?: number, $filter?: string, $select?: string, $orderby?: string, $expand?: string, $count?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'groupId' is not null or undefined
            assertParamExists('getApiV2FeedsGetAllForGroupGroupIdGroupId', 'groupId', groupId)
            const localVarPath = `/api/v2/Feeds/GetAllForGroup(groupId={groupId})`
                .replace(`{${"groupId"}}`, encodeURIComponent(String(groupId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if ($top !== undefined) {
                localVarQueryParameter['$top'] = $top;
            }

            if ($apply !== undefined) {
                localVarQueryParameter['$apply'] = $apply;
            }

            if ($skip !== undefined) {
                localVarQueryParameter['$skip'] = $skip;
            }

            if ($filter !== undefined) {
                localVarQueryParameter['$filter'] = $filter;
            }

            if ($select !== undefined) {
                localVarQueryParameter['$select'] = $select;
            }

            if ($orderby !== undefined) {
                localVarQueryParameter['$orderby'] = $orderby;
            }

            if ($expand !== undefined) {
                localVarQueryParameter['$expand'] = $expand;
            }

            if ($count !== undefined) {
                localVarQueryParameter['$count'] = $count;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} siteId 
         * @param {number} [$top] The max number of records.
         * @param {string} [$apply] Allows to specify a sequence of transformations to the entity set, such as groupby, filter, aggregate, etc.
         * @param {number} [$skip] The number of records to skip.
         * @param {string} [$filter] A function that must evaluate to true for a record to be returned.
         * @param {string} [$select] Specifies a subset of properties to return. Use a comma separated list.
         * @param {string} [$orderby] Determines what values are used to order a collection of records.
         * @param {string} [$expand] Use to add related query data.
         * @param {boolean} [$count] Allows clients to request a count of the matching resources included with the resources in the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiV2FeedsGetAllForSiteAndGroupAncestorsSiteIdSiteId: async (siteId: number, $top?: number, $apply?: string, $skip?: number, $filter?: string, $select?: string, $orderby?: string, $expand?: string, $count?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'siteId' is not null or undefined
            assertParamExists('getApiV2FeedsGetAllForSiteAndGroupAncestorsSiteIdSiteId', 'siteId', siteId)
            const localVarPath = `/api/v2/Feeds/GetAllForSiteAndGroupAncestors(siteId={siteId})`
                .replace(`{${"siteId"}}`, encodeURIComponent(String(siteId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if ($top !== undefined) {
                localVarQueryParameter['$top'] = $top;
            }

            if ($apply !== undefined) {
                localVarQueryParameter['$apply'] = $apply;
            }

            if ($skip !== undefined) {
                localVarQueryParameter['$skip'] = $skip;
            }

            if ($filter !== undefined) {
                localVarQueryParameter['$filter'] = $filter;
            }

            if ($select !== undefined) {
                localVarQueryParameter['$select'] = $select;
            }

            if ($orderby !== undefined) {
                localVarQueryParameter['$orderby'] = $orderby;
            }

            if ($expand !== undefined) {
                localVarQueryParameter['$expand'] = $expand;
            }

            if ($count !== undefined) {
                localVarQueryParameter['$count'] = $count;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} siteId 
         * @param {number} [$top] The max number of records.
         * @param {string} [$apply] Allows to specify a sequence of transformations to the entity set, such as groupby, filter, aggregate, etc.
         * @param {number} [$skip] The number of records to skip.
         * @param {string} [$filter] A function that must evaluate to true for a record to be returned.
         * @param {string} [$select] Specifies a subset of properties to return. Use a comma separated list.
         * @param {string} [$orderby] Determines what values are used to order a collection of records.
         * @param {string} [$expand] Use to add related query data.
         * @param {boolean} [$count] Allows clients to request a count of the matching resources included with the resources in the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiV2FeedsGetAllForSiteAndGroupDescendantsSiteIdSiteId: async (siteId: number, $top?: number, $apply?: string, $skip?: number, $filter?: string, $select?: string, $orderby?: string, $expand?: string, $count?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'siteId' is not null or undefined
            assertParamExists('getApiV2FeedsGetAllForSiteAndGroupDescendantsSiteIdSiteId', 'siteId', siteId)
            const localVarPath = `/api/v2/Feeds/GetAllForSiteAndGroupDescendants(siteId={siteId})`
                .replace(`{${"siteId"}}`, encodeURIComponent(String(siteId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if ($top !== undefined) {
                localVarQueryParameter['$top'] = $top;
            }

            if ($apply !== undefined) {
                localVarQueryParameter['$apply'] = $apply;
            }

            if ($skip !== undefined) {
                localVarQueryParameter['$skip'] = $skip;
            }

            if ($filter !== undefined) {
                localVarQueryParameter['$filter'] = $filter;
            }

            if ($select !== undefined) {
                localVarQueryParameter['$select'] = $select;
            }

            if ($orderby !== undefined) {
                localVarQueryParameter['$orderby'] = $orderby;
            }

            if ($expand !== undefined) {
                localVarQueryParameter['$expand'] = $expand;
            }

            if ($count !== undefined) {
                localVarQueryParameter['$count'] = $count;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} siteId 
         * @param {number} [$top] The max number of records.
         * @param {string} [$apply] Allows to specify a sequence of transformations to the entity set, such as groupby, filter, aggregate, etc.
         * @param {number} [$skip] The number of records to skip.
         * @param {string} [$filter] A function that must evaluate to true for a record to be returned.
         * @param {string} [$select] Specifies a subset of properties to return. Use a comma separated list.
         * @param {string} [$orderby] Determines what values are used to order a collection of records.
         * @param {string} [$expand] Use to add related query data.
         * @param {boolean} [$count] Allows clients to request a count of the matching resources included with the resources in the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiV2FeedsGetAllForSiteSiteIdSiteId: async (siteId: number, $top?: number, $apply?: string, $skip?: number, $filter?: string, $select?: string, $orderby?: string, $expand?: string, $count?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'siteId' is not null or undefined
            assertParamExists('getApiV2FeedsGetAllForSiteSiteIdSiteId', 'siteId', siteId)
            const localVarPath = `/api/v2/Feeds/GetAllForSite(siteId={siteId})`
                .replace(`{${"siteId"}}`, encodeURIComponent(String(siteId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if ($top !== undefined) {
                localVarQueryParameter['$top'] = $top;
            }

            if ($apply !== undefined) {
                localVarQueryParameter['$apply'] = $apply;
            }

            if ($skip !== undefined) {
                localVarQueryParameter['$skip'] = $skip;
            }

            if ($filter !== undefined) {
                localVarQueryParameter['$filter'] = $filter;
            }

            if ($select !== undefined) {
                localVarQueryParameter['$select'] = $select;
            }

            if ($orderby !== undefined) {
                localVarQueryParameter['$orderby'] = $orderby;
            }

            if ($expand !== undefined) {
                localVarQueryParameter['$expand'] = $expand;
            }

            if ($count !== undefined) {
                localVarQueryParameter['$count'] = $count;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} companyId 
         * @param {number} [$top] The max number of records.
         * @param {string} [$apply] Allows to specify a sequence of transformations to the entity set, such as groupby, filter, aggregate, etc.
         * @param {number} [$skip] The number of records to skip.
         * @param {string} [$filter] A function that must evaluate to true for a record to be returned.
         * @param {string} [$select] Specifies a subset of properties to return. Use a comma separated list.
         * @param {string} [$orderby] Determines what values are used to order a collection of records.
         * @param {string} [$expand] Use to add related query data.
         * @param {boolean} [$count] Allows clients to request a count of the matching resources included with the resources in the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiV2FeedsGetAllGlobalForCompanyCompanyIdCompanyId: async (companyId: string, $top?: number, $apply?: string, $skip?: number, $filter?: string, $select?: string, $orderby?: string, $expand?: string, $count?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('getApiV2FeedsGetAllGlobalForCompanyCompanyIdCompanyId', 'companyId', companyId)
            const localVarPath = `/api/v2/Feeds/GetAllGlobalForCompany(companyId={companyId})`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if ($top !== undefined) {
                localVarQueryParameter['$top'] = $top;
            }

            if ($apply !== undefined) {
                localVarQueryParameter['$apply'] = $apply;
            }

            if ($skip !== undefined) {
                localVarQueryParameter['$skip'] = $skip;
            }

            if ($filter !== undefined) {
                localVarQueryParameter['$filter'] = $filter;
            }

            if ($select !== undefined) {
                localVarQueryParameter['$select'] = $select;
            }

            if ($orderby !== undefined) {
                localVarQueryParameter['$orderby'] = $orderby;
            }

            if ($expand !== undefined) {
                localVarQueryParameter['$expand'] = $expand;
            }

            if ($count !== undefined) {
                localVarQueryParameter['$count'] = $count;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} key 
         * @param {number} [$top] The max number of records.
         * @param {string} [$apply] Allows to specify a sequence of transformations to the entity set, such as groupby, filter, aggregate, etc.
         * @param {number} [$skip] The number of records to skip.
         * @param {string} [$filter] A function that must evaluate to true for a record to be returned.
         * @param {string} [$select] Specifies a subset of properties to return. Use a comma separated list.
         * @param {string} [$orderby] Determines what values are used to order a collection of records.
         * @param {string} [$expand] Use to add related query data.
         * @param {boolean} [$count] Allows clients to request a count of the matching resources included with the resources in the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiV2FeedsKey: async (key: number, $top?: number, $apply?: string, $skip?: number, $filter?: string, $select?: string, $orderby?: string, $expand?: string, $count?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'key' is not null or undefined
            assertParamExists('getApiV2FeedsKey', 'key', key)
            const localVarPath = `/api/v2/Feeds({key})`
                .replace(`{${"key"}}`, encodeURIComponent(String(key)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if ($top !== undefined) {
                localVarQueryParameter['$top'] = $top;
            }

            if ($apply !== undefined) {
                localVarQueryParameter['$apply'] = $apply;
            }

            if ($skip !== undefined) {
                localVarQueryParameter['$skip'] = $skip;
            }

            if ($filter !== undefined) {
                localVarQueryParameter['$filter'] = $filter;
            }

            if ($select !== undefined) {
                localVarQueryParameter['$select'] = $select;
            }

            if ($orderby !== undefined) {
                localVarQueryParameter['$orderby'] = $orderby;
            }

            if ($expand !== undefined) {
                localVarQueryParameter['$expand'] = $expand;
            }

            if ($count !== undefined) {
                localVarQueryParameter['$count'] = $count;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} key 
         * @param {number} [$top] The max number of records.
         * @param {string} [$apply] Allows to specify a sequence of transformations to the entity set, such as groupby, filter, aggregate, etc.
         * @param {number} [$skip] The number of records to skip.
         * @param {string} [$filter] A function that must evaluate to true for a record to be returned.
         * @param {string} [$select] Specifies a subset of properties to return. Use a comma separated list.
         * @param {string} [$orderby] Determines what values are used to order a collection of records.
         * @param {string} [$expand] Use to add related query data.
         * @param {boolean} [$count] Allows clients to request a count of the matching resources included with the resources in the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiV2FeedsKey_2: async (key: number, $top?: number, $apply?: string, $skip?: number, $filter?: string, $select?: string, $orderby?: string, $expand?: string, $count?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'key' is not null or undefined
            assertParamExists('getApiV2FeedsKey_2', 'key', key)
            const localVarPath = `/api/v2/Feeds/{key}`
                .replace(`{${"key"}}`, encodeURIComponent(String(key)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if ($top !== undefined) {
                localVarQueryParameter['$top'] = $top;
            }

            if ($apply !== undefined) {
                localVarQueryParameter['$apply'] = $apply;
            }

            if ($skip !== undefined) {
                localVarQueryParameter['$skip'] = $skip;
            }

            if ($filter !== undefined) {
                localVarQueryParameter['$filter'] = $filter;
            }

            if ($select !== undefined) {
                localVarQueryParameter['$select'] = $select;
            }

            if ($orderby !== undefined) {
                localVarQueryParameter['$orderby'] = $orderby;
            }

            if ($expand !== undefined) {
                localVarQueryParameter['$expand'] = $expand;
            }

            if ($count !== undefined) {
                localVarQueryParameter['$count'] = $count;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} key 
         * @param {number} [$top] The max number of records.
         * @param {string} [$apply] Allows to specify a sequence of transformations to the entity set, such as groupby, filter, aggregate, etc.
         * @param {number} [$skip] The number of records to skip.
         * @param {string} [$filter] A function that must evaluate to true for a record to be returned.
         * @param {string} [$select] Specifies a subset of properties to return. Use a comma separated list.
         * @param {string} [$orderby] Determines what values are used to order a collection of records.
         * @param {string} [$expand] Use to add related query data.
         * @param {boolean} [$count] Allows clients to request a count of the matching resources included with the resources in the response.
         * @param {Array<Operation>} [operation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchApiV2FeedsKey: async (key: number, $top?: number, $apply?: string, $skip?: number, $filter?: string, $select?: string, $orderby?: string, $expand?: string, $count?: boolean, operation?: Array<Operation>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'key' is not null or undefined
            assertParamExists('patchApiV2FeedsKey', 'key', key)
            const localVarPath = `/api/v2/Feeds({key})`
                .replace(`{${"key"}}`, encodeURIComponent(String(key)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if ($top !== undefined) {
                localVarQueryParameter['$top'] = $top;
            }

            if ($apply !== undefined) {
                localVarQueryParameter['$apply'] = $apply;
            }

            if ($skip !== undefined) {
                localVarQueryParameter['$skip'] = $skip;
            }

            if ($filter !== undefined) {
                localVarQueryParameter['$filter'] = $filter;
            }

            if ($select !== undefined) {
                localVarQueryParameter['$select'] = $select;
            }

            if ($orderby !== undefined) {
                localVarQueryParameter['$orderby'] = $orderby;
            }

            if ($expand !== undefined) {
                localVarQueryParameter['$expand'] = $expand;
            }

            if ($count !== undefined) {
                localVarQueryParameter['$count'] = $count;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json;odata.metadata=minimal;odata.streaming=true';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(operation, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} key 
         * @param {number} [$top] The max number of records.
         * @param {string} [$apply] Allows to specify a sequence of transformations to the entity set, such as groupby, filter, aggregate, etc.
         * @param {number} [$skip] The number of records to skip.
         * @param {string} [$filter] A function that must evaluate to true for a record to be returned.
         * @param {string} [$select] Specifies a subset of properties to return. Use a comma separated list.
         * @param {string} [$orderby] Determines what values are used to order a collection of records.
         * @param {string} [$expand] Use to add related query data.
         * @param {boolean} [$count] Allows clients to request a count of the matching resources included with the resources in the response.
         * @param {Array<Operation>} [operation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchApiV2FeedsKey_3: async (key: number, $top?: number, $apply?: string, $skip?: number, $filter?: string, $select?: string, $orderby?: string, $expand?: string, $count?: boolean, operation?: Array<Operation>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'key' is not null or undefined
            assertParamExists('patchApiV2FeedsKey_3', 'key', key)
            const localVarPath = `/api/v2/Feeds/{key}`
                .replace(`{${"key"}}`, encodeURIComponent(String(key)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if ($top !== undefined) {
                localVarQueryParameter['$top'] = $top;
            }

            if ($apply !== undefined) {
                localVarQueryParameter['$apply'] = $apply;
            }

            if ($skip !== undefined) {
                localVarQueryParameter['$skip'] = $skip;
            }

            if ($filter !== undefined) {
                localVarQueryParameter['$filter'] = $filter;
            }

            if ($select !== undefined) {
                localVarQueryParameter['$select'] = $select;
            }

            if ($orderby !== undefined) {
                localVarQueryParameter['$orderby'] = $orderby;
            }

            if ($expand !== undefined) {
                localVarQueryParameter['$expand'] = $expand;
            }

            if ($count !== undefined) {
                localVarQueryParameter['$count'] = $count;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json;odata.metadata=minimal;odata.streaming=true';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(operation, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [$top] The max number of records.
         * @param {string} [$apply] Allows to specify a sequence of transformations to the entity set, such as groupby, filter, aggregate, etc.
         * @param {number} [$skip] The number of records to skip.
         * @param {string} [$filter] A function that must evaluate to true for a record to be returned.
         * @param {string} [$select] Specifies a subset of properties to return. Use a comma separated list.
         * @param {string} [$orderby] Determines what values are used to order a collection of records.
         * @param {string} [$expand] Use to add related query data.
         * @param {boolean} [$count] Allows clients to request a count of the matching resources included with the resources in the response.
         * @param {Feed} [feed] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postApiV2Feeds: async ($top?: number, $apply?: string, $skip?: number, $filter?: string, $select?: string, $orderby?: string, $expand?: string, $count?: boolean, feed?: Feed, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v2/Feeds`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if ($top !== undefined) {
                localVarQueryParameter['$top'] = $top;
            }

            if ($apply !== undefined) {
                localVarQueryParameter['$apply'] = $apply;
            }

            if ($skip !== undefined) {
                localVarQueryParameter['$skip'] = $skip;
            }

            if ($filter !== undefined) {
                localVarQueryParameter['$filter'] = $filter;
            }

            if ($select !== undefined) {
                localVarQueryParameter['$select'] = $select;
            }

            if ($orderby !== undefined) {
                localVarQueryParameter['$orderby'] = $orderby;
            }

            if ($expand !== undefined) {
                localVarQueryParameter['$expand'] = $expand;
            }

            if ($count !== undefined) {
                localVarQueryParameter['$count'] = $count;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json;odata.metadata=minimal;odata.streaming=true';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(feed, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} key 
         * @param {number} [$top] The max number of records.
         * @param {string} [$apply] Allows to specify a sequence of transformations to the entity set, such as groupby, filter, aggregate, etc.
         * @param {number} [$skip] The number of records to skip.
         * @param {string} [$filter] A function that must evaluate to true for a record to be returned.
         * @param {string} [$select] Specifies a subset of properties to return. Use a comma separated list.
         * @param {string} [$orderby] Determines what values are used to order a collection of records.
         * @param {string} [$expand] Use to add related query data.
         * @param {boolean} [$count] Allows clients to request a count of the matching resources included with the resources in the response.
         * @param {Feed} [feed] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putApiV2FeedsKey: async (key: number, $top?: number, $apply?: string, $skip?: number, $filter?: string, $select?: string, $orderby?: string, $expand?: string, $count?: boolean, feed?: Feed, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'key' is not null or undefined
            assertParamExists('putApiV2FeedsKey', 'key', key)
            const localVarPath = `/api/v2/Feeds({key})`
                .replace(`{${"key"}}`, encodeURIComponent(String(key)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if ($top !== undefined) {
                localVarQueryParameter['$top'] = $top;
            }

            if ($apply !== undefined) {
                localVarQueryParameter['$apply'] = $apply;
            }

            if ($skip !== undefined) {
                localVarQueryParameter['$skip'] = $skip;
            }

            if ($filter !== undefined) {
                localVarQueryParameter['$filter'] = $filter;
            }

            if ($select !== undefined) {
                localVarQueryParameter['$select'] = $select;
            }

            if ($orderby !== undefined) {
                localVarQueryParameter['$orderby'] = $orderby;
            }

            if ($expand !== undefined) {
                localVarQueryParameter['$expand'] = $expand;
            }

            if ($count !== undefined) {
                localVarQueryParameter['$count'] = $count;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json;odata.metadata=minimal;odata.streaming=true';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(feed, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} key 
         * @param {number} [$top] The max number of records.
         * @param {string} [$apply] Allows to specify a sequence of transformations to the entity set, such as groupby, filter, aggregate, etc.
         * @param {number} [$skip] The number of records to skip.
         * @param {string} [$filter] A function that must evaluate to true for a record to be returned.
         * @param {string} [$select] Specifies a subset of properties to return. Use a comma separated list.
         * @param {string} [$orderby] Determines what values are used to order a collection of records.
         * @param {string} [$expand] Use to add related query data.
         * @param {boolean} [$count] Allows clients to request a count of the matching resources included with the resources in the response.
         * @param {Feed} [feed] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putApiV2FeedsKey_4: async (key: number, $top?: number, $apply?: string, $skip?: number, $filter?: string, $select?: string, $orderby?: string, $expand?: string, $count?: boolean, feed?: Feed, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'key' is not null or undefined
            assertParamExists('putApiV2FeedsKey_4', 'key', key)
            const localVarPath = `/api/v2/Feeds/{key}`
                .replace(`{${"key"}}`, encodeURIComponent(String(key)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if ($top !== undefined) {
                localVarQueryParameter['$top'] = $top;
            }

            if ($apply !== undefined) {
                localVarQueryParameter['$apply'] = $apply;
            }

            if ($skip !== undefined) {
                localVarQueryParameter['$skip'] = $skip;
            }

            if ($filter !== undefined) {
                localVarQueryParameter['$filter'] = $filter;
            }

            if ($select !== undefined) {
                localVarQueryParameter['$select'] = $select;
            }

            if ($orderby !== undefined) {
                localVarQueryParameter['$orderby'] = $orderby;
            }

            if ($expand !== undefined) {
                localVarQueryParameter['$expand'] = $expand;
            }

            if ($count !== undefined) {
                localVarQueryParameter['$count'] = $count;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json;odata.metadata=minimal;odata.streaming=true';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(feed, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FeedsApi - functional programming interface
 * @export
 */
export const FeedsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FeedsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} key 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteApiV2FeedsKey(key: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteApiV2FeedsKey(key, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} key 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteApiV2FeedsKey_1(key: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteApiV2FeedsKey_1(key, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [$top] The max number of records.
         * @param {string} [$apply] Allows to specify a sequence of transformations to the entity set, such as groupby, filter, aggregate, etc.
         * @param {number} [$skip] The number of records to skip.
         * @param {string} [$filter] A function that must evaluate to true for a record to be returned.
         * @param {string} [$select] Specifies a subset of properties to return. Use a comma separated list.
         * @param {string} [$orderby] Determines what values are used to order a collection of records.
         * @param {string} [$expand] Use to add related query data.
         * @param {boolean} [$count] Allows clients to request a count of the matching resources included with the resources in the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getApiV2Feeds($top?: number, $apply?: string, $skip?: number, $filter?: string, $select?: string, $orderby?: string, $expand?: string, $count?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FeedIEnumerableScaleODataValue>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getApiV2Feeds($top, $apply, $skip, $filter, $select, $orderby, $expand, $count, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [$top] The max number of records.
         * @param {string} [$apply] Allows to specify a sequence of transformations to the entity set, such as groupby, filter, aggregate, etc.
         * @param {number} [$skip] The number of records to skip.
         * @param {string} [$filter] A function that must evaluate to true for a record to be returned.
         * @param {string} [$select] Specifies a subset of properties to return. Use a comma separated list.
         * @param {string} [$orderby] Determines what values are used to order a collection of records.
         * @param {string} [$expand] Use to add related query data.
         * @param {boolean} [$count] Allows clients to request a count of the matching resources included with the resources in the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getApiV2FeedsCount($top?: number, $apply?: string, $skip?: number, $filter?: string, $select?: string, $orderby?: string, $expand?: string, $count?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FeedIEnumerableScaleODataValue>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getApiV2FeedsCount($top, $apply, $skip, $filter, $select, $orderby, $expand, $count, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} companyId 
         * @param {number} [$top] The max number of records.
         * @param {string} [$apply] Allows to specify a sequence of transformations to the entity set, such as groupby, filter, aggregate, etc.
         * @param {number} [$skip] The number of records to skip.
         * @param {string} [$filter] A function that must evaluate to true for a record to be returned.
         * @param {string} [$select] Specifies a subset of properties to return. Use a comma separated list.
         * @param {string} [$orderby] Determines what values are used to order a collection of records.
         * @param {string} [$expand] Use to add related query data.
         * @param {boolean} [$count] Allows clients to request a count of the matching resources included with the resources in the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getApiV2FeedsGetAllForCompanyCompanyIdCompanyId(companyId: string, $top?: number, $apply?: string, $skip?: number, $filter?: string, $select?: string, $orderby?: string, $expand?: string, $count?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FeedIEnumerableScaleODataValue>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getApiV2FeedsGetAllForCompanyCompanyIdCompanyId(companyId, $top, $apply, $skip, $filter, $select, $orderby, $expand, $count, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} groupId 
         * @param {number} [$top] The max number of records.
         * @param {string} [$apply] Allows to specify a sequence of transformations to the entity set, such as groupby, filter, aggregate, etc.
         * @param {number} [$skip] The number of records to skip.
         * @param {string} [$filter] A function that must evaluate to true for a record to be returned.
         * @param {string} [$select] Specifies a subset of properties to return. Use a comma separated list.
         * @param {string} [$orderby] Determines what values are used to order a collection of records.
         * @param {string} [$expand] Use to add related query data.
         * @param {boolean} [$count] Allows clients to request a count of the matching resources included with the resources in the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getApiV2FeedsGetAllForGroupAndAncestorsGroupIdGroupId(groupId: number, $top?: number, $apply?: string, $skip?: number, $filter?: string, $select?: string, $orderby?: string, $expand?: string, $count?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FeedIEnumerableScaleODataValue>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getApiV2FeedsGetAllForGroupAndAncestorsGroupIdGroupId(groupId, $top, $apply, $skip, $filter, $select, $orderby, $expand, $count, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} groupId 
         * @param {number} [$top] The max number of records.
         * @param {string} [$apply] Allows to specify a sequence of transformations to the entity set, such as groupby, filter, aggregate, etc.
         * @param {number} [$skip] The number of records to skip.
         * @param {string} [$filter] A function that must evaluate to true for a record to be returned.
         * @param {string} [$select] Specifies a subset of properties to return. Use a comma separated list.
         * @param {string} [$orderby] Determines what values are used to order a collection of records.
         * @param {string} [$expand] Use to add related query data.
         * @param {boolean} [$count] Allows clients to request a count of the matching resources included with the resources in the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getApiV2FeedsGetAllForGroupAndDescendantsGroupIdGroupId(groupId: number, $top?: number, $apply?: string, $skip?: number, $filter?: string, $select?: string, $orderby?: string, $expand?: string, $count?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FeedIEnumerableScaleODataValue>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getApiV2FeedsGetAllForGroupAndDescendantsGroupIdGroupId(groupId, $top, $apply, $skip, $filter, $select, $orderby, $expand, $count, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} groupId 
         * @param {number} [$top] The max number of records.
         * @param {string} [$apply] Allows to specify a sequence of transformations to the entity set, such as groupby, filter, aggregate, etc.
         * @param {number} [$skip] The number of records to skip.
         * @param {string} [$filter] A function that must evaluate to true for a record to be returned.
         * @param {string} [$select] Specifies a subset of properties to return. Use a comma separated list.
         * @param {string} [$orderby] Determines what values are used to order a collection of records.
         * @param {string} [$expand] Use to add related query data.
         * @param {boolean} [$count] Allows clients to request a count of the matching resources included with the resources in the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getApiV2FeedsGetAllForGroupGroupIdGroupId(groupId: number, $top?: number, $apply?: string, $skip?: number, $filter?: string, $select?: string, $orderby?: string, $expand?: string, $count?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FeedIEnumerableScaleODataValue>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getApiV2FeedsGetAllForGroupGroupIdGroupId(groupId, $top, $apply, $skip, $filter, $select, $orderby, $expand, $count, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} siteId 
         * @param {number} [$top] The max number of records.
         * @param {string} [$apply] Allows to specify a sequence of transformations to the entity set, such as groupby, filter, aggregate, etc.
         * @param {number} [$skip] The number of records to skip.
         * @param {string} [$filter] A function that must evaluate to true for a record to be returned.
         * @param {string} [$select] Specifies a subset of properties to return. Use a comma separated list.
         * @param {string} [$orderby] Determines what values are used to order a collection of records.
         * @param {string} [$expand] Use to add related query data.
         * @param {boolean} [$count] Allows clients to request a count of the matching resources included with the resources in the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getApiV2FeedsGetAllForSiteAndGroupAncestorsSiteIdSiteId(siteId: number, $top?: number, $apply?: string, $skip?: number, $filter?: string, $select?: string, $orderby?: string, $expand?: string, $count?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FeedIEnumerableScaleODataValue>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getApiV2FeedsGetAllForSiteAndGroupAncestorsSiteIdSiteId(siteId, $top, $apply, $skip, $filter, $select, $orderby, $expand, $count, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} siteId 
         * @param {number} [$top] The max number of records.
         * @param {string} [$apply] Allows to specify a sequence of transformations to the entity set, such as groupby, filter, aggregate, etc.
         * @param {number} [$skip] The number of records to skip.
         * @param {string} [$filter] A function that must evaluate to true for a record to be returned.
         * @param {string} [$select] Specifies a subset of properties to return. Use a comma separated list.
         * @param {string} [$orderby] Determines what values are used to order a collection of records.
         * @param {string} [$expand] Use to add related query data.
         * @param {boolean} [$count] Allows clients to request a count of the matching resources included with the resources in the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getApiV2FeedsGetAllForSiteAndGroupDescendantsSiteIdSiteId(siteId: number, $top?: number, $apply?: string, $skip?: number, $filter?: string, $select?: string, $orderby?: string, $expand?: string, $count?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FeedIEnumerableScaleODataValue>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getApiV2FeedsGetAllForSiteAndGroupDescendantsSiteIdSiteId(siteId, $top, $apply, $skip, $filter, $select, $orderby, $expand, $count, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} siteId 
         * @param {number} [$top] The max number of records.
         * @param {string} [$apply] Allows to specify a sequence of transformations to the entity set, such as groupby, filter, aggregate, etc.
         * @param {number} [$skip] The number of records to skip.
         * @param {string} [$filter] A function that must evaluate to true for a record to be returned.
         * @param {string} [$select] Specifies a subset of properties to return. Use a comma separated list.
         * @param {string} [$orderby] Determines what values are used to order a collection of records.
         * @param {string} [$expand] Use to add related query data.
         * @param {boolean} [$count] Allows clients to request a count of the matching resources included with the resources in the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getApiV2FeedsGetAllForSiteSiteIdSiteId(siteId: number, $top?: number, $apply?: string, $skip?: number, $filter?: string, $select?: string, $orderby?: string, $expand?: string, $count?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FeedIEnumerableScaleODataValue>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getApiV2FeedsGetAllForSiteSiteIdSiteId(siteId, $top, $apply, $skip, $filter, $select, $orderby, $expand, $count, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} companyId 
         * @param {number} [$top] The max number of records.
         * @param {string} [$apply] Allows to specify a sequence of transformations to the entity set, such as groupby, filter, aggregate, etc.
         * @param {number} [$skip] The number of records to skip.
         * @param {string} [$filter] A function that must evaluate to true for a record to be returned.
         * @param {string} [$select] Specifies a subset of properties to return. Use a comma separated list.
         * @param {string} [$orderby] Determines what values are used to order a collection of records.
         * @param {string} [$expand] Use to add related query data.
         * @param {boolean} [$count] Allows clients to request a count of the matching resources included with the resources in the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getApiV2FeedsGetAllGlobalForCompanyCompanyIdCompanyId(companyId: string, $top?: number, $apply?: string, $skip?: number, $filter?: string, $select?: string, $orderby?: string, $expand?: string, $count?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FeedIEnumerableScaleODataValue>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getApiV2FeedsGetAllGlobalForCompanyCompanyIdCompanyId(companyId, $top, $apply, $skip, $filter, $select, $orderby, $expand, $count, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} key 
         * @param {number} [$top] The max number of records.
         * @param {string} [$apply] Allows to specify a sequence of transformations to the entity set, such as groupby, filter, aggregate, etc.
         * @param {number} [$skip] The number of records to skip.
         * @param {string} [$filter] A function that must evaluate to true for a record to be returned.
         * @param {string} [$select] Specifies a subset of properties to return. Use a comma separated list.
         * @param {string} [$orderby] Determines what values are used to order a collection of records.
         * @param {string} [$expand] Use to add related query data.
         * @param {boolean} [$count] Allows clients to request a count of the matching resources included with the resources in the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getApiV2FeedsKey(key: number, $top?: number, $apply?: string, $skip?: number, $filter?: string, $select?: string, $orderby?: string, $expand?: string, $count?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Feed>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getApiV2FeedsKey(key, $top, $apply, $skip, $filter, $select, $orderby, $expand, $count, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} key 
         * @param {number} [$top] The max number of records.
         * @param {string} [$apply] Allows to specify a sequence of transformations to the entity set, such as groupby, filter, aggregate, etc.
         * @param {number} [$skip] The number of records to skip.
         * @param {string} [$filter] A function that must evaluate to true for a record to be returned.
         * @param {string} [$select] Specifies a subset of properties to return. Use a comma separated list.
         * @param {string} [$orderby] Determines what values are used to order a collection of records.
         * @param {string} [$expand] Use to add related query data.
         * @param {boolean} [$count] Allows clients to request a count of the matching resources included with the resources in the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getApiV2FeedsKey_2(key: number, $top?: number, $apply?: string, $skip?: number, $filter?: string, $select?: string, $orderby?: string, $expand?: string, $count?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Feed>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getApiV2FeedsKey_2(key, $top, $apply, $skip, $filter, $select, $orderby, $expand, $count, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} key 
         * @param {number} [$top] The max number of records.
         * @param {string} [$apply] Allows to specify a sequence of transformations to the entity set, such as groupby, filter, aggregate, etc.
         * @param {number} [$skip] The number of records to skip.
         * @param {string} [$filter] A function that must evaluate to true for a record to be returned.
         * @param {string} [$select] Specifies a subset of properties to return. Use a comma separated list.
         * @param {string} [$orderby] Determines what values are used to order a collection of records.
         * @param {string} [$expand] Use to add related query data.
         * @param {boolean} [$count] Allows clients to request a count of the matching resources included with the resources in the response.
         * @param {Array<Operation>} [operation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchApiV2FeedsKey(key: number, $top?: number, $apply?: string, $skip?: number, $filter?: string, $select?: string, $orderby?: string, $expand?: string, $count?: boolean, operation?: Array<Operation>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Feed>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patchApiV2FeedsKey(key, $top, $apply, $skip, $filter, $select, $orderby, $expand, $count, operation, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} key 
         * @param {number} [$top] The max number of records.
         * @param {string} [$apply] Allows to specify a sequence of transformations to the entity set, such as groupby, filter, aggregate, etc.
         * @param {number} [$skip] The number of records to skip.
         * @param {string} [$filter] A function that must evaluate to true for a record to be returned.
         * @param {string} [$select] Specifies a subset of properties to return. Use a comma separated list.
         * @param {string} [$orderby] Determines what values are used to order a collection of records.
         * @param {string} [$expand] Use to add related query data.
         * @param {boolean} [$count] Allows clients to request a count of the matching resources included with the resources in the response.
         * @param {Array<Operation>} [operation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchApiV2FeedsKey_3(key: number, $top?: number, $apply?: string, $skip?: number, $filter?: string, $select?: string, $orderby?: string, $expand?: string, $count?: boolean, operation?: Array<Operation>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Feed>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patchApiV2FeedsKey_3(key, $top, $apply, $skip, $filter, $select, $orderby, $expand, $count, operation, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [$top] The max number of records.
         * @param {string} [$apply] Allows to specify a sequence of transformations to the entity set, such as groupby, filter, aggregate, etc.
         * @param {number} [$skip] The number of records to skip.
         * @param {string} [$filter] A function that must evaluate to true for a record to be returned.
         * @param {string} [$select] Specifies a subset of properties to return. Use a comma separated list.
         * @param {string} [$orderby] Determines what values are used to order a collection of records.
         * @param {string} [$expand] Use to add related query data.
         * @param {boolean} [$count] Allows clients to request a count of the matching resources included with the resources in the response.
         * @param {Feed} [feed] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postApiV2Feeds($top?: number, $apply?: string, $skip?: number, $filter?: string, $select?: string, $orderby?: string, $expand?: string, $count?: boolean, feed?: Feed, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Feed>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postApiV2Feeds($top, $apply, $skip, $filter, $select, $orderby, $expand, $count, feed, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} key 
         * @param {number} [$top] The max number of records.
         * @param {string} [$apply] Allows to specify a sequence of transformations to the entity set, such as groupby, filter, aggregate, etc.
         * @param {number} [$skip] The number of records to skip.
         * @param {string} [$filter] A function that must evaluate to true for a record to be returned.
         * @param {string} [$select] Specifies a subset of properties to return. Use a comma separated list.
         * @param {string} [$orderby] Determines what values are used to order a collection of records.
         * @param {string} [$expand] Use to add related query data.
         * @param {boolean} [$count] Allows clients to request a count of the matching resources included with the resources in the response.
         * @param {Feed} [feed] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putApiV2FeedsKey(key: number, $top?: number, $apply?: string, $skip?: number, $filter?: string, $select?: string, $orderby?: string, $expand?: string, $count?: boolean, feed?: Feed, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Feed>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putApiV2FeedsKey(key, $top, $apply, $skip, $filter, $select, $orderby, $expand, $count, feed, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} key 
         * @param {number} [$top] The max number of records.
         * @param {string} [$apply] Allows to specify a sequence of transformations to the entity set, such as groupby, filter, aggregate, etc.
         * @param {number} [$skip] The number of records to skip.
         * @param {string} [$filter] A function that must evaluate to true for a record to be returned.
         * @param {string} [$select] Specifies a subset of properties to return. Use a comma separated list.
         * @param {string} [$orderby] Determines what values are used to order a collection of records.
         * @param {string} [$expand] Use to add related query data.
         * @param {boolean} [$count] Allows clients to request a count of the matching resources included with the resources in the response.
         * @param {Feed} [feed] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putApiV2FeedsKey_4(key: number, $top?: number, $apply?: string, $skip?: number, $filter?: string, $select?: string, $orderby?: string, $expand?: string, $count?: boolean, feed?: Feed, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Feed>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putApiV2FeedsKey_4(key, $top, $apply, $skip, $filter, $select, $orderby, $expand, $count, feed, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * FeedsApi - factory interface
 * @export
 */
export const FeedsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FeedsApiFp(configuration)
    return {
        /**
         * 
         * @param {FeedsApiDeleteApiV2FeedsKeyRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteApiV2FeedsKey(requestParameters: FeedsApiDeleteApiV2FeedsKeyRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.deleteApiV2FeedsKey(requestParameters.key, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {FeedsApiDeleteApiV2FeedsKey0Request} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteApiV2FeedsKey_1(requestParameters: FeedsApiDeleteApiV2FeedsKey0Request, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.deleteApiV2FeedsKey_1(requestParameters.key, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {FeedsApiGetApiV2FeedsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiV2Feeds(requestParameters: FeedsApiGetApiV2FeedsRequest = {}, options?: AxiosRequestConfig): AxiosPromise<FeedIEnumerableScaleODataValue> {
            return localVarFp.getApiV2Feeds(requestParameters.$top, requestParameters.$apply, requestParameters.$skip, requestParameters.$filter, requestParameters.$select, requestParameters.$orderby, requestParameters.$expand, requestParameters.$count, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {FeedsApiGetApiV2FeedsCountRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiV2FeedsCount(requestParameters: FeedsApiGetApiV2FeedsCountRequest = {}, options?: AxiosRequestConfig): AxiosPromise<FeedIEnumerableScaleODataValue> {
            return localVarFp.getApiV2FeedsCount(requestParameters.$top, requestParameters.$apply, requestParameters.$skip, requestParameters.$filter, requestParameters.$select, requestParameters.$orderby, requestParameters.$expand, requestParameters.$count, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {FeedsApiGetApiV2FeedsGetAllForCompanyCompanyIdCompanyIdRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiV2FeedsGetAllForCompanyCompanyIdCompanyId(requestParameters: FeedsApiGetApiV2FeedsGetAllForCompanyCompanyIdCompanyIdRequest, options?: AxiosRequestConfig): AxiosPromise<FeedIEnumerableScaleODataValue> {
            return localVarFp.getApiV2FeedsGetAllForCompanyCompanyIdCompanyId(requestParameters.companyId, requestParameters.$top, requestParameters.$apply, requestParameters.$skip, requestParameters.$filter, requestParameters.$select, requestParameters.$orderby, requestParameters.$expand, requestParameters.$count, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {FeedsApiGetApiV2FeedsGetAllForGroupAndAncestorsGroupIdGroupIdRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiV2FeedsGetAllForGroupAndAncestorsGroupIdGroupId(requestParameters: FeedsApiGetApiV2FeedsGetAllForGroupAndAncestorsGroupIdGroupIdRequest, options?: AxiosRequestConfig): AxiosPromise<FeedIEnumerableScaleODataValue> {
            return localVarFp.getApiV2FeedsGetAllForGroupAndAncestorsGroupIdGroupId(requestParameters.groupId, requestParameters.$top, requestParameters.$apply, requestParameters.$skip, requestParameters.$filter, requestParameters.$select, requestParameters.$orderby, requestParameters.$expand, requestParameters.$count, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {FeedsApiGetApiV2FeedsGetAllForGroupAndDescendantsGroupIdGroupIdRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiV2FeedsGetAllForGroupAndDescendantsGroupIdGroupId(requestParameters: FeedsApiGetApiV2FeedsGetAllForGroupAndDescendantsGroupIdGroupIdRequest, options?: AxiosRequestConfig): AxiosPromise<FeedIEnumerableScaleODataValue> {
            return localVarFp.getApiV2FeedsGetAllForGroupAndDescendantsGroupIdGroupId(requestParameters.groupId, requestParameters.$top, requestParameters.$apply, requestParameters.$skip, requestParameters.$filter, requestParameters.$select, requestParameters.$orderby, requestParameters.$expand, requestParameters.$count, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {FeedsApiGetApiV2FeedsGetAllForGroupGroupIdGroupIdRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiV2FeedsGetAllForGroupGroupIdGroupId(requestParameters: FeedsApiGetApiV2FeedsGetAllForGroupGroupIdGroupIdRequest, options?: AxiosRequestConfig): AxiosPromise<FeedIEnumerableScaleODataValue> {
            return localVarFp.getApiV2FeedsGetAllForGroupGroupIdGroupId(requestParameters.groupId, requestParameters.$top, requestParameters.$apply, requestParameters.$skip, requestParameters.$filter, requestParameters.$select, requestParameters.$orderby, requestParameters.$expand, requestParameters.$count, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {FeedsApiGetApiV2FeedsGetAllForSiteAndGroupAncestorsSiteIdSiteIdRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiV2FeedsGetAllForSiteAndGroupAncestorsSiteIdSiteId(requestParameters: FeedsApiGetApiV2FeedsGetAllForSiteAndGroupAncestorsSiteIdSiteIdRequest, options?: AxiosRequestConfig): AxiosPromise<FeedIEnumerableScaleODataValue> {
            return localVarFp.getApiV2FeedsGetAllForSiteAndGroupAncestorsSiteIdSiteId(requestParameters.siteId, requestParameters.$top, requestParameters.$apply, requestParameters.$skip, requestParameters.$filter, requestParameters.$select, requestParameters.$orderby, requestParameters.$expand, requestParameters.$count, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {FeedsApiGetApiV2FeedsGetAllForSiteAndGroupDescendantsSiteIdSiteIdRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiV2FeedsGetAllForSiteAndGroupDescendantsSiteIdSiteId(requestParameters: FeedsApiGetApiV2FeedsGetAllForSiteAndGroupDescendantsSiteIdSiteIdRequest, options?: AxiosRequestConfig): AxiosPromise<FeedIEnumerableScaleODataValue> {
            return localVarFp.getApiV2FeedsGetAllForSiteAndGroupDescendantsSiteIdSiteId(requestParameters.siteId, requestParameters.$top, requestParameters.$apply, requestParameters.$skip, requestParameters.$filter, requestParameters.$select, requestParameters.$orderby, requestParameters.$expand, requestParameters.$count, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {FeedsApiGetApiV2FeedsGetAllForSiteSiteIdSiteIdRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiV2FeedsGetAllForSiteSiteIdSiteId(requestParameters: FeedsApiGetApiV2FeedsGetAllForSiteSiteIdSiteIdRequest, options?: AxiosRequestConfig): AxiosPromise<FeedIEnumerableScaleODataValue> {
            return localVarFp.getApiV2FeedsGetAllForSiteSiteIdSiteId(requestParameters.siteId, requestParameters.$top, requestParameters.$apply, requestParameters.$skip, requestParameters.$filter, requestParameters.$select, requestParameters.$orderby, requestParameters.$expand, requestParameters.$count, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {FeedsApiGetApiV2FeedsGetAllGlobalForCompanyCompanyIdCompanyIdRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiV2FeedsGetAllGlobalForCompanyCompanyIdCompanyId(requestParameters: FeedsApiGetApiV2FeedsGetAllGlobalForCompanyCompanyIdCompanyIdRequest, options?: AxiosRequestConfig): AxiosPromise<FeedIEnumerableScaleODataValue> {
            return localVarFp.getApiV2FeedsGetAllGlobalForCompanyCompanyIdCompanyId(requestParameters.companyId, requestParameters.$top, requestParameters.$apply, requestParameters.$skip, requestParameters.$filter, requestParameters.$select, requestParameters.$orderby, requestParameters.$expand, requestParameters.$count, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {FeedsApiGetApiV2FeedsKeyRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiV2FeedsKey(requestParameters: FeedsApiGetApiV2FeedsKeyRequest, options?: AxiosRequestConfig): AxiosPromise<Feed> {
            return localVarFp.getApiV2FeedsKey(requestParameters.key, requestParameters.$top, requestParameters.$apply, requestParameters.$skip, requestParameters.$filter, requestParameters.$select, requestParameters.$orderby, requestParameters.$expand, requestParameters.$count, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {FeedsApiGetApiV2FeedsKey0Request} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiV2FeedsKey_2(requestParameters: FeedsApiGetApiV2FeedsKey0Request, options?: AxiosRequestConfig): AxiosPromise<Feed> {
            return localVarFp.getApiV2FeedsKey_2(requestParameters.key, requestParameters.$top, requestParameters.$apply, requestParameters.$skip, requestParameters.$filter, requestParameters.$select, requestParameters.$orderby, requestParameters.$expand, requestParameters.$count, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {FeedsApiPatchApiV2FeedsKeyRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchApiV2FeedsKey(requestParameters: FeedsApiPatchApiV2FeedsKeyRequest, options?: AxiosRequestConfig): AxiosPromise<Feed> {
            return localVarFp.patchApiV2FeedsKey(requestParameters.key, requestParameters.$top, requestParameters.$apply, requestParameters.$skip, requestParameters.$filter, requestParameters.$select, requestParameters.$orderby, requestParameters.$expand, requestParameters.$count, requestParameters.operation, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {FeedsApiPatchApiV2FeedsKey0Request} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchApiV2FeedsKey_3(requestParameters: FeedsApiPatchApiV2FeedsKey0Request, options?: AxiosRequestConfig): AxiosPromise<Feed> {
            return localVarFp.patchApiV2FeedsKey_3(requestParameters.key, requestParameters.$top, requestParameters.$apply, requestParameters.$skip, requestParameters.$filter, requestParameters.$select, requestParameters.$orderby, requestParameters.$expand, requestParameters.$count, requestParameters.operation, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {FeedsApiPostApiV2FeedsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postApiV2Feeds(requestParameters: FeedsApiPostApiV2FeedsRequest = {}, options?: AxiosRequestConfig): AxiosPromise<Feed> {
            return localVarFp.postApiV2Feeds(requestParameters.$top, requestParameters.$apply, requestParameters.$skip, requestParameters.$filter, requestParameters.$select, requestParameters.$orderby, requestParameters.$expand, requestParameters.$count, requestParameters.feed, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {FeedsApiPutApiV2FeedsKeyRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putApiV2FeedsKey(requestParameters: FeedsApiPutApiV2FeedsKeyRequest, options?: AxiosRequestConfig): AxiosPromise<Feed> {
            return localVarFp.putApiV2FeedsKey(requestParameters.key, requestParameters.$top, requestParameters.$apply, requestParameters.$skip, requestParameters.$filter, requestParameters.$select, requestParameters.$orderby, requestParameters.$expand, requestParameters.$count, requestParameters.feed, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {FeedsApiPutApiV2FeedsKey0Request} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putApiV2FeedsKey_4(requestParameters: FeedsApiPutApiV2FeedsKey0Request, options?: AxiosRequestConfig): AxiosPromise<Feed> {
            return localVarFp.putApiV2FeedsKey_4(requestParameters.key, requestParameters.$top, requestParameters.$apply, requestParameters.$skip, requestParameters.$filter, requestParameters.$select, requestParameters.$orderby, requestParameters.$expand, requestParameters.$count, requestParameters.feed, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * FeedsApi - interface
 * @export
 * @interface FeedsApi
 */
export interface FeedsApiInterface {
    /**
     * 
     * @param {number} key 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeedsApiInterface
     */
    deleteApiV2FeedsKey(key: number, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @param {number} key 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeedsApiInterface
     */
    deleteApiV2FeedsKey_1(key: number, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @param {number} [$top] The max number of records.
     * @param {string} [$apply] Allows to specify a sequence of transformations to the entity set, such as groupby, filter, aggregate, etc.
     * @param {number} [$skip] The number of records to skip.
     * @param {string} [$filter] A function that must evaluate to true for a record to be returned.
     * @param {string} [$select] Specifies a subset of properties to return. Use a comma separated list.
     * @param {string} [$orderby] Determines what values are used to order a collection of records.
     * @param {string} [$expand] Use to add related query data.
     * @param {boolean} [$count] Allows clients to request a count of the matching resources included with the resources in the response.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeedsApiInterface
     */
    getApiV2Feeds($top?: number, $apply?: string, $skip?: number, $filter?: string, $select?: string, $orderby?: string, $expand?: string, $count?: boolean, options?: AxiosRequestConfig): AxiosPromise<FeedIEnumerableScaleODataValue>;

    /**
     * 
     * @param {number} [$top] The max number of records.
     * @param {string} [$apply] Allows to specify a sequence of transformations to the entity set, such as groupby, filter, aggregate, etc.
     * @param {number} [$skip] The number of records to skip.
     * @param {string} [$filter] A function that must evaluate to true for a record to be returned.
     * @param {string} [$select] Specifies a subset of properties to return. Use a comma separated list.
     * @param {string} [$orderby] Determines what values are used to order a collection of records.
     * @param {string} [$expand] Use to add related query data.
     * @param {boolean} [$count] Allows clients to request a count of the matching resources included with the resources in the response.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeedsApiInterface
     */
    getApiV2FeedsCount($top?: number, $apply?: string, $skip?: number, $filter?: string, $select?: string, $orderby?: string, $expand?: string, $count?: boolean, options?: AxiosRequestConfig): AxiosPromise<FeedIEnumerableScaleODataValue>;

    /**
     * 
     * @param {string} companyId 
     * @param {number} [$top] The max number of records.
     * @param {string} [$apply] Allows to specify a sequence of transformations to the entity set, such as groupby, filter, aggregate, etc.
     * @param {number} [$skip] The number of records to skip.
     * @param {string} [$filter] A function that must evaluate to true for a record to be returned.
     * @param {string} [$select] Specifies a subset of properties to return. Use a comma separated list.
     * @param {string} [$orderby] Determines what values are used to order a collection of records.
     * @param {string} [$expand] Use to add related query data.
     * @param {boolean} [$count] Allows clients to request a count of the matching resources included with the resources in the response.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeedsApiInterface
     */
    getApiV2FeedsGetAllForCompanyCompanyIdCompanyId(companyId: string, $top?: number, $apply?: string, $skip?: number, $filter?: string, $select?: string, $orderby?: string, $expand?: string, $count?: boolean, options?: AxiosRequestConfig): AxiosPromise<FeedIEnumerableScaleODataValue>;

    /**
     * 
     * @param {number} groupId 
     * @param {number} [$top] The max number of records.
     * @param {string} [$apply] Allows to specify a sequence of transformations to the entity set, such as groupby, filter, aggregate, etc.
     * @param {number} [$skip] The number of records to skip.
     * @param {string} [$filter] A function that must evaluate to true for a record to be returned.
     * @param {string} [$select] Specifies a subset of properties to return. Use a comma separated list.
     * @param {string} [$orderby] Determines what values are used to order a collection of records.
     * @param {string} [$expand] Use to add related query data.
     * @param {boolean} [$count] Allows clients to request a count of the matching resources included with the resources in the response.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeedsApiInterface
     */
    getApiV2FeedsGetAllForGroupAndAncestorsGroupIdGroupId(groupId: number, $top?: number, $apply?: string, $skip?: number, $filter?: string, $select?: string, $orderby?: string, $expand?: string, $count?: boolean, options?: AxiosRequestConfig): AxiosPromise<FeedIEnumerableScaleODataValue>;

    /**
     * 
     * @param {number} groupId 
     * @param {number} [$top] The max number of records.
     * @param {string} [$apply] Allows to specify a sequence of transformations to the entity set, such as groupby, filter, aggregate, etc.
     * @param {number} [$skip] The number of records to skip.
     * @param {string} [$filter] A function that must evaluate to true for a record to be returned.
     * @param {string} [$select] Specifies a subset of properties to return. Use a comma separated list.
     * @param {string} [$orderby] Determines what values are used to order a collection of records.
     * @param {string} [$expand] Use to add related query data.
     * @param {boolean} [$count] Allows clients to request a count of the matching resources included with the resources in the response.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeedsApiInterface
     */
    getApiV2FeedsGetAllForGroupAndDescendantsGroupIdGroupId(groupId: number, $top?: number, $apply?: string, $skip?: number, $filter?: string, $select?: string, $orderby?: string, $expand?: string, $count?: boolean, options?: AxiosRequestConfig): AxiosPromise<FeedIEnumerableScaleODataValue>;

    /**
     * 
     * @param {number} groupId 
     * @param {number} [$top] The max number of records.
     * @param {string} [$apply] Allows to specify a sequence of transformations to the entity set, such as groupby, filter, aggregate, etc.
     * @param {number} [$skip] The number of records to skip.
     * @param {string} [$filter] A function that must evaluate to true for a record to be returned.
     * @param {string} [$select] Specifies a subset of properties to return. Use a comma separated list.
     * @param {string} [$orderby] Determines what values are used to order a collection of records.
     * @param {string} [$expand] Use to add related query data.
     * @param {boolean} [$count] Allows clients to request a count of the matching resources included with the resources in the response.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeedsApiInterface
     */
    getApiV2FeedsGetAllForGroupGroupIdGroupId(groupId: number, $top?: number, $apply?: string, $skip?: number, $filter?: string, $select?: string, $orderby?: string, $expand?: string, $count?: boolean, options?: AxiosRequestConfig): AxiosPromise<FeedIEnumerableScaleODataValue>;

    /**
     * 
     * @param {number} siteId 
     * @param {number} [$top] The max number of records.
     * @param {string} [$apply] Allows to specify a sequence of transformations to the entity set, such as groupby, filter, aggregate, etc.
     * @param {number} [$skip] The number of records to skip.
     * @param {string} [$filter] A function that must evaluate to true for a record to be returned.
     * @param {string} [$select] Specifies a subset of properties to return. Use a comma separated list.
     * @param {string} [$orderby] Determines what values are used to order a collection of records.
     * @param {string} [$expand] Use to add related query data.
     * @param {boolean} [$count] Allows clients to request a count of the matching resources included with the resources in the response.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeedsApiInterface
     */
    getApiV2FeedsGetAllForSiteAndGroupAncestorsSiteIdSiteId(siteId: number, $top?: number, $apply?: string, $skip?: number, $filter?: string, $select?: string, $orderby?: string, $expand?: string, $count?: boolean, options?: AxiosRequestConfig): AxiosPromise<FeedIEnumerableScaleODataValue>;

    /**
     * 
     * @param {number} siteId 
     * @param {number} [$top] The max number of records.
     * @param {string} [$apply] Allows to specify a sequence of transformations to the entity set, such as groupby, filter, aggregate, etc.
     * @param {number} [$skip] The number of records to skip.
     * @param {string} [$filter] A function that must evaluate to true for a record to be returned.
     * @param {string} [$select] Specifies a subset of properties to return. Use a comma separated list.
     * @param {string} [$orderby] Determines what values are used to order a collection of records.
     * @param {string} [$expand] Use to add related query data.
     * @param {boolean} [$count] Allows clients to request a count of the matching resources included with the resources in the response.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeedsApiInterface
     */
    getApiV2FeedsGetAllForSiteAndGroupDescendantsSiteIdSiteId(siteId: number, $top?: number, $apply?: string, $skip?: number, $filter?: string, $select?: string, $orderby?: string, $expand?: string, $count?: boolean, options?: AxiosRequestConfig): AxiosPromise<FeedIEnumerableScaleODataValue>;

    /**
     * 
     * @param {number} siteId 
     * @param {number} [$top] The max number of records.
     * @param {string} [$apply] Allows to specify a sequence of transformations to the entity set, such as groupby, filter, aggregate, etc.
     * @param {number} [$skip] The number of records to skip.
     * @param {string} [$filter] A function that must evaluate to true for a record to be returned.
     * @param {string} [$select] Specifies a subset of properties to return. Use a comma separated list.
     * @param {string} [$orderby] Determines what values are used to order a collection of records.
     * @param {string} [$expand] Use to add related query data.
     * @param {boolean} [$count] Allows clients to request a count of the matching resources included with the resources in the response.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeedsApiInterface
     */
    getApiV2FeedsGetAllForSiteSiteIdSiteId(siteId: number, $top?: number, $apply?: string, $skip?: number, $filter?: string, $select?: string, $orderby?: string, $expand?: string, $count?: boolean, options?: AxiosRequestConfig): AxiosPromise<FeedIEnumerableScaleODataValue>;

    /**
     * 
     * @param {string} companyId 
     * @param {number} [$top] The max number of records.
     * @param {string} [$apply] Allows to specify a sequence of transformations to the entity set, such as groupby, filter, aggregate, etc.
     * @param {number} [$skip] The number of records to skip.
     * @param {string} [$filter] A function that must evaluate to true for a record to be returned.
     * @param {string} [$select] Specifies a subset of properties to return. Use a comma separated list.
     * @param {string} [$orderby] Determines what values are used to order a collection of records.
     * @param {string} [$expand] Use to add related query data.
     * @param {boolean} [$count] Allows clients to request a count of the matching resources included with the resources in the response.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeedsApiInterface
     */
    getApiV2FeedsGetAllGlobalForCompanyCompanyIdCompanyId(companyId: string, $top?: number, $apply?: string, $skip?: number, $filter?: string, $select?: string, $orderby?: string, $expand?: string, $count?: boolean, options?: AxiosRequestConfig): AxiosPromise<FeedIEnumerableScaleODataValue>;

    /**
     * 
     * @param {number} key 
     * @param {number} [$top] The max number of records.
     * @param {string} [$apply] Allows to specify a sequence of transformations to the entity set, such as groupby, filter, aggregate, etc.
     * @param {number} [$skip] The number of records to skip.
     * @param {string} [$filter] A function that must evaluate to true for a record to be returned.
     * @param {string} [$select] Specifies a subset of properties to return. Use a comma separated list.
     * @param {string} [$orderby] Determines what values are used to order a collection of records.
     * @param {string} [$expand] Use to add related query data.
     * @param {boolean} [$count] Allows clients to request a count of the matching resources included with the resources in the response.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeedsApiInterface
     */
    getApiV2FeedsKey(key: number, $top?: number, $apply?: string, $skip?: number, $filter?: string, $select?: string, $orderby?: string, $expand?: string, $count?: boolean, options?: AxiosRequestConfig): AxiosPromise<Feed>;

    /**
     * 
     * @param {number} key 
     * @param {number} [$top] The max number of records.
     * @param {string} [$apply] Allows to specify a sequence of transformations to the entity set, such as groupby, filter, aggregate, etc.
     * @param {number} [$skip] The number of records to skip.
     * @param {string} [$filter] A function that must evaluate to true for a record to be returned.
     * @param {string} [$select] Specifies a subset of properties to return. Use a comma separated list.
     * @param {string} [$orderby] Determines what values are used to order a collection of records.
     * @param {string} [$expand] Use to add related query data.
     * @param {boolean} [$count] Allows clients to request a count of the matching resources included with the resources in the response.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeedsApiInterface
     */
    getApiV2FeedsKey_2(key: number, $top?: number, $apply?: string, $skip?: number, $filter?: string, $select?: string, $orderby?: string, $expand?: string, $count?: boolean, options?: AxiosRequestConfig): AxiosPromise<Feed>;

    /**
     * 
     * @param {number} key 
     * @param {number} [$top] The max number of records.
     * @param {string} [$apply] Allows to specify a sequence of transformations to the entity set, such as groupby, filter, aggregate, etc.
     * @param {number} [$skip] The number of records to skip.
     * @param {string} [$filter] A function that must evaluate to true for a record to be returned.
     * @param {string} [$select] Specifies a subset of properties to return. Use a comma separated list.
     * @param {string} [$orderby] Determines what values are used to order a collection of records.
     * @param {string} [$expand] Use to add related query data.
     * @param {boolean} [$count] Allows clients to request a count of the matching resources included with the resources in the response.
     * @param {Array<Operation>} [operation] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeedsApiInterface
     */
    patchApiV2FeedsKey(key: number, $top?: number, $apply?: string, $skip?: number, $filter?: string, $select?: string, $orderby?: string, $expand?: string, $count?: boolean, operation?: Array<Operation>, options?: AxiosRequestConfig): AxiosPromise<Feed>;

    /**
     * 
     * @param {number} key 
     * @param {number} [$top] The max number of records.
     * @param {string} [$apply] Allows to specify a sequence of transformations to the entity set, such as groupby, filter, aggregate, etc.
     * @param {number} [$skip] The number of records to skip.
     * @param {string} [$filter] A function that must evaluate to true for a record to be returned.
     * @param {string} [$select] Specifies a subset of properties to return. Use a comma separated list.
     * @param {string} [$orderby] Determines what values are used to order a collection of records.
     * @param {string} [$expand] Use to add related query data.
     * @param {boolean} [$count] Allows clients to request a count of the matching resources included with the resources in the response.
     * @param {Array<Operation>} [operation] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeedsApiInterface
     */
    patchApiV2FeedsKey_3(key: number, $top?: number, $apply?: string, $skip?: number, $filter?: string, $select?: string, $orderby?: string, $expand?: string, $count?: boolean, operation?: Array<Operation>, options?: AxiosRequestConfig): AxiosPromise<Feed>;

    /**
     * 
     * @param {number} [$top] The max number of records.
     * @param {string} [$apply] Allows to specify a sequence of transformations to the entity set, such as groupby, filter, aggregate, etc.
     * @param {number} [$skip] The number of records to skip.
     * @param {string} [$filter] A function that must evaluate to true for a record to be returned.
     * @param {string} [$select] Specifies a subset of properties to return. Use a comma separated list.
     * @param {string} [$orderby] Determines what values are used to order a collection of records.
     * @param {string} [$expand] Use to add related query data.
     * @param {boolean} [$count] Allows clients to request a count of the matching resources included with the resources in the response.
     * @param {Feed} [feed] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeedsApiInterface
     */
    postApiV2Feeds($top?: number, $apply?: string, $skip?: number, $filter?: string, $select?: string, $orderby?: string, $expand?: string, $count?: boolean, feed?: Feed, options?: AxiosRequestConfig): AxiosPromise<Feed>;

    /**
     * 
     * @param {number} key 
     * @param {number} [$top] The max number of records.
     * @param {string} [$apply] Allows to specify a sequence of transformations to the entity set, such as groupby, filter, aggregate, etc.
     * @param {number} [$skip] The number of records to skip.
     * @param {string} [$filter] A function that must evaluate to true for a record to be returned.
     * @param {string} [$select] Specifies a subset of properties to return. Use a comma separated list.
     * @param {string} [$orderby] Determines what values are used to order a collection of records.
     * @param {string} [$expand] Use to add related query data.
     * @param {boolean} [$count] Allows clients to request a count of the matching resources included with the resources in the response.
     * @param {Feed} [feed] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeedsApiInterface
     */
    putApiV2FeedsKey(key: number, $top?: number, $apply?: string, $skip?: number, $filter?: string, $select?: string, $orderby?: string, $expand?: string, $count?: boolean, feed?: Feed, options?: AxiosRequestConfig): AxiosPromise<Feed>;

    /**
     * 
     * @param {number} key 
     * @param {number} [$top] The max number of records.
     * @param {string} [$apply] Allows to specify a sequence of transformations to the entity set, such as groupby, filter, aggregate, etc.
     * @param {number} [$skip] The number of records to skip.
     * @param {string} [$filter] A function that must evaluate to true for a record to be returned.
     * @param {string} [$select] Specifies a subset of properties to return. Use a comma separated list.
     * @param {string} [$orderby] Determines what values are used to order a collection of records.
     * @param {string} [$expand] Use to add related query data.
     * @param {boolean} [$count] Allows clients to request a count of the matching resources included with the resources in the response.
     * @param {Feed} [feed] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeedsApiInterface
     */
    putApiV2FeedsKey_4(key: number, $top?: number, $apply?: string, $skip?: number, $filter?: string, $select?: string, $orderby?: string, $expand?: string, $count?: boolean, feed?: Feed, options?: AxiosRequestConfig): AxiosPromise<Feed>;

}

/**
 * Request parameters for deleteApiV2FeedsKey operation in FeedsApi.
 * @export
 * @interface FeedsApiDeleteApiV2FeedsKeyRequest
 */
export interface FeedsApiDeleteApiV2FeedsKeyRequest {
    /**
     * 
     * @type {number}
     * @memberof FeedsApiDeleteApiV2FeedsKey
     */
    readonly key: number
}

/**
 * Request parameters for deleteApiV2FeedsKey_1 operation in FeedsApi.
 * @export
 * @interface FeedsApiDeleteApiV2FeedsKey0Request
 */
export interface FeedsApiDeleteApiV2FeedsKey0Request {
    /**
     * 
     * @type {number}
     * @memberof FeedsApiDeleteApiV2FeedsKey0
     */
    readonly key: number
}

/**
 * Request parameters for getApiV2Feeds operation in FeedsApi.
 * @export
 * @interface FeedsApiGetApiV2FeedsRequest
 */
export interface FeedsApiGetApiV2FeedsRequest {
    /**
     * The max number of records.
     * @type {number}
     * @memberof FeedsApiGetApiV2Feeds
     */
    readonly $top?: number

    /**
     * Allows to specify a sequence of transformations to the entity set, such as groupby, filter, aggregate, etc.
     * @type {string}
     * @memberof FeedsApiGetApiV2Feeds
     */
    readonly $apply?: string

    /**
     * The number of records to skip.
     * @type {number}
     * @memberof FeedsApiGetApiV2Feeds
     */
    readonly $skip?: number

    /**
     * A function that must evaluate to true for a record to be returned.
     * @type {string}
     * @memberof FeedsApiGetApiV2Feeds
     */
    readonly $filter?: string

    /**
     * Specifies a subset of properties to return. Use a comma separated list.
     * @type {string}
     * @memberof FeedsApiGetApiV2Feeds
     */
    readonly $select?: string

    /**
     * Determines what values are used to order a collection of records.
     * @type {string}
     * @memberof FeedsApiGetApiV2Feeds
     */
    readonly $orderby?: string

    /**
     * Use to add related query data.
     * @type {string}
     * @memberof FeedsApiGetApiV2Feeds
     */
    readonly $expand?: string

    /**
     * Allows clients to request a count of the matching resources included with the resources in the response.
     * @type {boolean}
     * @memberof FeedsApiGetApiV2Feeds
     */
    readonly $count?: boolean
}

/**
 * Request parameters for getApiV2FeedsCount operation in FeedsApi.
 * @export
 * @interface FeedsApiGetApiV2FeedsCountRequest
 */
export interface FeedsApiGetApiV2FeedsCountRequest {
    /**
     * The max number of records.
     * @type {number}
     * @memberof FeedsApiGetApiV2FeedsCount
     */
    readonly $top?: number

    /**
     * Allows to specify a sequence of transformations to the entity set, such as groupby, filter, aggregate, etc.
     * @type {string}
     * @memberof FeedsApiGetApiV2FeedsCount
     */
    readonly $apply?: string

    /**
     * The number of records to skip.
     * @type {number}
     * @memberof FeedsApiGetApiV2FeedsCount
     */
    readonly $skip?: number

    /**
     * A function that must evaluate to true for a record to be returned.
     * @type {string}
     * @memberof FeedsApiGetApiV2FeedsCount
     */
    readonly $filter?: string

    /**
     * Specifies a subset of properties to return. Use a comma separated list.
     * @type {string}
     * @memberof FeedsApiGetApiV2FeedsCount
     */
    readonly $select?: string

    /**
     * Determines what values are used to order a collection of records.
     * @type {string}
     * @memberof FeedsApiGetApiV2FeedsCount
     */
    readonly $orderby?: string

    /**
     * Use to add related query data.
     * @type {string}
     * @memberof FeedsApiGetApiV2FeedsCount
     */
    readonly $expand?: string

    /**
     * Allows clients to request a count of the matching resources included with the resources in the response.
     * @type {boolean}
     * @memberof FeedsApiGetApiV2FeedsCount
     */
    readonly $count?: boolean
}

/**
 * Request parameters for getApiV2FeedsGetAllForCompanyCompanyIdCompanyId operation in FeedsApi.
 * @export
 * @interface FeedsApiGetApiV2FeedsGetAllForCompanyCompanyIdCompanyIdRequest
 */
export interface FeedsApiGetApiV2FeedsGetAllForCompanyCompanyIdCompanyIdRequest {
    /**
     * 
     * @type {string}
     * @memberof FeedsApiGetApiV2FeedsGetAllForCompanyCompanyIdCompanyId
     */
    readonly companyId: string

    /**
     * The max number of records.
     * @type {number}
     * @memberof FeedsApiGetApiV2FeedsGetAllForCompanyCompanyIdCompanyId
     */
    readonly $top?: number

    /**
     * Allows to specify a sequence of transformations to the entity set, such as groupby, filter, aggregate, etc.
     * @type {string}
     * @memberof FeedsApiGetApiV2FeedsGetAllForCompanyCompanyIdCompanyId
     */
    readonly $apply?: string

    /**
     * The number of records to skip.
     * @type {number}
     * @memberof FeedsApiGetApiV2FeedsGetAllForCompanyCompanyIdCompanyId
     */
    readonly $skip?: number

    /**
     * A function that must evaluate to true for a record to be returned.
     * @type {string}
     * @memberof FeedsApiGetApiV2FeedsGetAllForCompanyCompanyIdCompanyId
     */
    readonly $filter?: string

    /**
     * Specifies a subset of properties to return. Use a comma separated list.
     * @type {string}
     * @memberof FeedsApiGetApiV2FeedsGetAllForCompanyCompanyIdCompanyId
     */
    readonly $select?: string

    /**
     * Determines what values are used to order a collection of records.
     * @type {string}
     * @memberof FeedsApiGetApiV2FeedsGetAllForCompanyCompanyIdCompanyId
     */
    readonly $orderby?: string

    /**
     * Use to add related query data.
     * @type {string}
     * @memberof FeedsApiGetApiV2FeedsGetAllForCompanyCompanyIdCompanyId
     */
    readonly $expand?: string

    /**
     * Allows clients to request a count of the matching resources included with the resources in the response.
     * @type {boolean}
     * @memberof FeedsApiGetApiV2FeedsGetAllForCompanyCompanyIdCompanyId
     */
    readonly $count?: boolean
}

/**
 * Request parameters for getApiV2FeedsGetAllForGroupAndAncestorsGroupIdGroupId operation in FeedsApi.
 * @export
 * @interface FeedsApiGetApiV2FeedsGetAllForGroupAndAncestorsGroupIdGroupIdRequest
 */
export interface FeedsApiGetApiV2FeedsGetAllForGroupAndAncestorsGroupIdGroupIdRequest {
    /**
     * 
     * @type {number}
     * @memberof FeedsApiGetApiV2FeedsGetAllForGroupAndAncestorsGroupIdGroupId
     */
    readonly groupId: number

    /**
     * The max number of records.
     * @type {number}
     * @memberof FeedsApiGetApiV2FeedsGetAllForGroupAndAncestorsGroupIdGroupId
     */
    readonly $top?: number

    /**
     * Allows to specify a sequence of transformations to the entity set, such as groupby, filter, aggregate, etc.
     * @type {string}
     * @memberof FeedsApiGetApiV2FeedsGetAllForGroupAndAncestorsGroupIdGroupId
     */
    readonly $apply?: string

    /**
     * The number of records to skip.
     * @type {number}
     * @memberof FeedsApiGetApiV2FeedsGetAllForGroupAndAncestorsGroupIdGroupId
     */
    readonly $skip?: number

    /**
     * A function that must evaluate to true for a record to be returned.
     * @type {string}
     * @memberof FeedsApiGetApiV2FeedsGetAllForGroupAndAncestorsGroupIdGroupId
     */
    readonly $filter?: string

    /**
     * Specifies a subset of properties to return. Use a comma separated list.
     * @type {string}
     * @memberof FeedsApiGetApiV2FeedsGetAllForGroupAndAncestorsGroupIdGroupId
     */
    readonly $select?: string

    /**
     * Determines what values are used to order a collection of records.
     * @type {string}
     * @memberof FeedsApiGetApiV2FeedsGetAllForGroupAndAncestorsGroupIdGroupId
     */
    readonly $orderby?: string

    /**
     * Use to add related query data.
     * @type {string}
     * @memberof FeedsApiGetApiV2FeedsGetAllForGroupAndAncestorsGroupIdGroupId
     */
    readonly $expand?: string

    /**
     * Allows clients to request a count of the matching resources included with the resources in the response.
     * @type {boolean}
     * @memberof FeedsApiGetApiV2FeedsGetAllForGroupAndAncestorsGroupIdGroupId
     */
    readonly $count?: boolean
}

/**
 * Request parameters for getApiV2FeedsGetAllForGroupAndDescendantsGroupIdGroupId operation in FeedsApi.
 * @export
 * @interface FeedsApiGetApiV2FeedsGetAllForGroupAndDescendantsGroupIdGroupIdRequest
 */
export interface FeedsApiGetApiV2FeedsGetAllForGroupAndDescendantsGroupIdGroupIdRequest {
    /**
     * 
     * @type {number}
     * @memberof FeedsApiGetApiV2FeedsGetAllForGroupAndDescendantsGroupIdGroupId
     */
    readonly groupId: number

    /**
     * The max number of records.
     * @type {number}
     * @memberof FeedsApiGetApiV2FeedsGetAllForGroupAndDescendantsGroupIdGroupId
     */
    readonly $top?: number

    /**
     * Allows to specify a sequence of transformations to the entity set, such as groupby, filter, aggregate, etc.
     * @type {string}
     * @memberof FeedsApiGetApiV2FeedsGetAllForGroupAndDescendantsGroupIdGroupId
     */
    readonly $apply?: string

    /**
     * The number of records to skip.
     * @type {number}
     * @memberof FeedsApiGetApiV2FeedsGetAllForGroupAndDescendantsGroupIdGroupId
     */
    readonly $skip?: number

    /**
     * A function that must evaluate to true for a record to be returned.
     * @type {string}
     * @memberof FeedsApiGetApiV2FeedsGetAllForGroupAndDescendantsGroupIdGroupId
     */
    readonly $filter?: string

    /**
     * Specifies a subset of properties to return. Use a comma separated list.
     * @type {string}
     * @memberof FeedsApiGetApiV2FeedsGetAllForGroupAndDescendantsGroupIdGroupId
     */
    readonly $select?: string

    /**
     * Determines what values are used to order a collection of records.
     * @type {string}
     * @memberof FeedsApiGetApiV2FeedsGetAllForGroupAndDescendantsGroupIdGroupId
     */
    readonly $orderby?: string

    /**
     * Use to add related query data.
     * @type {string}
     * @memberof FeedsApiGetApiV2FeedsGetAllForGroupAndDescendantsGroupIdGroupId
     */
    readonly $expand?: string

    /**
     * Allows clients to request a count of the matching resources included with the resources in the response.
     * @type {boolean}
     * @memberof FeedsApiGetApiV2FeedsGetAllForGroupAndDescendantsGroupIdGroupId
     */
    readonly $count?: boolean
}

/**
 * Request parameters for getApiV2FeedsGetAllForGroupGroupIdGroupId operation in FeedsApi.
 * @export
 * @interface FeedsApiGetApiV2FeedsGetAllForGroupGroupIdGroupIdRequest
 */
export interface FeedsApiGetApiV2FeedsGetAllForGroupGroupIdGroupIdRequest {
    /**
     * 
     * @type {number}
     * @memberof FeedsApiGetApiV2FeedsGetAllForGroupGroupIdGroupId
     */
    readonly groupId: number

    /**
     * The max number of records.
     * @type {number}
     * @memberof FeedsApiGetApiV2FeedsGetAllForGroupGroupIdGroupId
     */
    readonly $top?: number

    /**
     * Allows to specify a sequence of transformations to the entity set, such as groupby, filter, aggregate, etc.
     * @type {string}
     * @memberof FeedsApiGetApiV2FeedsGetAllForGroupGroupIdGroupId
     */
    readonly $apply?: string

    /**
     * The number of records to skip.
     * @type {number}
     * @memberof FeedsApiGetApiV2FeedsGetAllForGroupGroupIdGroupId
     */
    readonly $skip?: number

    /**
     * A function that must evaluate to true for a record to be returned.
     * @type {string}
     * @memberof FeedsApiGetApiV2FeedsGetAllForGroupGroupIdGroupId
     */
    readonly $filter?: string

    /**
     * Specifies a subset of properties to return. Use a comma separated list.
     * @type {string}
     * @memberof FeedsApiGetApiV2FeedsGetAllForGroupGroupIdGroupId
     */
    readonly $select?: string

    /**
     * Determines what values are used to order a collection of records.
     * @type {string}
     * @memberof FeedsApiGetApiV2FeedsGetAllForGroupGroupIdGroupId
     */
    readonly $orderby?: string

    /**
     * Use to add related query data.
     * @type {string}
     * @memberof FeedsApiGetApiV2FeedsGetAllForGroupGroupIdGroupId
     */
    readonly $expand?: string

    /**
     * Allows clients to request a count of the matching resources included with the resources in the response.
     * @type {boolean}
     * @memberof FeedsApiGetApiV2FeedsGetAllForGroupGroupIdGroupId
     */
    readonly $count?: boolean
}

/**
 * Request parameters for getApiV2FeedsGetAllForSiteAndGroupAncestorsSiteIdSiteId operation in FeedsApi.
 * @export
 * @interface FeedsApiGetApiV2FeedsGetAllForSiteAndGroupAncestorsSiteIdSiteIdRequest
 */
export interface FeedsApiGetApiV2FeedsGetAllForSiteAndGroupAncestorsSiteIdSiteIdRequest {
    /**
     * 
     * @type {number}
     * @memberof FeedsApiGetApiV2FeedsGetAllForSiteAndGroupAncestorsSiteIdSiteId
     */
    readonly siteId: number

    /**
     * The max number of records.
     * @type {number}
     * @memberof FeedsApiGetApiV2FeedsGetAllForSiteAndGroupAncestorsSiteIdSiteId
     */
    readonly $top?: number

    /**
     * Allows to specify a sequence of transformations to the entity set, such as groupby, filter, aggregate, etc.
     * @type {string}
     * @memberof FeedsApiGetApiV2FeedsGetAllForSiteAndGroupAncestorsSiteIdSiteId
     */
    readonly $apply?: string

    /**
     * The number of records to skip.
     * @type {number}
     * @memberof FeedsApiGetApiV2FeedsGetAllForSiteAndGroupAncestorsSiteIdSiteId
     */
    readonly $skip?: number

    /**
     * A function that must evaluate to true for a record to be returned.
     * @type {string}
     * @memberof FeedsApiGetApiV2FeedsGetAllForSiteAndGroupAncestorsSiteIdSiteId
     */
    readonly $filter?: string

    /**
     * Specifies a subset of properties to return. Use a comma separated list.
     * @type {string}
     * @memberof FeedsApiGetApiV2FeedsGetAllForSiteAndGroupAncestorsSiteIdSiteId
     */
    readonly $select?: string

    /**
     * Determines what values are used to order a collection of records.
     * @type {string}
     * @memberof FeedsApiGetApiV2FeedsGetAllForSiteAndGroupAncestorsSiteIdSiteId
     */
    readonly $orderby?: string

    /**
     * Use to add related query data.
     * @type {string}
     * @memberof FeedsApiGetApiV2FeedsGetAllForSiteAndGroupAncestorsSiteIdSiteId
     */
    readonly $expand?: string

    /**
     * Allows clients to request a count of the matching resources included with the resources in the response.
     * @type {boolean}
     * @memberof FeedsApiGetApiV2FeedsGetAllForSiteAndGroupAncestorsSiteIdSiteId
     */
    readonly $count?: boolean
}

/**
 * Request parameters for getApiV2FeedsGetAllForSiteAndGroupDescendantsSiteIdSiteId operation in FeedsApi.
 * @export
 * @interface FeedsApiGetApiV2FeedsGetAllForSiteAndGroupDescendantsSiteIdSiteIdRequest
 */
export interface FeedsApiGetApiV2FeedsGetAllForSiteAndGroupDescendantsSiteIdSiteIdRequest {
    /**
     * 
     * @type {number}
     * @memberof FeedsApiGetApiV2FeedsGetAllForSiteAndGroupDescendantsSiteIdSiteId
     */
    readonly siteId: number

    /**
     * The max number of records.
     * @type {number}
     * @memberof FeedsApiGetApiV2FeedsGetAllForSiteAndGroupDescendantsSiteIdSiteId
     */
    readonly $top?: number

    /**
     * Allows to specify a sequence of transformations to the entity set, such as groupby, filter, aggregate, etc.
     * @type {string}
     * @memberof FeedsApiGetApiV2FeedsGetAllForSiteAndGroupDescendantsSiteIdSiteId
     */
    readonly $apply?: string

    /**
     * The number of records to skip.
     * @type {number}
     * @memberof FeedsApiGetApiV2FeedsGetAllForSiteAndGroupDescendantsSiteIdSiteId
     */
    readonly $skip?: number

    /**
     * A function that must evaluate to true for a record to be returned.
     * @type {string}
     * @memberof FeedsApiGetApiV2FeedsGetAllForSiteAndGroupDescendantsSiteIdSiteId
     */
    readonly $filter?: string

    /**
     * Specifies a subset of properties to return. Use a comma separated list.
     * @type {string}
     * @memberof FeedsApiGetApiV2FeedsGetAllForSiteAndGroupDescendantsSiteIdSiteId
     */
    readonly $select?: string

    /**
     * Determines what values are used to order a collection of records.
     * @type {string}
     * @memberof FeedsApiGetApiV2FeedsGetAllForSiteAndGroupDescendantsSiteIdSiteId
     */
    readonly $orderby?: string

    /**
     * Use to add related query data.
     * @type {string}
     * @memberof FeedsApiGetApiV2FeedsGetAllForSiteAndGroupDescendantsSiteIdSiteId
     */
    readonly $expand?: string

    /**
     * Allows clients to request a count of the matching resources included with the resources in the response.
     * @type {boolean}
     * @memberof FeedsApiGetApiV2FeedsGetAllForSiteAndGroupDescendantsSiteIdSiteId
     */
    readonly $count?: boolean
}

/**
 * Request parameters for getApiV2FeedsGetAllForSiteSiteIdSiteId operation in FeedsApi.
 * @export
 * @interface FeedsApiGetApiV2FeedsGetAllForSiteSiteIdSiteIdRequest
 */
export interface FeedsApiGetApiV2FeedsGetAllForSiteSiteIdSiteIdRequest {
    /**
     * 
     * @type {number}
     * @memberof FeedsApiGetApiV2FeedsGetAllForSiteSiteIdSiteId
     */
    readonly siteId: number

    /**
     * The max number of records.
     * @type {number}
     * @memberof FeedsApiGetApiV2FeedsGetAllForSiteSiteIdSiteId
     */
    readonly $top?: number

    /**
     * Allows to specify a sequence of transformations to the entity set, such as groupby, filter, aggregate, etc.
     * @type {string}
     * @memberof FeedsApiGetApiV2FeedsGetAllForSiteSiteIdSiteId
     */
    readonly $apply?: string

    /**
     * The number of records to skip.
     * @type {number}
     * @memberof FeedsApiGetApiV2FeedsGetAllForSiteSiteIdSiteId
     */
    readonly $skip?: number

    /**
     * A function that must evaluate to true for a record to be returned.
     * @type {string}
     * @memberof FeedsApiGetApiV2FeedsGetAllForSiteSiteIdSiteId
     */
    readonly $filter?: string

    /**
     * Specifies a subset of properties to return. Use a comma separated list.
     * @type {string}
     * @memberof FeedsApiGetApiV2FeedsGetAllForSiteSiteIdSiteId
     */
    readonly $select?: string

    /**
     * Determines what values are used to order a collection of records.
     * @type {string}
     * @memberof FeedsApiGetApiV2FeedsGetAllForSiteSiteIdSiteId
     */
    readonly $orderby?: string

    /**
     * Use to add related query data.
     * @type {string}
     * @memberof FeedsApiGetApiV2FeedsGetAllForSiteSiteIdSiteId
     */
    readonly $expand?: string

    /**
     * Allows clients to request a count of the matching resources included with the resources in the response.
     * @type {boolean}
     * @memberof FeedsApiGetApiV2FeedsGetAllForSiteSiteIdSiteId
     */
    readonly $count?: boolean
}

/**
 * Request parameters for getApiV2FeedsGetAllGlobalForCompanyCompanyIdCompanyId operation in FeedsApi.
 * @export
 * @interface FeedsApiGetApiV2FeedsGetAllGlobalForCompanyCompanyIdCompanyIdRequest
 */
export interface FeedsApiGetApiV2FeedsGetAllGlobalForCompanyCompanyIdCompanyIdRequest {
    /**
     * 
     * @type {string}
     * @memberof FeedsApiGetApiV2FeedsGetAllGlobalForCompanyCompanyIdCompanyId
     */
    readonly companyId: string

    /**
     * The max number of records.
     * @type {number}
     * @memberof FeedsApiGetApiV2FeedsGetAllGlobalForCompanyCompanyIdCompanyId
     */
    readonly $top?: number

    /**
     * Allows to specify a sequence of transformations to the entity set, such as groupby, filter, aggregate, etc.
     * @type {string}
     * @memberof FeedsApiGetApiV2FeedsGetAllGlobalForCompanyCompanyIdCompanyId
     */
    readonly $apply?: string

    /**
     * The number of records to skip.
     * @type {number}
     * @memberof FeedsApiGetApiV2FeedsGetAllGlobalForCompanyCompanyIdCompanyId
     */
    readonly $skip?: number

    /**
     * A function that must evaluate to true for a record to be returned.
     * @type {string}
     * @memberof FeedsApiGetApiV2FeedsGetAllGlobalForCompanyCompanyIdCompanyId
     */
    readonly $filter?: string

    /**
     * Specifies a subset of properties to return. Use a comma separated list.
     * @type {string}
     * @memberof FeedsApiGetApiV2FeedsGetAllGlobalForCompanyCompanyIdCompanyId
     */
    readonly $select?: string

    /**
     * Determines what values are used to order a collection of records.
     * @type {string}
     * @memberof FeedsApiGetApiV2FeedsGetAllGlobalForCompanyCompanyIdCompanyId
     */
    readonly $orderby?: string

    /**
     * Use to add related query data.
     * @type {string}
     * @memberof FeedsApiGetApiV2FeedsGetAllGlobalForCompanyCompanyIdCompanyId
     */
    readonly $expand?: string

    /**
     * Allows clients to request a count of the matching resources included with the resources in the response.
     * @type {boolean}
     * @memberof FeedsApiGetApiV2FeedsGetAllGlobalForCompanyCompanyIdCompanyId
     */
    readonly $count?: boolean
}

/**
 * Request parameters for getApiV2FeedsKey operation in FeedsApi.
 * @export
 * @interface FeedsApiGetApiV2FeedsKeyRequest
 */
export interface FeedsApiGetApiV2FeedsKeyRequest {
    /**
     * 
     * @type {number}
     * @memberof FeedsApiGetApiV2FeedsKey
     */
    readonly key: number

    /**
     * The max number of records.
     * @type {number}
     * @memberof FeedsApiGetApiV2FeedsKey
     */
    readonly $top?: number

    /**
     * Allows to specify a sequence of transformations to the entity set, such as groupby, filter, aggregate, etc.
     * @type {string}
     * @memberof FeedsApiGetApiV2FeedsKey
     */
    readonly $apply?: string

    /**
     * The number of records to skip.
     * @type {number}
     * @memberof FeedsApiGetApiV2FeedsKey
     */
    readonly $skip?: number

    /**
     * A function that must evaluate to true for a record to be returned.
     * @type {string}
     * @memberof FeedsApiGetApiV2FeedsKey
     */
    readonly $filter?: string

    /**
     * Specifies a subset of properties to return. Use a comma separated list.
     * @type {string}
     * @memberof FeedsApiGetApiV2FeedsKey
     */
    readonly $select?: string

    /**
     * Determines what values are used to order a collection of records.
     * @type {string}
     * @memberof FeedsApiGetApiV2FeedsKey
     */
    readonly $orderby?: string

    /**
     * Use to add related query data.
     * @type {string}
     * @memberof FeedsApiGetApiV2FeedsKey
     */
    readonly $expand?: string

    /**
     * Allows clients to request a count of the matching resources included with the resources in the response.
     * @type {boolean}
     * @memberof FeedsApiGetApiV2FeedsKey
     */
    readonly $count?: boolean
}

/**
 * Request parameters for getApiV2FeedsKey_2 operation in FeedsApi.
 * @export
 * @interface FeedsApiGetApiV2FeedsKey0Request
 */
export interface FeedsApiGetApiV2FeedsKey0Request {
    /**
     * 
     * @type {number}
     * @memberof FeedsApiGetApiV2FeedsKey0
     */
    readonly key: number

    /**
     * The max number of records.
     * @type {number}
     * @memberof FeedsApiGetApiV2FeedsKey0
     */
    readonly $top?: number

    /**
     * Allows to specify a sequence of transformations to the entity set, such as groupby, filter, aggregate, etc.
     * @type {string}
     * @memberof FeedsApiGetApiV2FeedsKey0
     */
    readonly $apply?: string

    /**
     * The number of records to skip.
     * @type {number}
     * @memberof FeedsApiGetApiV2FeedsKey0
     */
    readonly $skip?: number

    /**
     * A function that must evaluate to true for a record to be returned.
     * @type {string}
     * @memberof FeedsApiGetApiV2FeedsKey0
     */
    readonly $filter?: string

    /**
     * Specifies a subset of properties to return. Use a comma separated list.
     * @type {string}
     * @memberof FeedsApiGetApiV2FeedsKey0
     */
    readonly $select?: string

    /**
     * Determines what values are used to order a collection of records.
     * @type {string}
     * @memberof FeedsApiGetApiV2FeedsKey0
     */
    readonly $orderby?: string

    /**
     * Use to add related query data.
     * @type {string}
     * @memberof FeedsApiGetApiV2FeedsKey0
     */
    readonly $expand?: string

    /**
     * Allows clients to request a count of the matching resources included with the resources in the response.
     * @type {boolean}
     * @memberof FeedsApiGetApiV2FeedsKey0
     */
    readonly $count?: boolean
}

/**
 * Request parameters for patchApiV2FeedsKey operation in FeedsApi.
 * @export
 * @interface FeedsApiPatchApiV2FeedsKeyRequest
 */
export interface FeedsApiPatchApiV2FeedsKeyRequest {
    /**
     * 
     * @type {number}
     * @memberof FeedsApiPatchApiV2FeedsKey
     */
    readonly key: number

    /**
     * The max number of records.
     * @type {number}
     * @memberof FeedsApiPatchApiV2FeedsKey
     */
    readonly $top?: number

    /**
     * Allows to specify a sequence of transformations to the entity set, such as groupby, filter, aggregate, etc.
     * @type {string}
     * @memberof FeedsApiPatchApiV2FeedsKey
     */
    readonly $apply?: string

    /**
     * The number of records to skip.
     * @type {number}
     * @memberof FeedsApiPatchApiV2FeedsKey
     */
    readonly $skip?: number

    /**
     * A function that must evaluate to true for a record to be returned.
     * @type {string}
     * @memberof FeedsApiPatchApiV2FeedsKey
     */
    readonly $filter?: string

    /**
     * Specifies a subset of properties to return. Use a comma separated list.
     * @type {string}
     * @memberof FeedsApiPatchApiV2FeedsKey
     */
    readonly $select?: string

    /**
     * Determines what values are used to order a collection of records.
     * @type {string}
     * @memberof FeedsApiPatchApiV2FeedsKey
     */
    readonly $orderby?: string

    /**
     * Use to add related query data.
     * @type {string}
     * @memberof FeedsApiPatchApiV2FeedsKey
     */
    readonly $expand?: string

    /**
     * Allows clients to request a count of the matching resources included with the resources in the response.
     * @type {boolean}
     * @memberof FeedsApiPatchApiV2FeedsKey
     */
    readonly $count?: boolean

    /**
     * 
     * @type {Array<Operation>}
     * @memberof FeedsApiPatchApiV2FeedsKey
     */
    readonly operation?: Array<Operation>
}

/**
 * Request parameters for patchApiV2FeedsKey_3 operation in FeedsApi.
 * @export
 * @interface FeedsApiPatchApiV2FeedsKey0Request
 */
export interface FeedsApiPatchApiV2FeedsKey0Request {
    /**
     * 
     * @type {number}
     * @memberof FeedsApiPatchApiV2FeedsKey0
     */
    readonly key: number

    /**
     * The max number of records.
     * @type {number}
     * @memberof FeedsApiPatchApiV2FeedsKey0
     */
    readonly $top?: number

    /**
     * Allows to specify a sequence of transformations to the entity set, such as groupby, filter, aggregate, etc.
     * @type {string}
     * @memberof FeedsApiPatchApiV2FeedsKey0
     */
    readonly $apply?: string

    /**
     * The number of records to skip.
     * @type {number}
     * @memberof FeedsApiPatchApiV2FeedsKey0
     */
    readonly $skip?: number

    /**
     * A function that must evaluate to true for a record to be returned.
     * @type {string}
     * @memberof FeedsApiPatchApiV2FeedsKey0
     */
    readonly $filter?: string

    /**
     * Specifies a subset of properties to return. Use a comma separated list.
     * @type {string}
     * @memberof FeedsApiPatchApiV2FeedsKey0
     */
    readonly $select?: string

    /**
     * Determines what values are used to order a collection of records.
     * @type {string}
     * @memberof FeedsApiPatchApiV2FeedsKey0
     */
    readonly $orderby?: string

    /**
     * Use to add related query data.
     * @type {string}
     * @memberof FeedsApiPatchApiV2FeedsKey0
     */
    readonly $expand?: string

    /**
     * Allows clients to request a count of the matching resources included with the resources in the response.
     * @type {boolean}
     * @memberof FeedsApiPatchApiV2FeedsKey0
     */
    readonly $count?: boolean

    /**
     * 
     * @type {Array<Operation>}
     * @memberof FeedsApiPatchApiV2FeedsKey0
     */
    readonly operation?: Array<Operation>
}

/**
 * Request parameters for postApiV2Feeds operation in FeedsApi.
 * @export
 * @interface FeedsApiPostApiV2FeedsRequest
 */
export interface FeedsApiPostApiV2FeedsRequest {
    /**
     * The max number of records.
     * @type {number}
     * @memberof FeedsApiPostApiV2Feeds
     */
    readonly $top?: number

    /**
     * Allows to specify a sequence of transformations to the entity set, such as groupby, filter, aggregate, etc.
     * @type {string}
     * @memberof FeedsApiPostApiV2Feeds
     */
    readonly $apply?: string

    /**
     * The number of records to skip.
     * @type {number}
     * @memberof FeedsApiPostApiV2Feeds
     */
    readonly $skip?: number

    /**
     * A function that must evaluate to true for a record to be returned.
     * @type {string}
     * @memberof FeedsApiPostApiV2Feeds
     */
    readonly $filter?: string

    /**
     * Specifies a subset of properties to return. Use a comma separated list.
     * @type {string}
     * @memberof FeedsApiPostApiV2Feeds
     */
    readonly $select?: string

    /**
     * Determines what values are used to order a collection of records.
     * @type {string}
     * @memberof FeedsApiPostApiV2Feeds
     */
    readonly $orderby?: string

    /**
     * Use to add related query data.
     * @type {string}
     * @memberof FeedsApiPostApiV2Feeds
     */
    readonly $expand?: string

    /**
     * Allows clients to request a count of the matching resources included with the resources in the response.
     * @type {boolean}
     * @memberof FeedsApiPostApiV2Feeds
     */
    readonly $count?: boolean

    /**
     * 
     * @type {Feed}
     * @memberof FeedsApiPostApiV2Feeds
     */
    readonly feed?: Feed
}

/**
 * Request parameters for putApiV2FeedsKey operation in FeedsApi.
 * @export
 * @interface FeedsApiPutApiV2FeedsKeyRequest
 */
export interface FeedsApiPutApiV2FeedsKeyRequest {
    /**
     * 
     * @type {number}
     * @memberof FeedsApiPutApiV2FeedsKey
     */
    readonly key: number

    /**
     * The max number of records.
     * @type {number}
     * @memberof FeedsApiPutApiV2FeedsKey
     */
    readonly $top?: number

    /**
     * Allows to specify a sequence of transformations to the entity set, such as groupby, filter, aggregate, etc.
     * @type {string}
     * @memberof FeedsApiPutApiV2FeedsKey
     */
    readonly $apply?: string

    /**
     * The number of records to skip.
     * @type {number}
     * @memberof FeedsApiPutApiV2FeedsKey
     */
    readonly $skip?: number

    /**
     * A function that must evaluate to true for a record to be returned.
     * @type {string}
     * @memberof FeedsApiPutApiV2FeedsKey
     */
    readonly $filter?: string

    /**
     * Specifies a subset of properties to return. Use a comma separated list.
     * @type {string}
     * @memberof FeedsApiPutApiV2FeedsKey
     */
    readonly $select?: string

    /**
     * Determines what values are used to order a collection of records.
     * @type {string}
     * @memberof FeedsApiPutApiV2FeedsKey
     */
    readonly $orderby?: string

    /**
     * Use to add related query data.
     * @type {string}
     * @memberof FeedsApiPutApiV2FeedsKey
     */
    readonly $expand?: string

    /**
     * Allows clients to request a count of the matching resources included with the resources in the response.
     * @type {boolean}
     * @memberof FeedsApiPutApiV2FeedsKey
     */
    readonly $count?: boolean

    /**
     * 
     * @type {Feed}
     * @memberof FeedsApiPutApiV2FeedsKey
     */
    readonly feed?: Feed
}

/**
 * Request parameters for putApiV2FeedsKey_4 operation in FeedsApi.
 * @export
 * @interface FeedsApiPutApiV2FeedsKey0Request
 */
export interface FeedsApiPutApiV2FeedsKey0Request {
    /**
     * 
     * @type {number}
     * @memberof FeedsApiPutApiV2FeedsKey0
     */
    readonly key: number

    /**
     * The max number of records.
     * @type {number}
     * @memberof FeedsApiPutApiV2FeedsKey0
     */
    readonly $top?: number

    /**
     * Allows to specify a sequence of transformations to the entity set, such as groupby, filter, aggregate, etc.
     * @type {string}
     * @memberof FeedsApiPutApiV2FeedsKey0
     */
    readonly $apply?: string

    /**
     * The number of records to skip.
     * @type {number}
     * @memberof FeedsApiPutApiV2FeedsKey0
     */
    readonly $skip?: number

    /**
     * A function that must evaluate to true for a record to be returned.
     * @type {string}
     * @memberof FeedsApiPutApiV2FeedsKey0
     */
    readonly $filter?: string

    /**
     * Specifies a subset of properties to return. Use a comma separated list.
     * @type {string}
     * @memberof FeedsApiPutApiV2FeedsKey0
     */
    readonly $select?: string

    /**
     * Determines what values are used to order a collection of records.
     * @type {string}
     * @memberof FeedsApiPutApiV2FeedsKey0
     */
    readonly $orderby?: string

    /**
     * Use to add related query data.
     * @type {string}
     * @memberof FeedsApiPutApiV2FeedsKey0
     */
    readonly $expand?: string

    /**
     * Allows clients to request a count of the matching resources included with the resources in the response.
     * @type {boolean}
     * @memberof FeedsApiPutApiV2FeedsKey0
     */
    readonly $count?: boolean

    /**
     * 
     * @type {Feed}
     * @memberof FeedsApiPutApiV2FeedsKey0
     */
    readonly feed?: Feed
}

/**
 * FeedsApi - object-oriented interface
 * @export
 * @class FeedsApi
 * @extends {BaseAPI}
 */
export class FeedsApi extends BaseAPI implements FeedsApiInterface {
    /**
     * 
     * @param {FeedsApiDeleteApiV2FeedsKeyRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeedsApi
     */
    public deleteApiV2FeedsKey(requestParameters: FeedsApiDeleteApiV2FeedsKeyRequest, options?: AxiosRequestConfig) {
        return FeedsApiFp(this.configuration).deleteApiV2FeedsKey(requestParameters.key, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {FeedsApiDeleteApiV2FeedsKey0Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeedsApi
     */
    public deleteApiV2FeedsKey_1(requestParameters: FeedsApiDeleteApiV2FeedsKey0Request, options?: AxiosRequestConfig) {
        return FeedsApiFp(this.configuration).deleteApiV2FeedsKey_1(requestParameters.key, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {FeedsApiGetApiV2FeedsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeedsApi
     */
    public getApiV2Feeds(requestParameters: FeedsApiGetApiV2FeedsRequest = {}, options?: AxiosRequestConfig) {
        return FeedsApiFp(this.configuration).getApiV2Feeds(requestParameters.$top, requestParameters.$apply, requestParameters.$skip, requestParameters.$filter, requestParameters.$select, requestParameters.$orderby, requestParameters.$expand, requestParameters.$count, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {FeedsApiGetApiV2FeedsCountRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeedsApi
     */
    public getApiV2FeedsCount(requestParameters: FeedsApiGetApiV2FeedsCountRequest = {}, options?: AxiosRequestConfig) {
        return FeedsApiFp(this.configuration).getApiV2FeedsCount(requestParameters.$top, requestParameters.$apply, requestParameters.$skip, requestParameters.$filter, requestParameters.$select, requestParameters.$orderby, requestParameters.$expand, requestParameters.$count, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {FeedsApiGetApiV2FeedsGetAllForCompanyCompanyIdCompanyIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeedsApi
     */
    public getApiV2FeedsGetAllForCompanyCompanyIdCompanyId(requestParameters: FeedsApiGetApiV2FeedsGetAllForCompanyCompanyIdCompanyIdRequest, options?: AxiosRequestConfig) {
        return FeedsApiFp(this.configuration).getApiV2FeedsGetAllForCompanyCompanyIdCompanyId(requestParameters.companyId, requestParameters.$top, requestParameters.$apply, requestParameters.$skip, requestParameters.$filter, requestParameters.$select, requestParameters.$orderby, requestParameters.$expand, requestParameters.$count, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {FeedsApiGetApiV2FeedsGetAllForGroupAndAncestorsGroupIdGroupIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeedsApi
     */
    public getApiV2FeedsGetAllForGroupAndAncestorsGroupIdGroupId(requestParameters: FeedsApiGetApiV2FeedsGetAllForGroupAndAncestorsGroupIdGroupIdRequest, options?: AxiosRequestConfig) {
        return FeedsApiFp(this.configuration).getApiV2FeedsGetAllForGroupAndAncestorsGroupIdGroupId(requestParameters.groupId, requestParameters.$top, requestParameters.$apply, requestParameters.$skip, requestParameters.$filter, requestParameters.$select, requestParameters.$orderby, requestParameters.$expand, requestParameters.$count, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {FeedsApiGetApiV2FeedsGetAllForGroupAndDescendantsGroupIdGroupIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeedsApi
     */
    public getApiV2FeedsGetAllForGroupAndDescendantsGroupIdGroupId(requestParameters: FeedsApiGetApiV2FeedsGetAllForGroupAndDescendantsGroupIdGroupIdRequest, options?: AxiosRequestConfig) {
        return FeedsApiFp(this.configuration).getApiV2FeedsGetAllForGroupAndDescendantsGroupIdGroupId(requestParameters.groupId, requestParameters.$top, requestParameters.$apply, requestParameters.$skip, requestParameters.$filter, requestParameters.$select, requestParameters.$orderby, requestParameters.$expand, requestParameters.$count, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {FeedsApiGetApiV2FeedsGetAllForGroupGroupIdGroupIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeedsApi
     */
    public getApiV2FeedsGetAllForGroupGroupIdGroupId(requestParameters: FeedsApiGetApiV2FeedsGetAllForGroupGroupIdGroupIdRequest, options?: AxiosRequestConfig) {
        return FeedsApiFp(this.configuration).getApiV2FeedsGetAllForGroupGroupIdGroupId(requestParameters.groupId, requestParameters.$top, requestParameters.$apply, requestParameters.$skip, requestParameters.$filter, requestParameters.$select, requestParameters.$orderby, requestParameters.$expand, requestParameters.$count, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {FeedsApiGetApiV2FeedsGetAllForSiteAndGroupAncestorsSiteIdSiteIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeedsApi
     */
    public getApiV2FeedsGetAllForSiteAndGroupAncestorsSiteIdSiteId(requestParameters: FeedsApiGetApiV2FeedsGetAllForSiteAndGroupAncestorsSiteIdSiteIdRequest, options?: AxiosRequestConfig) {
        return FeedsApiFp(this.configuration).getApiV2FeedsGetAllForSiteAndGroupAncestorsSiteIdSiteId(requestParameters.siteId, requestParameters.$top, requestParameters.$apply, requestParameters.$skip, requestParameters.$filter, requestParameters.$select, requestParameters.$orderby, requestParameters.$expand, requestParameters.$count, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {FeedsApiGetApiV2FeedsGetAllForSiteAndGroupDescendantsSiteIdSiteIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeedsApi
     */
    public getApiV2FeedsGetAllForSiteAndGroupDescendantsSiteIdSiteId(requestParameters: FeedsApiGetApiV2FeedsGetAllForSiteAndGroupDescendantsSiteIdSiteIdRequest, options?: AxiosRequestConfig) {
        return FeedsApiFp(this.configuration).getApiV2FeedsGetAllForSiteAndGroupDescendantsSiteIdSiteId(requestParameters.siteId, requestParameters.$top, requestParameters.$apply, requestParameters.$skip, requestParameters.$filter, requestParameters.$select, requestParameters.$orderby, requestParameters.$expand, requestParameters.$count, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {FeedsApiGetApiV2FeedsGetAllForSiteSiteIdSiteIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeedsApi
     */
    public getApiV2FeedsGetAllForSiteSiteIdSiteId(requestParameters: FeedsApiGetApiV2FeedsGetAllForSiteSiteIdSiteIdRequest, options?: AxiosRequestConfig) {
        return FeedsApiFp(this.configuration).getApiV2FeedsGetAllForSiteSiteIdSiteId(requestParameters.siteId, requestParameters.$top, requestParameters.$apply, requestParameters.$skip, requestParameters.$filter, requestParameters.$select, requestParameters.$orderby, requestParameters.$expand, requestParameters.$count, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {FeedsApiGetApiV2FeedsGetAllGlobalForCompanyCompanyIdCompanyIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeedsApi
     */
    public getApiV2FeedsGetAllGlobalForCompanyCompanyIdCompanyId(requestParameters: FeedsApiGetApiV2FeedsGetAllGlobalForCompanyCompanyIdCompanyIdRequest, options?: AxiosRequestConfig) {
        return FeedsApiFp(this.configuration).getApiV2FeedsGetAllGlobalForCompanyCompanyIdCompanyId(requestParameters.companyId, requestParameters.$top, requestParameters.$apply, requestParameters.$skip, requestParameters.$filter, requestParameters.$select, requestParameters.$orderby, requestParameters.$expand, requestParameters.$count, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {FeedsApiGetApiV2FeedsKeyRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeedsApi
     */
    public getApiV2FeedsKey(requestParameters: FeedsApiGetApiV2FeedsKeyRequest, options?: AxiosRequestConfig) {
        return FeedsApiFp(this.configuration).getApiV2FeedsKey(requestParameters.key, requestParameters.$top, requestParameters.$apply, requestParameters.$skip, requestParameters.$filter, requestParameters.$select, requestParameters.$orderby, requestParameters.$expand, requestParameters.$count, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {FeedsApiGetApiV2FeedsKey0Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeedsApi
     */
    public getApiV2FeedsKey_2(requestParameters: FeedsApiGetApiV2FeedsKey0Request, options?: AxiosRequestConfig) {
        return FeedsApiFp(this.configuration).getApiV2FeedsKey_2(requestParameters.key, requestParameters.$top, requestParameters.$apply, requestParameters.$skip, requestParameters.$filter, requestParameters.$select, requestParameters.$orderby, requestParameters.$expand, requestParameters.$count, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {FeedsApiPatchApiV2FeedsKeyRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeedsApi
     */
    public patchApiV2FeedsKey(requestParameters: FeedsApiPatchApiV2FeedsKeyRequest, options?: AxiosRequestConfig) {
        return FeedsApiFp(this.configuration).patchApiV2FeedsKey(requestParameters.key, requestParameters.$top, requestParameters.$apply, requestParameters.$skip, requestParameters.$filter, requestParameters.$select, requestParameters.$orderby, requestParameters.$expand, requestParameters.$count, requestParameters.operation, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {FeedsApiPatchApiV2FeedsKey0Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeedsApi
     */
    public patchApiV2FeedsKey_3(requestParameters: FeedsApiPatchApiV2FeedsKey0Request, options?: AxiosRequestConfig) {
        return FeedsApiFp(this.configuration).patchApiV2FeedsKey_3(requestParameters.key, requestParameters.$top, requestParameters.$apply, requestParameters.$skip, requestParameters.$filter, requestParameters.$select, requestParameters.$orderby, requestParameters.$expand, requestParameters.$count, requestParameters.operation, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {FeedsApiPostApiV2FeedsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeedsApi
     */
    public postApiV2Feeds(requestParameters: FeedsApiPostApiV2FeedsRequest = {}, options?: AxiosRequestConfig) {
        return FeedsApiFp(this.configuration).postApiV2Feeds(requestParameters.$top, requestParameters.$apply, requestParameters.$skip, requestParameters.$filter, requestParameters.$select, requestParameters.$orderby, requestParameters.$expand, requestParameters.$count, requestParameters.feed, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {FeedsApiPutApiV2FeedsKeyRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeedsApi
     */
    public putApiV2FeedsKey(requestParameters: FeedsApiPutApiV2FeedsKeyRequest, options?: AxiosRequestConfig) {
        return FeedsApiFp(this.configuration).putApiV2FeedsKey(requestParameters.key, requestParameters.$top, requestParameters.$apply, requestParameters.$skip, requestParameters.$filter, requestParameters.$select, requestParameters.$orderby, requestParameters.$expand, requestParameters.$count, requestParameters.feed, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {FeedsApiPutApiV2FeedsKey0Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeedsApi
     */
    public putApiV2FeedsKey_4(requestParameters: FeedsApiPutApiV2FeedsKey0Request, options?: AxiosRequestConfig) {
        return FeedsApiFp(this.configuration).putApiV2FeedsKey_4(requestParameters.key, requestParameters.$top, requestParameters.$apply, requestParameters.$skip, requestParameters.$filter, requestParameters.$select, requestParameters.$orderby, requestParameters.$expand, requestParameters.$count, requestParameters.feed, options).then((request) => request(this.axios, this.basePath));
    }
}
