import { CircularProgress } from "@mui/material";
import { Icon, IconButton } from "@scaleaq/scaleui";
import { CheckMark } from "@scaleaq/scaleui-react/core/icons/xsmall";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { WorkerEntry } from "workers/workerManager";
import { getWorkerProgressAsPercentage } from "../WorkersToastContent";

export type Props = Omit<React.ComponentProps<typeof IconButton>, "type" | "Icon" | "size"> & {
  workerEntry: WorkerEntry;
};

const Container = styled.div`
  width: 30px;
  height: 30px;
  position: relative;
`;

const ProgressContainer = styled.div`
  pointer-events: none;
`;

type StyledIconButtonProps = React.ComponentProps<typeof IconButton> & {
  $hoverable: boolean;
};

const StyledIconButton = styled(IconButton).attrs<StyledIconButtonProps>((_) => ({ $hoverable: false }))`
  border-radius: 100%;
  color: inherit;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);

  &:hover {
    opacity: 100%;
    color: inherit;
  }
`;

const StyledCircularProgress = styled(CircularProgress)`
  color: inherit;
`;

const StyledCheckMark = styled(CheckMark)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export default function (props: Props) {
  const { workerEntry, ...rest } = props;
  const { t } = useTranslation();

  const hasProgress = Array.isArray(workerEntry.progress) && workerEntry.progress.length == 2;
  const isDone = hasProgress && workerEntry.progress[0] >= workerEntry.progress[1];
  const progressValue = hasProgress ? getWorkerProgressAsPercentage(workerEntry.progress) : null;

  return (
    <Container>
      {isDone ? null : (
        <StyledIconButton
          $hoverable={!workerEntry.error}
          type={"button"}
          Icon={<Icon.Small.Close />}
          size={"small"}
          title={progressValue + "%"}
          aria-label={progressValue + "%"}
          {...rest}
        />
      )}
      {workerEntry.error ? null : (
        <ProgressContainer>
          <StyledCircularProgress
            size={30}
            variant={hasProgress ? "determinate" : "indeterminate"}
            value={progressValue}
          />
          {isDone && <StyledCheckMark />}
        </ProgressContainer>
      )}
    </Container>
  );
}
