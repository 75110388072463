import { GlobalStyles } from "tss-react";
import React from "react";
import { fontConfig } from "config/fontConfig";

const ManagementPortalGlobalStyles = () => {
  return (
    <GlobalStyles
      key="global"
      styles={{
        html: {
          minHeight: "100%",
          fontSize: "62.5%",
        },
        body: {
          width: "100vw",
          minHeight: "100vh",
          maxWidth: "100vw",
          overscrollBehavior: "none",
          overflowX: "hidden",
          backgroundColor: "#fff",
        },

        ".MuiBreadcrumbs-ol": {
          ".MuiBreadcrumbs-li": {
            "& a": {
              color: "#0C6596",
            },
            "&:last-child a": {
              color: "#41474B",
              textDecoration: "none",
            },
          },
        },
        ".tk-proxima-nova": {
          fontFamily: `${fontConfig.secondary.family}`,
        },
        "input::-webkit-outer-spin-button, input::-webkit-inner-spin-button": {
          WebkitAppearance: "none",
        },
        "input[type='password']::-ms-reveal, input[type='password']::-ms-clear": {
          display: "none",
          margin: 0,
        },
        "input[type='number']": {
          MozAppearance: "textfield",
        },
        ":focus-visible": {
          outline: "none",
        },
      }}
    />
  );
};

export default React.memo(ManagementPortalGlobalStyles);
