import { AccessAreaName } from "api/clients/accessApiV1Client";

export const scaleRoles = {
  admin: "scaleaq/administrator",
  supportAgent: "scaleaq/support_agent",
  accountManager: "scaleaq/account_manager",
};

export const managementPortalAreas: AccessAreaName[] = [
  AccessAreaName.GosUsers,
  AccessAreaName.GosOperations,
  AccessAreaName.GosLicense,
  AccessAreaName.GosLookups,
];
