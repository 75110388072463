import styled from "styled-components";

const ActiveWorkerContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 1rem 0;
  flex-wrap: wrap;
  position: relative;
  align-items: center;
`;

export default ActiveWorkerContainer;
