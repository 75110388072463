import { Icon, IconButton } from "@scaleaq/scaleui";
import styled from "styled-components";

export type Props = {
  onToggle: React.ComponentProps<typeof IconButton>["onClick"];
  onCancel: React.ComponentProps<typeof IconButton>["onClick"];
  open: boolean;
};

const Container = styled.div`
  display: flex;
`;

const StyledIconButton = styled(IconButton)`
  color: inherit;

  &:hover {
    color: inherit;
  }
`;

export default function ActiveWorkersTrayButtons(props: Props) {
  return (
    <Container>
      <StyledIconButton
        type={"button"}
        Icon={props.open ? <Icon.Small.ChevronUp /> : <Icon.Small.ChevronDown />}
        size={"small"}
        onClick={props.onToggle}
      />
      <StyledIconButton type={"button"} Icon={<Icon.Small.Close />} size={"small"} onClick={props.onCancel} />
    </Container>
  );
}
